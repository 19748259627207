import { ChangeDetectorRef, Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, TransferState, makeStateKey } from '@angular/core';
import { Observable, forkJoin, from } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import { StockDataService } from '../../stock-data.service';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/modules/stock';
import { Meta, Title  } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router'; // Import RouterModule

const ndq_DATA_KEY = makeStateKey<any>('ndq-data');  // Key to store stock data in TransferState
const sp1500_DATA_KEY = makeStateKey<any>('sp1500-data');  // Key to store stock data in TransferState
const DATA_KEY = makeStateKey<any>('data');  // Key to store stock data in TransferState

const cloudCrusaders_DATA_KEY = makeStateKey<any>('cloudCrusaders-data');
const dividendStocks_DATA_KEY = makeStateKey<any>('dividendStocks-data');
const evCharging_DATA_KEY = makeStateKey<any>('evCharging-data');
const pharmaPowerhouses_DATA_KEY = makeStateKey<any>('pharmaPowerhouses-data');
const digitalPayments_DATA_KEY = makeStateKey<any>('digitalPayments-data');
const industrialWaterTreatment_DATA_KEY = makeStateKey<any>('industrialWaterTreatment-data');
const electricAviationAndAirMobility_DATA_KEY = makeStateKey<any>('electricAviationAndAirMobility-data');
const smartFarmingAndIoTAgriculture_DATA_KEY = makeStateKey<any>('smartFarmingAndIoTAgriculture-data');



// Initialize the Stock module
if (typeof window !== 'undefined') {
  HighchartsStock(Highcharts);
}

@Component({
  selector: 'app-stock-display',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './stock-display.component.html',
  styleUrl: './stock-display.component.css'
})
export class StockDisplayComponent {
  @Input() content: string | undefined;
  @Input() stocks: string[] | undefined;
  @Input() title!: string;
  @Input() type!: string;
  @Input() img: string | undefined;
  @Input() seoTitle!: string;
  @Input() seoDescription!: string;
  @Input() index: boolean = false;
  @Input() stockDetails!: { [key: string]: any };


  Highcharts: typeof Highcharts = Highcharts;

  stockData: any[] = []; // To store processed stock data
  symbols: any[] = [];
  profileData: any[] = []; // To store processed stock data
  loading: boolean = false;
  isBrowser: boolean;

    // Cloud Crusaders
  cloudCrusaders = [
    "AMZN", "MSFT", "SNOW", "GOOGL", "CRM", "ORCL", "IBM", "ADBE", "NOW", 
    "WDAY", "NTNX", "ZS", "DDOG", "TEAM", "OKTA"
  ];

  // Dividends Forever
  dividendStocks = [
    "KO", "O", "PG", "JNJ", "PEP", "XOM", "CVX", "MMM", "T", "VZ", "MO", 
    "DUK", "SO", "WPC", "PPL"
  ];

  // EV Charging Superstars
  evCharging = [
    "CHPT", "BLNK", "EVGO", "TSLA", "SBEV", "WBX", "TSP", 
    "PLUG", "ENPH", "RUN", "FSR", "ARVL"
  ];

  // Pharma Powerhouses
  pharmaPowerhouses = [
    "PFE", "LLY", "MRK", "BMY", "GILD", "JNJ", "NVS", "AZN", "BIIB", 
    "AMGN", "REGN", "VRTX", "SNY", "ABBV", "RHHBY"
  ];

  // Digital Payments Dynamos
  digitalPayments = [
    "V", "PYPL", "SQ", "MA", "ADYEY", "FISV", "GPN", "FLT", "FOUR", "NU", 
    "AFRM", "SHOP", "STNE", "MELI", "WISE"
  ];

  // Industrial Water Treatment
  industrialWaterTreatment = [
    "XOM", "AOS", "ITT", "MTRN", "WTRG", "XPO", "EFX", 
    "SHW", "PLNT", "AWK", "BWA"
  ];

  // Electric Aviation and Air Mobility
  electricAviationAndAirMobility = [
    "HEXO", "BA", "UAL", "AAL", "NIO", "AAPL", "EVTL", "JOBY", "SPCE", 
    "ACIC", "PLTR", "LHX", "TRMB", "GE", "CVX"
  ];

  // Smart Farming and IoT Agriculture
  smartFarmingAndIoTAgriculture = [
    "DE", "SYT", "AGCO", "MSFT", "TSM", "NTR", "IOT", "TTC", "ADHI", 
    "TTD", "DOW", "NKE", "BA", "SYF"
  ];
  public highChart: any;
  public sectorChart: any;
  public industryChart: any;
  chart = null;


  constructor(private stockDataService:StockDataService, @Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  async ngOnInit(): Promise<void> {
    this.route.data.subscribe((data: any) => {
      this.stockData = data;  // The stock data is directly available here
      
      if( this.stockData.length>0 && this.isBrowser) {
        this.renderCharts();
        this.renderHighcharts();
      }


      this.changeDetectorRef.detectChanges();
      if (this.stockData.length>0 &&this.isBrowser&& this.highChart && typeof this.highChart.redraw === 'function') {
        this.updateHighcharts();
      }

      if (this.stockData.length>0 && this.isBrowser && this.sectorChart && typeof this.sectorChart.redraw === 'function') {
        this.updateSectorChart();
      }

      if (this.stockData.length>0 && this.isBrowser && this.industryChart && typeof this.industryChart.redraw === 'function') {
        this.updateIndustryChart();
      }
    });
    
  }

  async ngOnChanges(): Promise<void> {
    const data = await this.stockDataService.getStockLists(this.type).toPromise();

    this.stockData = data;  // The stock data is directly available here
    if( this.stockData.length>0  && this.isBrowser) {
      this.renderCharts();
      this.renderHighcharts();
    }

    this.changeDetectorRef.detectChanges();
    if (this.stockData.length>0 && this.highChart && typeof this.highChart.redraw === 'function') {
      this.updateHighcharts();
    }

    if (this.stockData.length>0 && this.sectorChart && typeof this.sectorChart.redraw === 'function') {
      this.updateSectorChart();
    }

    if (this.stockData.length>0 && this.industryChart && typeof this.industryChart.redraw === 'function') {
      this.updateIndustryChart();
    }
  }

  fetchStockData(stockArray: string[]): void {
    this.loading = true;
  
    const batchSize = 200; // Adjust based on system capacity
    const batchedArray = this.chunkArray(stockArray, batchSize);
  
    const batchedRequests = batchedArray.map((batch) => {
      const requests = batch.map((ticker: string) => {
        if (this.index) {
          return this.stockDataService.getStockRealTimeMetrics(ticker).pipe(
            
            map((response) => ({
              symbol: (response[0] && response[0].symbol) ? response[0].symbol: ticker,
              price: (response[0] && response[0].price) ?  response[0].price: 0,
              PE: (response[0] && response[0].PE) ? response[0].PE: 0,
              EPS: (response[0] && response[0].EPS) ? response[0].EPS: 0,
            }))
          );
        } else {
          return this.stockDataService.getStockData(ticker, this.stockDataService.OneYearAgo).pipe(
            map((response) => {
              const historical = response.historical.reverse() || [];
              if (historical.length > 1) {
                let cumulativeReturn = 0;
                let cumulativeReturns = historical.reverse().map((day: any, index: number) => {
                  const previousClose = index === 0 ? day.close : historical[index - 1].close;
                  const dailyReturn = ((day.close - previousClose) / previousClose) * 100;
                  cumulativeReturn += dailyReturn;
                  return [
                    new Date(day.date).getTime(),
                    cumulativeReturn,
                  ];
                }).filter((point: any) => point[1] !== null);
  
                const latest = historical[0];
                const last10Days = historical.slice(0, 10);
                const avgVol = last10Days.reduce((sum: any, day: any) => sum + day.volume, 0) / last10Days.length;
  
                return {
                  symbol: response.symbol,
                  cumulativeReturns,
                  close: latest.close,
                  change: latest.change,
                  changePercent: latest.changePercent,
                  volume: latest.volume,
                  avgVolume: avgVol,
                  vwap: latest.vwap,
                };
              }
              return null;
            })
          );
        }
      });
      return forkJoin(requests);
    });
  
    // Execute batched requests sequentially
    this.executeBatchesSequentially(batchedRequests).subscribe(
      (data) => {
        this.stockData = data.flat().filter((item) => item !== null);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching stock data:', error);
        this.loading = false;
      }
    );
  }
  

  renderHighcharts(): void {
    const seriesData: Highcharts.SeriesOptionsType[] = this.stockData.map((stock: any) => ({
      name: stock.symbol,
      type: 'line', // Explicitly set the series type
      data: stock.cumulativeReturns, // Ensure this is in the format [timestamp, value]
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%',
      },
    }));
  
    const options: Highcharts.Options = {
      rangeSelector: {
        selected: 3,
      },
      title: {
        text: 'Cumulative Stock Returns Over the Last Year',
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
      },
      yAxis: {
        labels: {
          format: '{value}%',
        },
        title: {
          text: 'Daily Returns (%)',
        },
        opposite: false,
      },
      tooltip: {
        shared: true,
        split: false,
      },
      series: seriesData,
      legend: {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
      },
      credits: {
        enabled: false, // Disable Highcharts branding
      },
    };
  
    this.highChart = Highcharts.stockChart('container', options);

  }
  

  fetchProfileData(stockArray: string[]): void {
    this.loading = true;
  
    const batchSize = 50; // Adjust based on system capacity
    const batchedArray = this.chunkArray(stockArray, batchSize);
    const batchedRequests = batchedArray.map((batch) =>
      forkJoin(batch.map((ticker: string) =>
        this.stockDataService.getProfile(ticker).pipe(
          map((response) => response[0][ticker]) // Assuming the response is an array with one object
        )
      ))
    );
  
    this.executeBatchesSequentially(batchedRequests).subscribe(
      (data) => {
        this.profileData = data.flat();
        // Attach the element name to stock data
        this.stockData = this.stockData.map((stock) => {
          const profile = this.profileData.find((profile) => (profile?.Symbol ? profile?.Symbol: "") === stock.symbol);
          if (profile) {
            return {
              ...stock,
              name: (profile && profile.companyName)? profile.companyName: "",
              exchangeShortName: (profile && profile.exchangeShortName) ? profile.exchangeShortName: "",
              Beta: (profile && profile.Beta) ? profile.Beta: 0,
              avgVol: (profile && profile.VolAvg) ? profile.VolAvg: 0,
            };
          }
          return stock;
        });
  
        this.loading = false;
  
        if (this.isBrowser && !this.index) {
          this.renderCharts();
          this.renderHighcharts();
        }
      },
      (error) => {
        console.error('Error fetching profile data:', error);
        this.loading = false;
      }
    );
  }

  private chunkArray(array: any[], chunkSize: number): any[][] {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
  
  private executeBatchesSequentially(batchedRequests: Observable<any[]>[]): Observable<any[]> {
    return from(batchedRequests).pipe(
      concatMap((batch) => batch), // Process one batch at a time
      toArray() // Collect all results into a single array
    );
  }
    

    renderCharts(): void {
      // Group by sector
      const sectorCounts = this.groupByField(this.stockData, 'sector');
      const sectorChartData = this.formatChartData(sectorCounts);
  
      // Group by industry
      const industryCounts = this.groupByField(this.stockData, 'industry');
      const industryChartData = this.formatChartData(industryCounts);
  
      // Render sector chart
      const sectorChartOptions: Highcharts.Options = {
          chart: {
              type: 'pie'
          },
          title: {
              text: 'Stock Sectors'
          },
          plotOptions: {
              pie: {
                  innerSize: '50%',
                  dataLabels: {
                      enabled: true,
                      format: '{point.name}: {point.percentage:.1f} %'
                  }
              }
          },
          series: [{
              type: 'pie',
              name: 'Sector',
              data: sectorChartData,
              showInLegend: true
          }]
      };
      this.sectorChart = Highcharts.chart('sector-container', sectorChartOptions);
  
      // Render industry chart
      const industryChartOptions: Highcharts.Options = {
          chart: {
              type: 'pie'
          },
          title: {
              text: 'Stock Industries'
          },
          plotOptions: {
              pie: {
                  innerSize: '50%',
                  dataLabels: {
                      enabled: true,
                      format: '{point.name}: {point.percentage:.1f} %'
                  }
              }
          },
          series: [{
              type: 'pie',
              name: 'Industry',
              data: industryChartData,
              showInLegend: true
          }]
      };
      this.industryChart =Highcharts.chart('industry-container', industryChartOptions);
      
    }
  

    groupByField(data: any[], field: string): Record<string, number> {
      return data.reduce((acc, item) => {
        const key = item[field];
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

    }

    formatChartData(counts: Record<string, number>): { name: string; y: number }[] {
      const total = Object.values(counts).reduce((sum, count) => sum + count, 0);
      return Object.entries(counts).map(([name, count]) => ({
        name,
        y: (count / total) * 100
      }));
    }

    updateHighcharts(): void {
      const seriesData: Highcharts.SeriesOptionsType[] = this.stockData.map((stock: any) => ({
        name: stock.symbol,
        type: 'line',
        data: stock.cumulativeReturns,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: '%',
        },
      }));
  
      if (this.highChart) {
        this.highChart.update({
          series: seriesData
        });
        this.highChart.redraw();
      }
    }
  
    updateSectorChart(): void {
      const sectorCounts = this.groupByField(this.stockData, 'sector');
      const sectorChartData = this.formatChartData(sectorCounts);
  
      if (this.sectorChart) {
        this.sectorChart.series[0].setData(sectorChartData);
      }
    }
  
    updateIndustryChart(): void {
      
      const industryCounts = this.groupByField(this.stockData, 'industry');
      const industryChartData = this.formatChartData(industryCounts);
  
      if (this.industryChart) {
        this.industryChart.series[0].setData(industryChartData);
      }
    }
  }

import { Component, OnInit, SimpleChanges , ViewChild, ElementRef, Input, ChangeDetectorRef, PLATFORM_ID, Inject } from '@angular/core';
import { Chart, registerables } from 'chart.js/auto';
import { StockDataService } from '../stock-data.service';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AnalystEstimateComponent } from '../analyst-estimate/analyst-estimate.component';
import { NewsBoxComponent } from '../news-box/news-box.component';
import { ChartStripComponent } from '../chart-strip/chart-strip.component';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { TickerTapeComponent } from '../ticker-tape/ticker-tape.component';
import { HighchartsChartModule } from 'highcharts-angular';
//import * as Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';

import IndicatorsCore from 'highcharts/indicators/indicators';
import vbp from 'highcharts/indicators/volume-by-price';
import atr from 'highcharts/indicators/atr';
import ema from 'highcharts/indicators/ema';
import stockTools from 'highcharts/modules/stock-tools';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import DragPanes from 'highcharts/modules/drag-panes';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import PriceIndicator from 'highcharts/modules/price-indicator';
import FullScreen from 'highcharts/modules/full-screen';
import StockTools from 'highcharts/modules/stock-tools';
import HeikinAshi from 'highcharts/modules/heikinashi';
import HollowCandlestick from 'highcharts/modules/hollowcandlestick';
import Accessibility from 'highcharts/modules/accessibility';
import { Meta, Title } from '@angular/platform-browser';

interface CustomOptions extends Highcharts.Options {
  indicators?: any[];
}

interface FinancialData {
  date: string;
  symbol: string;
  [key: string]: any; // Index signature for dynamic keys
}

@Component({
  selector: 'app-stock-chart',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, AnalystEstimateComponent, NewsBoxComponent, ChartStripComponent, NgxSkeletonLoaderModule, TickerTapeComponent, HighchartsChartModule,],
  templateUrl: './stock-chart.component.html',
  styleUrl: './stock-chart.component.css'
})
export class StockChartComponent implements OnInit {
  @ViewChild('stockChart') stockChartRef!: ElementRef<HTMLCanvasElement>;
  
  public chart: any
  id: string = '';
  incomeStatementData: FinancialData[] = [];
  incomeStatementTableHeaders: string[] = [];
  metricsData: FinancialData[] = [];
  metricsTableHeaders: string[] = [];
  profileData: FinancialData[] = [];
  profileTableHeaders: string[] = [];
  activeButtonIndex: number | null = 0;
  image: string = '';
  hoveredColumn: number = -1;
  lastPx: number = -1;
  lastClose: number = -1;
  name: string = '';
  percentChg: number = -1;
  selectedTimeframe: string = '1D'; // Default value
  todayISOString: string='';
  oneWeekAgoISOString: string='';
  oneMonthAgoISOString: string = '';
  threeMonthAgoISOString: string = '';
  sixMonthAgoISOString: string = '';
  oneYearAgoISOString: string='';
  fiveYearsAgoISOString: string = '';
  previousID: string = '';
  peers: string[]=[];
  peersData: any = {}
  fromDate: string = '';
  toDate: string = '';
  exch: string = '';
  filings: any = [];
  filterText: string = '';
  display: boolean = true;
  isMobileMenuOpen: boolean = false;
  displayCount = 10; // Number of filings to display initially
  metrics: string[] = [
  'peRatio',
  'enterpriseValueOverEBITDA',
  'evToFreeCashFlow',
  'roic',
  'netCurrentAssetValue',
  'bookValuePerShare',
  'priceToSalesRatio',
  'netIncomePerShare'
  ];
/*  Highcharts: typeof Highcharts = Highcharts;*/
  chartOptions: Highcharts.Options | undefined;
  isBrowser: boolean;

  constructor(
    private stockDataService: StockDataService, 
    private route: ActivatedRoute, 
    private elementRef: ElementRef, 
    private router: Router, @Inject(PLATFORM_ID) 
    private platformId: Object, 
    private titleService: Title, 
    private metaService: Meta, ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if( this.isBrowser) {
      this.chartOptions = {};
    } 

    Chart.register(...registerables);
    if (this.isBrowser && typeof window !== 'undefined') {
      IndicatorsCore(Highcharts);
      vbp(Highcharts);
      atr(Highcharts);
      ema(Highcharts);
      stockTools(Highcharts);
      // Initialize the modules
      IndicatorsAll(Highcharts);
      DragPanes(Highcharts);
      AnnotationsAdvanced(Highcharts);
      PriceIndicator(Highcharts);
      FullScreen(Highcharts);
      StockTools(Highcharts);
      HeikinAshi(Highcharts);
      HollowCandlestick(Highcharts);
      Accessibility(Highcharts);
    }
  }

 

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: any) => {
        // Use switchMap to handle asynchronous operations
        this.id = params.get('id').toUpperCase();
        this.exch = params.get('exch').toUpperCase();
        // Return a new observable (in this case, using of to emit the id synchronously)
        return of({ id: this.id, exch: this.exch });
      })
    ).subscribe(({ id, exch }: { id: string, exch: string }) => {
      this.id = id;
      this.exch = exch;



      if (this.exch === 'CRYPTO' || this.exch === 'FOREX' || this.exch === 'COMMODITY') {
        this.display = false;
      } else {
        this.display = true;
      }
      const market = this.exch;
      const description = ` ${this.id} operates on the ${market} exchange, providing financial insights including key statements, analyst estimates, and news updates.`;

      const title = `${this.id} Financials & Stock Analysis on ${market} Exchange`;
      
      // Set the dynamic title and description
      this.titleService.setTitle(title); // Set the page title dynamically

      // Set the meta description dynamically
      this.metaService.updateTag({
        name: 'description',
        content: description
      });
      const todayISOString = this.stockDataService.lastWrkDay;
      // Create a Date object for midnight of the current date
      const today = new Date(todayISOString);
      const fiveYearsAgo = new Date(today);
      fiveYearsAgo.setFullYear(today.getFullYear() - 5);
      this.fiveYearsAgoISOString = fiveYearsAgo.toISOString().split('T')[0];

      // ISO string for today
      this.todayISOString = today.toISOString().split('T')[0];

      this.getLastPx('1min');
      this.setActiveButton(0);
      this.previousID = this.id;
      this.peersData = {};
      this.renderPeers();
      this.renderChart(this.fiveYearsAgoISOString);
      //this.renderIntradayChart('1min', this.todayISOString);
      //this.renderMetrics();
      //this.renderProfile();
    });


    const todayISOString = this.stockDataService.lastWrkDay;

    // Create a Date object for midnight of the current date
    const today = new Date(todayISOString);
   

    // ISO string for 1 week ago
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    this.oneWeekAgoISOString = oneWeekAgo.toISOString().split('T')[0];

    // ISO string for 1 month ago
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);
    this.oneMonthAgoISOString = oneMonthAgo.toISOString().split('T')[0];

    // ISO string for 3 month ago
    const threeMonthAgo = new Date(today);
    threeMonthAgo.setMonth(today.getMonth() - 3);
    this.threeMonthAgoISOString = threeMonthAgo.toISOString().split('T')[0];

    // ISO string for 6 month ago
    const sixMonthAgo = new Date(today);
    sixMonthAgo.setMonth(today.getMonth() - 6);
    this.sixMonthAgoISOString = sixMonthAgo.toISOString().split('T')[0];

    // ISO string for 1 year ago
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    this.oneYearAgoISOString = oneYearAgo.toISOString().split('T')[0];

    // ISO string for 5 years ago
    const fiveYearsAgo = new Date(today);
    fiveYearsAgo.setFullYear(today.getFullYear() - 5);
    this.fiveYearsAgoISOString = fiveYearsAgo.toISOString().split('T')[0];

    this.fromDate = this.fiveYearsAgoISOString;
    this.toDate = this.todayISOString;
  }

  ngAfterViewInit(): void {
    this.setActiveButton(0);
  }

  updateSelectedTimeframe(timeframe: string): void {
    this.selectedTimeframe = timeframe;
    // Perform any additional actions based on the selected timeframe
    this.changeTimeframe();
  }

  setActiveButton(index: number): void {
    if (this.isBrowser && typeof window !== 'undefined' && window.innerWidth <= 1100) { // 68.75em * 16px = 1100px
      this.isMobileMenuOpen = false;
    }

    if (this.activeButtonIndex !== index || this.previousID!== this.id) {
      this.activeButtonIndex = index;
      this.clearChart();
      this.clearIncomeStatementData();
      this.handleActiveButtonChange();
    }
  }

  async switchPeers(ticker: string): Promise<void> {
    const stockCandidates = await this.stockDataService.stockList().toPromise();
    const peer = stockCandidates.find((candidate: any) => candidate.symbol.toUpperCase() === ticker.toUpperCase());
    this.router.navigate(['chart', peer.symbol, peer.exchangeShortName]);
  }

  changeTimeframe(): void {
    // Implement your logic based on selectedTimeframe using a switch statement
    this.clearChart();

    switch (this.selectedTimeframe) {
      case '1W':
        this.renderChart(this.fiveYearsAgoISOString);
        break;
      case '1M':
        this.renderChart(this.oneMonthAgoISOString);
        break;
      case '3M':
        this.renderChart(this.threeMonthAgoISOString);
        break;
      case '6M':
        this.renderChart(this.sixMonthAgoISOString);
        break;
      case '1Y':
        this.renderChart(this.oneYearAgoISOString);
        break;
      case '5Y':
        this.renderChart(this.fiveYearsAgoISOString);
        break;
      default: // Default to '1D'
        this.renderChart(this.fiveYearsAgoISOString);
        break;
    }
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  handleActiveButtonChange() {
    // Perform actions based on the change in activeButtonIndex
    switch (this.activeButtonIndex) {
      case 0:
        this.changeTimeframe();
        this.renderMetrics();
        this.renderProfile();
        break;
      case 1:
        this.renderIncomeStatement();
        break;
      case 2:
        this.renderBalanceStatement();
        break;
      case 3:
        this.renderCashStatement();
        break;
      case 4:
        this.renderCashStatement();
        break;
      case 5:
        this.renderPeers();
        break;
      case 6:
        this.renderFilings();
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  }

  clearChart(): void {
    if (this.chart !== undefined) {
      this.chart.destroy();
    }
    this.chart = undefined;
  }

  clearIncomeStatementData() {
    this.incomeStatementData = []; // Clear the data array when showIncome is false
  }

  isDateInRange(date: string): boolean {
    const fromDateObj = new Date(this.fromDate);
    const toDateObj = new Date(this.toDate);
    const recordDateObj = new Date(date);
    return recordDateObj >= fromDateObj && recordDateObj <= toDateObj;
  }


  renderIncomeStatement(): void {
    
    this.stockDataService.getIncomeStatement(this.id).subscribe((response: FinancialData[]) => {
      this.incomeStatementData = Object.values(response[0]).reverse();
      this.incomeStatementTableHeaders = Object.keys(this.incomeStatementData[0]).filter(key => key !== 'date' && key !== 'symbol');
    },
      (error) => {
        console.error('Error fetching data:', error);
      });
  }

  renderBalanceStatement(): void {
    this.stockDataService.getBalanceStatement(this.id).subscribe((response: FinancialData[]) => {
      this.incomeStatementData =  Object.values(response[0]).reverse();
      this.incomeStatementTableHeaders = Object.keys(this.incomeStatementData[0]).filter(key => key !== 'date' && key !== 'symbol');
    },
      (error) => {
        console.error('Error fetching data:', error);
      });
  }

  renderCashStatement(): void {
    this.stockDataService.getCashStatement(this.id).subscribe((response: FinancialData[]) => {
      this.incomeStatementData =  Object.values(response[0]).reverse();
      this.incomeStatementTableHeaders = Object.keys(this.incomeStatementData[0]).filter(key => key !== 'date' && key !== 'symbol');
    },
      (error) => {
        console.error('Error fetching data:', error);
      });
  } 

  shouldSkipHeader(header: string): boolean {
    const headersToSkip = ['_id', 'reportedCurrency', 'cik', 'acceptedDate'];
    return headersToSkip.includes(header);
  }

  shouldBeDisplayed(key: string): boolean {
    // List of keys you want to display
    const allowedKeys = [
      'freeCashFlowPerShare',
      'peRatio',
      'freeCashFlowYield',
      'roic',
      'payoutRatio',
      'beta',
      'VolAvg',
      'LastDiv',
      'MktCap',
      'industry',
      'Price', 
      'change', 'dayLow',
      'dayHigh', 'yearHigh',
      'yearLow', 'marketCap',
      'priceAvg50', 'priceAvg200', 'volume',
      'avgVolume', 'open',
      'previousClose'
      // Add other keys if needed
    ];

    return allowedKeys.includes(key);
  }

  isNumeric(value: any): boolean {
    return !isNaN(value);
  }

  renderMetrics(): void {
    if (this.exch === 'CRYPTO') {
      let metricsFields = ['price', 'changesPercentage',
        'change', 'dayLow',
        'dayHigh', 'yearHigh',
        'yearLow', 'marketCap'];
      let profileFields = ['priceAvg50', 'priceAvg200', 'volume',
        'avgVolume', 'open',
        'previousClose',
        'sharesOutstanding', 'name'];

      this.stockDataService.getCryptoData(this.id).subscribe((response: FinancialData[]) => {
        const filteredResponse = [metricsFields.reduce((acc: any, key: any) => {
            acc[key] = response[0][key];
            return acc;
        }, {})];

        const filteredResponse2 = [{
          [this.id]: profileFields.reduce((acc: any, key: any) => {
            acc[key] = response[0][key];
            return acc;
          }, {})
        }] as FinancialData[];

        this.metricsData = filteredResponse;
        this.profileData = filteredResponse2;

        this.metricsTableHeaders = Object.keys(this.metricsData[0]);
        this.profileTableHeaders = Object.keys(this.profileData[0][this.id]);
        //this.image = '';
        this.name = this.profileData[0][this.id]['name'] + '(' + this.id + ')';
      });

    } else if (this.exch === 'FOREX') {
      let metricsFields = ['price', 'changesPercentage',
        'change', 'dayLow',
        'dayHigh', 'yearHigh',
        'yearLow', 'marketCap'];
      let profileFields = ['priceAvg50', 'priceAvg200', 'volume',
        'avgVolume', 'open',
        'previousClose',
        'sharesOutstanding', 'name'];

      this.stockDataService.getForexData(this.id).subscribe((response: FinancialData[]) => {
        const filteredResponse = [metricsFields.reduce((acc: any, key: any) => {
          acc[key] = response[0][key];
          return acc;
        }, {})];

        const filteredResponse2 = [{
          [this.id]: profileFields.reduce((acc: any, key: any) => {
            acc[key] = response[0][key];
            return acc;
          }, {})
        }] as FinancialData[];

        this.metricsData = filteredResponse;
        this.profileData = filteredResponse2;

        this.metricsTableHeaders = Object.keys(this.metricsData[0]);
        this.profileTableHeaders = Object.keys(this.profileData[0][this.id]);
        //this.image = '';
        this.name = this.profileData[0][this.id]['name'] + '(' + this.id + ')';
      });
    } else {
      this.stockDataService.getKeyMetrics(this.id).subscribe((response: FinancialData[]) => {
        this.metricsData = response;
        this.metricsTableHeaders = Object.keys(this.metricsData[0]).filter(key => key !== 'date' && key !== 'symbol');

      },
        (error) => {
          console.error('Error fetching data:', error);
        });
    }
  }

  renderProfile(): void {
    if (this.exch !== 'FOREX' && this.exch !== 'CRYPTO') {
      this.stockDataService.getProfile(this.id).subscribe((response: FinancialData[]) => {
        let symbol = response[0]['ticker'];
        this.profileData = response;

        this.image = this.profileData[0][symbol]['image'];
        this.name = this.profileData[0][symbol]['companyName'] + '(' + symbol + ')';

        this.profileTableHeaders = Object.keys(this.profileData[0][symbol]).filter(key => key !== 'date' && key !== 'Symbol');
      },
        (error) => {
          console.error('Error fetching data:', error);
        });
    }
    
  }

  async renderPeers(): Promise<void> {
    const data = await this.stockDataService.stockPeers(this.id).toPromise();
    
    // Use a fallback to an empty array if peersList is undefined
    this.peers = data?.[0]?.peersList || [];
    this.peers = [this.id, ...this.peers];
  
    for (let stock of this.peers) {
      try {
        const temp = await this.stockDataService.getKeyMetrics(stock).toPromise();
        const temp2 = await this.stockDataService.getProfile(stock).toPromise();
        const realTime = await this.stockDataService.getStockRealTimeData(stock).toPromise();
  
        // Fetch the exchangeShortName from stockCandidates
        const stockCandidates = await this.stockDataService.stockList().toPromise();
        const stockData = stockCandidates.find((candidate: any) => candidate.symbol.toUpperCase() === stock.toUpperCase());
  
        this.peersData[stock] = {
          ...temp?.[0] || {},  // Use an empty object if temp[0] is undefined
          image: temp2?.[0]?.[stock]?.image || '',  // Fallback to an empty string
          name: temp2?.[0]?.[stock]?.companyName || 'Unknown Company',  // Fallback to 'Unknown Company'
          price: realTime?.[0]?.price || 0,  // Fallback to 0
          exchangeShortName: stockData?.exchangeShortName || ''  // Fallback to an empty string
        };
      } catch (error) {
        console.error(`Failed to fetch data for stock ${stock}:`, error);
        // You could optionally add a placeholder or error message for this stock in peersData
      }
    }
  }
  

  async renderFilings(): Promise<void> {
    this.filings = await this.stockDataService.getFilings(this.id).toPromise();
  }

  // Method to load more items
  loadMore() {
    this.displayCount += 10; // Increase by 10 each time
  }

  getLastPx(timeframe: string): void {
    this.stockDataService.getStockData(this.id, this.oneYearAgoISOString).subscribe(data => {
      const stockData = data.historical.map((item: any) => ({
        date: new Date(item.date),
        price: item.close

      }));
      this.lastPx = stockData[0].price;
      this.lastClose = stockData[1].price;
      this.percentChg = (this.lastPx - this.lastClose) / this.lastClose
    });
  }

  renderChart(from:string): void {
    if(this.isBrowser && typeof window !== 'undefined' ) {
      this.stockDataService.getStockData(this.id,from).subscribe(data => {
        const stockData = data.historical. map((item: any) => ({
          date: new Date(item.date),
          close: item.close,
          low: item.low,
          high: item.high,
          open: item.open,
          volume: item.volume
        }));
        const filteredStockData = stockData.filter((item: any) => {
          const date = item.date;
          // Check if the date is greater than today
          const isAfterDate = date > new Date(from);
          // Check if the time is between 9:30am and 4pm
          return isAfterDate;
        });
  
        // Sort the filteredStockData by date (ascending order)
        filteredStockData.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
  
        const dates = filteredStockData.map((item: any) => item.date);
        //const prices = filteredStockData.map((item: any) => item.price);
        const prices = filteredStockData.map((item: any) => item.close);
        const priceData = filteredStockData.map((item: any) => [
          new Date(item.date).getTime(), // Convert date to timestamp
          item.open,
          item.high,
          item.low,
          item.close
        ]);
  
        const volumeData = filteredStockData.map((item: any) => [
          new Date(item.date).getTime(), // Convert date to timestamp
          item.volume
        ]);
  
  
        var ctx = 'stockChart'; // jQuery instance
        var backgroundColor1 = '';
        var borderColor1 = '';
        if (prices[0] - prices[prices.length-1] >= 0) {
          backgroundColor1 = 'rgba(0, 180, 0, 0.25)';
          borderColor1 = 'rgba(0, 150, 0)';
        } else {
          backgroundColor1 = 'rgba(180, 0, 0, 0.25)';
          borderColor1 = 'rgba(150, 0, 0)';
        }
  
        const isSmallScreen = window.innerWidth <= 1100; // 68.75em * 16px = 1100px
        const chartOptions: CustomOptions = {
          chart: {
            style: {
              fontSize: isSmallScreen ? '200%' : '100%'
            }
          },
          yAxis: [{
            labels: {
              align: 'left'
            },
            height: '100%',
            resize: {
              enabled: true
            }
          }, {
            labels: {
              align: 'left'
            },
            top: '80%',
            height: '20%',
            offset: 0
          }],
          tooltip: {
            headerShape: 'callout',
            borderWidth: 0,
            shadow: false,
            positioner: function (width, height, point) {
              const chart = this.chart;
              let position;
  
              if (point.isHeader) {
                position = {
                  x: Math.max(
                    // Left side limit
                    chart.plotLeft,
                    Math.min(
                      point.plotX + chart.plotLeft - width / 2,
                      // Right side limit
                    )
                  ),
                  y: point.plotY
                };
              } else {
                position = {
                  x: point.series.chart.plotLeft,
                  y: point.series.chart.plotLeft
                };
              }
  
              return position;
            }
          },
          rangeSelector: {
            buttonSpacing: isSmallScreen ? 15 : 10
          },
          series: [{
            type: 'area',
            id: `${this.id}-ohlc`,
            
            name: `${this.id} Stock Price`,
            data: priceData,
            color: 'blue', // Color for down days
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, 'rgba(0, 100, 200, 0.5)'], // Lighter shade at the top
                  [1, 'rgba(0, 100, 200, 0.1)']  // Lighter shade at the bottom
              ]
            }
          }, {
            type: 'column',
              id: `${this.id} -volume`,
            name: `${this.id} Volume`,
            data: volumeData,
            yAxis: 1
          }],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 800,
                maxHeight: 10000
              },
              chartOptions: {
                rangeSelector: {
                  inputEnabled: false
                }
              }
            }]
          }
        };
  
        Highcharts.stockChart(this.elementRef.nativeElement.querySelector('#chart-container'), {
          ...chartOptions,
         
        });
  
        //this.chart = new Chart(ctx, {
        //  plugins: [{
        //    id:'customCursor',
        //    afterDraw: (chart: any) => {
        //      if (chart.tooltip?._active?.length) {
        //        let x = chart.tooltip._active[0].element.x;
        //        let y = chart.tooltip._active[0].element.y;
        //        let yAxis = chart.scales.y;
        //        let xAxis = chart.scales.x;
        //        let ctx = chart.ctx;
        //        ctx.save();
        //        ctx.beginPath();
        //        ctx.moveTo(x, yAxis.right);
        //        ctx.lineTo(x, yAxis.bottom);
        //        ctx.moveTo(y, yAxis.right);
        //        ctx.lineTo(y, yAxis.top);
        //        ctx.lineWidth = 1;
        //        ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
        //        ctx.stroke();
        //        ctx.restore();
  
                
        //      }
        //    }
        //  }],
        //  type: 'line',
        //  data: {
        //    labels: dates,
        //    datasets: [{
        //      label: 'Stock Prices',
        //      data: prices,
        //      backgroundColor: backgroundColor1,
        //      borderColor: borderColor1,
        //      borderWidth: 1,
        //      fill: true,
        //      pointRadius:0
        //    }]
        //  },
        //  options: {
        //    responsive: true,
        //    maintainAspectRatio: true,
        //    plugins: {
        //      legend: {
        //        display: false // Hides the legend
        //      }
        //    },
        //    scales: {
        //      x: {
        //        display: true,
        //        type: 'time',
        //        adapters: {
        //          date: {
        //            locale: enUS,
        //          }
        //        },
        //        title: {
        //          display: true,
        //          text: 'Date'
        //        },
        //        grid: {
        //          display: false, // Hides the grid lines on the x-axis
        //          color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
        //        }
        //      },
        //      y: {
        //        display: true,
        //        title: {
        //          display: true,
        //          text: 'Price'
        //        },
        //        grid: {
        //          display: false, // Hides the grid lines on the x-axis
        //          color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
        //        }
        //      }
        //    }
        //  }
        //});
         //this.chart.canvas.parentNode.style.height = '400px';
         //this.chart.canvas.parentNode.style.width = '400px';
      },
        error => {
          console.log('Error fetching data:', error);
        });
    }
  }

  async renderIntradayChart(timeframe:string, from:string): Promise<void> {
    const data = await this.stockDataService.getIntradayStockData(this.id, timeframe, from).toPromise();
    const stockData = data.map((item: any) => ({
      date: new Date(item.date),
      price: item.close
    }));
    const filteredStockData = stockData.filter((item: any) => {
      const date = item.date;
      // Check if the date is greater than today
      const isAfterDate = date > new Date(from);
      // Check if the time is between 9:30am and 4pm
      return isAfterDate;
    });

    const dates = filteredStockData.map((item: any) => item.date);
    const prices = filteredStockData.map((item: any) => item.price);

    var ctx = 'stockChart'; // jQuery instance
    var backgroundColor1 = '';
    var borderColor1 = '';
      
    if (prices[0] - prices[prices.length-1] >= 0) {
      backgroundColor1 = 'rgba(0, 180, 0, 0.25)';
      borderColor1 = 'rgba(0, 150, 0)';
    } else {
      backgroundColor1 = 'rgba(180, 0, 0, 0.25)';
      borderColor1 = 'rgba(150, 0, 0)';
    }

      this.chart = new Chart(ctx, {
      plugins: [{
        id:'customCursor',
        afterDraw: (chart: any) => {
          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let y = chart.tooltip._active[0].element.y;
            let yAxis = chart.scales.y;
            let xAxis = chart.scales.x;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.right);
            ctx.lineTo(x, yAxis.bottom);
            ctx.moveTo(y, yAxis.right);
            ctx.lineTo(y, yAxis.top);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
            ctx.stroke();
            ctx.restore();

              
          }
        }
      }],
      type: 'line',
      data: {
        labels: dates,
        datasets: [{
          label: 'Stock Prices',
          data: prices,
          backgroundColor: backgroundColor1,
          borderColor: borderColor1,
          borderWidth: 1,
          fill: true,
          pointRadius:0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false // Hides the legend
          }
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            adapters: {
              date: {
                locale: enUS,
              }
            },
            title: {
              display: true,
              text: 'Date'
            },
            grid: {
              display: false, // Hides the grid lines on the x-axis
              color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Price'
            },
            grid: {
              display: false, // Hides the grid lines on the x-axis
              color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
            }
          }
        }
      }
    });
  }


  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.querySelector('.income-table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table-data.xlsx');
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

}

<ng-container>
  <app-ticker-tape></app-ticker-tape>

  <div class="info-container">
    <div class="info-item-container">
      <div class="info-item">
        <div> S&P 500 </div>
        <div> {{lastPxSpy | currency:'USD':'symbol':'1.2-2'}}</div>
        <div [ngClass]="{ 'positive': lastPctNDQ > 0, 'negative': lastPctNDQ < 0 }">
          {{lastPctSpy | percent:'1.2-2' }}
        </div>
      </div>
      <div class="chart-item-container">
        <canvas id='S&P'></canvas>
      </div>
    </div>

    <div class="info-item-container">
      <div class="info-item">
        <div> Nasdaq </div>
        <div> {{lastPxNDQ | currency:'USD':'symbol':'1.2-2'}}</div>
        <div [ngClass]="{ 'positive': lastPctNDQ > 0, 'negative': lastPctNDQ < 0 }">
          {{lastPctNDQ | percent:'1.2-2' }}
        </div>
      </div>
      <div class="chart-item-container">
        <canvas id='NASDQ'></canvas>
      </div>
    </div>

    <div class="info-item-container">
      <div class="info-item">
        <div> Bitcoin </div>
        <div> {{lastPxBTC | currency:'USD':'symbol':'1.2-2'}}</div>
        <div [ngClass]="{ 'positive': lastPctBTC > 0, 'negative': lastPctBTC < 0 }">
          {{lastPctBTC | percent:'1.2-2' }}
        </div>
      </div>
      <div class="chart-item-container">
        <canvas id='BTC'></canvas>
      </div>
    </div>

    <div class="info-item-container">
      <div class="info-item">
        <div> Volatility Index </div>
        <div> {{lastPxVIX | currency:'USD':'symbol':'1.2-2'}}</div>
        <div [ngClass]="{ 'positive': lastPctVIX > 0, 'negative': lastPctVIX < 0 }">
          {{lastPctVIX | percent:'1.2-2' }}
        </div>
      </div>
      <div class="chart-item-container">
        <canvas id='VIX'></canvas>
      </div>
    </div>
  </div>
</ng-container>

<h2 mat-dialog-title>Search Stock</h2>

<mat-dialog-content>
  <input [(ngModel)]="searchQuery" (input)="onSearch($event)" placeholder="Search for a stock" />
  <ul id="dpdwn" *ngIf="isDropdownOpen" style="display: flex; flex-direction: column; list-style-type: none; padding-left: 0; width: 100%; overflow-y: auto; overflow-x: hidden;">
    <li *ngFor="let stock of filteredCandidates" (click)="addStockData(stock)"
      style="border-bottom: 0.05em solid #ddd; padding-bottom: 0.5em; padding-top: 0.5em; transition: background-color 0.3s; width: 100%; display:block; text-align:left; overflow-y:hidden;"
      (mouseenter)="hoveredItem = stock"
      (mouseleave)="hoveredItem = null"
      [ngStyle]="{ 'background-color': hoveredItem === stock ? '#ccebff' : 'transparent' }">
      {{ stock.symbol }} - {{ stock.name }}
    </li>
  </ul>

  <!-- Trade Information Section -->
  <div class="trade-info">
    <mat-form-field appearance="fill">
      <mat-label>Number of Shares</mat-label>
      <input matInput [(ngModel)]="shares" type="number" placeholder="Enter the amount of shares">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Trade Price</mat-label>
      <input matInput [(ngModel)]="tradePrice" type="number" placeholder="Enter trade price">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Trade Date</mat-label>
      <input matInput [(ngModel)]="tradeDate" [matDatepicker]="picker" placeholder="Select date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Trade Side</mat-label>
      <mat-select [(ngModel)]="tradeSide">
        <mat-option value="buy">Buy</mat-option>
        <mat-option value="sell">Sell</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="selectStock()">Select</button>
</mat-dialog-actions>

import { Component,Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Chart, registerables } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { format } from 'date-fns';
import { StockDataService } from '../stock-data.service';
import { CommonModule } from '@angular/common';
import { TickerTapeComponent } from '../ticker-tape/ticker-tape.component';
import { AUTO_STYLE } from '@angular/animations';
import { filter } from 'rxjs/operators';



Chart.register(...registerables);

@Component({
  selector: 'app-navigation-bar',
  standalone: true,
  imports: [RouterModule, CommonModule, TickerTapeComponent],
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.scss'
})
export class NavigationBarComponent {
  @ViewChild('searchInputRef') searchInputRef!: ElementRef;
  newsData: any[] = [];
  ticker: string = ''
  constructor(private stockDataService: StockDataService, private router: Router, private route: ActivatedRoute, private eRef: ElementRef) {
  }

  stockCandidates: any = [];
  cryptoCandidates: any = [];
  forexCandidates: any = [];
  candidates: any = [];
  filteredCandidates: any = [];
  path: string = '';
  isInputFocused: boolean = false;
  hoveredItem: any = null;
  isMenuOpen = false;

  async ngOnInit(): Promise<void> {

    this.stockCandidates = await this.stockDataService.stockList().toPromise();
    this.cryptoCandidates = await this.stockDataService.cryptoList().toPromise();
    this.forexCandidates = await this.stockDataService.forexList().toPromise();
    this.candidates = this.stockCandidates.concat(this.cryptoCandidates, this.forexCandidates);


    (this.candidates);

  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  // Listen for clicks outside the sidebar
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.isMenuOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;  // Close the menu if clicked outside
    }
  }

  onInputBlur() {
    setTimeout(() => {
      this.isInputFocused = false;
    }, 200); // Delay in milliseconds
  }

  onSearch(event: Event) {

    const inputValue = (event.target as HTMLInputElement)?.value || '';
    this.ticker = inputValue;
    this.filteredCandidates = this.candidates
      // First, sort by the length of the ticker
      .sort((a:any, b:any) => a.symbol.length - b.symbol.length)
      // Then filter by symbol or companyName
      .filter((candidate: any) => {
        const inputUpper = inputValue.toUpperCase();
        return (
          (!candidate.symbol.includes('.') && (candidate.symbol.toUpperCase().startsWith(inputUpper)) ||
          candidate.name?.toUpperCase().includes(inputUpper))
        );
      })
      // Limit the result to the top 5 candidates
      .slice(0, 5);
    if (this.filteredCandidates) {
      let height = this.filteredCandidates.length * 3.1; // Assuming each element has a height of 30px

      // Apply the height to the div element
      let dpdwnElement = document.getElementById("dpdwn");
      // Apply the height to the div element if it exists
      if (dpdwnElement) {
        dpdwnElement.style.height = height + "em";
      }
    }
    
    // Further logic using inputValue
  }

  search(candidate?: any) {
    // Implement logic to perform search action (e.g., API call)
    // This function can be called when the user clicks the Search button
    let foundObject = this.candidates.find((obj: any) => obj.symbol === this.ticker.toUpperCase());

    if (candidate && candidate.symbol) {
      this.router.navigate(['chart', candidate.symbol, candidate.exchangeShortName]);
      (this.searchInputRef.nativeElement as HTMLInputElement).value = '';
      (this.searchInputRef.nativeElement as HTMLInputElement).blur();
      this.filteredCandidates = [];
    } else if (this.ticker && foundObject) {
      this.router.navigate(['chart', this.ticker, foundObject.exchangeShortName]);
      // Ensure the input box is cleared
      const searchInput = this.searchInputRef.nativeElement as HTMLInputElement;
      if (searchInput) {
          searchInput.value = ''; // Clear the input value
          searchInput.blur(); // Remove focus from the input
      } else {
          console.error('Search input not found');
      }
      this.filteredCandidates = [];

    }
  }

  toggleDropdown(event: Event) {
    const dropdownContent = (event.target as HTMLElement).nextElementSibling as HTMLElement;
    if (dropdownContent) {
      dropdownContent.style.display = dropdownContent.style.display === 'block' ? 'none' : 'block';
    }
  }

}

!
/**
* Highstock JS v11.4.8 (2024-08-29)
*
* All technical indicators for Highcharts Stock
*
* (c) 2010-2024 Pawel Fus
*
* License: www.highcharts.com/license
*/
function (e) {
  "object" == typeof module && module.exports ? (e.default = e, module.exports = e) : "function" == typeof define && define.amd ? define("highcharts/indicators/indicators-all", ["highcharts", "highcharts/modules/stock"], function (t) {
    return e(t), e.Highcharts = t, e;
  }) : e("undefined" != typeof Highcharts ? Highcharts : void 0);
}(function (e) {
  "use strict";

  var t = e ? e._modules : {};
  function s(t, s, i, o) {
    t.hasOwnProperty(s) || (t[s] = o.apply(null, i), "function" == typeof CustomEvent && e.win.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: s,
        module: t[s]
      }
    })));
  }
  s(t, "Stock/Indicators/SMA/SMAIndicator.js", [t["Core/Chart/Chart.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        line: i
      } = t.seriesTypes,
      {
        addEvent: o,
        fireEvent: a,
        error: r,
        extend: n,
        isArray: l,
        merge: p,
        pick: u,
        splat: h
      } = s;
    class d extends i {
      destroy() {
        this.dataEventsToUnbind.forEach(function (e) {
          e();
        }), super.destroy.apply(this, arguments);
      }
      getName() {
        let e = [],
          t = this.name;
        return t || ((this.nameComponents || []).forEach(function (t, s) {
          e.push(this.options.params[t] + u(this.nameSuffixes[s], ""));
        }, this), t = (this.nameBase || this.type.toUpperCase()) + (this.nameComponents ? " (" + e.join(", ") + ")" : "")), t;
      }
      getValues(e, t) {
        let s = t.period,
          i = e.xData,
          o = e.yData,
          a = o.length,
          r = [],
          n = [],
          p = [],
          u,
          h = -1,
          d = 0,
          c,
          g = 0;
        if (!(i.length < s)) {
          for (l(o[0]) && (h = t.index ? t.index : 0); d < s - 1;) g += h < 0 ? o[d] : o[d][h], d++;
          for (u = d; u < a; u++) g += h < 0 ? o[u] : o[u][h], c = [i[u], g / s], r.push(c), n.push(c[0]), p.push(c[1]), g -= h < 0 ? o[u - d] : o[u - d][h];
          return {
            values: r,
            xData: n,
            yData: p
          };
        }
      }
      init(t, s) {
        let i = this;
        super.init.call(i, t, s);
        let a = o(e, "afterLinkSeries", function ({
          isUpdating: e
        }) {
          if (e) return;
          let s = !!i.dataEventsToUnbind.length;
          if (!i.linkedParent) return r("Series " + i.options.linkedTo + " not found! Check `linkedTo`.", !1, t);
          if (!s && (i.dataEventsToUnbind.push(o(i.linkedParent, "updatedData", function () {
            i.recalculateValues();
          })), i.calculateOn.xAxis && i.dataEventsToUnbind.push(o(i.linkedParent.xAxis, i.calculateOn.xAxis, function () {
            i.recalculateValues();
          }))), "init" === i.calculateOn.chart) i.processedYData || i.recalculateValues();else if (!s) {
            let e = o(i.chart, i.calculateOn.chart, function () {
              i.recalculateValues(), e();
            });
          }
        }, {
          order: 0
        });
        i.dataEventsToUnbind = [], i.eventsToUnbind.push(a);
      }
      recalculateValues() {
        let e = [],
          t = this.points || [],
          s = (this.xData || []).length,
          i = !0,
          o,
          r,
          n,
          l,
          p,
          u,
          d = this.linkedParent.options && this.linkedParent.yData && this.linkedParent.yData.length && this.getValues(this.linkedParent, this.options.params) || {
            values: [],
            xData: [],
            yData: []
          };
        if (s && !this.hasGroupedData && this.visible && this.points) {
          if (this.cropped) {
            for (this.xAxis && (l = this.xAxis.min, p = this.xAxis.max), n = this.cropData(d.xData, d.yData, l, p), u = 0; u < n.xData.length; u++) e.push([n.xData[u]].concat(h(n.yData[u])));
            o = d.xData.indexOf(this.xData[0]), r = d.xData.indexOf(this.xData[this.xData.length - 1]), -1 === o && r === d.xData.length - 2 && e[0][0] === t[0].x && e.shift(), this.updateData(e);
          } else (this.updateAllPoints || d.xData.length !== s - 1 && d.xData.length !== s + 1) && (i = !1, this.updateData(d.values));
        }
        i && (this.xData = d.xData, this.yData = d.yData, this.options.data = d.values), this.calculateOn.xAxis && this.processedXData && (delete this.processedXData, this.isDirty = !0, this.redraw()), this.isDirtyData = !!this.linkedSeries.length, a(this, "updatedData");
      }
      processData() {
        let e = this.options.compareToMain,
          t = this.linkedParent;
        super.processData.apply(this, arguments), this.dataModify && t && t.dataModify && t.dataModify.compareValue && e && (this.dataModify.compareValue = t.dataModify.compareValue);
      }
    }
    return d.defaultOptions = p(i.defaultOptions, {
      name: void 0,
      tooltip: {
        valueDecimals: 4
      },
      linkedTo: void 0,
      compareToMain: !1,
      params: {
        index: 3,
        period: 14
      }
    }), n(d.prototype, {
      calculateOn: {
        chart: "init"
      },
      hasDerivedData: !0,
      nameComponents: ["period"],
      nameSuffixes: [],
      useCommonDataGrouping: !0
    }), t.registerSeriesType("sma", d), d;
  }), s(t, "Stock/Indicators/EMA/EMAIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        isArray: o,
        merge: a
      } = t;
    class r extends s {
      accumulatePeriodPoints(e, t, s) {
        let i = 0,
          o = 0;
        for (; o < e;) i += t < 0 ? s[o] : s[o][t], o++;
        return i;
      }
      calculateEma(e, t, s, o, a, r, n) {
        let l = e[s - 1],
          p = r < 0 ? t[s - 1] : t[s - 1][r];
        return [l, void 0 === a ? n : i(p * o + a * (1 - o))];
      }
      getValues(e, t) {
        let s = t.period,
          i = e.xData,
          a = e.yData,
          r = a ? a.length : 0,
          n = 2 / (s + 1),
          l = [],
          p = [],
          u = [],
          h,
          d,
          c,
          g = -1,
          y = 0;
        if (!(r < s)) {
          for (o(a[0]) && (g = t.index ? t.index : 0), y = this.accumulatePeriodPoints(s, g, a) / s, c = s; c < r + 1; c++) d = this.calculateEma(i, a, c, n, h, g, y), l.push(d), p.push(d[0]), u.push(d[1]), h = d[1];
          return {
            values: l,
            xData: p,
            yData: u
          };
        }
      }
    }
    return r.defaultOptions = a(s.defaultOptions, {
      params: {
        index: 3,
        period: 9
      }
    }), e.registerSeriesType("ema", r), r;
  }), s(t, "Stock/Indicators/AD/ADIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        error: i,
        extend: o,
        merge: a
      } = t;
    class r extends s {
      static populateAverage(e, t, s, i, o) {
        let a = t[i][1],
          r = t[i][2],
          n = t[i][3],
          l = s[i];
        return [e[i], n === a && n === r || a === r ? 0 : (2 * n - r - a) / (a - r) * l];
      }
      getValues(e, t) {
        let s, o, a;
        let n = t.period,
          l = e.xData,
          p = e.yData,
          u = t.volumeSeriesID,
          h = e.chart.get(u),
          d = h && h.yData,
          c = p ? p.length : 0,
          g = [],
          y = [],
          m = [];
        if (!(l.length <= n) || !c || 4 === p[0].length) {
          if (!h) {
            i("Series " + u + " not found! Check `volumeSeriesID`.", !0, e.chart);
            return;
          }
          for (o = n; o < c; o++) s = g.length, a = r.populateAverage(l, p, d, o, n), s > 0 && (a[1] += g[s - 1][1]), g.push(a), y.push(a[0]), m.push(a[1]);
          return {
            values: g,
            xData: y,
            yData: m
          };
        }
      }
    }
    return r.defaultOptions = a(s.defaultOptions, {
      params: {
        index: void 0,
        volumeSeriesID: "volume"
      }
    }), o(r.prototype, {
      nameComponents: !1,
      nameBase: "Accumulation/Distribution"
    }), e.registerSeriesType("ad", r), r;
  }), s(t, "Stock/Indicators/AO/AOIndicator.js", [t["Core/Globals.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        noop: i
      } = e,
      {
        column: {
          prototype: o
        },
        sma: a
      } = t.seriesTypes,
      {
        extend: r,
        merge: n,
        correctFloat: l,
        isArray: p
      } = s;
    class u extends a {
      drawGraph() {
        let e;
        let t = this.options,
          s = this.points,
          i = this.userOptions.color,
          o = t.greaterBarColor,
          a = t.lowerBarColor,
          r = s[0];
        if (!i && r) for (e = 1, r.color = o; e < s.length; e++) s[e].y > s[e - 1].y ? s[e].color = o : s[e].y < s[e - 1].y ? s[e].color = a : s[e].color = s[e - 1].color;
      }
      getValues(e) {
        let t = e.xData || [],
          s = e.yData || [],
          i = s.length,
          o = [],
          a = [],
          r = [],
          n,
          u,
          h,
          d,
          c,
          g,
          y = 0,
          m = 0;
        if (!(t.length <= 34) && p(s[0]) && 4 === s[0].length) {
          for (c = 0; c < 33; c++) d = (s[c][1] + s[c][2]) / 2, c >= 29 && (m = l(m + d)), y = l(y + d);
          for (g = 33; g < i; g++) m = l(m + (d = (s[g][1] + s[g][2]) / 2)), y = l(y + d), n = l(m / 5 - y / 34), o.push([t[g], n]), a.push(t[g]), r.push(n), u = g + 1 - 5, h = g + 1 - 34, m = l(m - (s[u][1] + s[u][2]) / 2), y = l(y - (s[h][1] + s[h][2]) / 2);
          return {
            values: o,
            xData: a,
            yData: r
          };
        }
      }
    }
    return u.defaultOptions = n(a.defaultOptions, {
      params: {
        index: void 0,
        period: void 0
      },
      greaterBarColor: "#06b535",
      lowerBarColor: "#f21313",
      threshold: 0,
      groupPadding: .2,
      pointPadding: .2,
      crisp: !1,
      states: {
        hover: {
          halo: {
            size: 0
          }
        }
      }
    }), r(u.prototype, {
      nameBase: "AO",
      nameComponents: void 0,
      markerAttribs: i,
      getColumnMetrics: o.getColumnMetrics,
      crispCol: o.crispCol,
      translate: o.translate,
      drawPoints: o.drawPoints
    }), t.registerSeriesType("ao", u), u;
  }), s(t, "Stock/Indicators/MultipleLinesComposition.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    var s;
    let {
        sma: {
          prototype: i
        }
      } = e.seriesTypes,
      {
        defined: o,
        error: a,
        merge: r
      } = t;
    return function (e) {
      let t = ["bottomLine"],
        s = ["top", "bottom"],
        n = ["top"];
      function l(e) {
        return "plot" + e.charAt(0).toUpperCase() + e.slice(1);
      }
      function p(e, t) {
        let s = [];
        return (e.pointArrayMap || []).forEach(e => {
          e !== t && s.push(l(e));
        }), s;
      }
      function u() {
        let e = this,
          t = e.pointValKey,
          s = e.linesApiNames,
          n = e.areaLinesNames,
          u = e.points,
          h = e.options,
          d = e.graph,
          c = {
            options: {
              gapSize: h.gapSize
            }
          },
          g = [],
          y = p(e, t),
          m = u.length,
          f;
        if (y.forEach((e, t) => {
          for (g[t] = []; m--;) f = u[m], g[t].push({
            x: f.x,
            plotX: f.plotX,
            plotY: f[e],
            isNull: !o(f[e])
          });
          m = u.length;
        }), e.userOptions.fillColor && n.length) {
          let t = g[y.indexOf(l(n[0]))],
            s = 1 === n.length ? u : g[y.indexOf(l(n[1]))],
            o = e.color;
          e.points = s, e.nextPoints = t, e.color = e.userOptions.fillColor, e.options = r(u, c), e.graph = e.area, e.fillGraph = !0, i.drawGraph.call(e), e.area = e.graph, delete e.nextPoints, delete e.fillGraph, e.color = o;
        }
        s.forEach((t, s) => {
          g[s] ? (e.points = g[s], h[t] ? e.options = r(h[t].styles, c) : a('Error: "There is no ' + t + ' in DOCS options declared. Check if linesApiNames are consistent with your DOCS line names."'), e.graph = e["graph" + t], i.drawGraph.call(e), e["graph" + t] = e.graph) : a('Error: "' + t + " doesn't have equivalent in pointArrayMap. To many elements in linesApiNames relative to pointArrayMap.\"");
        }), e.points = u, e.options = h, e.graph = d, i.drawGraph.call(e);
      }
      function h(e) {
        let t,
          s = [],
          o = [];
        if (e = e || this.points, this.fillGraph && this.nextPoints) {
          if ((t = i.getGraphPath.call(this, this.nextPoints)) && t.length) {
            t[0][0] = "L", s = i.getGraphPath.call(this, e), o = t.slice(0, s.length);
            for (let e = o.length - 1; e >= 0; e--) s.push(o[e]);
          }
        } else s = i.getGraphPath.apply(this, arguments);
        return s;
      }
      function d(e) {
        let t = [];
        return (this.pointArrayMap || []).forEach(s => {
          t.push(e[s]);
        }), t;
      }
      function c() {
        let e = this.pointArrayMap,
          t = [],
          s;
        t = p(this), i.translate.apply(this, arguments), this.points.forEach(i => {
          e.forEach((e, o) => {
            s = i[e], this.dataModify && (s = this.dataModify.modifyValue(s)), null !== s && (i[t[o]] = this.yAxis.toPixels(s, !0));
          });
        });
      }
      e.compose = function (e) {
        let i = e.prototype;
        return i.linesApiNames = i.linesApiNames || t.slice(), i.pointArrayMap = i.pointArrayMap || s.slice(), i.pointValKey = i.pointValKey || "top", i.areaLinesNames = i.areaLinesNames || n.slice(), i.drawGraph = u, i.getGraphPath = h, i.toYData = d, i.translate = c, e;
      };
    }(s || (s = {})), s;
  }), s(t, "Stock/Indicators/Aroon/AroonIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        extend: o,
        merge: a,
        pick: r
      } = s;
    function n(e, t) {
      let s = e[0],
        i = 0,
        o;
      for (o = 1; o < e.length; o++) ("max" === t && e[o] >= s || "min" === t && e[o] <= s) && (s = e[o], i = o);
      return i;
    }
    class l extends i {
      getValues(e, t) {
        let s, i, o, a, l;
        let p = t.period,
          u = e.xData,
          h = e.yData,
          d = h ? h.length : 0,
          c = [],
          g = [],
          y = [];
        for (a = p - 1; a < d; a++) o = n((l = h.slice(a - p + 1, a + 2)).map(function (e) {
          return r(e[2], e);
        }), "min"), s = n(l.map(function (e) {
          return r(e[1], e);
        }), "max") / p * 100, i = o / p * 100, u[a + 1] && (c.push([u[a + 1], s, i]), g.push(u[a + 1]), y.push([s, i]));
        return {
          values: c,
          xData: g,
          yData: y
        };
      }
    }
    return l.defaultOptions = a(i.defaultOptions, {
      params: {
        index: void 0,
        period: 25
      },
      marker: {
        enabled: !1
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b><br/>Aroon Up: {point.y}<br/>Aroon Down: {point.aroonDown}<br/>'
      },
      aroonDown: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), o(l.prototype, {
      areaLinesNames: [],
      linesApiNames: ["aroonDown"],
      nameBase: "Aroon",
      pointArrayMap: ["y", "aroonDown"],
      pointValKey: "y"
    }), e.compose(l), t.registerSeriesType("aroon", l), l;
  }), s(t, "Stock/Indicators/AroonOscillator/AroonOscillatorIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        aroon: i
      } = t.seriesTypes,
      {
        extend: o,
        merge: a
      } = s;
    class r extends i {
      getValues(e, t) {
        let s, i;
        let o = [],
          a = [],
          r = [],
          n = super.getValues.call(this, e, t);
        for (i = 0; i < n.yData.length; i++) s = n.yData[i][0] - n.yData[i][1], o.push([n.xData[i], s]), a.push(n.xData[i]), r.push(s);
        return {
          values: o,
          xData: a,
          yData: r
        };
      }
    }
    return r.defaultOptions = a(i.defaultOptions, {
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b>: {point.y}'
      }
    }), o(r.prototype, {
      nameBase: "Aroon Oscillator",
      linesApiNames: [],
      pointArrayMap: ["y"],
      pointValKey: "y"
    }), e.compose(i), t.registerSeriesType("aroonoscillator", r), r;
  }), s(t, "Stock/Indicators/ATR/ATRIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isArray: i,
        merge: o
      } = t;
    function a(e, t) {
      return Math.max(e[1] - e[2], void 0 === t ? 0 : Math.abs(e[1] - t[3]), void 0 === t ? 0 : Math.abs(e[2] - t[3]));
    }
    class r extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          r = e.yData,
          n = r ? r.length : 0,
          l = [[o[0], r[0]]],
          p = [],
          u = [],
          h = [],
          d,
          c,
          g = 0,
          y = 1,
          m = 0;
        if (!(o.length <= s) && i(r[0]) && 4 === r[0].length) {
          for (c = 1; c <= n; c++) {
            var f, D;
            (function (e, t, s, i) {
              let o = t[i],
                a = s[i];
              e.push([o, a]);
            })(l, o, r, c), s < y ? (g = (f = c, D = g, d = [o[f - 1], (D * (s - 1) + a(r[f - 1], r[f - 2])) / s])[1], p.push(d), u.push(d[0]), h.push(d[1])) : (s === y ? (g = m / (c - 1), p.push([o[c - 1], g]), u.push(o[c - 1]), h.push(g)) : m += a(r[c - 1], r[c - 2]), y++);
          }
          return {
            values: p,
            xData: u,
            yData: h
          };
        }
      }
    }
    return r.defaultOptions = o(s.defaultOptions, {
      params: {
        index: void 0
      }
    }), e.registerSeriesType("atr", r), r;
  }), s(t, "Stock/Indicators/BB/BBIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        extend: o,
        isArray: a,
        merge: r
      } = s;
    class n extends i {
      init() {
        t.seriesTypes.sma.prototype.init.apply(this, arguments), this.options = r({
          topLine: {
            styles: {
              lineColor: this.color
            }
          },
          bottomLine: {
            styles: {
              lineColor: this.color
            }
          }
        }, this.options);
      }
      getValues(e, s) {
        let i, o, r, n, l, p, u, h, d;
        let c = s.period,
          g = s.standardDeviation,
          y = [],
          m = [],
          f = e.xData,
          D = e.yData,
          x = D ? D.length : 0,
          S = [];
        if (f.length < c) return;
        let v = a(D[0]);
        for (d = c; d <= x; d++) l = f.slice(d - c, d), p = D.slice(d - c, d), n = (h = t.seriesTypes.sma.prototype.getValues.call(this, {
          xData: l,
          yData: p
        }, s)).xData[0], i = h.yData[0], u = function (e, t, s, i) {
          let o = e.length,
            a = 0,
            r,
            n = 0;
          for (; a < o; a++) n += (r = (s ? e[a][t] : e[a]) - i) * r;
          return Math.sqrt(n /= o - 1);
        }(p, s.index, v, i), o = i + g * u, r = i - g * u, S.push([n, o, i, r]), y.push(n), m.push([o, i, r]);
        return {
          values: S,
          xData: y,
          yData: m
        };
      }
    }
    return n.defaultOptions = r(i.defaultOptions, {
      params: {
        period: 20,
        standardDeviation: 2,
        index: 3
      },
      bottomLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      topLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b><br/>Top: {point.top}<br/>Middle: {point.middle}<br/>Bottom: {point.bottom}<br/>'
      },
      marker: {
        enabled: !1
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), o(n.prototype, {
      areaLinesNames: ["top", "bottom"],
      linesApiNames: ["topLine", "bottomLine"],
      nameComponents: ["period", "standardDeviation"],
      pointArrayMap: ["top", "middle", "bottom"],
      pointValKey: "middle"
    }), e.compose(n), t.registerSeriesType("bb", n), n;
  }), s(t, "Stock/Indicators/CCI/CCIIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isArray: i,
        merge: o
      } = t;
    class a extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          a = e.yData,
          r = a ? a.length : 0,
          n = [],
          l = [],
          p = [],
          u = [],
          h,
          d,
          c = [],
          g,
          y = 1,
          m,
          f,
          D,
          x;
        if (!(o.length <= s) && i(a[0]) && 4 === a[0].length) {
          for (; y < s;) d = a[y - 1], n.push((d[1] + d[2] + d[3]) / 3), y++;
          for (x = s; x <= r; x++) f = ((d = a[x - 1])[1] + d[2] + d[3]) / 3, g = n.push(f), m = (c = n.slice(g - s)).reduce(function (e, t) {
            return e + t;
          }, 0) / s, D = function (e, t) {
            let s = e.length,
              i = 0,
              o;
            for (o = 0; o < s; o++) i += Math.abs(t - e[o]);
            return i;
          }(c, m) / s, h = (f - m) / (.015 * D), l.push([o[x - 1], h]), p.push(o[x - 1]), u.push(h);
          return {
            values: l,
            xData: p,
            yData: u
          };
        }
      }
    }
    return a.defaultOptions = o(s.defaultOptions, {
      params: {
        index: void 0
      }
    }), e.registerSeriesType("cci", a), a;
  }), s(t, "Stock/Indicators/CMF/CMFIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        merge: i
      } = t;
    class o extends s {
      constructor() {
        super(...arguments), this.nameBase = "Chaikin Money Flow";
      }
      isValid() {
        let e = this.chart,
          t = this.options,
          s = this.linkedParent,
          i = this.volumeSeries || (this.volumeSeries = e.get(t.params.volumeSeriesID)),
          o = s && s.yData && 4 === s.yData[0].length;
        function a(e) {
          return e.xData && e.xData.length >= t.params.period;
        }
        return !!(s && i && a(s) && a(i) && o);
      }
      getValues(e, t) {
        if (this.isValid()) return this.getMoneyFlow(e.xData, e.yData, this.volumeSeries.yData, t.period);
      }
      getMoneyFlow(e, t, s, i) {
        let o = t.length,
          a = [],
          r = [],
          n = [],
          l = [],
          p,
          u,
          h = -1,
          d = 0,
          c = 0;
        function g(e, t) {
          let s = e[1],
            i = e[2],
            o = e[3];
          return null !== t && null !== s && null !== i && null !== o && s !== i ? (o - i - (s - o)) / (s - i) * t : (h = p, null);
        }
        if (i > 0 && i <= o) {
          for (p = 0; p < i; p++) a[p] = g(t[p], s[p]), d += s[p], c += a[p];
          for (r.push(e[p - 1]), n.push(p - h >= i && 0 !== d ? c / d : null), l.push([r[0], n[0]]); p < o; p++) a[p] = g(t[p], s[p]), d -= s[p - i], d += s[p], c -= a[p - i], c += a[p], u = [e[p], p - h >= i ? c / d : null], r.push(u[0]), n.push(u[1]), l.push([u[0], u[1]]);
        }
        return {
          values: l,
          xData: r,
          yData: n
        };
      }
    }
    return o.defaultOptions = i(s.defaultOptions, {
      params: {
        index: void 0,
        volumeSeriesID: "volume"
      }
    }), e.registerSeriesType("cmf", o), o;
  }), s(t, "Stock/Indicators/DMI/DMIIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        correctFloat: o,
        extend: a,
        isArray: r,
        merge: n
      } = s;
    class l extends i {
      calculateDM(e, t, s) {
        let i = e[t][1],
          a = e[t][2],
          r = e[t - 1][1],
          n = e[t - 1][2];
        return o(i - r > n - a ? s ? Math.max(i - r, 0) : 0 : s ? 0 : Math.max(n - a, 0));
      }
      calculateDI(e, t) {
        return e / t * 100;
      }
      calculateDX(e, t) {
        return o(Math.abs(e - t) / Math.abs(e + t) * 100);
      }
      smoothValues(e, t, s) {
        return o(e - e / s + t);
      }
      getTR(e, t) {
        return o(Math.max(e[1] - e[2], t ? Math.abs(e[1] - t[3]) : 0, t ? Math.abs(e[2] - t[3]) : 0));
      }
      getValues(e, t) {
        let s = t.period,
          i = e.xData,
          o = e.yData,
          a = o ? o.length : 0,
          n = [],
          l = [],
          p = [];
        if (i.length <= s || !r(o[0]) || 4 !== o[0].length) return;
        let u = 0,
          h = 0,
          d = 0,
          c;
        for (c = 1; c < a; c++) {
          let e, t, a, r, g, y, m, f, D;
          c <= s ? (r = this.calculateDM(o, c, !0), g = this.calculateDM(o, c), y = this.getTR(o[c], o[c - 1]), u += r, h += g, d += y, c === s && (m = this.calculateDI(u, d), f = this.calculateDI(h, d), D = this.calculateDX(u, h), n.push([i[c], D, m, f]), l.push(i[c]), p.push([D, m, f]))) : (r = this.calculateDM(o, c, !0), g = this.calculateDM(o, c), y = this.getTR(o[c], o[c - 1]), e = this.smoothValues(u, r, s), t = this.smoothValues(h, g, s), a = this.smoothValues(d, y, s), u = e, h = t, d = a, m = this.calculateDI(u, d), f = this.calculateDI(h, d), D = this.calculateDX(u, h), n.push([i[c], D, m, f]), l.push(i[c]), p.push([D, m, f]));
        }
        return {
          values: n,
          xData: l,
          yData: p
        };
      }
    }
    return l.defaultOptions = n(i.defaultOptions, {
      params: {
        index: void 0
      },
      marker: {
        enabled: !1
      },
      tooltip: {
        pointFormat: '<span style="color: {point.color}">●</span><b> {series.name}</b><br/><span style="color: {point.color}">DX</span>: {point.y}<br/><span style="color: {point.series.options.plusDILine.styles.lineColor}">+DI</span>: {point.plusDI}<br/><span style="color: {point.series.options.minusDILine.styles.lineColor}">-DI</span>: {point.minusDI}<br/>'
      },
      plusDILine: {
        styles: {
          lineWidth: 1,
          lineColor: "#06b535"
        }
      },
      minusDILine: {
        styles: {
          lineWidth: 1,
          lineColor: "#f21313"
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), a(l.prototype, {
      areaLinesNames: [],
      nameBase: "DMI",
      linesApiNames: ["plusDILine", "minusDILine"],
      pointArrayMap: ["y", "plusDI", "minusDI"],
      parallelArrays: ["x", "y", "plusDI", "minusDI"],
      pointValKey: "y"
    }), e.compose(l), t.registerSeriesType("dmi", l), l;
  }), s(t, "Stock/Indicators/DPO/DPOIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o,
        correctFloat: a,
        pick: r
      } = t;
    function n(e, t, s, i, o) {
      let n = r(t[s][i], t[s]);
      return o ? a(e - n) : a(e + n);
    }
    class l extends s {
      getValues(e, t) {
        let s = t.period,
          i = t.index,
          o = Math.floor(s / 2 + 1),
          a = s + o,
          l = e.xData || [],
          p = e.yData || [],
          u = p.length,
          h = [],
          d = [],
          c = [],
          g,
          y,
          m,
          f,
          D,
          x = 0;
        if (!(l.length <= a)) {
          for (f = 0; f < s - 1; f++) x = n(x, p, f, i);
          for (D = 0; D <= u - a; D++) y = D + s - 1, m = D + a - 1, x = n(x, p, y, i), g = r(p[m][i], p[m]) - x / s, x = n(x, p, D, i, !0), h.push([l[m], g]), d.push(l[m]), c.push(g);
          return {
            values: h,
            xData: d,
            yData: c
          };
        }
      }
    }
    return l.defaultOptions = o(s.defaultOptions, {
      params: {
        index: 0,
        period: 21
      }
    }), i(l.prototype, {
      nameBase: "DPO"
    }), e.registerSeriesType("dpo", l), l;
  }), s(t, "Stock/Indicators/Chaikin/ChaikinIndicator.js", [t["Stock/Indicators/AD/ADIndicator.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        ema: i
      } = t.seriesTypes,
      {
        correctFloat: o,
        extend: a,
        merge: r,
        error: n
      } = s;
    class l extends i {
      getValues(t, s) {
        let i, a;
        let r = s.periods,
          l = s.period,
          p = [],
          u = [],
          h = [];
        if (2 !== r.length || r[1] <= r[0]) {
          n('Error: "Chaikin requires two periods. Notice, first period should be lower than the second one."');
          return;
        }
        let d = e.prototype.getValues.call(this, t, {
          volumeSeriesID: s.volumeSeriesID,
          period: l
        });
        if (!d) return;
        let c = super.getValues.call(this, d, {
            period: r[0]
          }),
          g = super.getValues.call(this, d, {
            period: r[1]
          });
        if (!c || !g) return;
        let y = r[1] - r[0];
        for (a = 0; a < g.yData.length; a++) i = o(c.yData[a + y] - g.yData[a]), p.push([g.xData[a], i]), u.push(g.xData[a]), h.push(i);
        return {
          values: p,
          xData: u,
          yData: h
        };
      }
    }
    return l.defaultOptions = r(i.defaultOptions, {
      params: {
        index: void 0,
        volumeSeriesID: "volume",
        period: 9,
        periods: [3, 10]
      }
    }), a(l.prototype, {
      nameBase: "Chaikin Osc",
      nameComponents: ["periods"]
    }), t.registerSeriesType("chaikin", l), l;
  }), s(t, "Stock/Indicators/CMO/CMOIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isNumber: i,
        merge: o
      } = t;
    class a extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          a = e.yData,
          r = a ? a.length : 0,
          n = [],
          l = [],
          p = [],
          u,
          h = t.index,
          d;
        if (o.length < s) return;
        i(a[0]) ? d = a : (h = Math.min(h, a[0].length - 1), d = a.map(e => e[h]));
        let c = 0,
          g = 0,
          y = 0,
          m;
        for (let e = s; e > 0; e--) d[e] > d[e - 1] ? g += d[e] - d[e - 1] : d[e] < d[e - 1] && (y += d[e - 1] - d[e]);
        for (m = g + y > 0 ? 100 * (g - y) / (g + y) : 0, l.push(o[s]), p.push(m), n.push([o[s], m]), u = s + 1; u < r; u++) c = Math.abs(d[u - s - 1] - d[u - s]), d[u] > d[u - 1] ? g += d[u] - d[u - 1] : d[u] < d[u - 1] && (y += d[u - 1] - d[u]), d[u - s] > d[u - s - 1] ? g -= c : y -= c, m = g + y > 0 ? 100 * (g - y) / (g + y) : 0, l.push(o[u]), p.push(m), n.push([o[u], m]);
        return {
          values: n,
          xData: l,
          yData: p
        };
      }
    }
    return a.defaultOptions = o(s.defaultOptions, {
      params: {
        period: 20,
        index: 3
      }
    }), e.registerSeriesType("cmo", a), a;
  }), s(t, "Stock/Indicators/DEMA/DEMAIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        ema: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        isArray: o,
        merge: a
      } = t;
    class r extends s {
      getEMA(e, t, s, i, o, a) {
        return super.calculateEma(a || [], e, void 0 === o ? 1 : o, this.EMApercent, t, void 0 === i ? -1 : i, s);
      }
      getValues(e, t) {
        let s = t.period,
          a = [],
          r = 2 * s,
          n = e.xData,
          l = e.yData,
          p = l ? l.length : 0,
          u = [],
          h = [],
          d = [],
          c = 0,
          g = 0,
          y,
          m,
          f,
          D,
          x = -1,
          S,
          v = 0;
        if (this.EMApercent = 2 / (s + 1), !(p < 2 * s - 1)) {
          for (o(l[0]) && (x = t.index ? t.index : 0), v = (c = super.accumulatePeriodPoints(s, x, l)) / s, c = 0, D = s; D < p + 2; D++) D < p + 1 && (g = this.getEMA(l, m, v, x, D)[1], a.push(g)), m = g, D < r ? c += g : (D === r && (v = c / s), g = a[D - s - 1], y = this.getEMA([g], f, v)[1], S = [n[D - 2], i(2 * g - y)], u.push(S), h.push(S[0]), d.push(S[1]), f = y);
          return {
            values: u,
            xData: h,
            yData: d
          };
        }
      }
    }
    return r.defaultOptions = a(s.defaultOptions), e.registerSeriesType("dema", r), r;
  }), s(t, "Stock/Indicators/TEMA/TEMAIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        ema: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        isArray: o,
        merge: a
      } = t;
    class r extends s {
      getEMA(e, t, s, i, o, a) {
        return super.calculateEma(a || [], e, void 0 === o ? 1 : o, this.EMApercent, t, void 0 === i ? -1 : i, s);
      }
      getTemaPoint(e, t, s, o) {
        return [e[o - 3], i(3 * s.level1 - 3 * s.level2 + s.level3)];
      }
      getValues(e, t) {
        let s = t.period,
          i = 2 * s,
          a = 3 * s,
          r = e.xData,
          n = e.yData,
          l = n ? n.length : 0,
          p = [],
          u = [],
          h = [],
          d = [],
          c = [],
          g = {},
          y = -1,
          m = 0,
          f = 0,
          D,
          x,
          S,
          v;
        if (this.EMApercent = 2 / (s + 1), !(l < 3 * s - 2)) {
          for (o(n[0]) && (y = t.index ? t.index : 0), f = (m = super.accumulatePeriodPoints(s, y, n)) / s, m = 0, S = s; S < l + 3; S++) S < l + 1 && (g.level1 = this.getEMA(n, D, f, y, S)[1], d.push(g.level1)), D = g.level1, S < i ? m += g.level1 : (S === i && (f = m / s, m = 0), g.level1 = d[S - s - 1], g.level2 = this.getEMA([g.level1], x, f)[1], c.push(g.level2), x = g.level2, S < a ? m += g.level2 : (S === a && (f = m / s), S === l + 1 && (g.level1 = d[S - s - 1], g.level2 = this.getEMA([g.level1], x, f)[1], c.push(g.level2)), g.level1 = d[S - s - 2], g.level2 = c[S - 2 * s - 1], g.level3 = this.getEMA([g.level2], g.prevLevel3, f)[1], (v = this.getTemaPoint(r, a, g, S)) && (p.push(v), u.push(v[0]), h.push(v[1])), g.prevLevel3 = g.level3));
          return {
            values: p,
            xData: u,
            yData: h
          };
        }
      }
    }
    return r.defaultOptions = a(s.defaultOptions), e.registerSeriesType("tema", r), r;
  }), s(t, "Stock/Indicators/TRIX/TRIXIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        tema: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        merge: o
      } = t;
    class a extends s {
      getTemaPoint(e, t, s, o) {
        if (o > t) return [e[o - 3], 0 !== s.prevLevel3 ? i(s.level3 - s.prevLevel3) / s.prevLevel3 * 100 : null];
      }
    }
    return a.defaultOptions = o(s.defaultOptions), e.registerSeriesType("trix", a), a;
  }), s(t, "Stock/Indicators/APO/APOIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        ema: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o,
        error: a
      } = t;
    class r extends s {
      getValues(e, t) {
        let s, i;
        let o = t.periods,
          r = t.index,
          n = [],
          l = [],
          p = [];
        if (2 !== o.length || o[1] <= o[0]) {
          a('Error: "APO requires two periods. Notice, first period should be lower than the second one."');
          return;
        }
        let u = super.getValues.call(this, e, {
            index: r,
            period: o[0]
          }),
          h = super.getValues.call(this, e, {
            index: r,
            period: o[1]
          });
        if (!u || !h) return;
        let d = o[1] - o[0];
        for (i = 0; i < h.yData.length; i++) s = u.yData[i + d] - h.yData[i], n.push([h.xData[i], s]), l.push(h.xData[i]), p.push(s);
        return {
          values: n,
          xData: l,
          yData: p
        };
      }
    }
    return r.defaultOptions = o(s.defaultOptions, {
      params: {
        period: void 0,
        periods: [10, 20]
      }
    }), i(r.prototype, {
      nameBase: "APO",
      nameComponents: ["periods"]
    }), e.registerSeriesType("apo", r), r;
  }), s(t, "Stock/Indicators/IKH/IKHIndicator.js", [t["Extensions/DataGrouping/ApproximationRegistry.js"], t["Core/Color/Color.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s, i) {
    let {
        parse: o
      } = t,
      {
        sma: a
      } = s.seriesTypes,
      {
        defined: r,
        extend: n,
        isArray: l,
        isNumber: p,
        getClosestDistance: u,
        merge: h,
        objectEach: d
      } = i;
    function c(e) {
      return {
        high: e.reduce(function (e, t) {
          return Math.max(e, t[1]);
        }, -1 / 0),
        low: e.reduce(function (e, t) {
          return Math.min(e, t[2]);
        }, 1 / 0)
      };
    }
    function g(e) {
      let t = e.indicator;
      t.points = e.points, t.nextPoints = e.nextPoints, t.color = e.color, t.options = h(e.options.senkouSpan.styles, e.gap), t.graph = e.graph, t.fillGraph = !0, s.seriesTypes.sma.prototype.drawGraph.call(t);
    }
    class y extends a {
      constructor() {
        super(...arguments), this.data = [], this.options = {}, this.points = [], this.graphCollection = [];
      }
      init() {
        super.init.apply(this, arguments), this.options = h({
          tenkanLine: {
            styles: {
              lineColor: this.color
            }
          },
          kijunLine: {
            styles: {
              lineColor: this.color
            }
          },
          chikouLine: {
            styles: {
              lineColor: this.color
            }
          },
          senkouSpanA: {
            styles: {
              lineColor: this.color,
              fill: o(this.color).setOpacity(.5).get()
            }
          },
          senkouSpanB: {
            styles: {
              lineColor: this.color,
              fill: o(this.color).setOpacity(.5).get()
            }
          },
          senkouSpan: {
            styles: {
              fill: o(this.color).setOpacity(.2).get()
            }
          }
        }, this.options);
      }
      toYData(e) {
        return [e.tenkanSen, e.kijunSen, e.chikouSpan, e.senkouSpanA, e.senkouSpanB];
      }
      translate() {
        for (let e of (s.seriesTypes.sma.prototype.translate.apply(this), this.points)) for (let t of this.pointArrayMap) {
          let s = e[t];
          p(s) && (e["plot" + t] = this.yAxis.toPixels(s, !0), e.plotY = e["plot" + t], e.tooltipPos = [e.plotX, e["plot" + t]], e.isNull = !1);
        }
      }
      drawGraph() {
        let e = this,
          t = e.points,
          i = e.options,
          o = e.graph,
          a = e.color,
          n = {
            options: {
              gapSize: i.gapSize
            }
          },
          l = e.pointArrayMap.length,
          p = [[], [], [], [], [], []],
          u = {
            tenkanLine: p[0],
            kijunLine: p[1],
            chikouLine: p[2],
            senkouSpanA: p[3],
            senkouSpanB: p[4],
            senkouSpan: p[5]
          },
          c = [],
          y = e.options.senkouSpan,
          m = y.color || y.styles.fill,
          f = y.negativeColor,
          D = [[], []],
          x = [[], []],
          S = t.length,
          v = 0,
          C,
          A,
          I,
          T,
          P,
          k,
          j,
          b,
          M,
          L,
          V,
          O,
          R;
        for (e.ikhMap = u; S--;) {
          for (I = 0, A = t[S]; I < l; I++) r(A[C = e.pointArrayMap[I]]) && p[I].push({
            plotX: A.plotX,
            plotY: A["plot" + C],
            isNull: !1
          });
          if (f && S !== t.length - 1) {
            let e = u.senkouSpanB.length - 1,
              t = function (e, t, s, i) {
                if (e && t && s && i) {
                  let o = t.plotX - e.plotX,
                    a = t.plotY - e.plotY,
                    r = i.plotX - s.plotX,
                    n = i.plotY - s.plotY,
                    l = e.plotX - s.plotX,
                    p = e.plotY - s.plotY,
                    u = (-a * l + o * p) / (-r * a + o * n),
                    h = (r * p - n * l) / (-r * a + o * n);
                  if (u >= 0 && u <= 1 && h >= 0 && h <= 1) return {
                    plotX: e.plotX + h * o,
                    plotY: e.plotY + h * a
                  };
                }
              }(u.senkouSpanA[e - 1], u.senkouSpanA[e], u.senkouSpanB[e - 1], u.senkouSpanB[e]);
            if (t) {
              let s = {
                plotX: t.plotX,
                plotY: t.plotY,
                isNull: !1,
                intersectPoint: !0
              };
              u.senkouSpanA.splice(e, 0, s), u.senkouSpanB.splice(e, 0, s), c.push(e);
            }
          }
        }
        if (d(u, (t, o) => {
          i[o] && "senkouSpan" !== o && (e.points = p[v], e.options = h(i[o].styles, n), e.graph = e["graph" + o], e.fillGraph = !1, e.color = a, s.seriesTypes.sma.prototype.drawGraph.call(e), e["graph" + o] = e.graph), v++;
        }), e.graphCollection) for (let t of e.graphCollection) e[t].destroy(), delete e[t];
        if (e.graphCollection = [], f && u.senkouSpanA[0] && u.senkouSpanB[0]) {
          for (c.unshift(0), c.push(u.senkouSpanA.length - 1), O = 0; O < c.length - 1; O++) if (T = c[O], P = c[O + 1], k = u.senkouSpanB.slice(T, P + 1), j = u.senkouSpanA.slice(T, P + 1), Math.floor(k.length / 2) >= 1) {
            let e = Math.floor(k.length / 2);
            if (k[e].plotY === j[e].plotY) {
              for (R = 0, b = 0, M = 0; R < k.length; R++) b += k[R].plotY, M += j[R].plotY;
              D[V = b > M ? 0 : 1] = D[V].concat(k), x[V] = x[V].concat(j);
            } else D[V = k[e].plotY > j[e].plotY ? 0 : 1] = D[V].concat(k), x[V] = x[V].concat(j);
          } else D[V = k[0].plotY > j[0].plotY ? 0 : 1] = D[V].concat(k), x[V] = x[V].concat(j);
          ["graphsenkouSpanColor", "graphsenkouSpanNegativeColor"].forEach(function (t, s) {
            D[s].length && x[s].length && (L = 0 === s ? m : f, g({
              indicator: e,
              points: D[s],
              nextPoints: x[s],
              color: L,
              options: i,
              gap: n,
              graph: e[t]
            }), e[t] = e.graph, e.graphCollection.push(t));
          });
        } else g({
          indicator: e,
          points: u.senkouSpanB,
          nextPoints: u.senkouSpanA,
          color: m,
          options: i,
          gap: n,
          graph: e.graphsenkouSpan
        }), e.graphsenkouSpan = e.graph;
        delete e.nextPoints, delete e.fillGraph, e.points = t, e.options = i, e.graph = o, e.color = a;
      }
      getGraphPath(e) {
        let t = [],
          i,
          o = [];
        if (e = e || this.points, this.fillGraph && this.nextPoints) {
          if ((i = s.seriesTypes.sma.prototype.getGraphPath.call(this, this.nextPoints)) && i.length) {
            i[0][0] = "L", t = s.seriesTypes.sma.prototype.getGraphPath.call(this, e), o = i.slice(0, t.length);
            for (let e = o.length - 1; e >= 0; e--) t.push(o[e]);
          }
        } else t = s.seriesTypes.sma.prototype.getGraphPath.apply(this, arguments);
        return t;
      }
      getValues(e, t) {
        let s, i, o, a, r, n, p, h, d, g;
        let y = t.period,
          m = t.periodTenkan,
          f = t.periodSenkouSpanB,
          D = e.xData,
          x = e.yData,
          S = e.xAxis,
          v = x && x.length || 0,
          C = u(S.series.map(e => e.xData || [])),
          A = [],
          I = [];
        if (D.length <= y || !l(x[0]) || 4 !== x[0].length) return;
        let T = D[0] - y * C;
        for (r = 0; r < y; r++) I.push(T + r * C);
        for (r = 0; r < v; r++) r >= m && (n = ((i = c(x.slice(r - m, r))).high + i.low) / 2), r >= y && (d = (n + (p = ((o = c(x.slice(r - y, r))).high + o.low) / 2)) / 2), r >= f && (g = ((a = c(x.slice(r - f, r))).high + a.low) / 2), h = x[r][3], s = D[r], void 0 === A[r] && (A[r] = []), void 0 === A[r + y - 1] && (A[r + y - 1] = []), A[r + y - 1][0] = n, A[r + y - 1][1] = p, A[r + y - 1][2] = void 0, void 0 === A[r + 1] && (A[r + 1] = []), A[r + 1][2] = h, r <= y && (A[r + y - 1][3] = void 0, A[r + y - 1][4] = void 0), void 0 === A[r + 2 * y - 2] && (A[r + 2 * y - 2] = []), A[r + 2 * y - 2][3] = d, A[r + 2 * y - 2][4] = g, I.push(s);
        for (r = 1; r <= y; r++) I.push(s + r * C);
        return {
          values: A,
          xData: I,
          yData: A
        };
      }
    }
    return y.defaultOptions = h(a.defaultOptions, {
      params: {
        index: void 0,
        period: 26,
        periodTenkan: 9,
        periodSenkouSpanB: 52
      },
      marker: {
        enabled: !1
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>TENKAN SEN: {point.tenkanSen:.3f}<br/>KIJUN SEN: {point.kijunSen:.3f}<br/>CHIKOU SPAN: {point.chikouSpan:.3f}<br/>SENKOU SPAN A: {point.senkouSpanA:.3f}<br/>SENKOU SPAN B: {point.senkouSpanB:.3f}<br/>'
      },
      tenkanLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      kijunLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      chikouLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      senkouSpanA: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      senkouSpanB: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      senkouSpan: {
        styles: {
          fill: "rgba(255, 0, 0, 0.5)"
        }
      },
      dataGrouping: {
        approximation: "ichimoku-averages"
      }
    }), n(y.prototype, {
      pointArrayMap: ["tenkanSen", "kijunSen", "chikouSpan", "senkouSpanA", "senkouSpanB"],
      pointValKey: "tenkanSen",
      nameComponents: ["periodSenkouSpanB", "period", "periodTenkan"]
    }), e["ichimoku-averages"] = function () {
      let t;
      let s = [];
      return [].forEach.call(arguments, function (i, o) {
        s.push(e.average(i)), t = !t && void 0 === s[o];
      }), t ? void 0 : s;
    }, s.registerSeriesType("ikh", y), y;
  }), s(t, "Stock/Indicators/KeltnerChannels/KeltnerChannelsIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        correctFloat: o,
        extend: a,
        merge: r
      } = s;
    class n extends i {
      init() {
        t.seriesTypes.sma.prototype.init.apply(this, arguments), this.options = r({
          topLine: {
            styles: {
              lineColor: this.color
            }
          },
          bottomLine: {
            styles: {
              lineColor: this.color
            }
          }
        }, this.options);
      }
      getValues(e, s) {
        let i, a, r, n, l, p, u;
        let h = s.period,
          d = s.periodATR,
          c = s.multiplierATR,
          g = s.index,
          y = e.yData,
          m = y ? y.length : 0,
          f = [],
          D = t.seriesTypes.ema.prototype.getValues(e, {
            period: h,
            index: g
          }),
          x = t.seriesTypes.atr.prototype.getValues(e, {
            period: d
          }),
          S = [],
          v = [];
        if (!(m < h)) {
          for (u = h; u <= m; u++) l = D.values[u - h], p = x.values[u - d], n = l[0], a = o(l[1] + c * p[1]), r = o(l[1] - c * p[1]), i = l[1], f.push([n, a, i, r]), S.push(n), v.push([a, i, r]);
          return {
            values: f,
            xData: S,
            yData: v
          };
        }
      }
    }
    return n.defaultOptions = r(i.defaultOptions, {
      params: {
        index: 0,
        period: 20,
        periodATR: 10,
        multiplierATR: 2
      },
      bottomLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      topLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b><br/>Upper Channel: {point.top}<br/>EMA({series.options.params.period}): {point.middle}<br/>Lower Channel: {point.bottom}<br/>'
      },
      marker: {
        enabled: !1
      },
      dataGrouping: {
        approximation: "averages"
      },
      lineWidth: 1
    }), a(n.prototype, {
      nameBase: "Keltner Channels",
      areaLinesNames: ["top", "bottom"],
      nameComponents: ["period", "periodATR", "multiplierATR"],
      linesApiNames: ["topLine", "bottomLine"],
      pointArrayMap: ["top", "middle", "bottom"],
      pointValKey: "middle"
    }), e.compose(n), t.registerSeriesType("keltnerchannels", n), n;
  }), s(t, "Stock/Indicators/Klinger/KlingerIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        ema: i,
        sma: o
      } = t.seriesTypes,
      {
        correctFloat: a,
        error: r,
        extend: n,
        isArray: l,
        merge: p
      } = s;
    class u extends o {
      calculateTrend(e, t) {
        return e[t][1] + e[t][2] + e[t][3] > e[t - 1][1] + e[t - 1][2] + e[t - 1][3] ? 1 : -1;
      }
      isValidData(e) {
        let t = this.chart,
          s = this.options,
          i = this.linkedParent,
          o = l(e) && 4 === e.length,
          a = this.volumeSeries || (this.volumeSeries = t.get(s.params.volumeSeriesID));
        return a || r("Series " + s.params.volumeSeriesID + " not found! Check `volumeSeriesID`.", !0, i.chart), !!([i, a].every(function (e) {
          return e && e.xData && e.xData.length >= s.params.slowAvgPeriod;
        }) && o);
      }
      getCM(e, t, s, i, o) {
        return a(t + (s === i ? e : o));
      }
      getDM(e, t) {
        return a(e - t);
      }
      getVolumeForce(e) {
        let t = [],
          s = 0,
          i,
          o = 1,
          a = 0,
          r = e[0][1] - e[0][2],
          n = 0,
          l;
        for (; o < e.length; o++) l = this.calculateTrend(e, o), i = this.getDM(e[o][1], e[o][2]), s = this.getCM(a, i, l, n, r), t.push([this.volumeSeries.yData[o] * l * Math.abs(2 * (i / s - 1)) * 100]), n = l, a = s, r = i;
        return t;
      }
      getEMA(e, t, s, o, a, r, n) {
        return i.prototype.calculateEma(n || [], e, void 0 === r ? 1 : r, o, t, void 0 === a ? -1 : a, s);
      }
      getSMA(e, t, s) {
        return i.prototype.accumulatePeriodPoints(e, t, s) / e;
      }
      getValues(e, t) {
        let s = [],
          i = e.xData,
          o = e.yData,
          r = [],
          n = [],
          l = [],
          p,
          u = 0,
          h = 0,
          d,
          c,
          g,
          y = null;
        if (!this.isValidData(o[0])) return;
        let m = this.getVolumeForce(o),
          f = this.getSMA(t.fastAvgPeriod, 0, m),
          D = this.getSMA(t.slowAvgPeriod, 0, m),
          x = 2 / (t.fastAvgPeriod + 1),
          S = 2 / (t.slowAvgPeriod + 1);
        for (; u < o.length; u++) u >= t.fastAvgPeriod && (c = h = this.getEMA(m, c, f, x, 0, u, i)[1]), u >= t.slowAvgPeriod && (g = d = this.getEMA(m, g, D, S, 0, u, i)[1], l.push(p = a(h - d)), l.length >= t.signalPeriod && (y = l.slice(-t.signalPeriod).reduce((e, t) => e + t) / t.signalPeriod), s.push([i[u], p, y]), r.push(i[u]), n.push([p, y]));
        return {
          values: s,
          xData: r,
          yData: n
        };
      }
    }
    return u.defaultOptions = p(o.defaultOptions, {
      params: {
        fastAvgPeriod: 34,
        slowAvgPeriod: 55,
        signalPeriod: 13,
        volumeSeriesID: "volume"
      },
      signalLine: {
        styles: {
          lineWidth: 1,
          lineColor: "#ff0000"
        }
      },
      dataGrouping: {
        approximation: "averages"
      },
      tooltip: {
        pointFormat: '<span style="color: {point.color}">●</span><b> {series.name}</b><br/><span style="color: {point.color}">Klinger</span>: {point.y}<br/><span style="color: {point.series.options.signalLine.styles.lineColor}">Signal</span>: {point.signal}<br/>'
      }
    }), n(u.prototype, {
      areaLinesNames: [],
      linesApiNames: ["signalLine"],
      nameBase: "Klinger",
      nameComponents: ["fastAvgPeriod", "slowAvgPeriod"],
      pointArrayMap: ["y", "signal"],
      parallelArrays: ["x", "y", "signal"],
      pointValKey: "y"
    }), e.compose(u), t.registerSeriesType("klinger", u), u;
  }), s(t, "Stock/Indicators/MACD/MACDIndicator.js", [t["Core/Globals.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        noop: i
      } = e,
      {
        column: o,
        sma: a
      } = t.seriesTypes,
      {
        extend: r,
        correctFloat: n,
        defined: l,
        merge: p
      } = s;
    class u extends a {
      init() {
        t.seriesTypes.sma.prototype.init.apply(this, arguments);
        let e = this.color;
        this.options && (l(this.colorIndex) && (this.options.signalLine && this.options.signalLine.styles && !this.options.signalLine.styles.lineColor && (this.options.colorIndex = this.colorIndex + 1, this.getCyclic("color", void 0, this.chart.options.colors), this.options.signalLine.styles.lineColor = this.color), this.options.macdLine && this.options.macdLine.styles && !this.options.macdLine.styles.lineColor && (this.options.colorIndex = this.colorIndex + 1, this.getCyclic("color", void 0, this.chart.options.colors), this.options.macdLine.styles.lineColor = this.color)), this.macdZones = {
          zones: this.options.macdLine.zones,
          startIndex: 0
        }, this.signalZones = {
          zones: this.macdZones.zones.concat(this.options.signalLine.zones),
          startIndex: this.macdZones.zones.length
        }), this.color = e;
      }
      toYData(e) {
        return [e.y, e.signal, e.MACD];
      }
      translate() {
        let t = this,
          s = ["plotSignal", "plotMACD"];
        e.seriesTypes.column.prototype.translate.apply(t), t.points.forEach(function (e) {
          [e.signal, e.MACD].forEach(function (i, o) {
            null !== i && (e[s[o]] = t.yAxis.toPixels(i, !0));
          });
        });
      }
      destroy() {
        this.graph = null, this.graphmacd = this.graphmacd && this.graphmacd.destroy(), this.graphsignal = this.graphsignal && this.graphsignal.destroy(), t.seriesTypes.sma.prototype.destroy.apply(this, arguments);
      }
      drawGraph() {
        let e = this,
          s = e.points,
          i = e.options,
          o = e.zones,
          a = {
            options: {
              gapSize: i.gapSize
            }
          },
          r = [[], []],
          n,
          u = s.length;
        for (; u--;) l((n = s[u]).plotMACD) && r[0].push({
          plotX: n.plotX,
          plotY: n.plotMACD,
          isNull: !l(n.plotMACD)
        }), l(n.plotSignal) && r[1].push({
          plotX: n.plotX,
          plotY: n.plotSignal,
          isNull: !l(n.plotMACD)
        });
        ["macd", "signal"].forEach((s, o) => {
          e.points = r[o], e.options = p(i[`${s}Line`]?.styles || {}, a), e.graph = e[`graph${s}`], e.zones = (e[`${s}Zones`].zones || []).slice(e[`${s}Zones`].startIndex || 0), t.seriesTypes.sma.prototype.drawGraph.call(e), e[`graph${s}`] = e.graph;
        }), e.points = s, e.options = i, e.zones = o;
      }
      applyZones() {
        let e = this.zones;
        this.zones = this.signalZones.zones, t.seriesTypes.sma.prototype.applyZones.call(this), this.graphmacd && this.options.macdLine.zones.length && this.graphmacd.hide(), this.zones = e;
      }
      getValues(e, s) {
        let i = s.longPeriod - s.shortPeriod,
          o = [],
          a = [],
          r = [],
          p,
          u,
          h,
          d = 0,
          c = [];
        if (!(e.xData.length < s.longPeriod + s.signalPeriod)) {
          for (h = 0, p = t.seriesTypes.ema.prototype.getValues(e, {
            period: s.shortPeriod,
            index: s.index
          }), u = t.seriesTypes.ema.prototype.getValues(e, {
            period: s.longPeriod,
            index: s.index
          }), p = p.values, u = u.values; h <= p.length; h++) l(u[h]) && l(u[h][1]) && l(p[h + i]) && l(p[h + i][0]) && o.push([p[h + i][0], 0, null, p[h + i][1] - u[h][1]]);
          for (h = 0; h < o.length; h++) a.push(o[h][0]), r.push([0, null, o[h][3]]);
          for (h = 0, c = (c = t.seriesTypes.ema.prototype.getValues({
            xData: a,
            yData: r
          }, {
            period: s.signalPeriod,
            index: 2
          })).values; h < o.length; h++) o[h][0] >= c[0][0] && (o[h][2] = c[d][1], r[h] = [0, c[d][1], o[h][3]], null === o[h][3] ? (o[h][1] = 0, r[h][0] = 0) : (o[h][1] = n(o[h][3] - c[d][1]), r[h][0] = n(o[h][3] - c[d][1])), d++);
          return {
            values: o,
            xData: a,
            yData: r
          };
        }
      }
    }
    return u.defaultOptions = p(a.defaultOptions, {
      params: {
        shortPeriod: 12,
        longPeriod: 26,
        signalPeriod: 9,
        period: 26
      },
      signalLine: {
        zones: [],
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      macdLine: {
        zones: [],
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      threshold: 0,
      groupPadding: .1,
      pointPadding: .1,
      crisp: !1,
      states: {
        hover: {
          halo: {
            size: 0
          }
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Value: {point.MACD}<br/>Signal: {point.signal}<br/>Histogram: {point.y}<br/>'
      },
      dataGrouping: {
        approximation: "averages"
      },
      minPointLength: 0
    }), r(u.prototype, {
      nameComponents: ["longPeriod", "shortPeriod", "signalPeriod"],
      pointArrayMap: ["y", "signal", "MACD"],
      parallelArrays: ["x", "y", "signal", "MACD"],
      pointValKey: "y",
      markerAttribs: i,
      getColumnMetrics: e.seriesTypes.column.prototype.getColumnMetrics,
      crispCol: e.seriesTypes.column.prototype.crispCol,
      drawPoints: e.seriesTypes.column.prototype.drawPoints
    }), t.registerSeriesType("macd", u), u;
  }), s(t, "Stock/Indicators/MFI/MFIIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o,
        error: a,
        isArray: r
      } = t;
    function n(e) {
      return e.reduce(function (e, t) {
        return e + t;
      });
    }
    function l(e) {
      return (e[1] + e[2] + e[3]) / 3;
    }
    class p extends s {
      getValues(e, t) {
        let s = t.period,
          i = e.xData,
          o = e.yData,
          p = o ? o.length : 0,
          u = t.decimals,
          h = e.chart.get(t.volumeSeriesID),
          d = h && h.yData,
          c = [],
          g = [],
          y = [],
          m = [],
          f = [],
          D,
          x,
          S,
          v,
          C,
          A,
          I = !1,
          T = 1;
        if (!h) {
          a("Series " + t.volumeSeriesID + " not found! Check `volumeSeriesID`.", !0, e.chart);
          return;
        }
        if (!(i.length <= s) && r(o[0]) && 4 === o[0].length && d) {
          for (D = l(o[T]); T < s + 1;) x = D, I = (D = l(o[T])) >= x, S = D * d[T], m.push(I ? S : 0), f.push(I ? 0 : S), T++;
          for (A = T - 1; A < p; A++) A > T - 1 && (m.shift(), f.shift(), x = D, I = (D = l(o[A])) > x, S = D * d[A], m.push(I ? S : 0), f.push(I ? 0 : S)), v = n(f), C = parseFloat((100 - 100 / (1 + n(m) / v)).toFixed(u)), c.push([i[A], C]), g.push(i[A]), y.push(C);
          return {
            values: c,
            xData: g,
            yData: y
          };
        }
      }
    }
    return p.defaultOptions = o(s.defaultOptions, {
      params: {
        index: void 0,
        volumeSeriesID: "volume",
        decimals: 4
      }
    }), i(p.prototype, {
      nameBase: "Money Flow Index"
    }), e.registerSeriesType("mfi", p), p;
  }), s(t, "Stock/Indicators/Momentum/MomentumIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        extend: i,
        isArray: o,
        merge: a
      } = t;
    function r(e, t, s, i, o) {
      let a = t[s - 1][o] - t[s - i - 1][o];
      return [e[s - 1], a];
    }
    class n extends s {
      getValues(e, t) {
        let s, i;
        let a = t.period,
          n = t.index,
          l = e.xData,
          p = e.yData,
          u = p ? p.length : 0,
          h = [],
          d = [],
          c = [];
        if (!(l.length <= a) && o(p[0])) {
          for (s = a + 1; s < u; s++) i = r(l, p, s, a, n), h.push(i), d.push(i[0]), c.push(i[1]);
          return i = r(l, p, s, a, n), h.push(i), d.push(i[0]), c.push(i[1]), {
            values: h,
            xData: d,
            yData: c
          };
        }
      }
    }
    return n.defaultOptions = a(s.defaultOptions, {
      params: {
        index: 3
      }
    }), i(n.prototype, {
      nameBase: "Momentum"
    }), e.registerSeriesType("momentum", n), n;
  }), s(t, "Stock/Indicators/NATR/NATRIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        atr: s
      } = e.seriesTypes,
      {
        merge: i
      } = t;
    class o extends s {
      getValues(e, t) {
        let s = super.getValues.apply(this, arguments),
          i = s.values.length,
          o = e.yData,
          a = 0,
          r = t.period - 1;
        if (s) {
          for (; a < i; a++) s.yData[a] = s.values[a][1] / o[r][3] * 100, s.values[a][1] = s.yData[a], r++;
          return s;
        }
      }
    }
    return o.defaultOptions = i(s.defaultOptions, {
      tooltip: {
        valueSuffix: "%"
      }
    }), e.registerSeriesType("natr", o), o;
  }), s(t, "Stock/Indicators/OBV/OBVIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isNumber: i,
        error: o,
        extend: a,
        merge: r
      } = t;
    class n extends s {
      getValues(e, t) {
        let s = e.chart.get(t.volumeSeriesID),
          a = e.xData,
          r = e.yData,
          n = [],
          l = [],
          p = [],
          u = !i(r[0]),
          h = [],
          d = 1,
          c = 0,
          g = 0,
          y = 0,
          m = 0,
          f;
        if (s) for (f = s.yData, h = [a[0], c], y = u ? r[0][3] : r[0], n.push(h), l.push(a[0]), p.push(h[1]); d < r.length; d++) g = (m = u ? r[d][3] : r[d]) > y ? c + f[d] : m === y ? c : c - f[d], h = [a[d], g], c = g, y = m, n.push(h), l.push(a[d]), p.push(h[1]);else {
          o("Series " + t.volumeSeriesID + " not found! Check `volumeSeriesID`.", !0, e.chart);
          return;
        }
        return {
          values: n,
          xData: l,
          yData: p
        };
      }
    }
    return n.defaultOptions = r(s.defaultOptions, {
      marker: {
        enabled: !1
      },
      params: {
        index: void 0,
        period: void 0,
        volumeSeriesID: "volume"
      },
      tooltip: {
        valueDecimals: 0
      }
    }), a(n.prototype, {
      nameComponents: void 0
    }), e.registerSeriesType("obv", n), n;
  }), s(t, "Stock/Indicators/PivotPoints/PivotPointsPoint.js", [t["Core/Series/SeriesRegistry.js"]], function (e) {
    let t = e.seriesTypes.sma.prototype.pointClass;
    function s(t, s) {
      let i = t.series.pointArrayMap,
        o,
        a = i.length;
      for (e.seriesTypes.sma.prototype.pointClass.prototype[s].call(t); a--;) t[o = "dataLabel" + i[a]] && t[o].element && t[o].destroy(), t[o] = null;
    }
    return class extends t {
      destroyElements() {
        s(this, "destroyElements");
      }
      destroy() {
        s(this, "destroyElements");
      }
    };
  }), s(t, "Stock/Indicators/PivotPoints/PivotPointsIndicator.js", [t["Stock/Indicators/PivotPoints/PivotPointsPoint.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        merge: o,
        extend: a,
        defined: r,
        isArray: n
      } = s;
    class l extends i {
      toYData(e) {
        return [e.P];
      }
      translate() {
        let e = this;
        super.translate.apply(e), e.points.forEach(function (t) {
          e.pointArrayMap.forEach(function (s) {
            r(t[s]) && (t["plot" + s] = e.yAxis.toPixels(t[s], !0));
          });
        }), e.plotEndPoint = e.xAxis.toPixels(e.endPoint, !0);
      }
      getGraphPath(e) {
        let t = this,
          s = [[], [], [], [], [], [], [], [], []],
          i = t.pointArrayMap.length,
          o = t.plotEndPoint,
          a = [],
          n,
          l,
          p = e.length,
          u;
        for (; p--;) {
          for (u = 0, l = e[p]; u < i; u++) r(l[n = t.pointArrayMap[u]]) && s[u].push({
            plotX: l.plotX,
            plotY: l["plot" + n],
            isNull: !1
          }, {
            plotX: o,
            plotY: l["plot" + n],
            isNull: !1
          }, {
            plotX: o,
            plotY: null,
            isNull: !0
          });
          o = l.plotX;
        }
        return s.forEach(e => {
          a = a.concat(super.getGraphPath.call(t, e));
        }), a;
      }
      drawDataLabels() {
        let e, t, s, i;
        let o = this,
          a = o.pointArrayMap;
        o.options.dataLabels.enabled && (t = o.points.length, a.concat([!1]).forEach((r, n) => {
          for (i = t; i--;) s = o.points[i], r ? (s.y = s[r], s.pivotLine = r, s.plotY = s["plot" + r], e = s["dataLabel" + r], n && (s["dataLabel" + a[n - 1]] = s.dataLabel), s.dataLabels || (s.dataLabels = []), s.dataLabels[0] = s.dataLabel = e = e && e.element ? e : null) : s["dataLabel" + a[n - 1]] = s.dataLabel;
          super.drawDataLabels.call(o);
        }));
      }
      getValues(e, t) {
        let s, i, o, a, r, l, p;
        let u = t.period,
          h = e.xData,
          d = e.yData,
          c = d ? d.length : 0,
          g = this[t.algorithm + "Placement"],
          y = [],
          m = [],
          f = [];
        if (!(h.length < u) && n(d[0]) && 4 === d[0].length) {
          for (p = u + 1; p <= c + u; p += u) o = h.slice(p - u - 1, p), a = d.slice(p - u - 1, p), i = o.length, s = o[i - 1], l = g(this.getPivotAndHLC(a)), r = y.push([s].concat(l)), m.push(s), f.push(y[r - 1].slice(1));
          return this.endPoint = o[0] + (s - o[0]) / i * u, {
            values: y,
            xData: m,
            yData: f
          };
        }
      }
      getPivotAndHLC(e) {
        let t = e[e.length - 1][3],
          s = -1 / 0,
          i = 1 / 0;
        return e.forEach(function (e) {
          s = Math.max(s, e[1]), i = Math.min(i, e[2]);
        }), [(s + i + t) / 3, s, i, t];
      }
      standardPlacement(e) {
        let t = e[1] - e[2];
        return [null, null, e[0] + t, 2 * e[0] - e[2], e[0], 2 * e[0] - e[1], e[0] - t, null, null];
      }
      camarillaPlacement(e) {
        let t = e[1] - e[2];
        return [e[3] + 1.5 * t, e[3] + 1.25 * t, e[3] + 1.1666 * t, e[3] + 1.0833 * t, e[0], e[3] - 1.0833 * t, e[3] - 1.1666 * t, e[3] - 1.25 * t, e[3] - 1.5 * t];
      }
      fibonacciPlacement(e) {
        let t = e[1] - e[2];
        return [null, e[0] + t, e[0] + .618 * t, e[0] + .382 * t, e[0], e[0] - .382 * t, e[0] - .618 * t, e[0] - t, null];
      }
    }
    return l.defaultOptions = o(i.defaultOptions, {
      params: {
        index: void 0,
        period: 28,
        algorithm: "standard"
      },
      marker: {
        enabled: !1
      },
      enableMouseTracking: !1,
      dataLabels: {
        enabled: !0,
        format: "{point.pivotLine}"
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), a(l.prototype, {
      nameBase: "Pivot Points",
      pointArrayMap: ["R4", "R3", "R2", "R1", "P", "S1", "S2", "S3", "S4"],
      pointValKey: "P",
      pointClass: e
    }), t.registerSeriesType("pivotpoints", l), l;
  }), s(t, "Stock/Indicators/PPO/PPOIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        ema: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        extend: o,
        merge: a,
        error: r
      } = t;
    class n extends s {
      getValues(e, t) {
        let s, o;
        let a = t.periods,
          n = t.index,
          l = [],
          p = [],
          u = [];
        if (2 !== a.length || a[1] <= a[0]) {
          r('Error: "PPO requires two periods. Notice, first period should be lower than the second one."');
          return;
        }
        let h = super.getValues.call(this, e, {
            index: n,
            period: a[0]
          }),
          d = super.getValues.call(this, e, {
            index: n,
            period: a[1]
          });
        if (!h || !d) return;
        let c = a[1] - a[0];
        for (o = 0; o < d.yData.length; o++) s = i((h.yData[o + c] - d.yData[o]) / d.yData[o] * 100), l.push([d.xData[o], s]), p.push(d.xData[o]), u.push(s);
        return {
          values: l,
          xData: p,
          yData: u
        };
      }
    }
    return n.defaultOptions = a(s.defaultOptions, {
      params: {
        period: void 0,
        periods: [12, 26]
      }
    }), o(n.prototype, {
      nameBase: "PPO",
      nameComponents: ["periods"]
    }), e.registerSeriesType("ppo", n), n;
  }), s(t, "Stock/Indicators/ArrayUtilities.js", [], function () {
    return {
      getArrayExtremes: function (e, t, s) {
        return e.reduce((e, i) => [Math.min(e[0], i[t]), Math.max(e[1], i[s])], [Number.MAX_VALUE, -Number.MAX_VALUE]);
      }
    };
  }), s(t, "Stock/Indicators/PC/PCIndicator.js", [t["Stock/Indicators/ArrayUtilities.js"], t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Color/Palettes.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s, i, o) {
    let {
        sma: a
      } = i.seriesTypes,
      {
        merge: r,
        extend: n
      } = o;
    class l extends a {
      getValues(t, s) {
        let i, o, a, r, n, l, p;
        let u = s.period,
          h = t.xData,
          d = t.yData,
          c = d ? d.length : 0,
          g = [],
          y = [],
          m = [];
        if (!(c < u)) {
          for (p = u; p <= c; p++) r = h[p - 1], n = d.slice(p - u, p), i = ((o = (l = e.getArrayExtremes(n, 2, 1))[1]) + (a = l[0])) / 2, g.push([r, o, i, a]), y.push(r), m.push([o, i, a]);
          return {
            values: g,
            xData: y,
            yData: m
          };
        }
      }
    }
    return l.defaultOptions = r(a.defaultOptions, {
      params: {
        index: void 0,
        period: 20
      },
      lineWidth: 1,
      topLine: {
        styles: {
          lineColor: s.colors[2],
          lineWidth: 1
        }
      },
      bottomLine: {
        styles: {
          lineColor: s.colors[8],
          lineWidth: 1
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), n(l.prototype, {
      areaLinesNames: ["top", "bottom"],
      nameBase: "Price Channel",
      nameComponents: ["period"],
      linesApiNames: ["topLine", "bottomLine"],
      pointArrayMap: ["top", "middle", "bottom"],
      pointValKey: "middle"
    }), t.compose(l), i.registerSeriesType("pc", l), l;
  }), s(t, "Stock/Indicators/PriceEnvelopes/PriceEnvelopesIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        extend: o,
        isArray: a,
        merge: r
      } = s;
    class n extends i {
      init() {
        super.init.apply(this, arguments), this.options = r({
          topLine: {
            styles: {
              lineColor: this.color
            }
          },
          bottomLine: {
            styles: {
              lineColor: this.color
            }
          }
        }, this.options);
      }
      getValues(e, t) {
        let s, i, o, r, n, l, p, u;
        let h = t.period,
          d = t.topBand,
          c = t.bottomBand,
          g = e.xData,
          y = e.yData,
          m = y ? y.length : 0,
          f = [],
          D = [],
          x = [];
        if (!(g.length < h) && a(y[0]) && 4 === y[0].length) {
          for (u = h; u <= m; u++) n = g.slice(u - h, u), l = y.slice(u - h, u), r = (p = super.getValues({
            xData: n,
            yData: l
          }, t)).xData[0], i = (s = p.yData[0]) * (1 + d), o = s * (1 - c), f.push([r, i, s, o]), D.push(r), x.push([i, s, o]);
          return {
            values: f,
            xData: D,
            yData: x
          };
        }
      }
    }
    return n.defaultOptions = r(i.defaultOptions, {
      marker: {
        enabled: !1
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b><br/>Top: {point.top}<br/>Middle: {point.middle}<br/>Bottom: {point.bottom}<br/>'
      },
      params: {
        period: 20,
        topBand: .1,
        bottomBand: .1
      },
      bottomLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      topLine: {
        styles: {
          lineWidth: 1
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), o(n.prototype, {
      areaLinesNames: ["top", "bottom"],
      linesApiNames: ["topLine", "bottomLine"],
      nameComponents: ["period", "topBand", "bottomBand"],
      nameBase: "Price envelopes",
      pointArrayMap: ["top", "middle", "bottom"],
      parallelArrays: ["x", "y", "top", "bottom"],
      pointValKey: "middle"
    }), e.compose(n), t.registerSeriesType("priceenvelopes", n), n;
  }), s(t, "Stock/Indicators/PSAR/PSARIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        merge: i
      } = t;
    function o(e, t) {
      return parseFloat(e.toFixed(t));
    }
    class a extends s {
      constructor() {
        super(...arguments), this.nameComponents = void 0;
      }
      getValues(e, t) {
        let s = e.xData,
          i = e.yData,
          a = t.maxAccelerationFactor,
          r = t.increment,
          n = t.initialAccelerationFactor,
          l = t.decimals,
          p = t.index,
          u = [],
          h = [],
          d = [],
          c = t.initialAccelerationFactor,
          g,
          y = i[0][1],
          m,
          f,
          D,
          x = 1,
          S,
          v,
          C,
          A,
          I = i[0][2],
          T,
          P,
          k,
          j;
        if (!(p >= i.length)) {
          for (j = 0; j < p; j++) y = Math.max(i[j][1], y), I = Math.min(i[j][2], o(I, l));
          for (g = i[j][1] > I ? 1 : -1, m = y - I, f = (c = t.initialAccelerationFactor) * m, u.push([s[p], I]), h.push(s[p]), d.push(o(I, l)), j = p + 1; j < i.length; j++) if (S = i[j - 1][2], v = i[j - 2][2], C = i[j - 1][1], A = i[j - 2][1], P = i[j][1], k = i[j][2], null !== v && null !== A && null !== S && null !== C && null !== P && null !== k) {
            var b, M, L, V, O, R, E, w, B, U, N, G, W;
            O = g, R = x, E = I, w = f, B = y, I = O === R ? 1 === O ? E + w < Math.min(v, S) ? E + w : Math.min(v, S) : E + w > Math.max(A, C) ? E + w : Math.max(A, C) : B, b = g, M = y, T = 1 === b ? P > M ? P : M : k < M ? k : M, L = x, V = I, U = D = 1 === L && k > V || -1 === L && P > V ? 1 : -1, N = g, G = y, W = c, f = (c = U === N ? 1 === U && T > G || -1 === U && T < G ? W === a ? a : o(W + r, 2) : W : n) * (m = T - I), u.push([s[j], o(I, l)]), h.push(s[j]), d.push(o(I, l)), x = g, g = D, y = T;
          }
          return {
            values: u,
            xData: h,
            yData: d
          };
        }
      }
    }
    return a.defaultOptions = i(s.defaultOptions, {
      lineWidth: 0,
      marker: {
        enabled: !0
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      params: {
        period: void 0,
        initialAccelerationFactor: .02,
        maxAccelerationFactor: .2,
        increment: .02,
        index: 2,
        decimals: 4
      }
    }), e.registerSeriesType("psar", a), a;
  }), s(t, "Stock/Indicators/ROC/ROCIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isArray: i,
        merge: o,
        extend: a
      } = t;
    class r extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          a = e.yData,
          r = a ? a.length : 0,
          n = [],
          l = [],
          p = [],
          u,
          h = -1,
          d;
        if (!(o.length <= s)) {
          for (i(a[0]) && (h = t.index), u = s; u < r; u++) d = function (e, t, s, i, o) {
            let a, r;
            return r = o < 0 ? (a = t[s - i]) ? (t[s] - a) / a * 100 : null : (a = t[s - i][o]) ? (t[s][o] - a) / a * 100 : null, [e[s], r];
          }(o, a, u, s, h), n.push(d), l.push(d[0]), p.push(d[1]);
          return {
            values: n,
            xData: l,
            yData: p
          };
        }
      }
    }
    return r.defaultOptions = o(s.defaultOptions, {
      params: {
        index: 3,
        period: 9
      }
    }), a(r.prototype, {
      nameBase: "Rate of Change"
    }), e.registerSeriesType("roc", r), r;
  }), s(t, "Stock/Indicators/RSI/RSIIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isNumber: i,
        merge: o
      } = t;
    function a(e, t) {
      return parseFloat(e.toFixed(t));
    }
    class r extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          r = e.yData,
          n = r ? r.length : 0,
          l = t.decimals,
          p = [],
          u = [],
          h = [],
          d = 0,
          c = 0,
          g = t.index,
          y = 1,
          m,
          f,
          D,
          x,
          S,
          v;
        if (!(o.length < s)) {
          for (i(r[0]) ? v = r : (g = Math.min(g, r[0].length - 1), v = r.map(e => e[g])); y < s;) (f = a(v[y] - v[y - 1], l)) > 0 ? d += f : c += Math.abs(f), y++;
          for (D = a(d / (s - 1), l), x = a(c / (s - 1), l), S = y; S < n; S++) (f = a(v[S] - v[S - 1], l)) > 0 ? (d = f, c = 0) : (d = 0, c = Math.abs(f)), D = a((D * (s - 1) + d) / s, l), m = 0 === (x = a((x * (s - 1) + c) / s, l)) ? 100 : 0 === D ? 0 : a(100 - 100 / (1 + D / x), l), p.push([o[S], m]), u.push(o[S]), h.push(m);
          return {
            values: p,
            xData: u,
            yData: h
          };
        }
      }
    }
    return r.defaultOptions = o(s.defaultOptions, {
      params: {
        decimals: 4,
        index: 3
      }
    }), e.registerSeriesType("rsi", r), r;
  }), s(t, "Stock/Indicators/Stochastic/StochasticIndicator.js", [t["Stock/Indicators/ArrayUtilities.js"], t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s, i) {
    let {
        sma: o
      } = s.seriesTypes,
      {
        extend: a,
        isArray: r,
        merge: n
      } = i;
    class l extends o {
      init() {
        super.init.apply(this, arguments), this.options = n({
          smoothedLine: {
            styles: {
              lineColor: this.color
            }
          }
        }, this.options);
      }
      getValues(t, s) {
        let i = s.periods[0],
          o = s.periods[1],
          a = t.xData,
          n = t.yData,
          l = n ? n.length : 0,
          p = [],
          u = [],
          h = [],
          d,
          c,
          g,
          y = null,
          m,
          f;
        if (l < i || !r(n[0]) || 4 !== n[0].length) return;
        let D = !0,
          x = 0;
        for (f = i - 1; f < l; f++) {
          if (d = n.slice(f - i + 1, f + 1), c = (m = e.getArrayExtremes(d, 2, 1))[0], isNaN(g = (n[f][3] - c) / (m[1] - c) * 100) && D) {
            x++;
            continue;
          }
          D && !isNaN(g) && (D = !1);
          let t = u.push(a[f]);
          isNaN(g) ? h.push([h[t - 2] && "number" == typeof h[t - 2][0] ? h[t - 2][0] : null, null]) : h.push([g, null]), f >= x + (i - 1) + (o - 1) && (y = super.getValues({
            xData: u.slice(-o),
            yData: h.slice(-o)
          }, {
            period: o
          }).yData[0]), p.push([a[f], g, y]), h[t - 1][1] = y;
        }
        return {
          values: p,
          xData: u,
          yData: h
        };
      }
    }
    return l.defaultOptions = n(o.defaultOptions, {
      params: {
        index: void 0,
        period: void 0,
        periods: [14, 3]
      },
      marker: {
        enabled: !1
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span><b> {series.name}</b><br/>%K: {point.y}<br/>%D: {point.smoothed}<br/>'
      },
      smoothedLine: {
        styles: {
          lineWidth: 1,
          lineColor: void 0
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), a(l.prototype, {
      areaLinesNames: [],
      nameComponents: ["periods"],
      nameBase: "Stochastic",
      pointArrayMap: ["y", "smoothed"],
      parallelArrays: ["x", "y", "smoothed"],
      pointValKey: "y",
      linesApiNames: ["smoothedLine"]
    }), t.compose(l), s.registerSeriesType("stochastic", l), l;
  }), s(t, "Stock/Indicators/SlowStochastic/SlowStochasticIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s,
        stochastic: i
      } = e.seriesTypes,
      {
        extend: o,
        merge: a
      } = t;
    class r extends i {
      getValues(e, t) {
        let i = t.periods,
          o = super.getValues.call(this, e, t),
          a = {
            values: [],
            xData: [],
            yData: []
          };
        if (!o) return;
        a.xData = o.xData.slice(i[1] - 1);
        let r = o.yData.slice(i[1] - 1),
          n = s.prototype.getValues.call(this, {
            xData: a.xData,
            yData: r
          }, {
            index: 1,
            period: i[2]
          });
        if (n) {
          for (let e = 0, t = a.xData.length; e < t; e++) a.yData[e] = [r[e][1], n.yData[e - i[2] + 1] || null], a.values[e] = [a.xData[e], r[e][1], n.yData[e - i[2] + 1] || null];
          return a;
        }
      }
    }
    return r.defaultOptions = a(i.defaultOptions, {
      params: {
        periods: [14, 3, 3]
      }
    }), o(r.prototype, {
      nameBase: "Slow Stochastic"
    }), e.registerSeriesType("slowstochastic", r), r;
  }), s(t, "Stock/Indicators/Supertrend/SupertrendIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        atr: s,
        sma: i
      } = e.seriesTypes,
      {
        addEvent: o,
        correctFloat: a,
        isArray: r,
        extend: n,
        merge: l,
        objectEach: p
      } = t;
    function u(e, t, s) {
      return {
        index: t,
        close: e.yData[t][s],
        x: e.xData[t]
      };
    }
    class h extends i {
      init() {
        let e = this;
        super.init.apply(e, arguments);
        let t = o(this.chart.constructor, "afterLinkSeries", () => {
          if (e.options) {
            let t = e.options,
              s = e.linkedParent.options;
            t.cropThreshold = s.cropThreshold - (t.params.period - 1);
          }
          t();
        }, {
          order: 1
        });
      }
      drawGraph() {
        let e = this,
          t = e.options,
          s = e.linkedParent,
          o = s ? s.points : [],
          a = e.points,
          r = e.graph,
          n = o.length - a.length,
          h = n > 0 ? n : 0,
          d = {
            options: {
              gapSize: t.gapSize
            }
          },
          c = {
            top: [],
            bottom: [],
            intersect: []
          },
          g = {
            top: {
              styles: {
                lineWidth: t.lineWidth,
                lineColor: t.fallingTrendColor || t.color,
                dashStyle: t.dashStyle
              }
            },
            bottom: {
              styles: {
                lineWidth: t.lineWidth,
                lineColor: t.risingTrendColor || t.color,
                dashStyle: t.dashStyle
              }
            },
            intersect: t.changeTrendLine
          },
          y,
          m,
          f,
          D,
          x,
          S,
          v,
          C,
          A,
          I = a.length;
        for (; I--;) y = a[I], m = a[I - 1], f = o[I - 1 + h], D = o[I - 2 + h], x = o[I + h], S = o[I + h + 1], v = y.options.color, C = {
          x: y.x,
          plotX: y.plotX,
          plotY: y.plotY,
          isNull: !1
        }, !D && f && s.yData[f.index - 1] && (D = u(s, f.index - 1, 3)), !S && x && s.yData[x.index + 1] && (S = u(s, x.index + 1, 3)), !f && D && s.yData[D.index + 1] ? f = u(s, D.index + 1, 3) : !f && x && s.yData[x.index - 1] && (f = u(s, x.index - 1, 3)), y && f && x && D && y.x !== f.x && (y.x === x.x ? (D = f, f = x) : y.x === D.x ? (f = D, D = {
          close: s.yData[f.index - 1][3],
          x: s.xData[f.index - 1]
        }) : S && y.x === S.x && (f = S, D = x)), m && D && f ? (A = {
          x: m.x,
          plotX: m.plotX,
          plotY: m.plotY,
          isNull: !1
        }, y.y >= f.close && m.y >= D.close ? (y.color = v || t.fallingTrendColor || t.color, c.top.push(C)) : y.y < f.close && m.y < D.close ? (y.color = v || t.risingTrendColor || t.color, c.bottom.push(C)) : (c.intersect.push(C), c.intersect.push(A), c.intersect.push(l(A, {
          isNull: !0
        })), y.y >= f.close && m.y < D.close ? (y.color = v || t.fallingTrendColor || t.color, m.color = v || t.risingTrendColor || t.color, c.top.push(C), c.top.push(l(A, {
          isNull: !0
        }))) : y.y < f.close && m.y >= D.close && (y.color = v || t.risingTrendColor || t.color, m.color = v || t.fallingTrendColor || t.color, c.bottom.push(C), c.bottom.push(l(A, {
          isNull: !0
        }))))) : f && (y.y >= f.close ? (y.color = v || t.fallingTrendColor || t.color, c.top.push(C)) : (y.color = v || t.risingTrendColor || t.color, c.bottom.push(C)));
        p(c, function (t, s) {
          e.points = t, e.options = l(g[s].styles, d), e.graph = e["graph" + s + "Line"], i.prototype.drawGraph.call(e), e["graph" + s + "Line"] = e.graph;
        }), e.points = a, e.options = t, e.graph = r;
      }
      getValues(e, t) {
        let i = t.period,
          o = t.multiplier,
          n = e.xData,
          l = e.yData,
          p = [],
          u = [],
          h = [],
          d = 0 === i ? 0 : i - 1,
          c = [],
          g = [],
          y = [],
          m,
          f,
          D,
          x,
          S,
          v,
          C,
          A,
          I;
        if (!(n.length <= i) && r(l[0]) && 4 === l[0].length && !(i < 0)) {
          for (I = 0, y = s.prototype.getValues.call(this, e, {
            period: i
          }).yData; I < y.length; I++) A = l[d + I], C = l[d + I - 1] || [], x = c[I - 1], S = g[I - 1], v = h[I - 1], 0 === I && (x = S = v = 0), m = a((A[1] + A[2]) / 2 + o * y[I]), f = a((A[1] + A[2]) / 2 - o * y[I]), m < x || C[3] > x ? c[I] = m : c[I] = x, f > S || C[3] < S ? g[I] = f : g[I] = S, v === x && A[3] < c[I] || v === S && A[3] < g[I] ? D = c[I] : (v === x && A[3] > c[I] || v === S && A[3] > g[I]) && (D = g[I]), p.push([n[d + I], D]), u.push(n[d + I]), h.push(D);
          return {
            values: p,
            xData: u,
            yData: h
          };
        }
      }
    }
    return h.defaultOptions = l(i.defaultOptions, {
      params: {
        index: void 0,
        multiplier: 3,
        period: 10
      },
      risingTrendColor: "#06b535",
      fallingTrendColor: "#f21313",
      changeTrendLine: {
        styles: {
          lineWidth: 1,
          lineColor: "#333333",
          dashStyle: "LongDash"
        }
      }
    }), n(h.prototype, {
      nameBase: "Supertrend",
      nameComponents: ["multiplier", "period"]
    }), e.registerSeriesType("supertrend", h), h;
  }), s(t, "Stock/Indicators/VBP/VBPPoint.js", [t["Core/Series/SeriesRegistry.js"]], function (e) {
    let {
      sma: {
        prototype: {
          pointClass: t
        }
      }
    } = e.seriesTypes;
    return class extends t {
      destroy() {
        this.negativeGraphic && (this.negativeGraphic = this.negativeGraphic.destroy()), super.destroy.apply(this, arguments);
      }
    };
  }), s(t, "Stock/Indicators/VBP/VBPIndicator.js", [t["Stock/Indicators/VBP/VBPPoint.js"], t["Core/Animation/AnimationUtilities.js"], t["Core/Globals.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s, i, o) {
    let {
        animObject: a
      } = t,
      {
        noop: r
      } = s,
      {
        column: {
          prototype: n
        },
        sma: l
      } = i.seriesTypes,
      {
        addEvent: p,
        arrayMax: u,
        arrayMin: h,
        correctFloat: d,
        defined: c,
        error: g,
        extend: y,
        isArray: m,
        merge: f
      } = o,
      D = Math.abs;
    class x extends l {
      init(e, t) {
        let s = this;
        delete t.data, super.init.apply(s, arguments);
        let i = p(this.chart.constructor, "afterLinkSeries", function () {
          if (s.options) {
            let t = s.options.params,
              i = s.linkedParent,
              o = e.get(t.volumeSeriesID);
            s.addCustomEvents(i, o);
          }
          i();
        }, {
          order: 1
        });
        return s;
      }
      addCustomEvents(e, t) {
        let s = this,
          i = () => {
            s.chart.redraw(), s.setData([]), s.zoneStarts = [], s.zoneLinesSVG && (s.zoneLinesSVG = s.zoneLinesSVG.destroy());
          };
        return s.dataEventsToUnbind.push(p(e, "remove", function () {
          i();
        })), t && s.dataEventsToUnbind.push(p(t, "remove", function () {
          i();
        })), s;
      }
      animate(e) {
        let t = this,
          s = t.chart.inverted,
          i = t.group,
          o = {};
        if (!e && i) {
          let e = s ? t.yAxis.top : t.xAxis.left;
          s ? (i["forceAnimate:translateY"] = !0, o.translateY = e) : (i["forceAnimate:translateX"] = !0, o.translateX = e), i.animate(o, y(a(t.options.animation), {
            step: function (e, s) {
              t.group.attr({
                scaleX: Math.max(.001, s.pos)
              });
            }
          }));
        }
      }
      drawPoints() {
        this.options.volumeDivision.enabled && (this.posNegVolume(!0, !0), n.drawPoints.apply(this, arguments), this.posNegVolume(!1, !1)), n.drawPoints.apply(this, arguments);
      }
      posNegVolume(e, t) {
        let s = t ? ["positive", "negative"] : ["negative", "positive"],
          i = this.options.volumeDivision,
          o = this.points.length,
          a = [],
          r = [],
          n = 0,
          l,
          p,
          u,
          h;
        for (e ? (this.posWidths = a, this.negWidths = r) : (a = this.posWidths, r = this.negWidths); n < o; n++) (h = this.points[n])[s[0] + "Graphic"] = h.graphic, h.graphic = h[s[1] + "Graphic"], e && (l = h.shapeArgs.width, (u = (p = this.priceZones[n]).wholeVolumeData) ? (a.push(l / u * p.positiveVolumeData), r.push(l / u * p.negativeVolumeData)) : (a.push(0), r.push(0))), h.color = t ? i.styles.positiveColor : i.styles.negativeColor, h.shapeArgs.width = t ? this.posWidths[n] : this.negWidths[n], h.shapeArgs.x = t ? h.shapeArgs.x : this.posWidths[n];
      }
      translate() {
        let e = this,
          t = e.options,
          s = e.chart,
          i = e.yAxis,
          o = i.min,
          a = e.options.zoneLines,
          r = e.priceZones,
          l = 0,
          p,
          h,
          c,
          g,
          y,
          m,
          f,
          x,
          S,
          v;
        n.translate.apply(e);
        let C = e.points;
        C.length && (f = t.pointPadding < .5 ? t.pointPadding : .1, p = u(e.volumeDataArray), h = s.plotWidth / 2, x = s.plotTop, c = D(i.toPixels(o) - i.toPixels(o + e.rangeStep)), y = D(i.toPixels(o) - i.toPixels(o + e.rangeStep)), f && (g = D(c * (1 - 2 * f)), l = D((c - g) / 2), c = D(g)), C.forEach(function (t, s) {
          S = t.barX = t.plotX = 0, v = t.plotY = i.toPixels(r[s].start) - x - (i.reversed ? c - y : c) - l, m = d(h * r[s].wholeVolumeData / p), t.pointWidth = m, t.shapeArgs = e.crispCol.apply(e, [S, v, m, c]), t.volumeNeg = r[s].negativeVolumeData, t.volumePos = r[s].positiveVolumeData, t.volumeAll = r[s].wholeVolumeData;
        }), a.enabled && e.drawZones(s, i, e.zoneStarts, a.styles));
      }
      getExtremes() {
        let e;
        let t = this.options.compare,
          s = this.options.cumulative;
        return this.options.compare ? (this.options.compare = void 0, e = super.getExtremes(), this.options.compare = t) : this.options.cumulative ? (this.options.cumulative = !1, e = super.getExtremes(), this.options.cumulative = s) : e = super.getExtremes(), e;
      }
      getValues(e, t) {
        let s = e.processedXData,
          i = e.processedYData,
          o = this.chart,
          a = t.ranges,
          r = [],
          n = [],
          l = [],
          p = o.get(t.volumeSeriesID);
        if (!e.chart) {
          g("Base series not found! In case it has been removed, add a new one.", !0, o);
          return;
        }
        if (!p || !p.processedXData.length) {
          let e = p && !p.processedXData.length ? " does not contain any data." : " not found! Check `volumeSeriesID`.";
          g("Series " + t.volumeSeriesID + e, !0, o);
          return;
        }
        let u = m(i[0]);
        if (u && 4 !== i[0].length) {
          g("Type of " + e.name + " series is different than line, OHLC or candlestick.", !0, o);
          return;
        }
        return (this.priceZones = this.specifyZones(u, s, i, a, p)).forEach(function (e, t) {
          r.push([e.x, e.end]), n.push(r[t][0]), l.push(r[t][1]);
        }), {
          values: r,
          xData: n,
          yData: l
        };
      }
      specifyZones(e, t, s, i, o) {
        let a = !!e && function (e) {
            let t = e.length,
              s = e[0][3],
              i = s,
              o = 1,
              a;
            for (; o < t; o++) (a = e[o][3]) < s && (s = a), a > i && (i = a);
            return {
              min: s,
              max: i
            };
          }(s),
          r = this.zoneStarts = [],
          n = [],
          l = a ? a.min : h(s),
          p = a ? a.max : u(s),
          g = 0,
          y = 1,
          m = this.linkedParent;
        if (!this.options.compareToMain && m.dataModify && (l = m.dataModify.modifyValue(l), p = m.dataModify.modifyValue(p)), !c(l) || !c(p)) return this.points.length && (this.setData([]), this.zoneStarts = [], this.zoneLinesSVG && (this.zoneLinesSVG = this.zoneLinesSVG.destroy())), [];
        let f = this.rangeStep = d(p - l) / i;
        for (r.push(l); g < i - 1; g++) r.push(d(r[g] + f));
        r.push(p);
        let D = r.length;
        for (; y < D; y++) n.push({
          index: y - 1,
          x: t[0],
          start: r[y - 1],
          end: r[y]
        });
        return this.volumePerZone(e, n, o, t, s);
      }
      volumePerZone(e, t, s, i, o) {
        let a, r, n, l, p;
        let u = this,
          h = s.processedXData,
          d = s.processedYData,
          c = t.length - 1,
          g = o.length,
          y = d.length;
        return D(g - y) && (i[0] !== h[0] && d.unshift(0), i[g - 1] !== h[y - 1] && d.push(0)), u.volumeDataArray = [], t.forEach(function (t) {
          for (p = 0, t.wholeVolumeData = 0, t.positiveVolumeData = 0, t.negativeVolumeData = 0; p < g; p++) {
            r = !1, n = !1, l = e ? o[p][3] : o[p], a = p ? e ? o[p - 1][3] : o[p - 1] : l;
            let s = u.linkedParent;
            !u.options.compareToMain && s.dataModify && (l = s.dataModify.modifyValue(l), a = s.dataModify.modifyValue(a)), l <= t.start && 0 === t.index && (r = !0), l >= t.end && t.index === c && (n = !0), (l > t.start || r) && (l < t.end || n) && (t.wholeVolumeData += d[p], a > l ? t.negativeVolumeData += d[p] : t.positiveVolumeData += d[p]);
          }
          u.volumeDataArray.push(t.wholeVolumeData);
        }), t;
      }
      drawZones(e, t, s, i) {
        let o = e.renderer,
          a = e.plotWidth,
          r = e.plotTop,
          n = this.zoneLinesSVG,
          l = [],
          p;
        s.forEach(function (s) {
          p = t.toPixels(s) - r, l = l.concat(e.renderer.crispLine([["M", 0, p], ["L", a, p]], i.lineWidth));
        }), n ? n.animate({
          d: l
        }) : n = this.zoneLinesSVG = o.path(l).attr({
          "stroke-width": i.lineWidth,
          stroke: i.color,
          dashstyle: i.dashStyle,
          zIndex: this.group.zIndex + .1
        }).add(this.group);
      }
    }
    return x.defaultOptions = f(l.defaultOptions, {
      params: {
        index: void 0,
        period: void 0,
        ranges: 12,
        volumeSeriesID: "volume"
      },
      zoneLines: {
        enabled: !0,
        styles: {
          color: "#0A9AC9",
          dashStyle: "LongDash",
          lineWidth: 1
        }
      },
      volumeDivision: {
        enabled: !0,
        styles: {
          positiveColor: "rgba(144, 237, 125, 0.8)",
          negativeColor: "rgba(244, 91, 91, 0.8)"
        }
      },
      animationLimit: 1e3,
      enableMouseTracking: !1,
      pointPadding: 0,
      zIndex: -1,
      crisp: !0,
      dataGrouping: {
        enabled: !1
      },
      dataLabels: {
        allowOverlap: !0,
        enabled: !0,
        format: "P: {point.volumePos:.2f} | N: {point.volumeNeg:.2f}",
        padding: 0,
        style: {
          fontSize: "0.5em"
        },
        verticalAlign: "top"
      }
    }), y(x.prototype, {
      nameBase: "Volume by Price",
      nameComponents: ["ranges"],
      calculateOn: {
        chart: "render",
        xAxis: "afterSetExtremes"
      },
      pointClass: e,
      markerAttribs: r,
      drawGraph: r,
      getColumnMetrics: n.getColumnMetrics,
      crispCol: n.crispCol
    }), i.registerSeriesType("vbp", x), x;
  }), s(t, "Stock/Indicators/VWAP/VWAPIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        error: i,
        isArray: o,
        merge: a
      } = t;
    class r extends s {
      getValues(e, t) {
        let s = e.chart,
          a = e.xData,
          r = e.yData,
          n = t.period,
          l = !0,
          p;
        if (!(p = s.get(t.volumeSeriesID))) {
          i("Series " + t.volumeSeriesID + " not found! Check `volumeSeriesID`.", !0, s);
          return;
        }
        return o(r[0]) || (l = !1), this.calculateVWAPValues(l, a, r, p, n);
      }
      calculateVWAPValues(e, t, s, i, o) {
        let a, r, n, l, p, u;
        let h = i.yData,
          d = i.xData.length,
          c = t.length,
          g = [],
          y = [],
          m = [],
          f = [],
          D = [];
        for (p = 0, a = c <= d ? c : d, u = 0; p < a; p++) r = (e ? (s[p][1] + s[p][2] + s[p][3]) / 3 : s[p]) * h[p], n = u ? g[p - 1] + r : r, l = u ? y[p - 1] + h[p] : h[p], g.push(n), y.push(l), D.push([t[p], n / l]), m.push(D[p][0]), f.push(D[p][1]), ++u === o && (u = 0);
        return {
          values: D,
          xData: m,
          yData: f
        };
      }
    }
    return r.defaultOptions = a(s.defaultOptions, {
      params: {
        index: void 0,
        period: 30,
        volumeSeriesID: "volume"
      }
    }), e.registerSeriesType("vwap", r), r;
  }), s(t, "Stock/Indicators/WilliamsR/WilliamsRIndicator.js", [t["Stock/Indicators/ArrayUtilities.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        extend: o,
        isArray: a,
        merge: r
      } = s;
    class n extends i {
      getValues(t, s) {
        let i, o, r, n, l, p;
        let u = s.period,
          h = t.xData,
          d = t.yData,
          c = d ? d.length : 0,
          g = [],
          y = [],
          m = [];
        if (!(h.length < u) && a(d[0]) && 4 === d[0].length) {
          for (p = u - 1; p < c; p++) i = d.slice(p - u + 1, p + 1), l = (o = e.getArrayExtremes(i, 2, 1))[0], r = -(((n = o[1]) - d[p][3]) / (n - l) * 100), h[p] && (g.push([h[p], r]), y.push(h[p]), m.push(r));
          return {
            values: g,
            xData: y,
            yData: m
          };
        }
      }
    }
    return n.defaultOptions = r(i.defaultOptions, {
      params: {
        index: void 0,
        period: 14
      }
    }), o(n.prototype, {
      nameBase: "Williams %R"
    }), t.registerSeriesType("williamsr", n), n;
  }), s(t, "Stock/Indicators/WMA/WMAIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isArray: i,
        merge: o
      } = t;
    function a(e, t, s, i, o) {
      let a = t[i],
        r = o < 0 ? s[i] : s[i][o];
      e.push([a, r]);
    }
    function r(e, t, s, i) {
      let o = e.length,
        a = e.reduce(function (e, t, s) {
          return [null, e[1] + t[1] * (s + 1)];
        })[1] / ((o + 1) / 2 * o),
        r = t[i - 1];
      return e.shift(), [r, a];
    }
    class n extends s {
      getValues(e, t) {
        let s = t.period,
          o = e.xData,
          n = e.yData,
          l = n ? n.length : 0,
          p = o[0],
          u = [],
          h = [],
          d = [],
          c = 1,
          g = -1,
          y,
          m,
          f = n[0];
        if (o.length < s) return;
        i(n[0]) && (g = t.index, f = n[0][g]);
        let D = [[p, f]];
        for (; c !== s;) a(D, o, n, c, g), c++;
        for (y = c; y < l; y++) u.push(m = r(D, o, n, y)), h.push(m[0]), d.push(m[1]), a(D, o, n, y, g);
        return u.push(m = r(D, o, n, y)), h.push(m[0]), d.push(m[1]), {
          values: u,
          xData: h,
          yData: d
        };
      }
    }
    return n.defaultOptions = o(s.defaultOptions, {
      params: {
        index: 3,
        period: 9
      }
    }), e.registerSeriesType("wma", n), n;
  }), s(t, "Stock/Indicators/Zigzag/ZigzagIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        merge: i,
        extend: o
      } = t;
    class a extends s {
      getValues(e, t) {
        let s = t.lowIndex,
          i = t.highIndex,
          o = t.deviation / 100,
          a = {
            low: 1 + o,
            high: 1 - o
          },
          r = e.xData,
          n = e.yData,
          l = n ? n.length : 0,
          p = [],
          u = [],
          h = [],
          d,
          c,
          g,
          y,
          m = !1,
          f = !1;
        if (!r || r.length <= 1 || l && (void 0 === n[0][s] || void 0 === n[0][i])) return;
        let D = n[0][s],
          x = n[0][i];
        for (d = 1; d < l; d++) n[d][s] <= x * a.high ? (p.push([r[0], x]), g = [r[d], n[d][s]], y = !0, m = !0) : n[d][i] >= D * a.low && (p.push([r[0], D]), g = [r[d], n[d][i]], y = !1, m = !0), m && (u.push(p[0][0]), h.push(p[0][1]), c = d++, d = l);
        for (d = c; d < l; d++) y ? (n[d][s] <= g[1] && (g = [r[d], n[d][s]]), n[d][i] >= g[1] * a.low && (f = i)) : (n[d][i] >= g[1] && (g = [r[d], n[d][i]]), n[d][s] <= g[1] * a.high && (f = s)), !1 !== f && (p.push(g), u.push(g[0]), h.push(g[1]), g = [r[d], n[d][f]], y = !y, f = !1);
        let S = p.length;
        return 0 !== S && p[S - 1][0] < r[l - 1] && (p.push(g), u.push(g[0]), h.push(g[1])), {
          values: p,
          xData: u,
          yData: h
        };
      }
    }
    return a.defaultOptions = i(s.defaultOptions, {
      params: {
        index: void 0,
        period: void 0,
        lowIndex: 2,
        highIndex: 1,
        deviation: 1
      }
    }), o(a.prototype, {
      nameComponents: ["deviation"],
      nameSuffixes: ["%"],
      nameBase: "Zig Zag"
    }), e.registerSeriesType("zigzag", a), a;
  }), s(t, "Stock/Indicators/LinearRegression/LinearRegressionIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        isArray: i,
        extend: o,
        merge: a
      } = t;
    class r extends s {
      getRegressionLineParameters(e, t) {
        let s = this.options.params.index,
          o = function (e, t) {
            return i(e) ? e[t] : e;
          },
          a = e.reduce(function (e, t) {
            return t + e;
          }, 0),
          r = t.reduce(function (e, t) {
            return o(t, s) + e;
          }, 0),
          n = a / e.length,
          l = r / t.length,
          p,
          u,
          h = 0,
          d = 0;
        for (u = 0; u < e.length; u++) h += (p = e[u] - n) * (o(t[u], s) - l), d += Math.pow(p, 2);
        let c = d ? h / d : 0;
        return {
          slope: c,
          intercept: l - c * n
        };
      }
      getEndPointY(e, t) {
        return e.slope * t + e.intercept;
      }
      transformXData(e, t) {
        let s = e[0];
        return e.map(function (e) {
          return (e - s) / t;
        });
      }
      findClosestDistance(e) {
        let t, s, i;
        for (i = 1; i < e.length - 1; i++) (t = e[i] - e[i - 1]) > 0 && (void 0 === s || t < s) && (s = t);
        return s;
      }
      getValues(e, t) {
        let s, i, o, a, r, n, l, p, u;
        let h = e.xData,
          d = e.yData,
          c = t.period,
          g = {
            xData: [],
            yData: [],
            values: []
          },
          y = this.options.params.xAxisUnit || this.findClosestDistance(h);
        for (i = c - 1; i <= h.length - 1; i++) o = i - c + 1, a = i + 1, r = h[i], l = h.slice(o, a), p = d.slice(o, a), u = this.transformXData(l, y), s = this.getRegressionLineParameters(u, p), n = this.getEndPointY(s, u[u.length - 1]), g.values.push({
          regressionLineParameters: s,
          x: r,
          y: n
        }), g.xData.push(r), g.yData.push(n);
        return g;
      }
    }
    return r.defaultOptions = a(s.defaultOptions, {
      params: {
        xAxisUnit: null
      },
      tooltip: {
        valueDecimals: 4
      }
    }), o(r.prototype, {
      nameBase: "Linear Regression Indicator"
    }), e.registerSeriesType("linearRegression", r), r;
  }), s(t, "Stock/Indicators/LinearRegressionSlopes/LinearRegressionSlopesIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        linearRegression: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o
      } = t;
    class a extends s {
      getEndPointY(e) {
        return e.slope;
      }
    }
    return a.defaultOptions = o(s.defaultOptions), i(a.prototype, {
      nameBase: "Linear Regression Slope Indicator"
    }), e.registerSeriesType("linearRegressionSlope", a), a;
  }), s(t, "Stock/Indicators/LinearRegressionIntercept/LinearRegressionInterceptIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        linearRegression: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o
      } = t;
    class a extends s {
      getEndPointY(e) {
        return e.intercept;
      }
    }
    return a.defaultOptions = o(s.defaultOptions), i(a.prototype, {
      nameBase: "Linear Regression Intercept Indicator"
    }), e.registerSeriesType("linearRegressionIntercept", a), a;
  }), s(t, "Stock/Indicators/LinearRegressionAngle/LinearRegressionAngleIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        linearRegression: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o
      } = t;
    class a extends s {
      slopeToAngle(e) {
        return 180 / Math.PI * Math.atan(e);
      }
      getEndPointY(e) {
        return this.slopeToAngle(e.slope);
      }
    }
    return a.defaultOptions = o(s.defaultOptions, {
      tooltip: {
        pointFormat: '<span style="color:{point.color}">●</span>{series.name}: <b>{point.y}\xb0</b><br/>'
      }
    }), i(a.prototype, {
      nameBase: "Linear Regression Angle Indicator"
    }), e.registerSeriesType("linearRegressionAngle", a), a;
  }), s(t, "Stock/Indicators/ABands/ABandsIndicator.js", [t["Stock/Indicators/MultipleLinesComposition.js"], t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t, s) {
    let {
        sma: i
      } = t.seriesTypes,
      {
        correctFloat: o,
        extend: a,
        merge: r
      } = s;
    class n extends i {
      getValues(e, t) {
        let s, i, a, r, n, l, p, u, h, d, c;
        let g = t.period,
          y = t.factor,
          m = t.index,
          f = e.xData,
          D = e.yData,
          x = D ? D.length : 0,
          S = [],
          v = [],
          C = [],
          A = [],
          I = [];
        if (!(x < g)) {
          for (c = 0; c <= x; c++) {
            if (c < x) {
              var T, P;
              T = D[c][2], n = o((P = D[c][1]) - T) / (o(P + T) / 2) * 1e3 * y, S.push(D[c][1] * o(1 + 2 * n)), v.push(D[c][2] * o(1 - 2 * n));
            }
            c >= g && (h = f.slice(c - g, c), d = D.slice(c - g, c), p = super.getValues.call(this, {
              xData: h,
              yData: S.slice(c - g, c)
            }, {
              period: g
            }), u = super.getValues.call(this, {
              xData: h,
              yData: v.slice(c - g, c)
            }, {
              period: g
            }), r = (l = super.getValues.call(this, {
              xData: h,
              yData: d
            }, {
              period: g,
              index: m
            })).xData[0], i = p.yData[0], a = u.yData[0], s = l.yData[0], C.push([r, i, s, a]), A.push(r), I.push([i, s, a]));
          }
          return {
            values: C,
            xData: A,
            yData: I
          };
        }
      }
    }
    return n.defaultOptions = r(i.defaultOptions, {
      params: {
        period: 20,
        factor: .001,
        index: 3
      },
      lineWidth: 1,
      topLine: {
        styles: {
          lineWidth: 1
        }
      },
      bottomLine: {
        styles: {
          lineWidth: 1
        }
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), a(n.prototype, {
      areaLinesNames: ["top", "bottom"],
      linesApiNames: ["topLine", "bottomLine"],
      nameBase: "Acceleration Bands",
      nameComponents: ["period", "factor"],
      pointArrayMap: ["top", "middle", "bottom"],
      pointValKey: "middle"
    }), e.compose(n), t.registerSeriesType("abands", n), n;
  }), s(t, "Stock/Indicators/TrendLine/TrendLineIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        extend: i,
        merge: o,
        isArray: a
      } = t;
    class r extends s {
      constructor() {
        super(...arguments), this.updateAllPoints = !0;
      }
      getValues(e, t) {
        let s = e.xData,
          i = e.yData,
          o = [],
          r = [],
          n = [],
          l = [],
          p = t.index,
          u = 0,
          h = 0,
          d = 0,
          c = 0,
          g = 0;
        for (let e = 0; e < s.length; e++) (0 === e || s[e] !== s[e - 1]) && g++, o.push(g);
        for (let e = 0; e < o.length; e++) d += o[e], c += a(i[e]) ? i[e][p] : i[e];
        let y = d / o.length,
          m = c / i.length;
        for (let e = 0; e < o.length; e++) {
          let t = a(i[e]) ? i[e][p] : i[e];
          u += (o[e] - y) * (t - m), h += Math.pow(o[e] - y, 2);
        }
        for (let e = 0; e < o.length; e++) {
          if (s[e] === n[n.length - 1]) continue;
          let t = s[e],
            i = m + u / h * (o[e] - y);
          r.push([t, i]), n.push(t), l.push(i);
        }
        return {
          xData: n,
          yData: l,
          values: r
        };
      }
    }
    return r.defaultOptions = o(s.defaultOptions, {
      params: {
        period: void 0,
        index: 3
      }
    }), i(r.prototype, {
      nameBase: "Trendline",
      nameComponents: void 0
    }), e.registerSeriesType("trendline", r), r;
  }), s(t, "Stock/Indicators/DisparityIndex/DisparityIndexIndicator.js", [t["Core/Series/SeriesRegistry.js"], t["Core/Utilities.js"]], function (e, t) {
    let {
        sma: s
      } = e.seriesTypes,
      {
        correctFloat: i,
        defined: o,
        extend: a,
        isArray: r,
        merge: n
      } = t;
    class l extends s {
      init() {
        let t = arguments,
          i = t[1].params,
          o = i && i.average ? i.average : void 0;
        this.averageIndicator = e.seriesTypes[o] || s, this.averageIndicator.prototype.init.apply(this, t);
      }
      calculateDisparityIndex(e, t) {
        return i(e - t) / t * 100;
      }
      getValues(e, t) {
        let s = t.index,
          i = e.xData,
          a = e.yData,
          n = a ? a.length : 0,
          l = [],
          p = [],
          u = [],
          h = this.averageIndicator,
          d = r(a[0]),
          c = h.prototype.getValues(e, t),
          g = c.yData,
          y = i.indexOf(c.xData[0]);
        if (g && 0 !== g.length && o(s) && !(a.length <= y)) {
          for (let e = y; e < n; e++) {
            let t = this.calculateDisparityIndex(d ? a[e][s] : a[e], g[e - y]);
            l.push([i[e], t]), p.push(i[e]), u.push(t);
          }
          return {
            values: l,
            xData: p,
            yData: u
          };
        }
      }
    }
    return l.defaultOptions = n(s.defaultOptions, {
      params: {
        average: "sma",
        index: 3
      },
      marker: {
        enabled: !1
      },
      dataGrouping: {
        approximation: "averages"
      }
    }), a(l.prototype, {
      nameBase: "Disparity Index",
      nameComponents: ["period", "average"]
    }), e.registerSeriesType("disparityindex", l), l;
  }), s(t, "masters/indicators/indicators-all.src.js", [t["Core/Globals.js"]], function (e) {
    return e;
  });
});
<div style="padding-top:2em;" class="date-selector">
  <label>From:</label>
  <input type="date" [(ngModel)]="fromDate">
  <label>To:</label>
  <input type="date" [(ngModel)]="toDate">
  <button (click)="filterEvents()">Apply</button>
</div>

<div class="economic-calendar">
  <table style="width:74em; padding-left:1em;">
    <thead>
      <tr>
        <th>Symbol</th>
        <th>Date</th>
        <th>Actual EPS</th>
        <th>Estimated EPS</th>
        <th>Actual Revenue</th>
        <th>Estimated Revenue</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of filteredEvents.slice(0, eventsPerPage)" >
        <td>{{ event.symbol }}</td>
        <td>{{ event.date | date: 'yyyy-MM-dd' }}</td>
        <td>{{ event.eps }}</td>
        <td>{{ event.epsEstimated }}</td>
        <td>{{ event.revenue | currency:'USD' }}</td>
        <td>{{ event.revenueEstimated | currency:'USD' }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="filteredEvents.length == 0">
    <ngx-skeleton-loader count="20" appearance="line"></ngx-skeleton-loader>
  </div>
  <div *ngIf="filteredEvents.length != eventsPerPage">
    <button (click)="showMoreEvents()">Show More</button>
  </div>
</div>







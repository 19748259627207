
<ng-container *ngIf="typ==='all'">
  <h2 mat-dialog-title>Filter Settings</h2>
  <div mat-dialog-content class="columns-container">
    <!-- Tab switcher -->
    <div *ngIf="!selectedTab" class="tab-switcher">
        <button (click)="selectedTab = 'tab1'" class="tab-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20" style="margin-right: 5px;">
              <path fill="currentColor" d="M4 14a10 10 0 1 1 20 0 10 10 0 0 1-20 0zM14 3a11 11 0 1 0 0 22 11 11 0 0 0 0-22zm1 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 11v-7h-1v-1h2v8h-1z"></path>
          </svg>
          Security Info
        </button>
        <button (click)="selectedTab = 'tab2'" class="tab-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20" style="margin-right: 5px;">
              <path fill="currentColor" fill-rule="evenodd" d="M11 4h-1v3H8.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H10v3h1v-3h1.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11V4ZM9 8v12h3V8H9Zm10-1h-1v3h-1.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5H18v3h1v-3h1.5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5H19V7Zm-2 10v-6h3v6h-3Z"></path>
          </svg>
          Market Data
        </button>
        <button (click)="selectedTab = 'tab3'" class="tab-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20" style="margin-right: 5px;">
              <path fill="currentColor" fill-rule="evenodd" d="M15 3h.5a9.5 9.5 0 0 1 9.5 9.5v.5H15V3Zm1 5.7v2.6l5.15-5.15a8.52 8.52 0 0 0-1.51-1.08L16 8.71Zm0-1.4 2.68-2.69a8.46 8.46 0 0 0-2.68-.6V7.3Zm3.3 4.7h-2.6l5.15-5.15c.41.47.77.97 1.08 1.51L19.29 12Zm1.4 0 2.69-2.68c.33.84.54 1.74.6 2.68H20.7ZM5 14.5a8.5 8.5 0 0 1 8-8.49V15h8.99A8.5 8.5 0 0 1 5 14.5ZM13.5 5a9.5 9.5 0 1 0 9.5 9.5V14h-9V5h-.5Z"></path>
          </svg>
          Valuation Metrics
      </button>
      <button (click)="selectedTab = 'tab4'" class="tab-button">
        <!-- Fourth SVG Icon -->
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
          <path fill="currentColor" d="m15.4 11.3 6-8-.8-.6-6 8 .8.6Z"></path>
          <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M17 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-1 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM21 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM21 22c0 .84-.67 1.47-1.45 1.86-.8.4-1.88.64-3.05.64a6.98 6.98 0 0 1-3.05-.64C12.67 23.47 12 22.84 12 22v-1.4c-.14.1-.3.18-.45.26-.8.4-1.88.64-3.05.64a6.98 6.98 0 0 1-3.05-.64C4.67 20.47 4 19.84 4 19v-9c0-.84.67-1.47 1.45-1.86.8-.4 1.88-.64 3.05-.64s2.25.24 3.05.64C12.33 8.53 13 9.16 13 10v4.4c.14-.1.3-.18.45-.26.8-.4 1.88-.64 3.05-.64s2.25.24 3.05.64c.78.39 1.45 1.02 1.45 1.86v6ZM5 19v-1.4c.14.1.3.18.45.26.8.4 1.88.64 3.05.64s2.25-.24 3.05-.64c.16-.08.3-.16.45-.26V19c0 .26-.22.63-.9.97a6 6 0 0 1-2.6.53 6 6 0 0 1-2.6-.53c-.68-.34-.9-.7-.9-.97Zm8 1.6V22c0 .26.22.63.9.97a6 6 0 0 0 2.6.53 6 6 0 0 0 2.6-.53c.68-.34.9-.7.9-.97v-1.4c-.14.1-.3.18-.45.26-.8.4-1.88.64-3.05.64a6.98 6.98 0 0 1-3.05-.64 3.9 3.9 0 0 1-.45-.26Zm0-3V19c0 .26.22.63.9.97a6 6 0 0 0 2.6.53 6 6 0 0 0 2.6-.53c.68-.34.9-.7.9-.97v-1.4c-.14.1-.3.18-.45.26-.8.4-1.88.64-3.05.64a6.98 6.98 0 0 1-3.05-.64 3.9 3.9 0 0 1-.45-.26ZM12 16v-1.4c-.14.1-.3.18-.45.26-.8.4-1.88.64-3.05.64a6.98 6.98 0 0 1-3.05-.64c-.16-.08-.3-.16-.45-.26V16c0 .26.22.63.9.97a6 6 0 0 0 2.6.53 6 6 0 0 0 2.6-.53c.68-.34.9-.7.9-.97Zm0-4.4V13c0 .26-.22.63-.9.97a6 6 0 0 1-2.6.53 6 6 0 0 1-2.6-.53c-.68-.34-.9-.7-.9-.97v-1.4c.14.1.3.18.45.26.8.4 1.88.64 3.05.64s2.25-.24 3.05-.64c.16-.08.3-.16.45-.26ZM5 10c0-.26.22-.63.9-.97a6 6 0 0 1 2.6-.53 6 6 0 0 1 2.6.53c.68.34.9.7.9.97 0 .26-.22.63-.9.97a6 6 0 0 1-2.6.53 6 6 0 0 1-2.6-.53c-.68-.34-.9-.7-.9-.97Zm8.9 5.03c-.68.34-.9.7-.9.97 0 .26.22.63.9.97a6 6 0 0 0 2.6.53 6 6 0 0 0 2.6-.53c.68-.34.9-.7.9-.97 0-.26-.22-.63-.9-.97a6 6 0 0 0-2.6-.53 6 6 0 0 0-2.6.53Z"></path>
        </svg>
        Margins & Ratios
      </button>
    </div>

    <!-- Back button and keys display -->
    <div *ngIf="selectedTab">
        <button (click)="selectedTab = null"> < </button> <!-- Back button -->
        
        <div *ngFor="let key of getKeysForTab(selectedTab); let i = index">
            <div class="price-range">
                <div class="price-range column">
                    <div class="input-container">
                        <label (click)="addKeyToArray(key)">{{ keyDescriptions[key] | titlecase }}:</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Close</button>
</div>
</ng-container>

<ng-container *ngIf="typ==='single'">
  <div class="container">
    <h2 class="form-label">{{keyDescriptions[this.keys2]}}</h2>
    <div class="input-container">
      <label>{{ keyDescriptions[keys2] | titlecase }}:</label>

      <div *ngIf="keys2 === 'sector'; else industryCheck">
        <select [ngModel]="filterValues[keys2]?.Value" (ngModelChange)="initializeSec(keys2, $event)" [name]="keys2 + 'Value'">
          <option *ngFor="let sector of sectors" [value]="sector">{{ sector }}</option>
        </select>
      </div>

      <ng-template #industryCheck>
        <div *ngIf="keys2 === 'industry'; else otherOptions">
          <select [ngModel]="filterValues[keys2]?.Value" (ngModelChange)="initializeSec(keys2, $event)" [name]="keys2 + 'Value'">
            <option *ngFor="let industry of industries" [value]="industry">{{ industry }}</option>
          </select>
        </div>
      </ng-template>

      <ng-template #otherOptions>
        <select [ngModel]="filterValues[keys2]?.Operator" (ngModelChange)="initializeOperator(keys2, $event)" [name]="keys2 + 'Operator'">
          <option value="greater">Greater Than</option>
          <option value="greater_or_equal">Greater Than or Equal</option>
          <option value="equal">Equal</option>
          <option value="less">Less Than</option>
          <option value="less_or_equal">Less Than or Equal</option>
        </select>
        <input type="text" [ngModel]="filterValues[keys2]?.Value" (ngModelChange)="initializeValue(keys2, $event)" [placeholder]="value" [name]="keys2">
      </ng-template>
      
    </div>
    <button class="apply-button" (click)="onNoClick()">Apply</button>
  </div>


</ng-container>

import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationBarComponent } from '../navigation-bar/navigation-bar.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';
import { sampleCorrelation } from 'simple-statistics';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-covariance',
  standalone: true,
  imports: [RouterModule, NavigationBarComponent, FormsModule, CommonModule],
  templateUrl: './covariance.component.html',
  styleUrls: ['./covariance.component.css']
})
export class CovarianceComponent implements OnInit {
  ticker1Str: string = '';
  ticker2Str: string = '';
  fromDate: string = '';
  toDate: string = '';
  stockData1: any = [];
  stockData2: any = [];
  covariance: any = undefined;

  constructor(private stockDataService: StockDataService, private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    // Set SEO title and meta description for the Covariance Calculator page
    const title = 'Covariance Calculator - Measure Correlation Between Stocks';
    const description = 'Use our Covariance Calculator to measure the correlation between two stocks based on historical price data. Easily analyze their relationship and make informed investment decisions.';
    
    // Set title
    this.titleService.setTitle(title);

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
  }

  extractCloseValues(data: any[]): number[] {
    return data.map(point => point.close);
  }

  async onSubmit() {
    this.stockData1 = await this.stockDataService.getStockData(this.ticker1Str, this.fromDate, this.toDate).toPromise();
    this.stockData2 = await this.stockDataService.getStockData(this.ticker2Str, this.fromDate, this.toDate).toPromise();

    const stock1 = this.extractCloseValues(this.stockData1.historical);
    const stock2 = this.extractCloseValues(this.stockData2.historical);
    this.covariance = sampleCorrelation(stock1, stock2);
  }
}

import { ChangeDetectorRef, Component, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';
import { RouterModule } from '@angular/router';
import { ChartStripComponent } from '../chart-strip/chart-strip.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-stock-screener',
  standalone: true,
  imports: [RouterModule, FormsModule, CommonModule, ChartStripComponent, PopupComponent, NgxSkeletonLoaderModule],
  templateUrl: './stock-screener.component.html',
  styleUrl: './stock-screener.component.css'
})
export class StockScreenerComponent {
  keys: string[] = [
    'price',
    'volume',
    'dividend',
    'sector',
    'industry',
    'revenuePerShare',
    'netIncomePerShare',
    'operatingCashFlowPerShare',
    'freeCashFlowPerShare',
    'cashPerShare',
    'bookValuePerShare',
    'tangibleBookValuePerShare',
    'shareholdersEquityPerShare',
    'interestDebtPerShare',
    'marketCap',
    'enterpriseValue',
    'peRatio',
    'priceToSalesRatio',
    'pocfratio',
    'pfcfRatio',
    'pbRatio',
    'ptbRatio',
    'evToSales',
    'enterpriseValueOverEBITDA',
    'evToOperatingCashFlow',
    'evToFreeCashFlow',
    'earningsYield',
    'freeCashFlowYield',
    'debtToEquity',
    'debtToAssets',
    'netDebtToEBITDA',
    'currentRatio',
    'interestCoverage',
    'incomeQuality',
    'dividendYield',
    'payoutRatio',
    'salesGeneralAndAdministrativeToRevenue',
    'researchAndDdevelopementToRevenue',
    'intangiblesToTotalAssets',
    'capexToOperatingCashFlow',
    'capexToRevenue',
    'capexToDepreciation',
    'stockBasedCompensationToRevenue',
    'grahamNumber',
    'roic',
    'returnOnTangibleAssets',
    'grahamNetNet',
    'workingCapital',
    'tangibleAssetValue',
    'netCurrentAssetValue',
    'investedCapital',
    'averageReceivables',
    'averagePayables',
    'averageInventory',
    'daysSalesOutstanding',
    'daysPayablesOutstanding',
    'daysOfInventoryOnHand',
    'receivablesTurnover',
    'payablesTurnover',
    'inventoryTurnover',
    'roe',
    'capexPerShare'
  ];

  // Categorized arrays
  securityInfo: string[] = [
    'sector',
    'industry',
    'marketCap',
    'enterpriseValue',
    'price',
    'dividend',
  ];

  marketData: string[] = [
    'volume',
    'price',
    'marketCap',
    'enterpriseValue',
  ];

  valuation: string[] = [
    'peRatio',
    'priceToSalesRatio',
    'pbRatio',
    'pfcfRatio',
    'evToSales',
    'enterpriseValueOverEBITDA',
    'evToOperatingCashFlow',
    'evToFreeCashFlow',
    'earningsYield',
    'freeCashFlowYield',
    'grahamNumber',
    'grahamNetNet',
  ];

  marginsAndRatios: string[] = [
    'revenuePerShare',
    'netIncomePerShare',
    'operatingCashFlowPerShare',
    'freeCashFlowPerShare',
    'cashPerShare',
    'bookValuePerShare',
    'tangibleBookValuePerShare',
    'shareholdersEquityPerShare',
    'interestDebtPerShare',
    'debtToEquity',
    'debtToAssets',
    'netDebtToEBITDA',
    'currentRatio',
    'interestCoverage',
    'incomeQuality',
    'dividendYield',
    'payoutRatio',
    'salesGeneralAndAdministrativeToRevenue',
    'researchAndDdevelopementToRevenue',
    'intangiblesToTotalAssets',
    'capexToOperatingCashFlow',
    'capexToRevenue',
    'capexToDepreciation',
    'stockBasedCompensationToRevenue',
    'roe',
    'roic',
    'workingCapital',
    'tangibleAssetValue',
    'netCurrentAssetValue',
    'investedCapital',
    'averageReceivables',
    'averagePayables',
    'averageInventory',
    'daysSalesOutstanding',
    'daysPayablesOutstanding',
    'daysOfInventoryOnHand',
    'receivablesTurnover',
    'payablesTurnover',
    'inventoryTurnover',
    'capexPerShare',
  ];

  keyShow: string[] = [
    'price',
    'sector',
    'industry',
    'revenuePerShare',
    'netIncomePerShare',
    'operatingCashFlowPerShare',
    'freeCashFlowPerShare',
  ];

  keyDescriptions: { [key: string]: string } = this.stockService.keyDescriptions;


  priceStr: string | undefined;
  volumeStr: string | undefined;
  dividendStr: string | undefined;
  sectorStr: string | undefined;
  industryStr: string | undefined;

  // Operators for each input
  priceOperator: string = 'greater';
  volumeOperator: string = 'greater';
  dividendOperator: string = 'greater';
  sectorOperator: string = 'greater';
  industryOperator: string = 'greater';

  revenuePerShareOperator: string = 'greater';
  revenuePerShareStr: string = '';

  netIncomePerShareOperator: string = 'greater';
  netIncomePerShareStr: string = '';

  operatingCashFlowPerShareOperator: string = 'greater';
  operatingCashFlowPerShareStr: string = '';

  freeCashFlowPerShareOperator: string = 'greater';
  freeCashFlowPerShareStr: string = '';

  cashPerShareOperator: string = 'greater';
  cashPerShareStr: string = '';

  bookValuePerShareOperator: string = 'greater';
  bookValuePerShareStr: string = '';

  tangibleBookValuePerShareOperator: string = 'greater';
  tangibleBookValuePerShareStr: string = '';

  shareholdersEquityPerShareOperator: string = 'greater';
  shareholdersEquityPerShareStr: string = '';

  interestDebtPerShareOperator: string = 'greater';
  interestDebtPerShareStr: string = '';

  marketCapOperator: string = 'greater';
  marketCapStr: string = '';

  enterpriseValueOperator: string = 'greater';
  enterpriseValueStr: string = '';

  peRatioOperator: string = 'greater';
  peRatioStr: string = '';

  priceToSalesRatioOperator: string = 'greater';
  priceToSalesRatioStr: string = '';

  pocfratioOperator: string = 'greater';
  pocfratioStr: string = '';

  pfcfRatioOperator: string = 'greater';
  pfcfRatioStr: string = '';

  pbRatioOperator: string = 'greater';
  pbRatioStr: string = '';

  ptbRatioOperator: string = 'greater';
  ptbRatioStr: string = '';

  evToSalesOperator: string = 'greater';
  evToSalesStr: string = '';

  enterpriseValueOverEBITDAOperator: string = 'greater';
  enterpriseValueOverEBITDAStr: string = '';

  evToOperatingCashFlowOperator: string = 'greater';
  evToOperatingCashFlowStr: string = '';

  evToFreeCashFlowOperator: string = 'greater';
  evToFreeCashFlowStr: string = '';

  earningsYieldOperator: string = 'greater';
  earningsYieldStr: string = '';

  freeCashFlowYieldOperator: string = 'greater';
  freeCashFlowYieldStr: string = '';

  debtToEquityOperator: string = 'greater';
  debtToEquityStr: string = '';

  debtToAssetsOperator: string = 'greater';
  debtToAssetsStr: string = '';

  netDebtToEBITDAOperator: string = 'greater';
  netDebtToEBITDAStr: string = '';

  currentRatioOperator: string = 'greater';
  currentRatioStr: string = '';

  interestCoverageOperator: string = 'greater';
  interestCoverageStr: string = '';

  incomeQualityOperator: string = 'greater';
  incomeQualityStr: string = '';

  dividendYieldOperator: string = 'greater';
  dividendYieldStr: string = '';

  payoutRatioOperator: string = 'greater';
  payoutRatioStr: string = '';

  salesGeneralAndAdministrativeToRevenueOperator: string = 'greater';
  salesGeneralAndAdministrativeToRevenueStr: string = '';

  researchAndDdevelopementToRevenueOperator: string = 'greater';
  researchAndDdevelopementToRevenueStr: string = '';

  intangiblesToTotalAssetsOperator: string = 'greater';
  intangiblesToTotalAssetsStr: string = '';

  capexToOperatingCashFlowOperator: string = 'greater';
  capexToOperatingCashFlowStr: string = '';

  capexToRevenueOperator: string = 'greater';
  capexToRevenueStr: string = '';

  capexToDepreciationOperator: string = 'greater';
  capexToDepreciationStr: string = '';

  stockBasedCompensationToRevenueOperator: string = 'greater';
  stockBasedCompensationToRevenueStr: string = '';

  grahamNumberOperator: string = 'greater';
  grahamNumberStr: string = '';

  roicOperator: string = 'greater';
  roicStr: string = '';

  returnOnTangibleAssetsOperator: string = 'greater';
  returnOnTangibleAssetsStr: string = '';

  grahamNetNetOperator: string = 'greater';
  grahamNetNetStr: string = '';

  workingCapitalOperator: string = 'greater';
  workingCapitalStr: string = '';

  tangibleAssetValueOperator: string = 'greater';
  tangibleAssetValueStr: string = '';

  netCurrentAssetValueOperator: string = 'greater';
  netCurrentAssetValueStr: string = '';

  investedCapitalOperator: string = 'greater';
  investedCapitalStr: string = '';

  averageReceivablesOperator: string = 'greater';
  averageReceivablesStr: string = '';

  averagePayablesOperator: string = 'greater';
  averagePayablesStr: string = '';

  averageInventoryOperator: string = 'greater';
  averageInventoryStr: string = '';

  daysSalesOutstandingOperator: string = 'greater';
  daysSalesOutstandingStr: string = '';

  daysPayablesOutstandingOperator: string = 'greater';
  daysPayablesOutstandingStr: string = '';

  daysOfInventoryOnHandOperator: string = 'greater';
  daysOfInventoryOnHandStr: string = '';

  receivablesTurnoverOperator: string = 'greater';
  receivablesTurnoverStr: string = '';

  payablesTurnoverOperator: string = 'greater';
  payablesTurnoverStr: string = '';

  inventoryTurnoverOperator: string = 'greater';
  inventoryTurnoverStr: string = '';

  roeOperator: string = 'greater';
  roeStr: string = '';

  capexPerShareOperator: string = 'greater';
  capexPerShareStr: string = '';

  loading: boolean = false;

  sortOrder: { [key: string]: boolean } = {};  // Track sort order for each column

  sortDirection: { [key: string]: boolean } = {};

  // Other filtering criteria variables
  filterValues: any = {}; // Declare filterValues object

  stocks: any[]=[]; // Fetched stock data
  filteredStocks: any[] = []; // Filtered stock data

  selectedTab: string = 'tab1'; // Set an initial active tab

  constructor(private stockService: StockDataService, 
    public dialog: MatDialog,
    private metaService: Meta,
    private titleService: Title) { }

  ngOnInit(): void {
    this.filterStocks();

    const title = 'Powerful Stock Screener: Filter Stocks by Key Metrics';
    const description = 'Easily filter stocks by PE ratio, market cap, and more with our powerful stock screener. Find the best stocks for your portfolio in minutes.';
    
    // Set title
    this.titleService.setTitle(title);

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
    // fetch stock data when the component initializes
    //this.stockService.filterStocks(undefined, undefined, this.priceOperator, this.priceStr).subscribe((data: any[]) => {
    //  this.filteredStocks = data; // initialize filteredstocks with all stocks
    //});
  }

  initializeOperator(key: string, event: any): void {
    this.filterValues[key] = this.filterValues[key] || {};
    this.filterValues[key]['Operator'] = event;
  }

  initializeValue(key: string, event: any): void {
    this.filterValues[key] = this.filterValues[key] || {};
    this.filterValues[key]['Value'] = event;
  }

  openDialog(typ: string, key: string): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '40%', // Adjust the width as needed
      data: {
        keysPass: key,
        type: typ,
        filters: this.filterValues,
        keyShow: this.keyShow
      } // Pass any data if required
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.filters) {
        // Handle the data returned from the dialog
        this.filterValues = {
          ...this.filterValues,
          ...result.filters
        };

      } else if (result && result.keyShow) {

        this.keyShow = result.keyShow;
      }
    });
  }

  async filterStocks(): Promise<void> {
    this.loading = true;
    this.filteredStocks = await this.stockService.filterStocks(this.filterValues).toPromise();
    console.log(this.filteredStocks);
    this.loading = false;
  }

  getFilters(): any[] {
    return Object.values(this.keyShow);
  }

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  sortTable(column: string): void {
    if (!this.sortDirection[column]) {
      this.sortDirection[column] = true; // Default to ascending
    } else {
      this.sortDirection[column] = !this.sortDirection[column];
    }

    this.filteredStocks.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (this.isNumber(aValue) && this.isNumber(bValue)) {
        return this.sortDirection[column] ? aValue - bValue : bValue - aValue;
      } else {
        const aStr = aValue.toString();
        const bStr = bValue.toString();
        return this.sortDirection[column] ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
      }
    });
  }
}

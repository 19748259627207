<!-- UI for stock screener -->
<!-- Assuming you have a tab switcher, you can use ngIf to toggle the content based on the selected tab -->
<!-- First tab content (up to 20 divs) -->

<div style="position: relative; width: 78.75em; height: 30em; padding-top:1em;">
  <app-chart-strip width="75em;"></app-chart-strip>
  <div style="display: flex; flex-direction: row; width: 100%; flex-wrap: wrap">
    <div *ngFor="let k of keyShow" style="display:flex; flex-direction:row; padding-top:1em; padding-left:1em;">
      <button mat-raised-button (click)="openDialog('single', k)" class="custom-button">{{keyDescriptions[k]}}</button>
    </div>
    <button mat-raised-button (click)="openDialog('all', '')" class="custom-button" style="margin:1em;">+</button>
  </div>


  <div style="padding-top:1em; padding-left:1em;">

  </div>

  <div style="padding-top: 1em; padding-left: 1em;">
    <button (click)="filterStocks()">Filter Stocks</button>
  </div>

  <!-- Display filtered stocks -->
  <!-- Display filtered stocks -->
  <div class="stock-table" *ngIf="filteredStocks.length">
    <table>
      <thead>
        <tr>
          <th (click)="sortTable('symbol')">
            Symbol
            <button class="sort-button" (click)="sortTable('symbol')">Sort</button>
          </th>
          <th *ngFor="let filter of getFilters()">
            {{ keyDescriptions[filter] }}
            <button class="sort-button" (click)="sortTable(filter)">Sort</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let stock of filteredStocks">
          <td>{{ stock.symbol }}</td>
          <td *ngFor="let filter of getFilters()">
            {{ isNumber(stock[filter]) ? (stock[filter] | number:'1.2-2') : stock[filter] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="margin-left: 1em; margin-top: 1em" *ngIf="loading">
    <ngx-skeleton-loader [theme]="{ width: '75em'}" count="50" appearance="line"></ngx-skeleton-loader>
  </div>
</div>


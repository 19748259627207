import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderComponent, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-earnings-calendar',
  standalone: true,
  imports: [RouterModule, CommonModule, NgxSkeletonLoaderModule, FormsModule],
  templateUrl: './earnings-calendar.component.html',
  styleUrl: './earnings-calendar.component.css'
})
export class EarningsCalendarComponent {
  earningsEvents: any[] = [];
  filteredEvents: any[] = [];
  eventsPerPage: number = 20; // Number of events to display per page
  fromDate: string = '';
  toDate: string = '';
  loading: boolean = true;
  econSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  econObs: Observable<any> = new Observable();
  refreshInterval: any;


  constructor(private stockDataService: StockDataService, 
    private routerActive: ActivatedRoute, 
    private router: Router, 
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta) {
  }

  ngOnInit() {
    this.stockDataService.pollEarningsEvents();

    // Set title and meta description dynamically for the Earnings Calendar page
    const title = 'Earnings Calendar - Upcoming Corporate Earnings Reports';
    const description = 'Track upcoming earnings reports from major companies. Stay updated on quarterly earnings announcements and financial performance of your investments.';

    // Set the dynamic title
    this.titleService.setTitle(title);

    // Set the dynamic meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routerActive.fragment.subscribe((title: any) => {
    });
    const today = new Date();
    this.fromDate = this.formatDate(today);
    const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    this.toDate = this.formatDate(oneWeekFromToday);
    this.loadEarningsEvents();
    this.filterEvents();

  }


  formatDate(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  loadEarningsEvents() {
    //this.econSubject.next(this.econObs);
    this.stockDataService.earnEvents$.subscribe(
      (data: any[]) => {
        if (data !== null && data.length > 0) {
          this.earningsEvents = data;
          //this.econSubject.next(this.economicEvents);
          this.loading = false;
        }
      },
      (error: any) => {
        console.error('Error fetching economic events:', error);
      }
    );
    //this.economicEvents = this.econSubject.getValue();
  }

  ngAfterViewInit() {
    // Start polling only after the view has been fully initialized
    if (isPlatformBrowser(this.platformId)) {
      // Start polling data every 2 minutes (120000 ms)
      this.refreshInterval = setInterval(() => {
        this.refreshData();
      }, 120000); // 2 minutes
    }
   
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  refreshData() {
    // Refresh data without affecting page load
    this.stockDataService.pollEconomicEvents();
    this.loadEarningsEvents();
    this.filterEvents();
  }

  showMoreEvents() {
    this.eventsPerPage += 20; // Increase the number of events to display
  }

  filterEvents() {
    this.filteredEvents = this.earningsEvents.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate >= new Date(this.fromDate) &&
        eventDate <= new Date(this.toDate) &&
        !event.symbol.includes('.');
    });
  }

}

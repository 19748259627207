import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { catchError, map } from 'rxjs/operators';
// Example of potential correct path if exports are nested
import { BaseLanguageModelParams } from '@langchain/core/language_models/base';
const { environment } = require('../../../environments/environments.prod');

@Injectable({
  providedIn: 'root'
})
export class LlmService {

  private model: string = 'NousResearch/Hermes-2-Pro-Llama-3-8B';
  
  private sessionID: string;
  //private apiUrl = `http://localhost:3000/stockService`;
  //private apiUrl = `https://${environment.container}:3000/stockService`;
  private apiUrl = `https://www.lambdafin.com/stockService`;
  private readonly pineconeApiKey = environment.pKey;
  private readonly modelUrl: any = `${environment.modelUrl}`;
  //private readonly modelUrl: any = `http://localhost:7654/`;
  // Array to hold messages in ChatGPT format (with possible tool calls)
  private chatHistory: { role: string, content?: string, tool_call_id?: any, name?:string }[] = [];


  constructor(private http: HttpClient) {
    this.sessionID = this.getOrCreateSessionId()
    this.saveSessionId(this.sessionID);
  }

  private getOrCreateSessionId(): string {
    // Check if sessionId exists in cookies, if not create a new one
    let sessionID = Cookies.get('sessionId');
    if (!sessionID) {
      sessionID = uuidv4();
      
      Cookies.set('sessionId', sessionID, { expires: 7, path: '' });
    }
    return sessionID;
  }

  // Function to get portfolio based on session ID
  getPortfolio(): Observable<any[]> {
    const sessionId = this.getOrCreateSessionId(); // Get or create the session ID
    const url = `${this.apiUrl}/getPortfolio/${sessionId}`; // Endpoint to fetch portfolio
  
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        // If the portfolio exists in the response, return it; otherwise, return an empty array
        return response && response.portfolio ? response.portfolio : [];
      }),
      catchError((error) => {
        // If error is 404, return an empty array as well
        if (error.status === 404) {
          return of([]); // Return an empty array in case of 404
        } else {
          console.error('Error retrieving portfolio:', error);
          return of([]); // Return empty array for other errors
        }
      })
    );
  }

  // Function to save session ID
  saveSessionId(sessionId: string): void {
    const url = `${this.apiUrl}/saveSession`;
    const body = { sessionId };

    // Call the API to save the sessionId to the database
    this.http.post(url, body).subscribe({
      next: () => {}, // No logging for successful response
      error: () => {} // No logging for errors
    });
  }

  savePortfolio(portfolio: any[]): void {
    const sessionId = this.getOrCreateSessionId();
    const url = `${this.apiUrl}/savePortfolio`;
    const body = { sessionId, portfolio };

    this.http.post(url, body).subscribe({
      next: () => {}, // No logging for successful response
      error: () => {} // No logging for errors
    });
  }

  transformToChatGptFormat(message: string[], model: string, user_id: string): any {
    // Alternate between 'user' and 'assistant' roles based on index
    const messages = message.map((msg: string, index: number) => ({
      role: index % 2 === 0 ? "user" : "assistant", // Alternating roles
      content: msg
    }));

    // Create the ChatGPT-compatible payload
    const chatGptPayload = {
      model: model,
      user_id: user_id,
      messages: messages,
      stream: false,
      temperature: 0,
      max_tokens: 512
    };

    return chatGptPayload;
  }


  postData(message: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    this.chatHistory.push({ role: 'user', content: message });
  
    const body = {
      messages: this.chatHistory
    };
  
    console.log('Sending request with body:', body);
  
    return new Observable(observer => {
      let isCompleted = false;
      let eventSource: EventSource;
      let accumulatedContent = "";  // Temporary accumulator for content
      let accumulatedToolData: any = null;  // Temporary holder for tool data

      // Send initial POST request to trigger the stream and get session_id
      this.http.post<{ session_id: string }>(`${this.modelUrl}api/question`, body)
        .subscribe({
          next: (response) => {
            const sessionId = response.session_id;
            if (!sessionId) {
              observer.error("No session_id received");
              return;
            }
    
            // Now connect to EventSource for the stream using session_id
            eventSource = new EventSource(`${this.modelUrl}api/stream/${sessionId}`);
    
            eventSource.onmessage = (event) => {
              try {
                const jsonData = JSON.parse(event.data);
                console.log(jsonData);
    
                if (jsonData.content === '[DONE]') {
                  isCompleted = true;
                  console.log(accumulatedContent, "hello");


                  // Push accumulated content and any tool data as a single message
                  const assistantMessage:any = { role: 'assistant', content: accumulatedContent };

                  if (accumulatedToolData) {
                    assistantMessage['role'] = 'tool'
                    assistantMessage['name'] = accumulatedToolData.name;  // Include tool data if it exists
                    assistantMessage['tool_call_id'] = accumulatedToolData.id;
                  }
                  
                  // Push the accumulated content as a single message
                  this.chatHistory.push(assistantMessage);


                  observer.complete();
                  eventSource.close();
                } else {

                  // Append streamed content to the content accumulator
                  if (jsonData.content) {
                    accumulatedContent += jsonData.content;
                  }

                  // If there's tool data, store it in the tool accumulator
                  if (jsonData.tool) {
                    accumulatedToolData = jsonData.tool;
                  }

                  observer.next(jsonData);
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            };
    
            eventSource.onerror = (error) => {
              console.error('EventSource error:', error);
              observer.error(error);
              eventSource.close();
            };
          },
          error: (error) => {
            console.error('Error in POST request:', error);
            observer.error(error);
          }
        });
    
      // Cleanup when unsubscribing
      return () => {
        console.log('Observable unsubscribed');
        console.log(!isCompleted && eventSource);
        if (!isCompleted && eventSource) {
          if (accumulatedContent) {
            console.log(this.chatHistory);
            this.chatHistory.push({ role: 'assistant', content: accumulatedContent });
          }

          eventSource.close();
        }
      };
    });
  }    

  // Method to retrieve chat history
  getChatHistory() {
    return this.chatHistory;
  }

}

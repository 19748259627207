<form class="loan-form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="loanAmount">Loan Amount:</label>
    <input type="text" [(ngModel)]="loanAmountStr" placeholder="Enter Loan Amount" name="loanAmountStr" id="loanAmount">
  </div>

  <div class="form-group">
    <label for="interestRate">Interest Rate:</label>
    <input type="text" [(ngModel)]="interestRateStr" placeholder="Enter Interest Rate" name="interestRateStr" id="interestRate">
  </div>

  <div class="form-group">
    <label for="paymentFrequency">Payment Frequency:</label>
    <select [(ngModel)]="paymentFrequency" name="paymentFrequency" id="paymentFrequency">
      <option value="monthly">Monthly</option>
      <option value="quarterly">Quarterly</option>
      <option value="annually">Annually</option>
    </select>
  </div>

  <div class="form-group">
    <label for="paymentAmount">Payment Amount:</label>
    <input type="number" [(ngModel)]="paymentAmountStr" placeholder="Enter Payment Amount" name="paymentAmountStr" id="paymentAmount">
  </div>

  <button type="submit">Calculate</button>
</form>

<div class="scrollable-table" *ngIf="mortgageSchedule.length">
  <table>
    <!-- Table headers -->
    <thead>
      <tr>
        <th *ngFor="let header of mortgageSchedule[0]">{{ header }}</th>
      </tr>
    </thead>
    <!-- Table body -->
    <tbody>
      <tr *ngFor="let row of mortgageSchedule.slice(1)">
        <td *ngFor="let cell of row">{{ cell }}</td>
      </tr>
    </tbody>
  </table>
</div>



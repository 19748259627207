<div class="heat-map-container">
  <app-chart-strip></app-chart-strip>
  <div class="time-period-buttons-container" *ngIf="isBrowser">
    <button class="time-period-button" (click)="changeTimePeriod('1D')">1D</button>
    <button class="time-period-button" (click)="changeTimePeriod('5D')">5D</button>
    <button class="time-period-button" (click)="changeTimePeriod('1M')">1M</button>
    <button class="time-period-button" (click)="changeTimePeriod('3M')">3M</button>
    <button class="time-period-button" (click)="changeTimePeriod('6M')">6M</button>
    <button class="time-period-button" (click)="changeTimePeriod('1Y')">1Y</button>
    <button class="time-period-button" (click)="changeTimePeriod('5Y')">5Y</button>
    <button class="time-period-button" (click)="changeTimePeriod('10Y')">10Y</button>
  </div>
  <!--<highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>-->
  <div id="stockHeatMap" class="stock-heat-map" *ngIf="isBrowser"></div>
  <div class="product-promo-container">
    <div class="product-promo">
      <a href="https://amzn.to/3O0laN3" target="_blank">
        <div class="promo-text">
          <strong>MERACH Vibration Plate Exercise Machine</strong><br>
          Whole-body workout with Bluetooth & silicone pedal - perfect for lymphatic drainage and weight loss!
        </div>
        <img src="assets/pilates.jpg" alt="MERACH Vibration Plate" class="promo-image">
      </a>
    </div>
    
    <div class="product-promo">
      <a href="https://amzn.to/3AssVbs" target="_blank">
        <div class="promo-text">
          <strong>The New World Video Game</strong><br>
          Dive into a rich, open-world experience with immersive gameplay, exploration, and crafting in this action-packed MMORPG.
        </div>
        <img src="assets/newWorld.jpg" alt="New World Video Game" class="promo-image">
      </a>
    </div>
  </div>
</div>



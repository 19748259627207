import { Component, Input, OnChanges, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, map, Observable } from 'rxjs';

@Component({
  selector: 'app-news-box',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule],
  templateUrl: './news-box.component.html',
  styleUrl: './news-box.component.css'
})
export class NewsBoxComponent implements OnChanges {
  newsData: any[] = [];
  @Input() ticker?: string;
  @Input() amt?: number;
  @Input() typ?: string;
  @Input() tickerArr?: string[];
  constructor(private stockDataService: StockDataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loadNews();
  }

  ngOnChanges() {
    this.newsData = [];
    this.loadNews();
  }

  loadNews(): void {
    if (this.tickerArr && this.tickerArr.length > 0) {
      // Call the function for multiple tickers if tickerArr is provided

      this.loadNewsForMultipleTickers(this.tickerArr);
    } else {
      // Otherwise, call the news function for a single ticker

      this.loadSingleTickerNews();
    }
  }

  loadSingleTickerNews() {
    let nEvents = this.amt === undefined ? 6 : this.amt;
    const fetchNews = (observable: any) => {
      observable.subscribe(
        (data: any) => {
          // Validate and filter the news data
          const validatedNews = data
            .filter((item: any) => 
              this.isValidUrl(item.url) && 
              this.isValidUrl(item.image) &&
              item.title && 
              item.text
            )
            .map((item: any) => ({
              ...item,
              url: this.sanitizer.sanitize(SecurityContext.URL, item.url) || '',
              image: this.sanitizer.sanitize(SecurityContext.URL, item.image) || null
            }));

          this.newsData = validatedNews.slice(0, nEvents);
        },
        (error: any) => {
          console.error('Error fetching news:', error);
        }
      );
    };

    if (this.typ === 'general') {
      fetchNews(this.stockDataService.getNews());
    } else if (this.typ === 'CRYPTO') {
      fetchNews(this.stockDataService.getCryptoNews(this.ticker));
    } else if (this.typ === 'FOREX') {
      fetchNews(this.stockDataService.getForexNews(this.ticker));
    } else {
      fetchNews(this.stockDataService.getStockNews(this.ticker));
    }
  }

  loadNewsForMultipleTickers(tickers: string[]): void {
    let nEvents = this.amt === undefined ? 6 : this.amt;
    let combinedNews: any[] = [];
    let newsByTicker: any[] = [];
    
    // Function to fetch news for each ticker
    const fetchNewsForTicker = (ticker: string): Observable<any> => {
      if (this.typ === 'general') {
        return this.stockDataService.getNews(); // For general news, no ticker is needed
      } else if (this.typ === 'CRYPTO') {
        return this.stockDataService.getCryptoNews(ticker);
      } else if (this.typ === 'FOREX') {
        return this.stockDataService.getForexNews(ticker);
      } else {
        return this.stockDataService.getStockNews(ticker);
      }
    };
  
    // Fetch news for all tickers
    const fetchAllNews = () => {
      // Iterate over all tickers and fetch their news
      const newsObservables = tickers.map((ticker) => {
        return fetchNewsForTicker(ticker).pipe(
          map((data: any) => {
            // Validate and filter the news data
            const validatedNews = data
              .filter((item: any) => 
                this.isValidUrl(item.url) && 
                this.isValidUrl(item.image) &&
                item.title && 
                item.text
              )
              .map((item: any) => ({
                ...item,
                url: this.sanitizer.sanitize(SecurityContext.URL, item.url) || '',
                image: this.sanitizer.sanitize(SecurityContext.URL, item.image) || null
              }));
  
            return validatedNews.slice(0, nEvents); // Limit to 'nEvents'
          })
        );
      });
  
      // Combine the results of all observables
      forkJoin(newsObservables).subscribe(
        (results: any[]) => {
          // Store the news for each ticker
          newsByTicker = results;
          
          // Combine the news in round-robin style
          const combined: any[] = [];
          let maxLength = Math.max(...newsByTicker.map(news => news.length)); // Get the maximum length of news arrays
          
          for (let i = 0; i < maxLength; i++) {
            newsByTicker.forEach((tickerNews) => {
              if (tickerNews[i]) { // Only add if the news exists for this index
                combined.push(tickerNews[i]);
              }
            });
          }
          
          // Set the final combined news
          this.newsData = combined;
        },
        (error: any) => {
          console.error('Error fetching news:', error);
        }
      );
    };
  
    fetchAllNews();
  }

  private isValidUrl(url: string | undefined): boolean {
    if (!url) return false;
    try {
      const parsedUrl = new URL(url);
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } catch {
      return false;
    }
  }

  redirectToNews(url: string): void {
    if (url) {
      // Open the URL in a new tab or window
      window.open(url, '_blank');
    }
  }
}

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderComponent, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-economic-calendar',
  standalone: true,
  imports: [RouterModule, CommonModule, NgxSkeletonLoaderModule, FormsModule],
  templateUrl: './economic-calendar.component.html',
  styleUrl: './economic-calendar.component.css'
})
export class EconomicCalendarComponent implements OnInit {
  economicEvents: any[] = [];
  filteredEvents: any[] =[];
  eventsPerPage: number = 20; // Number of events to display per page
  fromDate: string='';
  toDate: string = '';
  loading: boolean = true;
  econSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  econObs: Observable<any> = new Observable();
  countries: string[] = [];
  selectedCountry: string = '';
  refreshInterval: any;


  constructor(private stockDataService: StockDataService, 
    private routerActive: ActivatedRoute, 
    private router: Router, 
    @Inject(PLATFORM_ID) 
    private platformId: Object,
    private titleService: Title,
    private metaService: Meta) {
  }

  ngOnInit() {

    // Set title and meta description dynamically for the Economic Calendar page
    const title = 'Economic Calendar - Upcoming Financial Events and Reports';
    const description = 'Stay updated with the latest economic events and financial reports, including GDP, inflation, employment data, and more. Track economic indicators that impact the financial markets and investment strategies.';

    // Set the dynamic title
    this.titleService.setTitle(title);

    // Set the dynamic meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
    this.stockDataService.pollEconomicEvents();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const today = new Date();
    this.fromDate = this.formatDate(today);
    const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    this.toDate = this.formatDate(oneWeekFromToday);
    this.loadEconomicEvents();
    this.filterEvents();

  }

  ngAfterViewInit() {
    // Start polling only after the view has been fully initialized
    if (isPlatformBrowser(this.platformId)) {
      this.refreshInterval = setInterval(() => {
        this.refreshData();
      }, 120000); // 2 minutes
    }
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  refreshData() {
    // Refresh data without affecting page load
    this.stockDataService.pollEconomicEvents();
    this.loadEconomicEvents();
    this.filterEvents();
  }



  formatDate(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  loadEconomicEvents() {
    //this.econSubject.next(this.econObs);
    // Function to convert GMT to EST
    const convertGmtToEst = (gmtDate: string): string => {

      const date = new Date(gmtDate);

      // Get the user's local time zone offset in minutes
      const localOffset = date.getTimezoneOffset(); // Offset in minutes from GMT (UTC)

      // Calculate the time difference in milliseconds
      const localOffsetInMillis = localOffset * 60 * 1000;

      // Adjust the GMT date to the user's local time
      const localDate = new Date(date.getTime() - localOffsetInMillis);

      // Get the user's local time zone from the browser
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Define formatting options to include time zone abbreviation
      const options: Intl.DateTimeFormatOptions = {
        timeZone: userTimeZone, // Automatically use the browser's time zone
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // 24-hour time format
        timeZoneName: 'short' // Include timezone abbreviation (e.g., EST, CST, MST, PST)
      };

      // Convert to user's local time zone with timezone label
      const localDateStr = new Intl.DateTimeFormat('en-US', options).format(localDate);

      return localDateStr;
    };

    this.stockDataService.econEvents$.pipe(
  map((data: any[]) => {
    if (data !== null && data.length > 0) {
      const updatedData = data.filter(event => event.country === 'US');
      // Convert all date fields from GMT to EST
      return updatedData.map(event => ({
        ...event,
        date: convertGmtToEst(event.date) // assuming 'date' is the field name
      }));
    }
    return [];
  })
).subscribe(
  (convertedData: any[]) => {
    this.economicEvents = convertedData;
    this.loading = false;
  },
  (error: any) => {
    console.error('Error fetching economic events:', error);
  }
);
  }

  showMoreEvents() {
    this.eventsPerPage += 20; // Increase the number of events to display
  }

  filterEvents() {
    this.filteredEvents = this.economicEvents.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate >= new Date(this.fromDate) &&
        eventDate <= new Date(this.toDate) &&
        event.country === "US";
    }).reverse();
  }
}

import { ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { MatTabGroup, MatTab } from '@angular/material/tabs';  // Import MatTabGroup and MatTab
import { MatIcon } from '@angular/material/icon';    
import { MatTableModule } from '@angular/material/table';
import { StockDataService } from '../stock-data.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StockSearchDialogComponent } from '../stock-search-dialog/stock-search-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator'; // Optional for pagination
import { MatSortModule } from '@angular/material/sort'; // Optional for sorting
import { LlmService } from '../llm.service';
import { NewsBoxComponent } from '../news-box/news-box.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { formatInTimeZone } from 'date-fns-tz';


@Component({
  selector: 'app-portfolio-analysis',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatIcon,
    CommonModule,
    MatDialogModule,
    StockSearchDialogComponent,
    MatTableModule,
    MatPaginatorModule, 
    MatSortModule,
    NewsBoxComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    HighchartsChartModule
  ],
  templateUrl: './portfolio-analysis.component.html',
  styleUrl: './portfolio-analysis.component.css'
})
export class PortfolioAnalysisComponent {
  @ViewChild('searchInputRef') searchInputRef!: ElementRef;
  tickers: string[] = [];
  filteredCandidates: any = [];
  isInputFocused: boolean = false;
  hoveredItem: any = null;
  isMenuOpen: boolean = false;
  selectedStock: any = null;
  displayedColumns: string[] = ['symbol', 'name', 'price','tradePrice','shares', 'marketValue', 'costBasis', 'performance', 'ttmEps', 'ttmPe'];
  fundamentalsColumns: string[] = ['symbol', 'name','revenuePerShare', 'netIncomePerShare', 'freeCashFlowPerShare', 'enterpriseValue', 'roic'];
  performanceColumns: string[] = ['symbol', 'name', 'annualReturn', 'volatility', 'sharpeRatio'];
  portfolio: any[] = [];  // Initialized as an empty array
  fundamentals: any[] = [];
  dataSource = new MatTableDataSource(this.portfolio);  // Initialize MatTableDataSource
  fundamentalsDataSource = new MatTableDataSource(this.fundamentals);  // Placeholder data for summary
  performanceDataSource = new MatTableDataSource(this.portfolio);  // Placeholder data for performance
  Highcharts: typeof Highcharts |undefined;
  chartOptions: Highcharts.Options | undefined;
  ET_TIMEZONE = 'America/New_York';
  portfolioDetails: any = [];
  isBrowser: boolean;

  private dynamicTitle: string = 'Advanced Portfolio Analysis: Insights for Financial Success';
  private dynamicDescription: string = 
    "Streamline your investment strategy with Lambda's intuitive Portfolio Analysis. From trends to risk insights, we've got you covered.";



  constructor(
    private stockDataService: StockDataService,
    private eRef: ElementRef,
    private dialog: MatDialog,
    private llmService: LlmService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title, 
    private metaService: Meta
  ) {
      // Initialize the MatTableDataSource
      this.isBrowser = isPlatformBrowser(this.platformId);
      if (this.isBrowser) {
        this.Highcharts = Highcharts;
        this.chartOptions = {};
      }
  }

  async ngOnInit(): Promise<void> {
    // Get the portfolio as a Promise and set it to the portfolio array
    this.setPageMetadata();
    await this.loadPortfolio();
  }

  private setPageMetadata(): void {
    this.titleService.setTitle(this.dynamicTitle);
    this.metaService.updateTag({
      name: 'description',
      content: this.dynamicDescription
    });
  }

  async loadPortfolio(): Promise<void> {
    try {
      const portfolioData = await this.llmService.getPortfolio().toPromise();
      this.portfolio = portfolioData || [];
      this.portfolioDetails = this.portfolio.map(item => ({ ...item }));

      
      if (this.portfolio.length > 0) {
        
        await this.calculateSummaryData();
        this.createAccountValueChart();
        console.log("heelo");
      }
      console.log(this.portfolioDetails);
      this.dataSource.data = this.portfolioDetails;
      
    } catch (error) {

    }
  }

  onAddStock() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    setTimeout(() => {
      this.isInputFocused = false;
    }, 200);
  }

  private clearInput() {
    if (this.searchInputRef) {
      const inputElement = this.searchInputRef.nativeElement as HTMLInputElement;
      inputElement.value = '';
      inputElement.blur();
    }
    this.filteredCandidates = [];
  }

  async openDialog(): Promise<void> {
    const dialogRef = this.dialog.open(StockSearchDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && !this.portfolio.some(trade => trade.symbol === result.symbol)) {
        this.portfolio.push(result);
        this.portfolioDetails.push(result);
        console.log(this.portfolio);
        this.llmService.savePortfolio(this.portfolio);

        await this.calculateSummaryData();
        this.createAccountValueChart();
        this.dataSource.data = this.portfolioDetails;
      }
    });
  }

  async calculateSummaryData(): Promise<void> {
    this.tickers = [];
    let totalMarketValue = 0;
    let totalCostBasis = 0;
    let totalPerformance = 0;
  
    // Reset fundamentals and ensure compatibility with portfolioDetails
    this.fundamentals = [];
  
    // Calculate individual stock values and accumulate totals
    await Promise.all(
      this.portfolioDetails.map(async (stock: any) => {
        // Skip the "Total" row
        if (stock.symbol === "Total") {
          return;
        }
  
        // Fetch stock data
        const [priceObj, priceObj2, priceObj3] = await Promise.all([
          this.stockDataService.getStockRealTimeMetrics(stock.symbol).toPromise(),
          this.stockDataService.getV2KeyMetrics(stock.symbol).toPromise(),
          this.stockDataService.getStockData(stock.symbol).toPromise(),
        ]);
  
        // Populate fundamentals and add to the list
        const fundamentals = {
          ...priceObj2[0],
          name: stock.name,
        };
        this.fundamentals.push(fundamentals);
  
        // Update stock properties in portfolioDetails
        stock.priceHistory = priceObj3.historical.reverse();
        stock.price = priceObj[0].price;
        stock.PE = priceObj[0].PE;
        stock.EPS = priceObj[0].EPS;
        stock.marketValue = stock.price * stock.shares;
        stock.costBasis = stock.tradePrice * stock.shares;
        stock.performance = (stock.price - stock.tradePrice) / stock.tradePrice;
  
        // Accumulate totals
        totalMarketValue += stock.marketValue;
        totalCostBasis += stock.costBasis;
  
        // Collect tickers
        this.tickers.push(stock.symbol);
      })
    );
  
    // Update the fundamentals data source
    this.fundamentalsDataSource.data = this.fundamentals;
  
    // Calculate the total performance as a weighted average
    if (totalCostBasis !== 0) {
      totalPerformance = (totalMarketValue - totalCostBasis) / totalCostBasis;
    }
  
    // Define the "Total" row
    const totalRow = {
      symbol: "Total",
      name: "", // Empty name as requested
      marketValue: totalMarketValue,
      costBasis: totalCostBasis,
      performance: totalPerformance,
    };
  
    // Remove any existing "Total" row and add the new one at the end
    this.portfolioDetails = this.portfolioDetails.filter(
      (stock: any) => stock.symbol !== "Total"
    );
    this.portfolioDetails.push(totalRow);
    console.log(this.portfolioDetails);
  
    // Trigger change detection to update the view
    this.changeDetectorRef.detectChanges();
  }
  


  async onStockUpdate(stock: any, field: string, value: any): Promise<void> {
    stock[field] = value;

    if (field === 'shares' && stock.shares === 0) {
      this.portfolio = this.portfolio.filter(s => s.symbol !== stock.symbol);
      this.portfolioDetails = this.portfolioDetails.filter((d: any) => d.symbol !== stock.symbol);
    } else {
      const details = this.portfolioDetails.find((item: any) => item.symbol === stock.symbol);
      if (details) {
        details.marketValue = stock.tradePrice * stock.shares;
      }
    }

    console.log(this.portfolioDetails);
    await this.calculateSummaryData();
    this.llmService.savePortfolio(this.portfolio);
    this.dataSource.data = this.portfolioDetails;
  }

  createAccountValueChart() {
    if(this.isBrowser) {
      // Step 1: Sort trades by date
      this.portfolioDetails.sort((a: any, b: any) => new Date(a.tradeDate).getTime() - new Date(b.tradeDate).getTime());

      // Step 2: Initialize variables
      const accountValueByDate: Record<string, number> = {};
      let cashBalance = this.portfolioDetails
        .filter((trade: any) => trade.symbol !== "Total") // Exclude trades with "Total"
        .reduce((acc: number, trade: any) => acc + trade.tradePrice * trade.shares, 0);
      const holdings: Record<string, number> = {}; // Tracks quantity held for each stock
      // Step 3: Process each trade
      this.portfolioDetails.forEach((trade: any) => {
        if(trade.symbol!= "Total") {
          console.log(trade);
          const tradeDate = trade.tradeDate;
          const shares = trade.shares;
    
          // Calculate total trade cost
          const totalTradeCost = trade.tradePrice * shares;
    
          // Update cash balance and holdings
          cashBalance -= totalTradeCost; // Subtract total trade cost from cash balance
          holdings[trade.symbol] = (holdings[trade.symbol] || 0) + shares;
    
          // Process price history starting from the trade date
          trade.priceHistory.forEach((history: any) => {
            // Parse and normalize historyDate and tradeDate
            const historyDate = new Date(history.date); // Date already in ET
            const tradeDateET = new Date(tradeDate);
          
            const normalizedHistoryDate = new Date(
              historyDate.getFullYear(),
              historyDate.getMonth(),
              historyDate.getDate()
            );
            const normalizedTradeDate = new Date(
              tradeDateET.getFullYear(),
              tradeDateET.getMonth(),
              tradeDateET.getDate()
            );
          
            if (normalizedHistoryDate >= normalizedTradeDate) {
              // Calculate the total account value on this date
              const holdingValue = Object.keys(holdings).reduce((sum, stock) => {
                // Find the stock in the portfolio
                const stockTrade = this.portfolioDetails.find((t: any) => t.symbol === stock);
          
                if (!stockTrade) return sum;
                
                // Find the closing price for the stock on the normalized date
                const stockPrice = stockTrade.priceHistory.find(
                  (h: any) => {
                    const priceDate = new Date(h.date);
                    return (
                      priceDate.getFullYear() === normalizedHistoryDate.getFullYear() &&
                      priceDate.getMonth() === normalizedHistoryDate.getMonth() &&
                      priceDate.getDate() === normalizedHistoryDate.getDate()
                    );
                  }
                )?.close;
          
                return sum + (stockPrice || 0) * holdings[stock];
              }, 0);
          
              accountValueByDate[normalizedHistoryDate.toISOString()] =
                holdingValue + cashBalance;
            }
          });        
        }
      });
      console.log(accountValueByDate);

      // Step 4: Prepare data for Highcharts
      const dates = Object.keys(accountValueByDate).sort();
      const accountValues = dates.map((date) => ({
        x: new Date(date).getTime(),
        y: accountValueByDate[date],
      }));

      // Step 5: Configure Highcharts options
      this.chartOptions = {
        chart: {
          type: 'line',
        },
        title: {
          text: 'Account Value Over Time',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Date',
          },
        },
        yAxis: {
          title: {
            text: 'Account Value',
          },
        },
        series: [
          {
            name: 'Account Value',
            data: accountValues,
            type: 'line',
          },
        ],
      };

      Highcharts.stockChart('container', this.chartOptions);
    }
    
  }


  

}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-use-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './how-to-use-popup.component.html',
  styleUrl: './how-to-use-popup.component.css'
})
export class HowToUsePopupComponent {
  // This boolean controls whether the popup is visible or not
  public isVisible = false;

  // Function to show or hide the popup
  togglePopup() {
    this.isVisible = !this.isVisible;
  }
}

import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChartStripComponent } from '../chart-strip/chart-strip.component';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { StockDisplayComponent } from './stock-display/stock-display.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-stock-list',
  standalone: true,
  imports: [FormsModule, CommonModule, ChartStripComponent, StockDisplayComponent],
  templateUrl: './stock-list.component.html',
  styleUrl: './stock-list.component.css'
})
export class StockListComponent {
  @Input() content: string | undefined;
  @Input() stocks: string[] | undefined;

  
  stockDetails = {
    cloudCrusaders: {
      MSFT: "Microsoft (MSFT) is a leader in cloud computing, with its Azure platform competing closely with Amazon Web Services (AWS). Azure's rapid growth has made it a major player, offering services in AI, machine learning, and IoT that appeal to industries like finance and healthcare. Its scalability and seamless integration with enterprise systems make it a top choice for businesses modernizing their infrastructure. Azure's strong security and hybrid cloud capabilities also make it attractive to large organizations needing robust data security. With continuous innovation and investment in AI and other cutting-edge technologies, Microsoft is well-positioned to benefit from the increasing adoption of cloud services, making MSFT a compelling long-term investment opportunity.",
    
      AMZN: "Amazon (AMZN) dominates the cloud market through AWS, its highly profitable division providing scalable, flexible cloud services to businesses across industries. AWS's innovation and rapid scalability give it a competitive edge, allowing companies to deploy applications, store data, and manage workloads efficiently. AWS's success stems from its ability to continually introduce new services, attracting a broad range of customers, including startups and enterprises. As demand for cloud services grows, AWS remains a key contributor to Amazon's profitability and long-term growth, presenting a strong investment opportunity for those seeking exposure to the cloud sector.",
    
      SNOW: "Snowflake (SNOW) specializes in cloud-based data management and analytics. Its platform allows businesses to manage vast amounts of data across multiple cloud providers, including AWS, Azure, and Google Cloud. Snowflake's multi-cloud compatibility, scalability, and cost-efficient architecture make it an attractive choice for companies looking to gain actionable insights from their data. Its emphasis on data-driven strategies has led to strong growth in industries such as finance, healthcare, and retail. Snowflake is well-positioned to capitalize on the increasing demand for cloud data solutions, making it a unique opportunity for investors in the expanding data cloud market."
    },    
    dividendStocks: {
      KO: "Coca-Cola (KO) is known for its consistent dividends, making it a favorite for income-focused investors. The company's strong global presence and diverse beverage portfolio ensure steady revenue, even during economic downturns. As a Dividend Aristocrat, Coca-Cola has raised its dividend for over 50 years, making it an attractive long-term hold for those seeking stability and reliable returns.",
      
      O: "Realty Income (O) is a real estate investment trust (REIT) that specializes in providing monthly dividend payouts. Known as 'The Monthly Dividend Company,' it holds a diverse portfolio of commercial properties leased to long-term tenants. Realty Income's commitment to dividend growth, even during economic downturns, makes it a reliable income-generating investment.",
      
      DUK: "Duke Energy (DUK) is a major U.S. utility company offering stable dividends due to the steady demand for its services. In addition to its long history of regular dividend increases, Duke is investing heavily in renewable energy, positioning itself for growth while maintaining its dividend stability. This makes it a strong choice for long-term, income-seeking investors."
    },
    evCharging: {
      ARVL: "Arrival (ARVL) focuses on revolutionizing the electric vehicle (EV) market, particularly in the commercial sector. With a focus on electric vans and charging infrastructure, Arrival is addressing key challenges in the adoption of electric vehicles. The company's sustainable approach to transportation and energy positions it well for future growth in the EV space.",
      
      RIDE: "Lordstown Motors (RIDE) is focused on producing electric light-duty trucks. Its flagship model, the Endurance, is designed for businesses needing a sustainable, zero-emissions alternative to gasoline-powered trucks. Lordstown's focus on eco-friendly transportation solutions makes it an appealing investment in the growing electric vehicle sector.",
      
      BLNK: "Blink Charging (BLNK) provides electric vehicle charging solutions across residential, commercial, and fleet sectors. With a robust network of charging stations and innovative services, Blink is positioned as a key player in the growing EV infrastructure market, offering investors exposure to the expanding electric vehicle ecosystem."
    },
    pharmaPowerhouses: {
      PFE: "Pfizer (PFE) is a global leader in pharmaceuticals, known for its groundbreaking COVID-19 vaccine and strong pipeline in oncology, immunology, and cardiology. With a commitment to R&D and strategic acquisitions, Pfizer remains a key player in the healthcare sector, offering growth and stability for investors.",
      
      LLY: "Eli Lilly (LLY) is a leader in diabetes, oncology, and other therapeutic areas. Known for its insulin treatments and recent breakthroughs in cancer therapies, Eli Lilly’s focus on R&D and precision medicine positions it for long-term growth in the pharmaceutical sector, making it an attractive investment for growth-focused investors.",
      
      ABBV: "AbbVie (ABBV) is a leader in immunology and oncology, with a strong portfolio including Humira and newer treatments like Rinvoq and Skyrizi. Its focus on innovation and strategic acquisitions, such as Allergan, enhances AbbVie’s growth prospects, making it a solid choice for long-term investors seeking exposure to the pharmaceutical industry."
    },    
    digitalPayments: {
      FLT: "FleetCor Technologies (FLT) is a global leader in providing payment solutions, specializing in fleet and corporate payments. With a broad range of services for industries such as transportation and logistics, FleetCor offers tools that optimize financial operations and reduce inefficiencies. Its payment solutions for fuel, tolls, and other expenses make it an essential partner for fleet management. FleetCor’s focus on innovation in digital payments enhances its competitiveness in the rapidly evolving financial technology landscape, positioning it as a leader in B2B payments.",
      
      PYPL: "PayPal (PYPL) is a pioneering force in digital payments, offering a platform for secure online transactions. With services like PayPal, Venmo, and Braintree, the company provides solutions for both consumers and businesses. As e-commerce grows, PayPal benefits from increased transactions and strategic partnerships. The company's integration of cryptocurrency solutions positions it well in the evolving digital assets space, making it an attractive investment in the future of cashless payments and e-commerce.",
      
      SQ: "Square, now Block, Inc. (SQ), has transformed from a payment processor into a diversified fintech company. Through its Square Point of Sale system and Cash App, Block, Inc. empowers small businesses and integrates cryptocurrency solutions. The company’s move into blockchain and decentralized finance (DeFi) shows its innovation in the digital payments space. Block's continued expansion into various financial products and services makes it a compelling investment for those interested in the future of fintech and cryptocurrency."
    },
    waterWarriors: {
      XPO: "XPO Logistics (XPO) is a global leader in freight transportation, offering logistics solutions that address supply chain challenges, particularly in water treatment-related industries. By providing specialized transportation services for water treatment equipment, chemicals, and machinery, XPO ensures the efficient movement of goods across the water sector. As sustainability in water management becomes more critical, XPO's tech-driven solutions and focus on optimization make it an essential partner for the water industry.",
      
      WTRG: "Essential Utilities (WTRG) provides water and wastewater services across the U.S., focusing on sustainability and infrastructure development. With its broad water asset portfolio, WTRG ensures reliable, affordable services while investing in technologies to improve water treatment. As demand for water increases due to population growth and climate change, WTRG's focus on green solutions and infrastructure positions it as a leader in the water sector, offering long-term growth potential.",
      
      AWK: "American Water Works (AWK) is the largest publicly traded water utility in the U.S., providing water and wastewater management services across several states. As demand for clean water grows, AWK plays a critical role in improving water infrastructure and distribution systems. The company’s focus on sustainability, energy-efficient solutions, and aging infrastructure upgrades makes it a solid choice for those seeking stability in the essential water industry. With consistent dividend payments and a strong growth outlook, AWK is an attractive investment in the water sector."
    },    
    "skyRevolution": {
      JOBY: "Joby Aviation (JOBY) is an innovator in electric vertical takeoff and landing (eVTOL) aircraft, aiming to revolutionize urban air mobility. Their aircraft reduce congestion, lower carbon emissions, and provide faster, more efficient transportation. Joby is building a network of aerial ridesharing services, and has secured strategic partnerships with aviation companies and governments, positioning itself as a leader in the emerging eVTOL market. Joby's technology and partnerships make it a key player in the future of urban air travel.",
      
      ACHR: "Archer Aviation (ACHR) is developing electric vertical takeoff and landing (eVTOL) aircraft for short-distance urban travel. Their eVTOL solutions aim to reduce traffic congestion and provide an energy-efficient alternative to traditional air travel. Archer is building a network of air mobility services and has partnered with companies like United Airlines to accelerate development. Archer’s focus on sustainability and innovative technology positions it as a strong competitor in the rapidly growing urban air mobility market.",
      
      BA: "Boeing (BA) is investing in electric propulsion and eVTOL technology to lead the urban air mobility market. The company collaborates with air mobility startups like Wisk to develop eVTOL aircraft. Boeing's vast expertise in aviation design and manufacturing, combined with its strategic investments in electric aviation, positions it as a major player in the transition to sustainable air travel. As cities face growing congestion, Boeing's focus on electric aviation positions it as a key leader in the future of air transportation."
    },
    "farmFutures": {
      DE: "Deere & Company (DE) leads the agricultural equipment industry with AI and IoT technologies to improve farming efficiency and sustainability. Their precision farming tools enable farmers to optimize crop yields, reduce resource consumption, and enhance productivity. Deere’s focus on smart farming solutions places it at the forefront of agriculture, helping farmers meet the increasing demand for sustainable food production and positioning the company for growth in the evolving agricultural sector.",
      
      SYF: "Synchrony Financial (SYF) supports agriculture by providing financial solutions to help farmers invest in smart farming technologies. Their financing options enable farmers to adopt precision farming tools, advanced irrigation systems, and other tech-driven solutions that improve efficiency and sustainability. As digitization and automation grow in agriculture, Synchrony’s ability to provide accessible financing helps farmers transition to more sustainable practices, positioning the company as a key player in agricultural fintech.",
      
      AGCO: "AGCO Corporation (AGCO) is a global leader in precision agriculture and sustainable farming technologies. Their equipment, including tractors and harvesters, integrates AI, IoT, and GPS to optimize farming efficiency. AGCO’s commitment to sustainability includes developing fuel-efficient machinery and tools that minimize environmental impact. As the demand for sustainable farming grows, AGCO’s technological innovations and global reach position it as a leading player in the future of agriculture."
    } 
  };
  

    // Cloud Crusaders: Riding the Digital Transformation Wave
  cloudCrusaders = [
    "AMZN", // Amazon (AWS)
    "MSFT", // Microsoft (Azure)
    "SNOW", // Snowflake
    "GOOGL", // Alphabet (Google Cloud)
    "CRM", // Salesforce
    "ORCL", // Oracle
    "IBM", // IBM (Cloud & AI)
    "ADBE", // Adobe
    "NOW", // ServiceNow
    "WDAY", // Workday
    "NTNX", // Nutanix
    "ZS", // Zscaler
    "DDOG", // Datadog
    "TEAM", // Atlassian
    "OKTA" // Okta
  ];

  // Dividends Forever: Stocks That Pay You to Wait
  dividendStocks = [
    "KO", // Coca-Cola
    "O", // Realty Income
    "PG", // Procter & Gamble
    "JNJ", // Johnson & Johnson
    "PEP", // PepsiCo
    "XOM", // ExxonMobil
    "CVX", // Chevron
    "MMM", // 3M Company
    "T", // AT&T
    "VZ", // Verizon
    "MO", // Altria Group
    "DUK", // Duke Energy
    "SO", // Southern Company
    "WPC", // W.P. Carey
    "PPL" // PPL Corporation
  ];

  // EV Charging Superstars: Powering the Future of Transport
  evCharging = [
    "CHPT", // ChargePoint
    "BLNK", // Blink Charging
    "EVGO", // EVgo
    "TSLA", // Tesla (Supercharger Network)
    "SBEV", // Splash Beverage Group (EV-focused)
    "ABB", // ABB Ltd (EV chargers)
    "VLTA", // Volta Inc.
    "WBX", // Wallbox
    "TSP", // TuSimple (Autonomous tech w/ EV focus)
    "PLUG", // Plug Power
    "ENPH", // Enphase Energy (Solar integration for EVs)
    "RUN", // Sunrun Inc. (Solar EV connections)
    "FSR", // Fisker Inc. (EV chargers as part of EV plans)
    "RIDE", // Lordstown Motors
    "ARVL" // Arrival
  ];

  // Pharma Powerhouses: A Prescription for Growth
  pharmaPowerhouses = [
    "PFE", // Pfizer
    "LLY", // Eli Lilly
    "MRK", // Merck
    "BMY", // Bristol-Myers Squibb
    "GILD", // Gilead Sciences
    "JNJ", // Johnson & Johnson
    "NVS", // Novartis
    "AZN", // AstraZeneca
    "BIIB", // Biogen
    "AMGN", // Amgen
    "REGN", // Regeneron
    "VRTX", // Vertex Pharmaceuticals
    "SNY", // Sanofi
    "ABBV", // AbbVie
    "RHHBY" // Roche Holding AG
  ];

  // Digital Payments Dynamos: Cashless Society in Action
  digitalPayments = [
    "V", // Visa
    "PYPL", // PayPal
    "SQ", // Block, Inc.
    "MA", // Mastercard
    "ADYEY", // Adyen N.V.
    "FISV", // Fiserv
    "GPN", // Global Payments
    "FLT", // FleetCor
    "FOUR", // Shift4 Payments
    "NU", // Nu Holdings
    "AFRM", // Affirm Holdings
    "SHOP", // Shopify (with payment integration)
    "STNE", // StoneCo
    "MELI", // MercadoLibre (payment dominance in LATAM)
    "WISE" // Wise (formerly TransferWise)
  ];
  
  cloudCrusadersTitle = "Cloud Crusaders: Driving the Future of Digital Innovation";
  // Cloud Crusaders: Riding the Digital Transformation Wave
  cloudCrusadersDescription = `
  The cloud revolution is powering modern life as we know it. From hosting massive data lakes to enabling your favorite apps to run smoothly, cloud computing companies form the backbone of the digital economy. These stocks are the key players in infrastructure (IaaS), platforms (PaaS), and software-as-a-service (SaaS). Whether it's enterprise solutions, secure storage, or advanced analytics, the growth opportunities are sky-high.
  
  Cloud providers are redefining scalability with containerization technologies like Kubernetes and Docker, enabling seamless deployment and orchestration of microservices. Innovations in edge computing are bringing low-latency processing closer to end-users, while serverless architectures are eliminating the need for infrastructure management. 
  
  As businesses adopt hybrid cloud and multi-cloud strategies to enhance flexibility, leaders in cloud security are pioneering solutions like zero-trust models and encryption at rest and in transit. Meanwhile, artificial intelligence and machine learning frameworks hosted in the cloud are revolutionizing industries, driving insights from big data faster than ever.
  
  Explore these innovators who are shaping the future of connectivity, empowering the Internet of Things (IoT), and creating a resilient, virtual-first world for enterprises and individuals alike.
  `;
  
  dividendStocksDescription = `
  Earning money while you sleep? That's the beauty of dividend stocks. These companies prioritize rewarding shareholders with regular payouts in the form of dividends, making them staples for those who value passive income and portfolio stability. Sectors such as consumer staples, utilities, and energy dominate the dividend landscape, often boasting consistent free cash flow and resilient business models.
  
  Many of these companies implement dividend growth strategies, where payouts increase annually, outpacing inflation. With lower beta values, dividend stocks are often less volatile, making them attractive during periods of market turbulence. For income-focused investors, metrics such as the dividend yield, payout ratio, and dividend coverage ratio are key indicators of sustainability and growth potential. Whether you're building retirement income or seeking a defensive portfolio anchor, these stocks turn patience into profitability.
  `;
  

  evChargingDescription = `
  Electric vehicles (EVs) are revolutionizing the transportation industry, but their success depends on the strength of the supporting infrastructure: EV charging networks. These companies are pioneers in deploying Level 2 and DC fast chargers, enabling rapid energy replenishment across urban and highway locations. Their solutions integrate with renewable energy sources like solar and wind, creating sustainable ecosystems that align with the global push toward carbon neutrality.
  
  Innovations such as bidirectional charging (vehicle-to-grid, or V2G) and smart-grid technologies enhance efficiency and grid stability while reducing peak energy loads. These companies also utilize software platforms to optimize charger utilization through advanced analytics and AI-based demand forecasting. As governments worldwide roll out incentives and mandates for EV adoption, the charging sector represents a vital growth engine for a cleaner, electrified future.
  `;
  

  // Pharma Powerhouses: A Prescription for Growth
  pharmaPowerhousesDescription = `
  The pharmaceutical industry fuels advancements that reshape modern healthcare, driven by relentless research and innovation. These companies are leaders in developing blockbuster drugs, with robust R&D pipelines targeting high-growth areas like oncology, immunology, and gene therapy. Leveraging technologies such as CRISPR gene editing and mRNA platforms, they are redefining the treatment landscape for complex diseases.
  
  Pharma giants also capitalize on biosimilars and biologics, catering to unmet medical needs while navigating patent cliffs strategically. They utilize data-driven approaches to accelerate clinical trials, employing AI for drug discovery and patient stratification. With global populations aging and chronic diseases rising, the demand for cutting-edge therapies and vaccines ensures long-term growth. If you seek a portfolio dose of innovation paired with defensive stability, pharma stocks are a potent prescription.
  `;
  

  // Digital Payments Dynamos: Cashless Society in Action
  digitalPaymentsDescription = `
  The transition to a cashless society is reshaping the financial ecosystem, and digital payment platforms are at the forefront of this transformation. These companies power frictionless transactions through technologies like tokenization, near-field communication (NFC), and secure payment gateways. From mobile wallets to buy-now-pay-later (BNPL) solutions, they enable seamless commerce in physical stores and e-commerce platforms alike.
  
  With real-time payment systems and blockchain-based networks gaining traction, digital payment providers are enhancing transaction speed and security. Emerging markets are embracing these innovations, driving financial inclusion and expanding addressable markets. Key metrics like total payment volume (TPV), user engagement, and merchant penetration underscore the sector's growth trajectory. As the lines blur between fintech and traditional finance, these companies are shaping the way money moves in a digital-first world.
  `;

  // Industrial Water Treatment: Cleansing the Future of Sustainability
  industrialWaterTreatment = [
    "XOM", // ExxonMobil
    "AOS", // A. O. Smith Corporation
    "ITT", // ITT Inc.
    "MTRN", // Materion Corporation
    "SWM", // Schweitzer-Mauduit International
    "WTRG", // Aqua America
    "AQUA", // AquaVenture Holdings
    "XPO", // XPO Logistics
    "EFX", // Equifax
    "SHW", // Sherwin-Williams
    "PLNT", // Plant Based Food and Beverage Inc.
    "AWK", // American Water Works
    "BWA" // BorgWarner
  ];

  industrialWaterTreatmentDescription = `
  Industrial water treatment is vital to maintaining sustainability in sectors like manufacturing, energy, and utilities. These companies focus on optimizing water usage and minimizing waste by utilizing advanced filtration, reverse osmosis, and chemical treatments. In an age of environmental consciousness and stricter regulations, water treatment stocks are essential in providing innovative solutions to address water scarcity, contamination, and pollution.
    
  From water management systems to desalination and recycling, this industry is growing rapidly due to increasing demand for clean and safe water. As governments and industries around the world strive to meet sustainability goals, water treatment companies play a pivotal role in delivering sustainable, scalable solutions. With rising environmental concerns and water-related challenges, the industry is poised for continued growth.
  `;

  industrialWaterTreatmentTitle = "Water Stocks: Industrial Water Treatment & Sustainability Solutions";

  // Electric Aviation and Air Mobility: Soaring to New Heights
  electricAviationAndAirMobility = [
    "HEXO", // Hexcel Corporation
    "BA", // Boeing
    "UAL", // United Airlines
    "AAL", // American Airlines Group
    "NIO", // Nio Inc.
    "AAPL", // Apple (Electric aircraft tech through partnerships)
    "EVTL", // Vertical Aerospace
    "JOBY", // Joby Aviation
    "SPCE", // Virgin Galactic
    "ACIC", // Atlas Crest Investment Corp
    "PLTR", // Palantir Technologies
    "LHX", // L3Harris Technologies
    "TRMB", // Trimble Inc.
    "GE", // General Electric
    "CVX" // Chevron (investing in electric aviation solutions)
  ];

  electricAviationAndAirMobilityDescription = `
  Electric aviation and air mobility are on the verge of transforming the way we travel, with companies pioneering electric aircraft, flying cars, and urban air mobility solutions. The sector encompasses electric vertical take-off and landing (eVTOL) aircraft, hybrid aircraft, and drones designed for passenger and cargo transportation.
    
  With advancements in battery technology, reduced carbon emissions, and a focus on sustainable aviation, these stocks represent a future of more efficient and eco-friendly air travel. With rising demand for sustainable transport solutions, governments and private players alike are making major investments to push air mobility into the mainstream.
  `;

  electricAviationAndAirMobilityTitle = "Sky Revolution: Electric Aviation's Leap into the Future";

  // Smart Farming and IoT Agriculture: Cultivating Tomorrow with Innovation
  smartFarmingAndIoTAgriculture = [
    "DE", // Deere & Co.
    "SYT", // Syngenta
    "AGCO", // AGCO Corporation
    "MSFT", // Microsoft (Azure IoT solutions for farming)
    "TSM", // Taiwan Semiconductor Manufacturing Company (IoT components)
    "NTR", // Nutrien
    "IOT", // IOT Group Ltd
    "TTC", // Toro Company
    "ADHI", // ADvanced Micro Devices
    "TTD", // The Trade Desk (agriculture data solutions)
    "DOW", // Dow Inc. (IoT agriculture)
    "NKE", // Nike (agriculture tech partnerships)
    "BA", // Boeing (drone and IoT-based precision farming)
    "SYF" // Synchrony Financial (supporting agricultural fintech)
  ];

  smartFarmingAndIoTAgricultureDescription = `
  The future of agriculture lies in smart farming powered by IoT technologies that increase efficiency, sustainability, and crop yields. By integrating IoT devices such as sensors, drones, and automated machinery, these companies are driving precision farming that reduces resource waste and maximizes outputs.
    
  Smart farming is evolving with innovations in soil health monitoring, climate-adaptive farming systems, and connected tractors and harvesters. The market for IoT-based agriculture solutions is expanding as both large-scale farms and smallholder farmers look to leverage data and technology to improve productivity and sustainability in the face of climate change and global food security challenges.
  `;

  smartFarmingAndIoTAgricultureTitle = "Agriculture Stocks: How IoT is Cultivating Tomorrow's Harvest";


  nasdaq100Stocks: any[] = [];
  sp1500Stocks: any[] = [];

  actives: any[] = [];
  type: string = '';
  refreshInterval: any;
  others: boolean = false;

  displayData: any = {
    waterWarriors: {
      stocks: this.industrialWaterTreatment,
      description: this.industrialWaterTreatmentDescription,
      title: this.industrialWaterTreatmentTitle,
      img: '../../assets/waterWarriors.jpg',
      seoTitle: "Water Stocks: The Future of Industrial Water Treatment",
      seoMetaDescription: "Explore top stocks revolutionizing industrial water treatment, tackling global water challenges while driving sustainable growth."
    },
    skyRevolution: {
      stocks: this.electricAviationAndAirMobility,
      description: this.electricAviationAndAirMobilityDescription,
      title: this.electricAviationAndAirMobilityTitle,
      img: '../../assets/skyRevolution.jpg',
      seoTitle: "Sky Revolution: Investing in Electric Aviation",
      seoMetaDescription: "Dive into the electric aviation revolution. Discover innovative stocks reshaping air mobility and sustainable transport."
    },
    farmFutures: {
      stocks: this.smartFarmingAndIoTAgriculture,
      description: this.smartFarmingAndIoTAgricultureDescription,
      title: this.smartFarmingAndIoTAgricultureTitle,
      img: '../../assets/farmFutures.jpg',
      seoTitle: "Agriculture Stocks: Smart Farming & IoT in Agriculture",
      seoMetaDescription: "Uncover stocks driving innovation in smart farming and IoT. Revolutionizing agriculture for a sustainable future."
    },
    cloudCrusaders: {
      stocks: this.cloudCrusaders,
      description: this.cloudCrusadersDescription,
      title: this.cloudCrusadersTitle,
      img: '../../assets/cloudCrusaders1.jpg',
      seoTitle: "Cloud Crusaders: The Next Wave in Cloud Computing",
      seoMetaDescription: "Discover the top cloud computing stocks powering digital transformation and the future of cloud technology."
    },
    dividendStocks: {
      stocks: this.dividendStocks,
      description: this.dividendStocksDescription,
      title: "Dividends Forever: Stocks That Pay You to Wait",
      img: '../../assets/dividendStocks.jpg',
      seoTitle: "Dividends Forever: Top Stocks for Long-Term Income",
      seoMetaDescription: "Maximize your returns with dividend-paying stocks. Learn about companies that pay you to wait while growing your wealth."
    },
    evCharging: {
      stocks: this.evCharging,
      description: this.evChargingDescription,
      title: "EV Charging Superstars: Powering the Future of Transport",
      img: '../../assets/evCharging.jpg',
      seoTitle: "EV Charging Superstars: Leading the Future of Mobility",
      seoMetaDescription: "Invest in the future of transport. Explore leading stocks in EV charging infrastructure and sustainable mobility."
    },
    pharmaPowerhouses: {
      stocks: this.pharmaPowerhouses,
      description: this.pharmaPowerhousesDescription,
      title: "Pharma Powerhouses: A Prescription for Growth",
      img: '../../assets/pharmaPowerhouses.jpg',
      seoTitle: "Pharma Powerhouses: The Future of Healthcare Innovation",
      seoMetaDescription: "Invest in pharma stocks driving healthcare breakthroughs. A prescription for sustainable growth and innovation."
    },
    digitalPayments: {
      stocks: this.digitalPayments,
      description: this.digitalPaymentsDescription,
      title: "Digital Payments Dynamos: Cashless Society in Action",
      img: '../../assets/digitalPayments.jpg',
      seoTitle: "Digital Payments Dynamos: Cashless Society Leaders",
      seoMetaDescription: "Cash in on the cashless revolution. Discover top stocks driving innovation in digital payments and financial tech."
    },
    nasdaq100: {
      stocks: this.nasdaq100Stocks,  // Define stocks here
      description: "The Nasdaq 100 is a prestigious stock market index that represents 100 of the largest non-financial companies listed on the Nasdaq stock exchange. Established in 1985, the Nasdaq 100 has become a global benchmark for high-growth sectors, including technology, biotech, and consumer services. The index is home to market giants like Apple, Microsoft, Amazon, and Tesla, whose innovations have reshaped industries and daily life. Over the years, the Nasdaq 100 has consistently outperformed many other major indices, reflecting the dominance of technology-driven companies. It plays a key role in the digital economy, offering investors a front-row seat to the rise of the world's most innovative businesses. As of today, the Nasdaq 100 is a primary indicator for investors seeking exposure to the booming technology and biotech sectors, with many of its constituent companies being at the forefront of global economic transformation.",
      title: "Nasdaq 100: The Cutting Edge of Innovation and Growth",
      img: '../../assets/nasdaq100.png',
      index: true,
      seoTitle: "Nasdaq 100: The Top Tech and Growth Stocks in the Market",
      seoMetaDescription: "Explore the Nasdaq 100, featuring leading tech stocks and innovative companies at the forefront of growth. Find top-performing companies shaping the future of global industries. Learn about the history and impact of this trailblazing index that has transformed investing since 1985."
    }, sp1500: {
      stocks: this.sp1500Stocks,  // Define stocks here
      description: "The S&P 1500 index is a comprehensive market index that blends the S&P 500, S&P 400, and S&P 600 indices, offering a complete view of the U.S. stock market. Launched in 1990, the S&P 1500 represents large-cap, mid-cap, and small-cap companies, giving investors diversified exposure across all market segments. The index covers a broad array of industries from technology to healthcare, financials, and consumer goods, providing a balanced approach to U.S. equities. With over three decades of data, the S&P 1500 has been a reliable tool for portfolio diversification, capturing the performance of both well-established companies and emerging growth sectors. By combining large, mid, and small-cap stocks, the index offers a more holistic view of the U.S. economy and allows investors to tailor their exposure to varying levels of risk and growth potential. The S&P 1500 is widely used by investors seeking broad exposure to the U.S. equity market while balancing both stable, large companies and dynamic, growth-oriented firms.",
      title: "S&P 1500: A Complete View of U.S. Equities",
      img: '../../assets/sp1500.png',
      index: true,
      seoTitle: "S&P 1500: Diversified Investment Across U.S. Markets",
      seoMetaDescription: "Gain exposure to the U.S. equity market with the S&P 1500, a comprehensive index that combines large, mid, and small-cap stocks. Discover a balanced mix of high-growth and stable investments, and explore the history and significance of this diverse index that has evolved since 1990."
    }
  };    


  selectedDisplay: any = {};

  constructor(private stockDataService: StockDataService, 
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta, 
    @Inject(PLATFORM_ID) private platformId: Object) { 
      
    }

  async ngOnInit() {
    

    this.route.paramMap.subscribe(async (params: any) => {
      this.type = params['params']['type'];
      if (this.type === 'gainers') {
        this.others = false;
        this.loadGainers();
      } else if (this.type === 'losers') {
        this.others = false;
        this.loadLosers();
      } else if (this.type === 'most') {
        this.others = false;
        this.loadActives();
      } else {
        if (this.displayData[this.type]) {
          
          this.selectedDisplay = this.displayData[this.type];
          this.others = true;

          this.titleService.setTitle(this.selectedDisplay.seoTitle);
          this.metaService.updateTag({ name: 'description', content: this.selectedDisplay.seoMetaDescription });
        } else {
          this.others = false;
        }
      }
    });
    
  }

  ngAfterViewInit() {
    // Set up interval to refresh every minute (60000 ms)
    if (isPlatformBrowser(this.platformId)) {
      this.refreshInterval = setInterval(async () => {
        this.stockDataService.pollMostActive();
        this.stockDataService.pollLosers();
        this.stockDataService.pollGainers();
      }, 120000); // 1 minute
    }
  }

  loadGainers(): void {
    this.stockDataService.gainers$.subscribe(
      (data: any[]) => {
        this.actives = data;
      },
      (error: any) => {
        console.error('Error fetching economic events:', error);
      }
    );
  }

  loadLosers(): void {
    this.stockDataService.losers$.subscribe(
      (data: any[]) => {
        this.actives = data;
      },
      (error: any) => {
        console.error('Error fetching economic events:', error);
      }
    );
  }

  loadActives(): void {
    this.stockDataService.actives$.subscribe(
      (data: any[]) => {
        this.actives = data;
      },
      (error: any) => {
        console.error('error fetching economic events:', error);
      }
    );
  }
}

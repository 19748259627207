<!-- Question mark button to trigger the popup -->
<button (click)="togglePopup()" class="help-button">?</button>

<!-- Popup modal -->
<div *ngIf="isVisible" class="popup-overlay">
  <div class="popup-content">
    <h2>How to Use This Page</h2>
    <p>
      Welcome to the user guide for this page! Here's how you can use it:
    </p>
    <ul>
      <li><strong>Get Financial Statements</strong>: Retrieve balance sheets, income statements, or cash flow statements for a selected year and ticker. Multiple statement types can be requested at once.</li>
      <li><strong>Vertical Analysis</strong>: View a breakdown of financial statements into percentages for a selected company and year, providing a deeper look at the company's financial performance.</li>
      <li><strong>Comparables Analysis</strong>: Compare multiple companies' financial data (up to 5 tickers) over the past 5 years, analyzing their performance side by side.</li>
      <li><strong>Regression Analysis</strong>: Perform a regression analysis between two companies over a custom time period, displaying the data in both graph and table form.</li>
      <li><strong>Rolling Correlation</strong>: Calculate the rolling correlation between two stock tickers over a defined window and time range, providing insight into their co-movement.</li>
      <li><strong>Total Return Calculator</strong>: Calculate the total return of up to 5 stocks based on ticker symbols, share amounts, and a chosen date range.</li>
    </ul>
    <button (click)="togglePopup()" class="close-popup-button">Close</button>
  </div>
</div>


<div class="portfolio-analysis">
    <!-- Add Stocks/ETFs Floating Action Button -->
    <div style="display: flex; flex-direction: row; width: 100%; flex-wrap: wrap">
        <button mat-raised-button (click)="openDialog()" class="custom-button" style="margin: 1em;">
          +
        </button>
    </div>

    <!-- Portfolio Analysis Tabs -->
    <mat-tab-group>
        <!-- Summary Tab -->
        <mat-tab label="Summary">
            <div class="tab-content">
              <table mat-table [dataSource]="dataSource">
                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                  <th mat-header-cell *matHeaderCellDef> Symbol </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.symbol }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.name }}</td>
                </ng-container>
              
                <!-- Price Column (Editable) -->
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef> Price </th>
                  <td mat-cell *matCellDef="let stock">{{stock.price}}</td>
                </ng-container>
                
                <!-- Trade Price Column (Editable) -->
                <ng-container matColumnDef="tradePrice">
                  <th mat-header-cell *matHeaderCellDef> Last Buy/Sell Price </th>
                  <td mat-cell *matCellDef="let stock">
                    <input *ngIf="stock.symbol !== 'Total'" type="number" [(ngModel)]="stock.tradePrice" (change)="onStockUpdate(stock, 'tradePrice', stock.tradePrice)" />
                  </td>
                </ng-container>
              
                <!-- Shares Column (Editable) -->
                <ng-container matColumnDef="shares">
                  <th mat-header-cell *matHeaderCellDef> Shares </th>
                  <td mat-cell *matCellDef="let stock">
                    <input *ngIf="stock.symbol !== 'Total'" type="number" [(ngModel)]="stock.shares" (change)="onStockUpdate(stock, 'shares', stock.shares)" />
                  </td>
                </ng-container>
              
                <!-- Market Value Column (Calculated) -->
                <ng-container matColumnDef="marketValue">
                  <th mat-header-cell *matHeaderCellDef> Market Value </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.marketValue | currency }}</td>
                </ng-container>
              
                <!-- Cost Basis Column (Calculated) -->
                <ng-container matColumnDef="costBasis">
                  <th mat-header-cell *matHeaderCellDef> Cost Basis </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.costBasis | currency }}</td>
                </ng-container>
              
                <!-- Performance Column (Calculated) -->
                <ng-container matColumnDef="performance">
                  <th mat-header-cell *matHeaderCellDef> Performance </th>
                  <td mat-cell *matCellDef="let stock" [ngClass]="{'positive': stock.performance > 0, 'negative': stock.performance < 0}">
                    {{ stock.performance | percent:'1.2-2' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ttmEps">
                  <th mat-header-cell *matHeaderCellDef> TTM EPS </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.EPS | currency }}</td>
                </ng-container>

                <!-- Performance Column -->
                <ng-container matColumnDef="ttmPe">
                  <th mat-header-cell *matHeaderCellDef> TTM PE </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.PE | number:'1.2-2' }}</td>
                </ng-container>
              
                <!-- Header Row -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              
                <!-- Data Row -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>              
            </div>
        </mat-tab>
      
        <!-- Fundamentals Tab -->
        <mat-tab label="Fundamentals">
            <div class="tab-content">
              <table mat-table [dataSource]="fundamentalsDataSource">
                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef> Symbol </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.symbol }}</td>
                </ng-container>
                  
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.name }}</td>
                </ng-container>
                  
                <ng-container matColumnDef="revenuePerShare">
                    <th mat-header-cell *matHeaderCellDef> Revenue / Share </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.revenuePerShare | currency }}</td>
                </ng-container>
                  
                <ng-container matColumnDef="netIncomePerShare">
                    <th mat-header-cell *matHeaderCellDef> Net Income / Share </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.netIncomePerShare | currency }}</td>
                </ng-container>
                  
                <ng-container matColumnDef="freeCashFlowPerShare">
                    <th mat-header-cell *matHeaderCellDef> Free Cash Flow / Share </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.freeCashFlowPerShare | currency }}</td>
                </ng-container>
                  
                <ng-container matColumnDef="enterpriseValue">
                    <th mat-header-cell *matHeaderCellDef> Enterprise Value </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.enterpriseValue | currency }}</td>
                </ng-container>
                 
                <ng-container matColumnDef="roic">
                    <th mat-header-cell *matHeaderCellDef> ROIC </th>
                    <td mat-cell *matCellDef="let stock">{{ stock.roic | percent:'1.0-2' }}</td>
                </ng-container>
                  
          
                <!-- Header Row -->
                <tr mat-header-row *matHeaderRowDef="fundamentalsColumns"></tr>
          
                <!-- Data Row -->
                <tr mat-row *matRowDef="let row; columns: fundamentalsColumns;"></tr>
              </table>
            </div>
        </mat-tab>
      
        <!-- Holdings Tab -->
        <mat-tab label="Holdings">
            <div class="tab-content">
              <table mat-table [dataSource]="portfolioDetails">
                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                  <th mat-header-cell *matHeaderCellDef> Symbol </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.symbol }}</td>
                </ng-container>
          
                <!-- Price Column -->
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef> Price </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.price | currency }}</td>
                </ng-container>
          
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.name }}</td>
                </ng-container>

                <!-- Trade Price Column (Editable) -->
                <ng-container matColumnDef="tradePrice">
                  <th mat-header-cell *matHeaderCellDef> Last Buy/Sell Price </th>
                  <td mat-cell *matCellDef="let stock">
                    <input *ngIf="stock.symbol !== 'Total'" type="number" [(ngModel)]="stock.tradePrice" (change)="onStockUpdate(stock, 'tradePrice', stock.tradePrice)" />
                  </td>
                </ng-container>

                <!-- Shares Column (Editable) -->
                <ng-container matColumnDef="shares">
                  <th mat-header-cell *matHeaderCellDef> Shares </th>
                  <td mat-cell *matCellDef="let stock">
                    <input type="number" [(ngModel)]="stock.shares" (change)="onStockUpdate(stock, 'shares', stock.shares)" />
                  </td>
                </ng-container>
          
                <!-- Market Value Column -->
                <ng-container matColumnDef="marketValue">
                  <th mat-header-cell *matHeaderCellDef> Market Value </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.marketValue | currency }}</td>
                </ng-container>
          
                <!-- Cost Basis Column -->
                <ng-container matColumnDef="costBasis">
                  <th mat-header-cell *matHeaderCellDef> Cost Basis </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.costBasis | currency }}</td>
                </ng-container>
          
                <!-- Performance Column -->
                <ng-container matColumnDef="performance">
                  <th mat-header-cell *matHeaderCellDef> Performance </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.performance | percent:'1.2-2' }}</td>
                </ng-container>

                                <!-- Performance Column -->
                <ng-container matColumnDef="ttmEps">
                  <th mat-header-cell *matHeaderCellDef> TTM EPS </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.EPS | percent:'1.2-2' }}</td>
                </ng-container>

                <!-- Performance Column -->
                <ng-container matColumnDef="ttmPe">
                  <th mat-header-cell *matHeaderCellDef> TTM PE </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.PE | percent:'1.2-2' }}</td>
                </ng-container>
          
                <!-- Header Row -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          
                <!-- Data Row -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
        </mat-tab>
      
        <!-- Technicals Tab -->
        <mat-tab label="Technicals">
            <div class="tab-content">
              <table mat-table [dataSource]="dataSource">
                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                  <th mat-header-cell *matHeaderCellDef> Symbol </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.symbol }}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.name }}</td>
                </ng-container>
          
                <!-- Price Column -->
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef> Price </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.price | currency }}</td>
                </ng-container>

                <!-- Trade Price Column (Editable) -->
                <ng-container matColumnDef="tradePrice">
                  <th mat-header-cell *matHeaderCellDef> Last Buy/Sell Price </th>
                  <td mat-cell *matCellDef="let stock">
                    <input *ngIf="stock.symbol !== 'Total'" type="number" [(ngModel)]="stock.tradePrice" (change)="onStockUpdate(stock, 'tradePrice', stock.tradePrice)" />
                  </td>
                </ng-container>

                <!-- Shares Column (Editable) -->
                <ng-container matColumnDef="shares">
                  <th mat-header-cell *matHeaderCellDef> Shares </th>
                  <td mat-cell *matCellDef="let stock">
                    <input type="number" [(ngModel)]="stock.shares" (change)="onStockUpdate(stock, 'shares', stock.shares)" />
                  </td>
                </ng-container>
        
          
                <!-- Market Value Column -->
                <ng-container matColumnDef="marketValue">
                  <th mat-header-cell *matHeaderCellDef> Market Value </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.marketValue | currency }}</td>
                </ng-container>
          
                <!-- Cost Basis Column -->
                <ng-container matColumnDef="costBasis">
                  <th mat-header-cell *matHeaderCellDef> Cost Basis </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.costBasis | currency }}</td>
                </ng-container>
          
                <!-- Performance Column -->
                <ng-container matColumnDef="performance">
                  <th mat-header-cell *matHeaderCellDef> Performance </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.performance | percent:'1.2-2' }}</td>
                </ng-container>

                <!-- Performance Column -->
                <ng-container matColumnDef="ttmEps">
                  <th mat-header-cell *matHeaderCellDef> TTM EPS </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.EPS | percent:'1.2-2' }}</td>
                </ng-container>

                <!-- Performance Column -->
                <ng-container matColumnDef="ttmPe">
                  <th mat-header-cell *matHeaderCellDef> TTM PE </th>
                  <td mat-cell *matCellDef="let stock">{{ stock.PE | percent:'1.2-2' }}</td>
                </ng-container>
          
                <!-- Header Row -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          
                <!-- Data Row -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
        </mat-tab>
        <mat-tab label="Calendar">
            <div class="tab-content">
              <mat-form-field>
                <mat-label>Select Date</mat-label>
                <input matInput [matDatepicker]="picker">
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-tab>          
    </mat-tab-group>

    <div>
      <div id="container" *ngIf="isBrowser" style="width: 100%; height: 40em; display: block;"></div>
    </div>
      
  
    <!-- News Section -->
    <div class="news-box">
      <h3>Latest News</h3>
      <app-news-box [tickerArr]="tickers"></app-news-box>
    </div>
  </div>
  
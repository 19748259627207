import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'app-ddm-calculator',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './ddm-calculator.component.html',
  styleUrls: ['./ddm-calculator.component.css']
})
export class DdmCalculatorComponent {
  ddmForm: FormGroup;
  result: number | null = null;

  constructor(private fb: FormBuilder) {
    this.ddmForm = this.fb.group({
      currentDividend: [2.0, Validators.required],
      discountRate: [0.10, Validators.required],
      ddmType: ['regular'],
      growthRate: [0.05],
      highGrowthPeriod: [5],
      highGrowthRate: [0.10],
      stableGrowthRate: [0.05]
    });
  }

  onSubmit() {
    const formValues = this.ddmForm.value;
    if (formValues.ddmType === 'regular') {
      this.result = this.gordonGrowthModel(formValues);
    } else {
      this.result = this.twoStageDDM(formValues);
    }
  }

  gordonGrowthModel(input: { currentDividend: number; growthRate: number; discountRate: number }): number {
    const { currentDividend, growthRate, discountRate } = input;
    if (discountRate <= growthRate) {
      throw new Error('Discount rate must be greater than growth rate.');
    }
    return currentDividend * (1 + growthRate) / (discountRate - growthRate);
  }

  twoStageDDM(input: {
    currentDividend: number;
    highGrowthPeriod: number;
    highGrowthRate: number;
    stableGrowthRate: number;
    discountRate: number;
  }): number {
    const {
      currentDividend,
      highGrowthPeriod,
      highGrowthRate,
      stableGrowthRate,
      discountRate
    } = input;

    if (discountRate <= highGrowthRate || discountRate <= stableGrowthRate) {
      throw new Error('Discount rate must be greater than both growth rates.');
    }

    let highGrowthDividends = 0;
    let dividend = currentDividend;
    for (let t = 1; t <= highGrowthPeriod; t++) {
      dividend *= (1 + highGrowthRate);
      highGrowthDividends += dividend / Math.pow(1 + discountRate, t);
    }

    const stableDividend = dividend * (1 + stableGrowthRate);
    const stableValue = stableDividend / (discountRate - stableGrowthRate);
    const discountedStableValue = stableValue / Math.pow(1 + discountRate, highGrowthPeriod);

    return highGrowthDividends + discountedStableValue;
  }
}

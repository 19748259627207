<div class="ticker-container">
  <div class="ticker-content" [ngStyle]="{'animation-duration': tickerDuration}">
    <ng-container *ngFor="let item of tickerItems">
      <span class="ticker-item">
        <span lass="symbol-text">{{ item.symbol }}</span>
        <span [ngClass]="{'green-text': item.changesPercentage > 0, 'red-text': item.changesPercentage <= 0}">
          {{ item.changesPercentage > 0 ? '+' : '' }}{{ item.changesPercentage / 100 | percent: '1.2-2' }}
        </span>
      </span>
    </ng-container>
  </div>
</div>


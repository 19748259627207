import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

const ndq_DATA_KEY = makeStateKey<any>('ndq-data');  // Key to store stock data in TransferState
const sp1500_DATA_KEY = makeStateKey<any>('sp1500-data');  // Key to store stock data in TransferState
const DATA_KEY = makeStateKey<any>('data');  // Key to store stock data in TransferState

const cloudCrusaders_DATA_KEY = makeStateKey<any>('cloudCrusaders-data');
const dividendStocks_DATA_KEY = makeStateKey<any>('dividendStocks-data');
const evCharging_DATA_KEY = makeStateKey<any>('evCharging-data');
const pharmaPowerhouses_DATA_KEY = makeStateKey<any>('pharmaPowerhouses-data');
const digitalPayments_DATA_KEY = makeStateKey<any>('digitalPayments-data');
const industrialWaterTreatment_DATA_KEY = makeStateKey<any>('industrialWaterTreatment-data');
const electricAviationAndAirMobility_DATA_KEY = makeStateKey<any>('electricAviationAndAirMobility-data');
const smartFarmingAndIoTAgriculture_DATA_KEY = makeStateKey<any>('smartFarmingAndIoTAgriculture-data');


@Injectable({
  providedIn: 'root'
})
export class StockListResolver implements Resolve<any> {

  constructor(    private stockDataService: StockDataService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // Define all stock groups

    // Stock groups data fetch
    const type = route.params['type']; // Get the type from the route params
    if (type === 'gainers' || type === 'losers' || type === 'most') {
      return true
    }

    // Stock groups data fetch
    const data = await this.stockDataService.getStockLists(type).toPromise();

    
    return data.length > 0 ? data.stockData: false;
  }
}

<div class="stock-display">
    <!-- Display content -->
     <!-- your-component.component.html -->
    <div class="image-container">
      <img [src]="img" alt="Cloud Crusaders" />
      <h2 class="image-title">{{ title }}</h2>
    </div>
    

    <div class="content" *ngIf="content" style="padding:1em;">
      
      <p>{{ content }}</p>
    </div>

    <div *ngIf="!loading && isBrowser && !index" style="display:flex;">
      <div id="container" style="width: 95%; height: 40em;"></div>
    </div>

    <div *ngIf="!loading && isBrowser && !index" style="display:flex; flex-direction: row;">
      <div id="sector-container" style="width: 45%; height: 20em;"></div>
      <div id="industry-container" style="width: 45%; height: 20em; margin-top: 20px;"></div>
    </div>

    <div *ngIf="!index && stockData && stockData.length > 0">
      <div class="top-stocks-container">
        <div class="stock-card" *ngFor="let stock of stockData">
          <ng-container *ngIf="stockDetails[type][stock.ticker];">
            <img [src]="stock.image" alt="{{ stock.companyName }}" class="stock-image" />
            <div class="stock-details">
              <h2 class="stock-name">{{ stock.companyName }}</h2>
              <p>{{stockDetails[type][stock.ticker]}}</p>
              <p *ngIf="stock.PE" class="stock-pe">PE Ratio: {{ stock.PE | number: '1.2-2' }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  
    <!-- Display stock data table -->
    <div *ngIf="stockData && stockData.length > 0">
      <!-- Existing Table -->
      <div *ngIf="!index">
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Close</th>
              <th>Change</th>
              <th>Change (%)</th>
              <th>Volume</th>
              <th>Avg Volume (10 days)</th>
              <th>VWAP</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stock of stockData">
              <td>
                <a [routerLink]="['/chart', stock.symbol, stock.exchangeShortName]">
                  {{ stock.symbol }}
                </a>
              </td>
              <td>{{ stock.name }}</td>
              <td>{{ stock.close | number: '1.2-2' }}</td>
              <td [ngClass]="{'positive': stock.change > 0, 'negative': stock.change < 0}">
                {{ stock.change | number: '1.2-2' }}
              </td>
              <td [ngClass]="{'positive': stock.changePercent > 0, 'negative': stock.changePercent < 0}">
                {{ stock.changePercent | number: '1.2-2' }}%
              </td>
              <td>{{ stock.volume | number }}</td>
              <td>{{ stock.avgVolume | number }}</td>
              <td>{{ stock.vwap | number: '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <!-- New Table for Updated Data Shape -->
      <div *ngIf="index">
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Beta</th>
              <th>Price</th>
              <th>PE Ratio</th>
              <th>EPS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stock of stockData">
              <td>
                <a [routerLink]="['/chart', stock.symbol, stock.exchangeShortName]">
                  {{ stock.symbol }}
                </a>
              </td>
              <td>{{ stock.name }}</td>
              <td>{{ stock.Beta | number: '1.2-2' }}</td>
              <td>{{ stock.PE | number: '1.2-2' }}</td>
              <td>{{ stock.EPS | number: '1.2-2' }}</td>
              <td>{{ stock.price | number: '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>    
  
    <!-- Loading state -->
    <div *ngIf="loading">
      <p>Loading stock data...</p>
    </div>
  
    <!-- No data message -->
    <div *ngIf="!loading && stockData && stockData.length === 0">
      <p>No stock data available.</p>
    </div>
  </div>
  
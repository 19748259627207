import { Component,Input } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Chart, registerables } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { format } from 'date-fns';
import { StockDataService } from '../stock-data.service';
import { CommonModule } from '@angular/common';
import { TickerTapeComponent } from '../ticker-tape/ticker-tape.component';
import { AUTO_STYLE } from '@angular/animations';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-chart-strip',
  standalone: true,
  imports: [TickerTapeComponent, CommonModule],
  templateUrl: './chart-strip.component.html',
  styleUrl: './chart-strip.component.css'
})
export class ChartStripComponent {
    ticker: string = ''
  constructor(private stockDataService: StockDataService, private router: Router, private route: ActivatedRoute) { }

  public chartSpy: boolean = false;
  public lastPxSpy: number = 0;
  public lastPctSpy: number = 0;

  public chartNDQ: boolean = false;
  public lastPxNDQ: number = 0;
  public lastPctNDQ: number = 0;

  public chartBTC: boolean = false;
  public lastPxBTC: number = 0;
  public lastPctBTC: number = 0;

  public chartVIX: boolean = false;
  public lastPxVIX: number = 0;
  public lastPctVIX: number = 0;

  path: string = '';

  async ngAfterViewInit(): Promise<void> {
    if (!this.chartSpy) {
      let temp = await this.renderChart('^GSPC', 'S&P', this.chartSpy);
      this.lastPxSpy = temp[0];
      this.lastPctSpy = temp[1];
      this.chartSpy = true;
    } else {
      let canvas = document.getElementById('S&P') as any;
      canvas.chart.destroy();
      let temp = await this.renderChart('^GSPC', 'S&P', this.chartSpy);
      this.lastPxSpy = temp[0];
      this.lastPctSpy = temp[1];
    }

    if (!this.chartNDQ) {
      let temp = await this.renderChart('^IXIC', 'NASDQ', this.chartNDQ);
      this.lastPxNDQ = temp[0];
      this.lastPctNDQ = temp[1];
      this.chartNDQ = true;
    } else {
      let canvas = document.getElementById('NASDQ') as any;
      canvas.chart.destroy();
      let temp = await this.renderChart('^IXIC', 'NASDQ', this.chartNDQ);
      this.lastPxNDQ = temp[0];
      this.lastPctNDQ = temp[1];
    }

    if (!this.chartBTC) {
      let temp = await this.renderChart('BTCUSD', 'BTC', this.chartBTC);
      this.lastPxBTC = temp[0];
      this.lastPctBTC = temp[1];
      this.chartBTC = true;
    } else {
      let canvas = document.getElementById('BTC') as any;
      canvas.chart.destroy();
      let temp = await this.renderChart('BTCUSD', 'BTC', this.chartBTC);
      this.lastPxBTC = temp[0];
      this.lastPctBTC = temp[1];
    }

    if (!this.chartVIX) {
      let temp = await this.renderChart('^VIX', 'VIX', this.chartVIX);
      this.lastPxVIX = temp[0];
      this.lastPctVIX = temp[1];
      this.chartVIX = true;
    } else {
      let canvas = document.getElementById('VIX') as any;
      canvas.chart.destroy();
      let temp = await this.renderChart('^VIX', 'VIX', this.chartVIX);
      this.lastPxVIX = temp[0];
      this.lastPctVIX = temp[1];
    }
  }

  async renderChart(ticker: string, id: string, chart: any): Promise<number[]> {
    let lastPx = -1;
    let lastPct = -1;

    try {
      const data = await this.stockDataService.getIntradayStockData(ticker).toPromise();
      const data2 = await this.stockDataService.getStockData(ticker).toPromise();
      

      const stockData = data.map((item: any) => ({
        date: new Date(item.date),
        price: item.close
      }));
      const stockPx = data2.historical.map((item: any) => ({
        date: new Date(item.date),
        price: item.close
      }));

      

      const dates = stockData.map((item: any) => item.date);
      const dateStrings = dates.map((dateObj: any) => format(dateObj, 'yyyy-MM-dd HH:mm:ss'));
      const prices = stockData.map((item: any) => item.price);
      const pricesC = stockPx.map((item: any) => item.price);

      var ctx = id;
      var backgroundColor1 = '';
      var borderColor1 = '';

      if (pricesC[0] - pricesC[1] >= 0) {
        backgroundColor1 = 'rgba(0, 180, 0, 0.25)';
        borderColor1 = 'rgba(0, 150, 0)';
      } else {
        backgroundColor1 = 'rgba(180, 0, 0, 0.25)';
        borderColor1 = 'rgba(150, 0, 0)';
      }

      lastPx = pricesC[0];
      lastPct = (pricesC[0] - pricesC[1]) / pricesC[1];

      chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dateStrings.reverse(),
          datasets: [{
            label: 'Stock Prices',
            data: prices.reverse(),
            backgroundColor: backgroundColor1,
            borderColor: borderColor1,
            borderWidth: 1,
            fill: true,
            pointRadius: 0
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false // Hides the legend
            }
          },
          scales: {
            x: {
              type: 'category',
              display: false,
              title: {
                display: true,
                text: 'Date'
              },
              grid: {
                display: false,
                color: 'rgba(0, 0, 0, 0)'
              }
            },
            y: {
              display: false,
              title: {
                display: true,
                text: 'Price'
              },
              grid: {
                display: false,
                color: 'rgba(0, 0, 0, 0)'
              }
            }
          }
        }
      });
    } catch (error) {
      console.log('Error fetching data:', error);
    }

    return [lastPx, lastPct];
  }

}

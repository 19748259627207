
<div class="parent-container">
  <div class="wrapper">
    <div class="content-wrapper">
      <div class="chart-container">
        <app-ticker-tape></app-ticker-tape>
        <div class="stock-header">
          <div class="stock-title-container">
            <div class="stock-title-underline">
              <img  [src]="image" class="whitespace-below" />
              <span class="company-name">{{name}}</span>
              <span class="whitespace-below" [ngClass]="{ 'positive': percentChg > 0, 'negative': percentChg < 0 }"> {{lastPx | currency:'USD':'symbol':'1.2-2'}}</span>
              <span class="whitespace-below" [ngClass]="{ 'positive': percentChg > 0, 'negative': percentChg < 0 }"> ({{percentChg | percent:'1.2-2'}})</span>
            </div>
            <!--<div>
              <span style="" class="whitespace-below" [ngClass]="{ 'positive': percentChg > 0, 'negative': percentChg < 0 }"> {{lastPx | currency:'USD':'symbol':'1.2-2'}}</span>
              <span class="whitespace-below" [ngClass]="{ 'positive': percentChg > 0, 'negative': percentChg < 0 }"> ({{percentChg | percent:'1.2-2'}})</span>
            </div>-->
          </div>
          <div class="sidebar">
            <div class="sidebar-content" [class.mobile-open]="isMobileMenuOpen">
              <button class="toggle-button" (click)="setActiveButton(0)" [ngClass]="{ 'active': activeButtonIndex === 0 }">Summary</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(1)" [ngClass]="{ 'active': activeButtonIndex === 1 }">Income Statement</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(2)" [ngClass]="{ 'active': activeButtonIndex === 2 }">Balance Sheet Statement</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(3)" [ngClass]="{ 'active': activeButtonIndex === 3 }">Cash Flow Statement</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(4)" [ngClass]="{ 'active': activeButtonIndex === 4 }">Profile</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(5)" [ngClass]="{ 'active': activeButtonIndex === 5 }">Comparison</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(6)" [ngClass]="{ 'active': activeButtonIndex === 6 }">Filings</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(7)" [ngClass]="{ 'active': activeButtonIndex === 7 }">Statistics</button>
              <button *ngIf="display" class="toggle-button" (click)="setActiveButton(8)" [ngClass]="{ 'active': activeButtonIndex === 8 }">Chart</button>
            </div>
          </div>
        </div>
        
    
        <div *ngIf="activeButtonIndex === 0" class="summary-container">
          <div class="chart-outer-container">
            <!-- Box with buttons -->
            <!--<div style="display: flex; flex-direction: row; margin-bottom: 10px;">
              <button (click)="updateSelectedTimeframe('1D')" style="margin-left:1.5em; margin-right: 1.5em;">1D</button>
              <button (click)="updateSelectedTimeframe('1W')" style="margin-right: 1.5em;">1W</button>
              <button (click)="updateSelectedTimeframe('1M')" style="margin-right: 1.5em;">1M</button>
              <button (click)="updateSelectedTimeframe('3M')" style="margin-right: 1.5em;">3M</button>
              <button (click)="updateSelectedTimeframe('6M')" style="margin-right: 1.5em;">6M</button>
              <button (click)="updateSelectedTimeframe('1Y')" style="margin-right: 1.5em;">1Y</button>
              <button (click)="updateSelectedTimeframe('5Y')">5Y</button>
            </div>-->
            <!-- Chart container -->
            <div *ngIf = "isBrowser" class="chart-inner-container">
              <!-- Chart canvas -->
              <!--<canvas id='stockChart'>{{ chart }}</canvas>-->
              <div style="height:100%;" id="chart-container"></div>
              <div class="tools-container"></div>
            </div>
          </div>
          <div class="stock-info-container">
            <table class="income-table">
              <tbody>
                <ng-container *ngFor="let header of metricsTableHeaders">
                  <tr *ngIf="shouldBeDisplayed(header)">
                    <td>{{ header }}</td>
                    <td>
                      <ng-container *ngIf="!isNumeric(metricsData[0][header]); else notDate">
                        {{ metricsData[0][header] }}
                      </ng-container>
                      <ng-template #notDate>
                        {{ metricsData[0][header] | number }}
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <table class="income-table">
              <tbody>
                <ng-container *ngFor="let header of profileTableHeaders">
                  <tr *ngIf="shouldBeDisplayed(header)">
                    <td>{{ header }}</td>
                    <td>
                      <ng-container *ngIf="profileData?.[0]?.[id] && profileData[0][id][header] !== undefined && !isNumeric(profileData[0][id][header]); else notDate">
                        {{ profileData[0][id][header] }}
                      </ng-container>
                      <ng-template #notDate>
                        {{ profileData?.[0]?.[id]?.[header] | number }}
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
    
    
        <div class="financial-statement-container" *ngIf="activeButtonIndex === 1 || activeButtonIndex === 2 || activeButtonIndex === 3">
          <div class="export-controls">
            <button (click)="exportToExcel()" class="export-button">
              <img src="../../assets/excelIcon.png" alt="Excel Icon" class="excel-icon">
              Export to Excel
            </button>
            <div class="date-range-controls">
              <label>From:</label>
              <input type="date" [(ngModel)]="fromDate">
              <label>To:</label>
              <input type="date" [(ngModel)]="toDate">
            </div>
          </div>
          <div class="table-container">
            <table class="income-table">
              <thead>
                <tr>
                  <th></th>
                  <ng-container *ngFor="let record of incomeStatementData">
                    <th *ngIf="isDateInRange(record.date)">{{ record.date }}</th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let header of incomeStatementTableHeaders; let i = index">
                  <tr *ngIf="!shouldSkipHeader(header)">
                    <td>{{ header }}</td>
                    <ng-container *ngFor="let record of incomeStatementData; let j = index">
                      <td (mouseenter)="hoveredColumn = j"
                          (mouseleave)="hoveredColumn = -1"
                          [ngClass]="{ 'highlight-column': j === hoveredColumn }"
                          *ngIf="record.date && isDateInRange(record.date)">
                        <ng-container *ngIf="header === 'date'; else notDate">
                          {{ record[header] }}
                        </ng-container>
                        <ng-template #notDate>
                          <ng-container *ngIf="isNumeric(record[header]); else notANumber">
                            {{ record[header] | number }}
                          </ng-container>
                          <ng-template #notANumber>
                            {{ record[header] }} <!-- Display the value as is if it's not a number -->
                          </ng-template>
                        </ng-template>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="profile-container" *ngIf="activeButtonIndex === 4">
          <h1>{{ profileData[0][id]?.companyName }}</h1>
          <p><strong>Description:</strong> {{ profileData[0][id]?.description }}</p>
          <p><strong>Website:</strong> <a [href]="profileData[0][id]?.website">{{ profileData[0][id]?.website }}</a></p>
          <p><strong>Industry:</strong> {{ profileData[0][id]?.industry }}</p>
          <p><strong>Sector:</strong> {{ profileData[0][id]?.sector }}</p>
          <p><strong>CEO:</strong> {{ profileData[0][id]?.ceo }}</p>
          <p><strong>Full-Time Employees:</strong> {{ profileData[0][id]?.fullTimeEmployees }}</p>
          <p><strong>Address:</strong> {{ profileData[0][id]?.address }}, {{ profileData[0][id]?.city }}, {{ profileData[0][id]?.state }}, {{ profileData[0][id]?.zip }}, {{ profileData[0][id]?.country }}</p>
          <p><strong>Phone:</strong> {{ profileData[0][id]?.phone }}</p>
          <p><strong>Currency:</strong> {{ profileData[0][id]?.currency }}</p>
          <p><strong>CIK:</strong> {{ profileData[0][id]?.cik }}</p>
          <p><strong>ISIN:</strong> {{ profileData[0][id]?.isin }}</p>
          <p><strong>CUSIP:</strong> {{ profileData[0][id]?.cusip }}</p>
          <p><strong>Exchange:</strong> {{ profileData[0][id]?.exchange }}</p>
          <p><strong>Exchange Short Name:</strong> {{ profileData[0][id]?.exchangeShortName }}</p>
          <p><strong>IPO Date:</strong> {{ profileData[0][id]?.ipoDate }}</p>
        </div>
        <div class="content-container" *ngIf="activeButtonIndex === 5">
          <table class="income-table">
            <tr>
              <th>Ticker</th>
              <th *ngFor="let metric of metrics">{{ metric }}</th>
            </tr>
            <tr *ngFor="let ticker of peers">
              <td>{{ ticker }}</td>
              <td *ngFor="let metric of metrics" style="padding-left: 1em;">{{ peersData[ticker][metric] | number:'1.2-2' }}</td>
            </tr>
          </table>
        </div>
        <div class="content-container" *ngIf="activeButtonIndex === 6">
          <input type="text" 
           [(ngModel)]="filterText" 
           placeholder="Filter by symbol, type, or CIK" 
           class="filter-input" />
          <div class="filings-container">
            <div *ngFor="let filing of filings.slice(0, displayCount); let i = index"
                 [style.backgroundColor]="i % 2 === 0 ? '#f0f0f0' : '#ffffff'"
                 style="padding: 1em; border-bottom: 0.05em solid #ddd; display: flex; flex-direction: column;">
              <div><strong>Type:</strong> {{ filing.type }}</div>
              <div><strong>Accepted Date:</strong> {{ filing.acceptedDate }}</div>
              <div><strong>Filling Date:</strong> {{ filing.fillingDate }}</div>
              <div><a [href]="filing.finalLink" target="_blank">Link</a></div>
            </div>
    
            <button *ngIf="displayCount < filings.length" (click)="loadMore()" style="margin-top: 1em; padding: 0.5em 1em;">
              Load More
            </button>
          </div>
        </div>
        <div *ngIf="display" class="news-section analyst-estimates">
          <h1>Analyst Estimates</h1>
          <div class="analyst-estimate-container">
            <app-analyst-estimate [ticker]="id"></app-analyst-estimate>
          </div>
        </div>
        
        <div class="news-section">
          <h2>Latest News</h2>
          <app-news-box class="news-box" [ticker]="id" [typ]="exch"></app-news-box>
        </div>
        
        
    </div>
    
    
    </div>  
    <div class="comps-container">
      <span>Comps</span>
      <div class="highlight-on-hover" *ngFor="let key of objectKeys(peersData)">
        <div *ngIf="key != id" class="peer-item" 
        [routerLink]="['/chart', key, peersData[key]?.exchangeShortName]">
            <img 
              *ngIf="peersData[key]?.image" 
              [src]="peersData[key]?.image" 
              class="peer-image"
              [alt]="'Logo of ' + peersData[key]?.name"
            >
            <div class="peer-info">
              <span class="peer-name">{{ peersData[key]?.name }}</span>
              <span class="peer-ticker">{{ key }}</span>
            </div>
            <div class="peer-price-container">
              <span class="peer-price">{{ peersData[key]?.price | currency:'USD' }}</span>
            </div>
        </div>
      </div>      
    </div>
  </div>

  <div class="general-news">
    <h2>General News</h2>
    <app-news-box class="news-box" [typ]="'general'"></app-news-box>
  </div>
</div>




import { Component, OnInit, SecurityContext } from '@angular/core';
import { forkJoin } from 'rxjs'; // To handle multiple requests
import { StockDataService } from './../stock-data.service';
import { FormsModule } from '@angular/forms';  // <-- Import FormsModule
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DomSanitizer, Meta, SafeUrl, Title } from '@angular/platform-browser';

export interface News {
  title: string;
  text: string;
  url: string;
  symbol?: string;
  image?: string;
}

@Component({
  selector: 'app-news-list',
  standalone: true,
  imports: [FormsModule, CommonModule, NgxSkeletonLoaderModule],
  templateUrl: './news-list.component.html',
  styleUrl: './news-list.component.css'
})
export class NewsListComponent {


  searchQuery: string = '';
  newsList: News[] = [];

  filteredStockNews: News[] = [];
  filteredCryptoNews: News[] = [];
  filteredForexNews: News[] = [];
  filteredGeneralNews: News[] = [];

  constructor(private stockDataService: StockDataService, 
    private sanitizer: DomSanitizer,
    private titleService: Title, 
    private metaService: Meta ) { }

  ngOnInit(): void {

    const title = 'Latest Stock, Crypto, Forex & General News';
    const description = 'Stay updated with the latest news on stocks, cryptocurrencies, forex, and more. Get live feeds from top sources.';

    // Set title
    this.titleService.setTitle(title);

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });

    
    forkJoin({
      stockNews: this.stockDataService.getStockNews(),
      cryptoNews: this.stockDataService.getCryptoNews(),
      forexNews: this.stockDataService.getForexNews(),
      generalNews: this.stockDataService.getNews()
    }).subscribe({
      next: (results) => {
        // Store the original news list for filtering
         // Set initial filtered news lists with sanitized URLs
         this.filteredStockNews = this.sanitizeNewsList(results.stockNews);
         this.filteredCryptoNews = this.sanitizeNewsList(results.cryptoNews);
         this.filteredForexNews = this.sanitizeNewsList(results.forexNews);
         this.filteredGeneralNews = this.sanitizeNewsList(results.generalNews);

        this.newsList = [
          ...this.filteredStockNews,
          ...this.filteredCryptoNews,
          ...this.filteredForexNews,
          ...this.filteredGeneralNews
        ];
      },
      error: (err) => console.error('Error fetching news: ', err)
    });
  }

  sanitizeNewsList(newsList: News[]): News[] {
    return newsList.filter(news => 
      this.isValidUrl(news.url) && 
      (news.image === undefined || this.isValidUrl(news.image))
    ).map(news => ({
      ...news,
      safeUrl: this.sanitizer.bypassSecurityTrustUrl(news.url),
      safeImageUrl: news.image ? this.sanitizer.bypassSecurityTrustUrl(news.image) : undefined
    }));
  }

  private isValidUrl(url: string | undefined): boolean {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  redirectToNews(url: string): void {
    if (url) {
      // Open the URL in a new tab or window
      window.open(url, '_blank');
    }
  }

  onSearch(): void {
    const query = this.searchQuery.toLowerCase();

    // Filter each news list by the query
    this.filteredStockNews = this.newsList.filter(newsItem =>
      newsItem.title.toLowerCase().includes(query) && this.isStockNews(newsItem)
    );

    this.filteredCryptoNews = this.newsList.filter(newsItem =>
      newsItem.title.toLowerCase().includes(query) && this.isCryptoNews(newsItem)
    );

    this.filteredForexNews = this.newsList.filter(newsItem =>
      newsItem.title.toLowerCase().includes(query) && this.isForexNews(newsItem)
    );

    this.filteredGeneralNews = this.newsList.filter(newsItem =>
      newsItem.title.toLowerCase().includes(query) && this.isGeneralNews(newsItem)
    );
  }

  // Helper functions to determine the type of news
  isStockNews(newsItem: News): boolean {
    // Add logic to identify if the news is stock news
    return this.newsList.includes(newsItem) && !!newsItem.symbol; // Example condition
  }

  isCryptoNews(newsItem: News): boolean {
    // Add logic to identify if the news is crypto news
    return this.newsList.includes(newsItem) && !!newsItem.symbol; // Example condition
  }

  isForexNews(newsItem: News): boolean {
    // Add logic to identify if the news is forex news
    return this.newsList.includes(newsItem) && !!newsItem.symbol; // Example condition
  }

  isGeneralNews(newsItem: News): boolean {
    // Add logic to identify if the news is general news
    return !this.isStockNews(newsItem) && !this.isCryptoNews(newsItem) && !this.isForexNews(newsItem);
  }
}

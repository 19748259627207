import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: 'popup.component.html',
  styleUrl: './popup.component.css'
})
export class PopupComponent {
   keys: string[] = [
    'price',
    'volume',
    'dividend',
    'sector',
    'industry',
    'revenuePerShare',
    'netIncomePerShare',
    'operatingCashFlowPerShare',
    'freeCashFlowPerShare',
    'cashPerShare',
    'bookValuePerShare',
    'tangibleBookValuePerShare',
    'shareholdersEquityPerShare',
    'interestDebtPerShare',
    'marketCap',
    'enterpriseValue',
    'peRatio',
    'priceToSalesRatio',
    'pocfratio',
    'pfcfRatio',
    'pbRatio',
    'ptbRatio',
    'evToSales',
    'enterpriseValueOverEBITDA',
    'evToOperatingCashFlow',
    'evToFreeCashFlow',
    'earningsYield',
    'freeCashFlowYield',
    'debtToEquity',
    'debtToAssets',
    'netDebtToEBITDA',
    'currentRatio',
    'interestCoverage',
    'incomeQuality',
    'dividendYield',
    'payoutRatio',
    'salesGeneralAndAdministrativeToRevenue',
    'researchAndDdevelopementToRevenue',
    'intangiblesToTotalAssets',
    'capexToOperatingCashFlow',
    'capexToRevenue',
    'capexToDepreciation',
    'stockBasedCompensationToRevenue',
    'grahamNumber',
    'roic',
    'returnOnTangibleAssets',
    'grahamNetNet',
    'workingCapital',
    'tangibleAssetValue',
    'netCurrentAssetValue',
    'investedCapital',
    'averageReceivables',
    'averagePayables',
    'averageInventory',
    'daysSalesOutstanding',
    'daysPayablesOutstanding',
    'daysOfInventoryOnHand',
    'receivablesTurnover',
    'payablesTurnover',
    'inventoryTurnover',
    'roe',
    'capexPerShare'
  ];

  fundamentalsKeys: string[] = [
  'price',
  'volume',
  'dividend',
  'sector',
  'industry',
  'marketCap'
];

statementRatiosKeys: string[] = [
  'revenuePerShare',
  'netIncomePerShare',
  'operatingCashFlowPerShare',
  'freeCashFlowPerShare',
  'cashPerShare',
  'bookValuePerShare',
  'tangibleBookValuePerShare',
  'shareholdersEquityPerShare',
  'interestDebtPerShare',
  'workingCapital',
  'tangibleAssetValue',
  'netCurrentAssetValue',
  'investedCapital',
  'averageReceivables',
  'averagePayables',
  'averageInventory',
  'daysSalesOutstanding',
  'daysPayablesOutstanding',
  'daysOfInventoryOnHand',
  'receivablesTurnover',
  'payablesTurnover',
  'inventoryTurnover',
  'roe',
  'capexPerShare',
  'salesGeneralAndAdministrativeToRevenue',
  'researchAndDdevelopementToRevenue',
  'roic',
  'returnOnTangibleAssets'
];

otherRatiosKeys: string[] = [
  'peRatio',
  'priceToSalesRatio',
  'pocfratio',
  'pfcfRatio',
  'pbRatio',
  'ptbRatio',
  'evToSales',
  'enterpriseValueOverEBITDA',
  'evToOperatingCashFlow',
  'evToFreeCashFlow',
  'earningsYield',
  'freeCashFlowYield',
  'debtToEquity',
  'debtToAssets',
  'netDebtToEBITDA',
  'currentRatio',
  'interestCoverage',
  'incomeQuality',
  'dividendYield',
  'payoutRatio',
  'intangiblesToTotalAssets',
  'capexToOperatingCashFlow',
  'capexToRevenue',
  'capexToDepreciation',
  'stockBasedCompensationToRevenue',
  'grahamNumber',
  'grahamNetNet'
  ];

  // Categorized arrays
  securityInfo: string[] = [
    'sector',
    'industry',
    'marketCap',
    'enterpriseValue',
    'price',
    'dividend',
  ];

  marketData: string[] = [
    'volume',
    'price',
    'marketCap',
    'enterpriseValue',
  ];

  valuation: string[] = [
    'peRatio',
    'priceToSalesRatio',
    'pbRatio',
    'pfcfRatio',
    'evToSales',
    'enterpriseValueOverEBITDA',
    'evToOperatingCashFlow',
    'evToFreeCashFlow',
    'earningsYield',
    'freeCashFlowYield',
    'grahamNumber',
    'grahamNetNet',
  ];

  marginsAndRatios: string[] = [
    'revenuePerShare',
    'netIncomePerShare',
    'operatingCashFlowPerShare',
    'freeCashFlowPerShare',
    'cashPerShare',
    'bookValuePerShare',
    'tangibleBookValuePerShare',
    'shareholdersEquityPerShare',
    'interestDebtPerShare',
    'debtToEquity',
    'debtToAssets',
    'netDebtToEBITDA',
    'currentRatio',
    'interestCoverage',
    'incomeQuality',
    'dividendYield',
    'payoutRatio',
    'salesGeneralAndAdministrativeToRevenue',
    'researchAndDdevelopementToRevenue',
    'intangiblesToTotalAssets',
    'capexToOperatingCashFlow',
    'capexToRevenue',
    'capexToDepreciation',
    'stockBasedCompensationToRevenue',
    'roe',
    'roic',
    'workingCapital',
    'tangibleAssetValue',
    'netCurrentAssetValue',
    'investedCapital',
    'averageReceivables',
    'averagePayables',
    'averageInventory',
    'daysSalesOutstanding',
    'daysPayablesOutstanding',
    'daysOfInventoryOnHand',
    'receivablesTurnover',
    'payablesTurnover',
    'inventoryTurnover',
    'capexPerShare',
  ];

  sectors: string[] = [
    "Basic Materials",
    "Communication Services",
    "Consumer Cyclical",
    "Consumer Defensive",
    "Energy",
    "Financial Services",
    "Healthcare",
    "Industrials",
    "Real Estate",
    "Technology",
    "Utilities"
  ];

  industries: string[] = [
    "Steel",
    "Silver",
    "Other Precious Metals",
    "Gold",
    "Copper",
    "Aluminum",
    "Paper, Lumber & Forest Products",
    "Industrial Materials",
    "Construction Materials",
    "Chemicals - Specialty",
    "Chemicals",
    "Agricultural Inputs",
    "Telecommunications Services",
    "Internet Content & Information",
    "Publishing",
    "Broadcasting",
    "Advertising Agencies",
    "Entertainment",
    "Travel Lodging",
    "Travel Services",
    "Specialty Retail",
    "Luxury Goods",
    "Home Improvement",
    "Residential Construction",
    "Department Stores",
    "Personal Products & Services",
    "Leisure",
    "Gambling, Resorts & Casinos",
    "Furnishings, Fixtures & Appliances",
    "Restaurants",
    "Auto - Parts",
    "Auto - Manufacturers",
    "Auto - Recreational Vehicles",
    "Auto - Dealerships",
    "Apparel - Retail",
    "Apparel - Manufacturers",
    "Apparel - Footwear & Accessories",
    "Packaging & Containers",
    "Tobacco",
    "Grocery Stores",
    "Discount Stores",
    "Household & Personal Products",
    "Packaged Foods",
    "Food Distribution",
    "Food Confectioners",
    "Agricultural Farm Products",
    "Education & Training Services",
    "Beverages - Wineries & Distilleries",
    "Beverages - Non-Alcoholic",
    "Beverages - Alcoholic",
    "Uranium",
    "Solar",
    "Oil & Gas Refining & Marketing",
    "Oil & Gas Midstream",
    "Oil & Gas Integrated",
    "Oil & Gas Exploration & Production",
    "Oil & Gas Equipment & Services",
    "Oil & Gas Energy",
    "Oil & Gas Drilling",
    "Coal",
    "Shell Companies",
    "Investment - Banking & Investment Services",
    "Insurance - Specialty",
    "Insurance - Reinsurance",
    "Insurance - Property & Casualty",
    "Insurance - Life",
    "Insurance - Diversified",
    "Insurance - Brokers",
    "Financial - Mortgages",
    "Financial - Diversified",
    "Financial - Data & Stock Exchanges",
    "Financial - Credit Services",
    "Financial - Conglomerates",
    "Financial - Capital Markets",
    "Banks - Regional",
    "Banks - Diversified",
    "Banks",
    "Asset Management",
    "Asset Management - Bonds",
    "Asset Management - Income",
    "Asset Management - Leveraged",
    "Asset Management - Cryptocurrency",
    "Asset Management - Global",
    "Medical - Specialties",
    "Medical - Pharmaceuticals",
    "Medical - Instruments & Supplies",
    "Medical - Healthcare Plans",
    "Medical - Healthcare Information Services",
    "Medical - Equipment & Services",
    "Medical - Distribution",
    "Medical - Diagnostics & Research",
    "Medical - Devices",
    "Medical - Care Facilities",
    "Drug Manufacturers - Specialty & Generic",
    "Drug Manufacturers - General",
    "Biotechnology",
    "Waste Management",
    "Trucking",
    "Railroads",
    "Aerospace & Defense",
    "Marine Shipping",
    "Integrated Freight & Logistics",
    "Airlines, Airports & Air Services",
    "General Transportation",
    "Manufacturing - Tools & Accessories",
    "Manufacturing - Textiles",
    "Manufacturing - Miscellaneous",
    "Manufacturing - Metal Fabrication",
    "Industrial - Distribution",
    "Industrial - Specialties",
    "Industrial - Pollution & Treatment Controls",
    "Environmental Services",
    "Industrial - Machinery",
    "Industrial - Infrastructure Operations",
    "Industrial - Capital Goods",
    "Consulting Services",
    "Business Equipment & Supplies",
    "Staffing & Employment Services",
    "Rental & Leasing Services",
    "Engineering & Construction",
    "Security & Protection Services",
    "Specialty Business Services",
    "Construction",
    "Conglomerates",
    "Electrical Equipment & Parts",
    "Agricultural - Machinery",
    "Agricultural - Commodities/Milling",
    "REIT - Specialty",
    "REIT - Retail",
    "REIT - Residential",
    "REIT - Office",
    "REIT - Mortgage",
    "REIT - Industrial",
    "REIT - Hotel & Motel",
    "REIT - Healthcare Facilities",
    "REIT - Diversified",
    "Real Estate - Services",
    "Real Estate - Diversified",
    "Real Estate - Development",
    "Real Estate - General",
    "Information Technology Services",
    "Hardware, Equipment & Parts",
    "Computer Hardware",
    "Electronic Gaming & Multimedia",
    "Software - Services",
    "Software - Infrastructure",
    "Software - Application",
    "Semiconductors",
    "Media & Entertainment",
    "Communication Equipment",
    "Technology Distributors",
    "Consumer Electronics",
    "Renewable Utilities",
    "Regulated Water",
    "Regulated Gas",
    "Regulated Electric",
    "Independent Power Producers",
    "Diversified Utilities",
    "General Utilities"
  ];

  keyShow: string[] = [];

  keyDescriptions: { [key: string]: string } = this.stockService.keyDescriptions;

  keys2: string = '';
  // Other filtering criteria variables
  filterValues: any = {}; // Declare filterValues object

  stocks: any[]=[]; // Fetched stock data
  filteredStocks: any[] = []; // Filtered stock data

  typ: string = '';

  value: any  = '';
  operator: string = '';
  selectedTab: string | null = null;

  constructor(private stockService: StockDataService,
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keys2 = data.keysPass;
    this.typ = data.type;
    if (data.filters[this.keys2] !== undefined) {

      this.filterValues[this.keys2] = this.filterValues[this.keys2] || {};

      this.filterValues[this.keys2]['Operator'] = data.filters[this.keys2].Operator;
      this.filterValues[this.keys2]['Value'] = data.filters[this.keys2].Value;
      this.value = data.filters[this.keys2].Value;
    }
    if (data.keyShow) {
      this.keyShow = data.keyShow;
    }
    
  }

  onNoClick(): void {
    if (this.typ === 'single') {
      this.dialogRef.close({ filters: this.filterValues });
    } else {
      this.dialogRef.close({ keyShow: this.keyShow });
    }
  }

   initializeOperator(key: string, event: any): void {
    this.filterValues[key] = this.filterValues[key] || {};
    this.filterValues[key]['Operator'] = event;
  }

  initializeValue(key: string, event: any): void {
    this.filterValues[key] = this.filterValues[key] || {};
    this.filterValues[key]['Value'] = event;
  }

  initializeSec(key: string, event: any): void {
    this.filterValues[key] = this.filterValues[key] || {};
    this.filterValues[key]['Value'] = event;
    this.filterValues[key]['Operator'] = 'equal';
  }

  getKeysForTab(tab: string): string[] {
    if (tab === 'tab1') {
      return this.securityInfo;
    } else if (tab === 'tab2') {
      return this.marketData;
    } else if (tab === 'tab3') {
      return this.valuation;
    }  else {
      return this.marginsAndRatios;
    }
  }

  addKeyToArray(key: string) {
    if (!this.keyShow.includes(key)) {
      this.keyShow.push(key);
    }
  }

}

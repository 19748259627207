import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationBarComponent } from '../navigation-bar/navigation-bar.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mortgage-pmt',
  standalone: true,
  imports: [RouterModule, NavigationBarComponent, FormsModule, CommonModule],
  templateUrl: './mortgage-pmt.component.html',
  styleUrls: ['./mortgage-pmt.component.css']
})
export class MortgagePmtComponent implements OnInit {
  loanAmountStr: string = '';
  interestRateStr: string = '';
  paymentFrequency: string = '';
  paymentAmountStr: string = '';
  mortgageSchedule: any[] = [];

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    // Set SEO title and meta description for the Mortgage Payment Calculator page
    const title = 'Mortgage Payment Calculator - Calculate Your Monthly Payment';
    const description = 'Use our Mortgage Payment Calculator to easily calculate your monthly mortgage payments. Enter your loan amount, interest rate, and payment frequency to get an amortization schedule and payment breakdown.';

    // Set title
    this.titleService.setTitle(title);

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
  }

  onSubmit(): void {
    let interestRate = Number(this.interestRateStr);
    let loanAmount = Number(this.loanAmountStr);
    let paymentAmount = Number(this.paymentAmountStr);
    const scheduleTable = [];
    const headers = ['Payment #', 'Payment Date', 'Payment Amount', 'Principal', 'Interest', 'Remaining Loan'];

    let loanBalance = loanAmount;
    let paymentNumber = 1;
    let currentDate = new Date();
    currentDate.setDate(1); // Start from the first day of the month

    while (loanBalance > 0) {
      const interest = loanBalance * ((interestRate / 100) / 12);
      const principal = paymentAmount - interest;
      const remainingLoan = loanBalance - principal;

      const cells = [
        paymentNumber,
        currentDate.toDateString(),
        paymentAmount.toFixed(2),
        principal.toFixed(2),
        interest.toFixed(2),
        remainingLoan.toFixed(2)
      ];

      scheduleTable.push(cells);

      loanBalance = remainingLoan;
      paymentNumber++;

      // Increment date based on payment frequency
      if (this.paymentFrequency === 'monthly') {
        currentDate.setMonth(currentDate.getMonth() + 1);
      } else if (this.paymentFrequency === 'quarterly') {
        currentDate.setMonth(currentDate.getMonth() + 3);
      } else if (this.paymentFrequency === 'annually') {
        currentDate.setFullYear(currentDate.getFullYear() + 1);
      }
    }

    // Transforming the table for display
    this.mortgageSchedule = [headers, ...scheduleTable];
  }
}

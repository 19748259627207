!
/**
* Highstock JS v11.4.8 (2024-08-29)
*
* Advanced Highcharts Stock tools
*
* (c) 2010-2024 Highsoft AS
* Author: Torstein Honsi
*
* License: www.highcharts.com/license
*/
function (t) {
  "object" == typeof module && module.exports ? (t.default = t, module.exports = t) : "function" == typeof define && define.amd ? define("highcharts/modules/stock-tools", ["highcharts", "highcharts/modules/stock"], function (i) {
    return t(i), t.Highcharts = i, t;
  }) : t("undefined" != typeof Highcharts ? Highcharts : void 0);
}(function (t) {
  "use strict";

  var i = t ? t._modules : {};
  function e(i, e, s, n) {
    i.hasOwnProperty(e) || (i[e] = n.apply(null, s), "function" == typeof CustomEvent && t.win.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: e,
        module: i[e]
      }
    })));
  }
  e(i, "Core/Chart/ChartNavigationComposition.js", [], function () {
    var t;
    return function (t) {
      t.compose = function (t) {
        return t.navigation || (t.navigation = new i(t)), t;
      };
      class i {
        constructor(t) {
          this.updates = [], this.chart = t;
        }
        addUpdate(t) {
          this.chart.navigation.updates.push(t);
        }
        update(t, i) {
          this.updates.forEach(e => {
            e.call(this.chart, t, i);
          });
        }
      }
      t.Additions = i;
    }(t || (t = {})), t;
  }), e(i, "Extensions/Annotations/NavigationBindingsDefaults.js", [i["Extensions/Annotations/NavigationBindingsUtilities.js"], i["Core/Utilities.js"]], function (t, i) {
    let {
        getAssignedAxis: e
      } = t,
      {
        isNumber: s,
        merge: n
      } = i;
    return {
      lang: {
        navigation: {
          popup: {
            simpleShapes: "Simple shapes",
            lines: "Lines",
            circle: "Circle",
            ellipse: "Ellipse",
            rectangle: "Rectangle",
            label: "Label",
            shapeOptions: "Shape options",
            typeOptions: "Details",
            fill: "Fill",
            format: "Text",
            strokeWidth: "Line width",
            stroke: "Line color",
            title: "Title",
            name: "Name",
            labelOptions: "Label options",
            labels: "Labels",
            backgroundColor: "Background color",
            backgroundColors: "Background colors",
            borderColor: "Border color",
            borderRadius: "Border radius",
            borderWidth: "Border width",
            style: "Style",
            padding: "Padding",
            fontSize: "Font size",
            color: "Color",
            height: "Height",
            shapes: "Shape options"
          }
        }
      },
      navigation: {
        bindingsClassName: "highcharts-bindings-container",
        bindings: {
          circleAnnotation: {
            className: "highcharts-circle-annotation",
            start: function (t) {
              let i = this.chart.pointer?.getCoordinates(t),
                s = i && e(i.xAxis),
                o = i && e(i.yAxis),
                a = this.chart.options.navigation;
              if (s && o) return this.chart.addAnnotation(n({
                langKey: "circle",
                type: "basicAnnotation",
                shapes: [{
                  type: "circle",
                  point: {
                    x: s.value,
                    y: o.value,
                    xAxis: s.axis.index,
                    yAxis: o.axis.index
                  },
                  r: 5
                }]
              }, a.annotationsOptions, a.bindings.circleAnnotation.annotationsOptions));
            },
            steps: [function (t, i) {
              let e;
              let n = i.options.shapes,
                o = n && n[0] && n[0].point || {};
              if (s(o.xAxis) && s(o.yAxis)) {
                let i = this.chart.inverted,
                  s = this.chart.xAxis[o.xAxis].toPixels(o.x),
                  n = this.chart.yAxis[o.yAxis].toPixels(o.y);
                e = Math.max(Math.sqrt(Math.pow(i ? n - t.chartX : s - t.chartX, 2) + Math.pow(i ? s - t.chartY : n - t.chartY, 2)), 5);
              }
              i.update({
                shapes: [{
                  r: e
                }]
              });
            }]
          },
          ellipseAnnotation: {
            className: "highcharts-ellipse-annotation",
            start: function (t) {
              let i = this.chart.pointer?.getCoordinates(t),
                s = i && e(i.xAxis),
                o = i && e(i.yAxis),
                a = this.chart.options.navigation;
              if (s && o) return this.chart.addAnnotation(n({
                langKey: "ellipse",
                type: "basicAnnotation",
                shapes: [{
                  type: "ellipse",
                  xAxis: s.axis.index,
                  yAxis: o.axis.index,
                  points: [{
                    x: s.value,
                    y: o.value
                  }, {
                    x: s.value,
                    y: o.value
                  }],
                  ry: 1
                }]
              }, a.annotationsOptions, a.bindings.ellipseAnnotation.annotationOptions));
            },
            steps: [function (t, i) {
              let e = i.shapes[0],
                s = e.getAbsolutePosition(e.points[1]);
              e.translatePoint(t.chartX - s.x, t.chartY - s.y, 1), e.redraw(!1);
            }, function (t, i) {
              let e = i.shapes[0],
                s = e.getAbsolutePosition(e.points[0]),
                n = e.getAbsolutePosition(e.points[1]),
                o = e.getDistanceFromLine(s, n, t.chartX, t.chartY),
                a = e.getYAxis(),
                r = Math.abs(a.toValue(0) - a.toValue(o));
              e.setYRadius(r), e.redraw(!1);
            }]
          },
          rectangleAnnotation: {
            className: "highcharts-rectangle-annotation",
            start: function (t) {
              let i = this.chart.pointer?.getCoordinates(t),
                s = i && e(i.xAxis),
                o = i && e(i.yAxis);
              if (!s || !o) return;
              let a = s.value,
                r = o.value,
                l = s.axis.index,
                c = o.axis.index,
                h = this.chart.options.navigation;
              return this.chart.addAnnotation(n({
                langKey: "rectangle",
                type: "basicAnnotation",
                shapes: [{
                  type: "path",
                  points: [{
                    xAxis: l,
                    yAxis: c,
                    x: a,
                    y: r
                  }, {
                    xAxis: l,
                    yAxis: c,
                    x: a,
                    y: r
                  }, {
                    xAxis: l,
                    yAxis: c,
                    x: a,
                    y: r
                  }, {
                    xAxis: l,
                    yAxis: c,
                    x: a,
                    y: r
                  }, {
                    command: "Z"
                  }]
                }]
              }, h.annotationsOptions, h.bindings.rectangleAnnotation.annotationsOptions));
            },
            steps: [function (t, i) {
              let s = i.options.shapes,
                n = s && s[0] && s[0].points || [],
                o = this.chart.pointer?.getCoordinates(t),
                a = o && e(o.xAxis),
                r = o && e(o.yAxis);
              if (a && r) {
                let t = a.value,
                  e = r.value;
                n[1].x = t, n[2].x = t, n[2].y = e, n[3].y = e, i.update({
                  shapes: [{
                    points: n
                  }]
                });
              }
            }]
          },
          labelAnnotation: {
            className: "highcharts-label-annotation",
            start: function (t) {
              let i = this.chart.pointer?.getCoordinates(t),
                s = i && e(i.xAxis),
                o = i && e(i.yAxis),
                a = this.chart.options.navigation;
              if (s && o) return this.chart.addAnnotation(n({
                langKey: "label",
                type: "basicAnnotation",
                labelOptions: {
                  format: "{y:.2f}",
                  overflow: "none",
                  crop: !0
                },
                labels: [{
                  point: {
                    xAxis: s.axis.index,
                    yAxis: o.axis.index,
                    x: s.value,
                    y: o.value
                  }
                }]
              }, a.annotationsOptions, a.bindings.labelAnnotation.annotationsOptions));
            }
          }
        },
        events: {},
        annotationsOptions: {
          animation: {
            defer: 0
          }
        }
      }
    };
  }), e(i, "Extensions/Annotations/NavigationBindings.js", [i["Core/Chart/ChartNavigationComposition.js"], i["Core/Defaults.js"], i["Core/Templating.js"], i["Core/Globals.js"], i["Extensions/Annotations/NavigationBindingsDefaults.js"], i["Extensions/Annotations/NavigationBindingsUtilities.js"], i["Core/Utilities.js"]], function (t, i, e, s, n, o, a) {
    let {
        setOptions: r
      } = i,
      {
        format: l
      } = e,
      {
        composed: c,
        doc: h,
        win: p
      } = s,
      {
        getAssignedAxis: d,
        getFieldType: g
      } = o,
      {
        addEvent: u,
        attr: m,
        defined: y,
        fireEvent: f,
        isArray: v,
        isFunction: b,
        isNumber: x,
        isObject: k,
        merge: A,
        objectEach: C,
        pick: w,
        pushUnique: T
      } = a;
    function N() {
      this.chart.navigationBindings && this.chart.navigationBindings.deselectAnnotation();
    }
    function O() {
      this.navigationBindings && this.navigationBindings.destroy();
    }
    function S() {
      let t = this.options;
      t && t.navigation && t.navigation.bindings && (this.navigationBindings = new z(this, t.navigation), this.navigationBindings.initEvents(), this.navigationBindings.initUpdate());
    }
    function L() {
      let t = this.navigationBindings,
        i = "highcharts-disabled-btn";
      if (this && t) {
        let e = !1;
        if (this.series.forEach(t => {
          !t.options.isInternal && t.visible && (e = !0);
        }), this.navigationBindings && this.navigationBindings.container && this.navigationBindings.container[0]) {
          let s = this.navigationBindings.container[0];
          C(t.boundClassNames, (t, n) => {
            let o = s.querySelectorAll("." + n);
            if (o) for (let s = 0; s < o.length; s++) {
              let n = o[s],
                a = n.className;
              "normal" === t.noDataState ? -1 !== a.indexOf(i) && n.classList.remove(i) : e ? -1 !== a.indexOf(i) && n.classList.remove(i) : -1 === a.indexOf(i) && (n.className += " " + i);
            }
          });
        }
      }
    }
    function B() {
      this.deselectAnnotation();
    }
    function E() {
      this.selectedButtonElement = null;
    }
    function I(t) {
      let i, e;
      let s = t.prototype.defaultOptions.events && t.prototype.defaultOptions.events.click;
      function n(t) {
        let i = this,
          e = i.chart.navigationBindings,
          n = e.activeAnnotation;
        s && s.call(i, t), n !== i ? (e.deselectAnnotation(), e.activeAnnotation = i, i.setControlPointsVisibility(!0), f(e, "showPopup", {
          annotation: i,
          formType: "annotation-toolbar",
          options: e.annotationToFields(i),
          onSubmit: function (t) {
            if ("remove" === t.actionType) e.activeAnnotation = !1, e.chart.removeAnnotation(i);else {
              let s = {};
              e.fieldsToOptions(t.fields, s), e.deselectAnnotation();
              let n = s.typeOptions;
              "measure" === i.options.type && (n.crosshairY.enabled = 0 !== n.crosshairY.strokeWidth, n.crosshairX.enabled = 0 !== n.crosshairX.strokeWidth), i.update(s);
            }
          }
        })) : f(e, "closePopup"), t.activeAnnotation = !0;
      }
      A(!0, t.prototype.defaultOptions.events, {
        click: n,
        touchstart: function (t) {
          i = t.touches[0].clientX, e = t.touches[0].clientY;
        },
        touchend: function (t) {
          i && Math.sqrt(Math.pow(i - t.changedTouches[0].clientX, 2) + Math.pow(e - t.changedTouches[0].clientY, 2)) >= 4 || n.call(this, t);
        }
      });
    }
    class z {
      static compose(t, i) {
        T(c, "NavigationBindings") && (u(t, "remove", N), I(t), C(t.types, t => {
          I(t);
        }), u(i, "destroy", O), u(i, "load", S), u(i, "render", L), u(z, "closePopup", B), u(z, "deselectButton", E), r(n));
      }
      constructor(t, i) {
        this.boundClassNames = void 0, this.chart = t, this.options = i, this.eventsToUnbind = [], this.container = this.chart.container.getElementsByClassName(this.options.bindingsClassName || ""), this.container.length || (this.container = h.getElementsByClassName(this.options.bindingsClassName || ""));
      }
      getCoords(t) {
        let i = this.chart.pointer?.getCoordinates(t);
        return [i && d(i.xAxis), i && d(i.yAxis)];
      }
      initEvents() {
        let t = this,
          i = t.chart,
          e = t.container,
          n = t.options;
        t.boundClassNames = {}, C(n.bindings || {}, i => {
          t.boundClassNames[i.className] = i;
        }), [].forEach.call(e, i => {
          t.eventsToUnbind.push(u(i, "click", e => {
            let s = t.getButtonEvents(i, e);
            s && !s.button.classList.contains("highcharts-disabled-btn") && t.bindingsButtonClick(s.button, s.events, e);
          }));
        }), C(n.events || {}, (i, e) => {
          b(i) && t.eventsToUnbind.push(u(t, e, i, {
            passive: !1
          }));
        }), t.eventsToUnbind.push(u(i.container, "click", function (e) {
          !i.cancelClick && i.isInsidePlot(e.chartX - i.plotLeft, e.chartY - i.plotTop, {
            visiblePlotOnly: !0
          }) && t.bindingsChartClick(this, e);
        })), t.eventsToUnbind.push(u(i.container, s.isTouchDevice ? "touchmove" : "mousemove", function (i) {
          t.bindingsContainerMouseMove(this, i);
        }, s.isTouchDevice ? {
          passive: !1
        } : void 0));
      }
      initUpdate() {
        let i = this;
        t.compose(this.chart).navigation.addUpdate(t => {
          i.update(t);
        });
      }
      bindingsButtonClick(t, i, e) {
        let s = this.chart,
          n = s.renderer.boxWrapper,
          o = !0;
        this.selectedButtonElement && (this.selectedButtonElement.classList === t.classList && (o = !1), f(this, "deselectButton", {
          button: this.selectedButtonElement
        }), this.nextEvent && (this.currentUserDetails && "annotations" === this.currentUserDetails.coll && s.removeAnnotation(this.currentUserDetails), this.mouseMoveEvent = this.nextEvent = !1)), o ? (this.selectedButton = i, this.selectedButtonElement = t, f(this, "selectButton", {
          button: t
        }), i.init && i.init.call(this, t, e), (i.start || i.steps) && s.renderer.boxWrapper.addClass("highcharts-draw-mode")) : (s.stockTools && t.classList.remove("highcharts-active"), n.removeClass("highcharts-draw-mode"), this.nextEvent = !1, this.mouseMoveEvent = !1, this.selectedButton = null);
      }
      bindingsChartClick(t, i) {
        t = this.chart;
        let e = this.activeAnnotation,
          s = this.selectedButton,
          n = t.renderer.boxWrapper;
        e && (e.cancelClick || i.activeAnnotation || !i.target.parentNode || function (t, i) {
          let e = p.Element.prototype,
            s = e.matches || e.msMatchesSelector || e.webkitMatchesSelector,
            n = null;
          if (e.closest) n = e.closest.call(t, i);else do {
            if (s.call(t, i)) return t;
            t = t.parentElement || t.parentNode;
          } while (null !== t && 1 === t.nodeType);
          return n;
        }(i.target, ".highcharts-popup") ? e.cancelClick && setTimeout(() => {
          e.cancelClick = !1;
        }, 0) : f(this, "closePopup")), s && s.start && (this.nextEvent ? (this.nextEvent(i, this.currentUserDetails), this.steps && (this.stepIndex++, s.steps[this.stepIndex] ? this.mouseMoveEvent = this.nextEvent = s.steps[this.stepIndex] : (f(this, "deselectButton", {
          button: this.selectedButtonElement
        }), n.removeClass("highcharts-draw-mode"), s.end && s.end.call(this, i, this.currentUserDetails), this.nextEvent = !1, this.mouseMoveEvent = !1, this.selectedButton = null))) : (this.currentUserDetails = s.start.call(this, i), this.currentUserDetails && s.steps ? (this.stepIndex = 0, this.steps = !0, this.mouseMoveEvent = this.nextEvent = s.steps[this.stepIndex]) : (f(this, "deselectButton", {
          button: this.selectedButtonElement
        }), n.removeClass("highcharts-draw-mode"), this.steps = !1, this.selectedButton = null, s.end && s.end.call(this, i, this.currentUserDetails))));
      }
      bindingsContainerMouseMove(t, i) {
        this.mouseMoveEvent && this.mouseMoveEvent(i, this.currentUserDetails);
      }
      fieldsToOptions(t, i) {
        return C(t, (t, e) => {
          let s = parseFloat(t),
            n = e.split("."),
            o = n.length - 1;
          if (!x(s) || t.match(/px|em/g) || e.match(/format/g) || (t = s), "undefined" !== t) {
            let e = i;
            n.forEach((i, s) => {
              if ("__proto__" !== i && "constructor" !== i) {
                let a = w(n[s + 1], "");
                o === s ? e[i] = t : (e[i] || (e[i] = a.match(/\d/g) ? [] : {}), e = e[i]);
              }
            });
          }
        }), i;
      }
      deselectAnnotation() {
        this.activeAnnotation && (this.activeAnnotation.setControlPointsVisibility(!1), this.activeAnnotation = !1);
      }
      annotationToFields(t) {
        let i = t.options,
          e = z.annotationsEditable,
          s = e.nestedOptions,
          n = w(i.type, i.shapes && i.shapes[0] && i.shapes[0].type, i.labels && i.labels[0] && i.labels[0].type, "label"),
          o = z.annotationsNonEditable[i.langKey] || [],
          a = {
            langKey: i.langKey,
            type: n
          };
        function r(i, e, n, a, c) {
          let h;
          n && y(i) && -1 === o.indexOf(e) && ((n.indexOf && n.indexOf(e)) >= 0 || n[e] || !0 === n) && (v(i) ? (a[e] = [], i.forEach((t, i) => {
            k(t) ? (a[e][i] = {}, C(t, (t, n) => {
              r(t, n, s[e], a[e][i], e);
            })) : r(t, 0, s[e], a[e], e);
          })) : k(i) ? (h = {}, v(a) ? (a.push(h), h[e] = {}, h = h[e]) : a[e] = h, C(i, (t, i) => {
            r(t, i, 0 === e ? n : s[e], h, e);
          })) : "format" === e ? a[e] = [l(i, t.labels[0].points[0]).toString(), "text"] : v(a) ? a.push([i, g(c, i)]) : a[e] = [i, g(e, i)]);
        }
        return C(i, (t, o) => {
          "typeOptions" === o ? (a[o] = {}, C(i[o], (t, i) => {
            r(t, i, s, a[o], i);
          })) : r(t, o, e[n], a, o);
        }), a;
      }
      getClickedClassNames(t, i) {
        let e = i.target,
          s = [],
          n;
        for (; e && e.tagName && ((n = m(e, "class")) && (s = s.concat(n.split(" ").map(t => [t, e]))), (e = e.parentNode) !== t););
        return s;
      }
      getButtonEvents(t, i) {
        let e;
        let s = this;
        return this.getClickedClassNames(t, i).forEach(t => {
          s.boundClassNames[t[0]] && !e && (e = {
            events: s.boundClassNames[t[0]],
            button: t[1]
          });
        }), e;
      }
      update(t) {
        this.options = A(!0, this.options, t), this.removeEvents(), this.initEvents();
      }
      removeEvents() {
        this.eventsToUnbind.forEach(t => t());
      }
      destroy() {
        this.removeEvents();
      }
    }
    return z.annotationsEditable = {
      nestedOptions: {
        labelOptions: ["style", "format", "backgroundColor"],
        labels: ["style"],
        label: ["style"],
        style: ["fontSize", "color"],
        background: ["fill", "strokeWidth", "stroke"],
        innerBackground: ["fill", "strokeWidth", "stroke"],
        outerBackground: ["fill", "strokeWidth", "stroke"],
        shapeOptions: ["fill", "strokeWidth", "stroke"],
        shapes: ["fill", "strokeWidth", "stroke"],
        line: ["strokeWidth", "stroke"],
        backgroundColors: [!0],
        connector: ["fill", "strokeWidth", "stroke"],
        crosshairX: ["strokeWidth", "stroke"],
        crosshairY: ["strokeWidth", "stroke"]
      },
      circle: ["shapes"],
      ellipse: ["shapes"],
      verticalLine: [],
      label: ["labelOptions"],
      measure: ["background", "crosshairY", "crosshairX"],
      fibonacci: [],
      tunnel: ["background", "line", "height"],
      pitchfork: ["innerBackground", "outerBackground"],
      rect: ["shapes"],
      crookedLine: [],
      basicAnnotation: ["shapes", "labelOptions"]
    }, z.annotationsNonEditable = {
      rectangle: ["crosshairX", "crosshairY", "labelOptions"],
      ellipse: ["labelOptions"],
      circle: ["labelOptions"]
    }, z;
  }), e(i, "Stock/StockTools/StockToolsUtilities.js", [i["Core/Defaults.js"], i["Extensions/Annotations/NavigationBindingsUtilities.js"], i["Core/Series/Series.js"], i["Core/Utilities.js"]], function (t, i, e, s) {
    let {
        getOptions: n
      } = t,
      {
        getAssignedAxis: o,
        getFieldType: a
      } = i,
      {
        defined: r,
        fireEvent: l,
        isNumber: c,
        uniqueKey: h
      } = s,
      p = ["apo", "ad", "aroon", "aroonoscillator", "atr", "ao", "cci", "chaikin", "cmf", "cmo", "disparityindex", "dmi", "dpo", "linearRegressionAngle", "linearRegressionIntercept", "linearRegressionSlope", "klinger", "macd", "mfi", "momentum", "natr", "obv", "ppo", "roc", "rsi", "slowstochastic", "stochastic", "trix", "williamsr"],
      d = ["ad", "cmf", "klinger", "mfi", "obv", "vbp", "vwap"];
    function g(t, i) {
      let e = i.pointer?.getCoordinates(t),
        s,
        n,
        a = Number.MAX_VALUE,
        r;
      if (i.navigationBindings && e && (s = o(e.xAxis), n = o(e.yAxis)), !s || !n) return;
      let l = s.value,
        c = n.value;
      if (n.axis.series.forEach(i => {
        if (i.points) {
          let e = i.searchPoint(t, !0);
          e && a > Math.abs(e.x - l) && (a = Math.abs(e.x - l), r = e);
        }
      }), r && r.x && r.y) return {
        x: r.x,
        y: r.y,
        below: c < r.y,
        series: r.series,
        xAxis: r.series.xAxis.index || 0,
        yAxis: r.series.yAxis.index || 0
      };
    }
    return {
      indicatorsWithAxes: p,
      indicatorsWithVolume: d,
      addFlagFromForm: function (t) {
        return function (i) {
          let e = this,
            s = e.chart,
            n = s.stockTools,
            o = g(i, s);
          if (!o) return;
          let r = {
              x: o.x,
              y: o.y
            },
            c = {
              type: "flags",
              onSeries: o.series.id,
              shape: t,
              data: [r],
              xAxis: o.xAxis,
              yAxis: o.yAxis,
              point: {
                events: {
                  click: function () {
                    let t = this,
                      i = t.options;
                    l(e, "showPopup", {
                      point: t,
                      formType: "annotation-toolbar",
                      options: {
                        langKey: "flags",
                        type: "flags",
                        title: [i.title, a("title", i.title)],
                        name: [i.name, a("name", i.name)]
                      },
                      onSubmit: function (i) {
                        "remove" === i.actionType ? t.remove() : t.update(e.fieldsToOptions(i.fields, {}));
                      }
                    });
                  }
                }
              }
            };
          n && n.guiEnabled || s.addSeries(c), l(e, "showPopup", {
            formType: "flag",
            options: {
              langKey: "flags",
              type: "flags",
              title: ["A", a("label", "A")],
              name: ["Flag A", a("label", "Flag A")]
            },
            onSubmit: function (t) {
              e.fieldsToOptions(t.fields, c.data[0]), s.addSeries(c);
            }
          });
        };
      },
      attractToPoint: g,
      getAssignedAxis: o,
      isNotNavigatorYAxis: function (t) {
        return "highcharts-navigator-yaxis" !== t.userOptions.className;
      },
      isPriceIndicatorEnabled: function (t) {
        return t.some(t => t.lastVisiblePrice || t.lastPrice);
      },
      manageIndicators: function (t) {
        let i, s, o, a;
        let c = this.chart,
          g = {
            linkedTo: t.linkedTo,
            type: t.type
          };
        if ("edit" === t.actionType) this.fieldsToOptions(t.fields, g), (a = c.get(t.seriesId)) && a.update(g, !1);else if ("remove" === t.actionType) {
          if ((a = c.get(t.seriesId)) && (i = a.yAxis, a.linkedSeries && a.linkedSeries.forEach(t => {
            t.remove(!1);
          }), a.remove(!1), p.indexOf(a.type) >= 0)) {
            let t = {
              height: i.options.height,
              top: i.options.top
            };
            i.remove(!1), this.resizeYAxes(t);
          }
        } else g.id = h(), this.fieldsToOptions(t.fields, g), s = c.get(g.linkedTo), o = n().plotOptions, void 0 !== s && s instanceof e && "sum" === s.getDGApproximation() && !r(o && o[g.type] && o.dataGrouping && o.dataGrouping.approximation) && (g.dataGrouping = {
          approximation: "sum"
        }), p.indexOf(t.type) >= 0 ? (i = c.addAxis({
          id: h(),
          offset: 0,
          opposite: !0,
          title: {
            text: ""
          },
          tickPixelInterval: 40,
          showLastLabel: !1,
          labels: {
            align: "left",
            y: -2
          }
        }, !1, !1), g.yAxis = i.options.id, this.resizeYAxes()) : g.yAxis = c.get(t.linkedTo).options.yAxis, d.indexOf(t.type) >= 0 && (g.params.volumeSeriesID = c.series.filter(function (t) {
          return "column" === t.options.type;
        })[0].options.id), c.addSeries(g, !1);
        l(this, "deselectButton", {
          button: this.selectedButtonElement
        }), c.redraw();
      },
      shallowArraysEqual: function (t, i) {
        if (!r(t) || !r(i) || t.length !== i.length) return !1;
        for (let e = 0; e < t.length; e++) if (t[e] !== i[e]) return !1;
        return !0;
      },
      updateHeight: function (t, i) {
        let e = i.options.typeOptions,
          s = c(e.yAxis) && this.chart.yAxis[e.yAxis];
        s && e.points && i.update({
          typeOptions: {
            height: s.toValue(t[s.horiz ? "chartX" : "chartY"]) - (e.points[1].y || 0)
          }
        });
      },
      updateNthPoint: function (t) {
        return function (i, e) {
          let s = e.options.typeOptions,
            n = c(s.xAxis) && this.chart.xAxis[s.xAxis],
            o = c(s.yAxis) && this.chart.yAxis[s.yAxis];
          n && o && (s.points.forEach((e, s) => {
            s >= t && (e.x = n.toValue(i[n.horiz ? "chartX" : "chartY"]), e.y = o.toValue(i[o.horiz ? "chartX" : "chartY"]));
          }), e.update({
            typeOptions: {
              points: s.points
            }
          }));
        };
      },
      updateRectSize: function (t, i) {
        let e = i.chart,
          s = i.options.typeOptions,
          n = c(s.xAxis) && e.xAxis[s.xAxis],
          o = c(s.yAxis) && e.yAxis[s.yAxis];
        if (n && o) {
          let a = n.toValue(t[n.horiz ? "chartX" : "chartY"]),
            r = o.toValue(t[o.horiz ? "chartX" : "chartY"]),
            l = a - s.point.x,
            c = s.point.y - r;
          i.update({
            typeOptions: {
              background: {
                width: e.inverted ? c : l,
                height: e.inverted ? l : c
              }
            }
          });
        }
      }
    };
  }), e(i, "Stock/StockTools/StockToolsBindings.js", [i["Core/Globals.js"], i["Stock/StockTools/StockToolsUtilities.js"], i["Core/Utilities.js"]], function (t, i, e) {
    let {
        addFlagFromForm: s,
        attractToPoint: n,
        isNotNavigatorYAxis: o,
        isPriceIndicatorEnabled: a,
        manageIndicators: r,
        updateHeight: l,
        updateNthPoint: c,
        updateRectSize: h
      } = i,
      {
        fireEvent: p,
        merge: d
      } = e;
    return {
      segment: {
        className: "highcharts-segment",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "segment",
              type: "crookedLine",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.segment.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      arrowSegment: {
        className: "highcharts-arrow-segment",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "arrowSegment",
              type: "crookedLine",
              typeOptions: {
                line: {
                  markerEnd: "arrow"
                },
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.arrowSegment.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      ray: {
        className: "highcharts-ray",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "ray",
              type: "infinityLine",
              typeOptions: {
                type: "ray",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.ray.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      arrowRay: {
        className: "highcharts-arrow-ray",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "arrowRay",
              type: "infinityLine",
              typeOptions: {
                type: "ray",
                line: {
                  markerEnd: "arrow"
                },
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.arrowRay.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      infinityLine: {
        className: "highcharts-infinity-line",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "infinityLine",
              type: "infinityLine",
              typeOptions: {
                type: "line",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.infinityLine.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      arrowInfinityLine: {
        className: "highcharts-arrow-infinity-line",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "arrowInfinityLine",
              type: "infinityLine",
              typeOptions: {
                type: "line",
                line: {
                  markerEnd: "arrow"
                },
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }, {
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.arrowInfinityLine.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [c(1)]
      },
      horizontalLine: {
        className: "highcharts-horizontal-line",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "horizontalLine",
              type: "infinityLine",
              draggable: "y",
              typeOptions: {
                type: "horizontalLine",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.horizontalLine.annotationsOptions);
          this.chart.addAnnotation(n);
        }
      },
      verticalLine: {
        className: "highcharts-vertical-line",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              langKey: "verticalLine",
              type: "infinityLine",
              draggable: "x",
              typeOptions: {
                type: "verticalLine",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value
                }]
              }
            }, s.annotationsOptions, s.bindings.verticalLine.annotationsOptions);
          this.chart.addAnnotation(n);
        }
      },
      crooked3: {
        className: "highcharts-crooked3",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "crooked3",
              type: "crookedLine",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              }
            }, o.annotationsOptions, o.bindings.crooked3.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), c(2)]
      },
      crooked5: {
        className: "highcharts-crooked5",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "crooked5",
              type: "crookedLine",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              }
            }, o.annotationsOptions, o.bindings.crooked5.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), c(2), c(3), c(4)]
      },
      elliott3: {
        className: "highcharts-elliott3",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "elliott3",
              type: "elliottWave",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.elliott3.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), c(2), c(3)]
      },
      elliott5: {
        className: "highcharts-elliott5",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "elliott5",
              type: "elliottWave",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.elliott5.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), c(2), c(3), c(4), c(5)]
      },
      measureX: {
        className: "highcharts-measure-x",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "measure",
              type: "measure",
              typeOptions: {
                selectType: "x",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                point: {
                  x: s,
                  y: n
                },
                crosshairX: {
                  strokeWidth: 1,
                  stroke: "#000000"
                },
                crosshairY: {
                  enabled: !1,
                  strokeWidth: 0,
                  stroke: "#000000"
                },
                background: {
                  width: 0,
                  height: 0,
                  strokeWidth: 0,
                  stroke: "#ffffff"
                }
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.measureX.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [h]
      },
      measureY: {
        className: "highcharts-measure-y",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "measure",
              type: "measure",
              typeOptions: {
                selectType: "y",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                point: {
                  x: s,
                  y: n
                },
                crosshairX: {
                  enabled: !1,
                  strokeWidth: 0,
                  stroke: "#000000"
                },
                crosshairY: {
                  strokeWidth: 1,
                  stroke: "#000000"
                },
                background: {
                  width: 0,
                  height: 0,
                  strokeWidth: 0,
                  stroke: "#ffffff"
                }
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.measureY.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [h]
      },
      measureXY: {
        className: "highcharts-measure-xy",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "measure",
              type: "measure",
              typeOptions: {
                selectType: "xy",
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                point: {
                  x: s,
                  y: n
                },
                background: {
                  width: 0,
                  height: 0,
                  strokeWidth: 10
                },
                crosshairX: {
                  strokeWidth: 1,
                  stroke: "#000000"
                },
                crosshairY: {
                  strokeWidth: 1,
                  stroke: "#000000"
                }
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.measureXY.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [h]
      },
      fibonacci: {
        className: "highcharts-fibonacci",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "fibonacci",
              type: "fibonacci",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              },
              labelOptions: {
                style: {
                  color: "#666666"
                }
              }
            }, o.annotationsOptions, o.bindings.fibonacci.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), l]
      },
      parallelChannel: {
        className: "highcharts-parallel-channel",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "parallelChannel",
              type: "tunnel",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }]
              }
            }, o.annotationsOptions, o.bindings.parallelChannel.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), l]
      },
      pitchfork: {
        className: "highcharts-pitchfork",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = i.value,
            n = e.value,
            o = this.chart.options.navigation,
            a = d({
              langKey: "pitchfork",
              type: "pitchfork",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value,
                  y: e.value,
                  controlPoint: {
                    style: {
                      fill: "#f21313"
                    }
                  }
                }, {
                  x: s,
                  y: n
                }, {
                  x: s,
                  y: n
                }],
                innerBackground: {
                  fill: "rgba(100, 170, 255, 0.8)"
                }
              },
              shapeOptions: {
                strokeWidth: 2
              }
            }, o.annotationsOptions, o.bindings.pitchfork.annotationsOptions);
          return this.chart.addAnnotation(a);
        },
        steps: [c(1), c(2)]
      },
      verticalCounter: {
        className: "highcharts-vertical-counter",
        start: function (t) {
          let i = n(t, this.chart);
          if (!i) return;
          this.verticalCounter = this.verticalCounter || 0;
          let e = this.chart.options.navigation,
            s = d({
              langKey: "verticalCounter",
              type: "verticalLine",
              typeOptions: {
                point: {
                  x: i.x,
                  y: i.y,
                  xAxis: i.xAxis,
                  yAxis: i.yAxis
                },
                label: {
                  offset: i.below ? 40 : -40,
                  text: this.verticalCounter.toString()
                }
              },
              labelOptions: {
                style: {
                  color: "#666666",
                  fontSize: "0.7em"
                }
              },
              shapeOptions: {
                stroke: "rgba(0, 0, 0, 0.75)",
                strokeWidth: 1
              }
            }, e.annotationsOptions, e.bindings.verticalCounter.annotationsOptions),
            o = this.chart.addAnnotation(s);
          this.verticalCounter++, o.options.events.click.call(o, {});
        }
      },
      timeCycles: {
        className: "highcharts-time-cycles",
        start: function (t) {
          let i = n(t, this.chart);
          if (!i) return;
          let e = this.chart.options.navigation,
            s = d({
              langKey: "timeCycles",
              type: "timeCycles",
              typeOptions: {
                xAxis: i.xAxis,
                yAxis: i.yAxis,
                points: [{
                  x: i.x
                }, {
                  x: i.x
                }],
                line: {
                  stroke: "rgba(0, 0, 0, 0.75)",
                  fill: "transparent",
                  strokeWidth: 2
                }
              }
            }, e.annotationsOptions, e.bindings.timeCycles.annotationsOptions),
            o = this.chart.addAnnotation(s);
          return o.options.events.click.call(o, {}), o;
        },
        steps: [c(1)]
      },
      verticalLabel: {
        className: "highcharts-vertical-label",
        start: function (t) {
          let i = n(t, this.chart);
          if (!i) return;
          let e = this.chart.options.navigation,
            s = d({
              langKey: "verticalLabel",
              type: "verticalLine",
              typeOptions: {
                point: {
                  x: i.x,
                  y: i.y,
                  xAxis: i.xAxis,
                  yAxis: i.yAxis
                },
                label: {
                  offset: i.below ? 40 : -40
                }
              },
              labelOptions: {
                style: {
                  color: "#666666",
                  fontSize: "0.7em"
                }
              },
              shapeOptions: {
                stroke: "rgba(0, 0, 0, 0.75)",
                strokeWidth: 1
              }
            }, e.annotationsOptions, e.bindings.verticalLabel.annotationsOptions),
            o = this.chart.addAnnotation(s);
          o.options.events.click.call(o, {});
        }
      },
      verticalArrow: {
        className: "highcharts-vertical-arrow",
        start: function (t) {
          let i = n(t, this.chart);
          if (!i) return;
          let e = this.chart.options.navigation,
            s = d({
              langKey: "verticalArrow",
              type: "verticalLine",
              typeOptions: {
                point: {
                  x: i.x,
                  y: i.y,
                  xAxis: i.xAxis,
                  yAxis: i.yAxis
                },
                label: {
                  offset: i.below ? 40 : -40,
                  format: " "
                },
                connector: {
                  fill: "none",
                  stroke: i.below ? "#f21313" : "#06b535"
                }
              },
              shapeOptions: {
                stroke: "rgba(0, 0, 0, 0.75)",
                strokeWidth: 1
              }
            }, e.annotationsOptions, e.bindings.verticalArrow.annotationsOptions),
            o = this.chart.addAnnotation(s);
          o.options.events.click.call(o, {});
        }
      },
      fibonacciTimeZones: {
        className: "highcharts-fibonacci-time-zones",
        start: function (t) {
          let [i, e] = this.getCoords(t);
          if (!i || !e) return;
          let s = this.chart.options.navigation,
            n = d({
              type: "fibonacciTimeZones",
              langKey: "fibonacciTimeZones",
              typeOptions: {
                xAxis: i.axis.index,
                yAxis: e.axis.index,
                points: [{
                  x: i.value
                }]
              }
            }, s.annotationsOptions, s.bindings.fibonacciTimeZones.annotationsOptions);
          return this.chart.addAnnotation(n);
        },
        steps: [function (t, i) {
          let e = i.options.typeOptions.points,
            s = e && e[0].x,
            [n, o] = this.getCoords(t);
          n && o && i.update({
            typeOptions: {
              xAxis: n.axis.index,
              yAxis: o.axis.index,
              points: [{
                x: s
              }, {
                x: n.value
              }]
            }
          });
        }]
      },
      flagCirclepin: {
        className: "highcharts-flag-circlepin",
        start: s("circlepin")
      },
      flagDiamondpin: {
        className: "highcharts-flag-diamondpin",
        start: s("flag")
      },
      flagSquarepin: {
        className: "highcharts-flag-squarepin",
        start: s("squarepin")
      },
      flagSimplepin: {
        className: "highcharts-flag-simplepin",
        start: s("nopin")
      },
      zoomX: {
        className: "highcharts-zoom-x",
        init: function (t) {
          this.chart.update({
            chart: {
              zooming: {
                type: "x"
              }
            }
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      zoomY: {
        className: "highcharts-zoom-y",
        init: function (t) {
          this.chart.update({
            chart: {
              zooming: {
                type: "y"
              }
            }
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      zoomXY: {
        className: "highcharts-zoom-xy",
        init: function (t) {
          this.chart.update({
            chart: {
              zooming: {
                type: "xy"
              }
            }
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeLine: {
        className: "highcharts-series-type-line",
        init: function (t) {
          this.chart.series[0].update({
            type: "line",
            useOhlcData: !0
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeOhlc: {
        className: "highcharts-series-type-ohlc",
        init: function (t) {
          this.chart.series[0].update({
            type: "ohlc"
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeCandlestick: {
        className: "highcharts-series-type-candlestick",
        init: function (t) {
          this.chart.series[0].update({
            type: "candlestick"
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeHeikinAshi: {
        className: "highcharts-series-type-heikinashi",
        init: function (t) {
          this.chart.series[0].update({
            type: "heikinashi"
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeHLC: {
        className: "highcharts-series-type-hlc",
        init: function (t) {
          this.chart.series[0].update({
            type: "hlc",
            useOhlcData: !0
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      seriesTypeHollowCandlestick: {
        className: "highcharts-series-type-hollowcandlestick",
        init: function (t) {
          this.chart.series[0].update({
            type: "hollowcandlestick"
          }), p(this, "deselectButton", {
            button: t
          });
        }
      },
      fullScreen: {
        className: "highcharts-full-screen",
        noDataState: "normal",
        init: function (t) {
          this.chart.fullscreen && this.chart.fullscreen.toggle(), p(this, "deselectButton", {
            button: t
          });
        }
      },
      currentPriceIndicator: {
        className: "highcharts-current-price-indicator",
        init: function (t) {
          let i = this.chart,
            e = i.series,
            s = i.stockTools,
            n = a(i.series);
          s && s.guiEnabled && (e.forEach(function (t) {
            t.update({
              lastPrice: {
                enabled: !n
              },
              lastVisiblePrice: {
                enabled: !n,
                label: {
                  enabled: !0
                }
              }
            }, !1);
          }), i.redraw()), p(this, "deselectButton", {
            button: t
          });
        }
      },
      indicators: {
        className: "highcharts-indicators",
        init: function () {
          let t = this;
          p(t, "showPopup", {
            formType: "indicators",
            options: {},
            onSubmit: function (i) {
              r.call(t, i);
            }
          });
        }
      },
      toggleAnnotations: {
        className: "highcharts-toggle-annotations",
        init: function (t) {
          let i = this.chart,
            e = i.stockTools,
            s = e.getIconsURL();
          this.toggledAnnotations = !this.toggledAnnotations, (i.annotations || []).forEach(function (t) {
            t.setVisibility(!this.toggledAnnotations);
          }, this), e && e.guiEnabled && (this.toggledAnnotations ? t.firstChild.style["background-image"] = 'url("' + s + 'annotations-hidden.svg")' : t.firstChild.style["background-image"] = 'url("' + s + 'annotations-visible.svg")'), p(this, "deselectButton", {
            button: t
          });
        }
      },
      saveChart: {
        className: "highcharts-save-chart",
        noDataState: "normal",
        init: function (i) {
          let e = this.chart,
            s = [],
            n = [],
            a = [],
            r = [];
          e.annotations.forEach(function (t, i) {
            s[i] = t.userOptions;
          }), e.series.forEach(function (t) {
            t.is("sma") ? n.push(t.userOptions) : "flags" === t.type && a.push(t.userOptions);
          }), e.yAxis.forEach(function (t) {
            o(t) && r.push(t.options);
          }), t.win.localStorage.setItem("highcharts-chart", JSON.stringify({
            annotations: s,
            indicators: n,
            flags: a,
            yAxes: r
          })), p(this, "deselectButton", {
            button: i
          });
        }
      }
    };
  }), e(i, "Stock/StockTools/StockToolsDefaults.js", [], function () {
    return {
      lang: {
        stockTools: {
          gui: {
            simpleShapes: "Simple shapes",
            lines: "Lines",
            crookedLines: "Crooked lines",
            measure: "Measure",
            advanced: "Advanced",
            toggleAnnotations: "Toggle annotations",
            verticalLabels: "Vertical labels",
            flags: "Flags",
            zoomChange: "Zoom change",
            typeChange: "Type change",
            saveChart: "Save chart",
            indicators: "Indicators",
            currentPriceIndicator: "Current Price Indicators",
            zoomX: "Zoom X",
            zoomY: "Zoom Y",
            zoomXY: "Zooom XY",
            fullScreen: "Fullscreen",
            typeOHLC: "OHLC",
            typeLine: "Line",
            typeCandlestick: "Candlestick",
            typeHLC: "HLC",
            typeHollowCandlestick: "Hollow Candlestick",
            typeHeikinAshi: "Heikin Ashi",
            circle: "Circle",
            ellipse: "Ellipse",
            label: "Label",
            rectangle: "Rectangle",
            flagCirclepin: "Flag circle",
            flagDiamondpin: "Flag diamond",
            flagSquarepin: "Flag square",
            flagSimplepin: "Flag simple",
            measureXY: "Measure XY",
            measureX: "Measure X",
            measureY: "Measure Y",
            segment: "Segment",
            arrowSegment: "Arrow segment",
            ray: "Ray",
            arrowRay: "Arrow ray",
            line: "Line",
            arrowInfinityLine: "Arrow line",
            horizontalLine: "Horizontal line",
            verticalLine: "Vertical line",
            infinityLine: "Infinity line",
            crooked3: "Crooked 3 line",
            crooked5: "Crooked 5 line",
            elliott3: "Elliott 3 line",
            elliott5: "Elliott 5 line",
            verticalCounter: "Vertical counter",
            verticalLabel: "Vertical label",
            verticalArrow: "Vertical arrow",
            fibonacci: "Fibonacci",
            fibonacciTimeZones: "Fibonacci Time Zones",
            pitchfork: "Pitchfork",
            parallelChannel: "Parallel channel",
            timeCycles: "Time Cycles"
          }
        },
        navigation: {
          popup: {
            circle: "Circle",
            ellipse: "Ellipse",
            rectangle: "Rectangle",
            label: "Label",
            segment: "Segment",
            arrowSegment: "Arrow segment",
            ray: "Ray",
            arrowRay: "Arrow ray",
            line: "Line",
            arrowInfinityLine: "Arrow line",
            horizontalLine: "Horizontal line",
            verticalLine: "Vertical line",
            crooked3: "Crooked 3 line",
            crooked5: "Crooked 5 line",
            elliott3: "Elliott 3 line",
            elliott5: "Elliott 5 line",
            verticalCounter: "Vertical counter",
            verticalLabel: "Vertical label",
            verticalArrow: "Vertical arrow",
            fibonacci: "Fibonacci",
            fibonacciTimeZones: "Fibonacci Time Zones",
            pitchfork: "Pitchfork",
            parallelChannel: "Parallel channel",
            infinityLine: "Infinity line",
            measure: "Measure",
            measureXY: "Measure XY",
            measureX: "Measure X",
            measureY: "Measure Y",
            timeCycles: "Time Cycles",
            flags: "Flags",
            addButton: "Add",
            saveButton: "Save",
            editButton: "Edit",
            removeButton: "Remove",
            series: "Series",
            volume: "Volume",
            connector: "Connector",
            innerBackground: "Inner background",
            outerBackground: "Outer background",
            crosshairX: "Crosshair X",
            crosshairY: "Crosshair Y",
            tunnel: "Tunnel",
            background: "Background",
            noFilterMatch: "No match",
            searchIndicators: "Search Indicators",
            clearFilter: "✕ clear filter",
            index: "Index",
            period: "Period",
            periods: "Periods",
            standardDeviation: "Standard deviation",
            periodTenkan: "Tenkan period",
            periodSenkouSpanB: "Senkou Span B period",
            periodATR: "ATR period",
            multiplierATR: "ATR multiplier",
            shortPeriod: "Short period",
            longPeriod: "Long period",
            signalPeriod: "Signal period",
            decimals: "Decimals",
            algorithm: "Algorithm",
            topBand: "Top band",
            bottomBand: "Bottom band",
            initialAccelerationFactor: "Initial acceleration factor",
            maxAccelerationFactor: "Max acceleration factor",
            increment: "Increment",
            multiplier: "Multiplier",
            ranges: "Ranges",
            highIndex: "High index",
            lowIndex: "Low index",
            deviation: "Deviation",
            xAxisUnit: "x-axis unit",
            factor: "Factor",
            fastAvgPeriod: "Fast average period",
            slowAvgPeriod: "Slow average period",
            average: "Average",
            indicatorAliases: {
              abands: ["Acceleration Bands"],
              bb: ["Bollinger Bands"],
              dema: ["Double Exponential Moving Average"],
              ema: ["Exponential Moving Average"],
              ikh: ["Ichimoku Kinko Hyo"],
              keltnerchannels: ["Keltner Channels"],
              linearRegression: ["Linear Regression"],
              pivotpoints: ["Pivot Points"],
              pc: ["Price Channel"],
              priceenvelopes: ["Price Envelopes"],
              psar: ["Parabolic SAR"],
              sma: ["Simple Moving Average"],
              supertrend: ["Super Trend"],
              tema: ["Triple Exponential Moving Average"],
              vbp: ["Volume by Price"],
              vwap: ["Volume Weighted Moving Average"],
              wma: ["Weighted Moving Average"],
              zigzag: ["Zig Zag"],
              apo: ["Absolute price indicator"],
              ad: ["Accumulation/Distribution"],
              aroon: ["Aroon"],
              aroonoscillator: ["Aroon oscillator"],
              atr: ["Average True Range"],
              ao: ["Awesome oscillator"],
              cci: ["Commodity Channel Index"],
              chaikin: ["Chaikin"],
              cmf: ["Chaikin Money Flow"],
              cmo: ["Chande Momentum Oscillator"],
              disparityindex: ["Disparity Index"],
              dmi: ["Directional Movement Index"],
              dpo: ["Detrended price oscillator"],
              klinger: ["Klinger Oscillator"],
              linearRegressionAngle: ["Linear Regression Angle"],
              linearRegressionIntercept: ["Linear Regression Intercept"],
              linearRegressionSlope: ["Linear Regression Slope"],
              macd: ["Moving Average Convergence Divergence"],
              mfi: ["Money Flow Index"],
              momentum: ["Momentum"],
              natr: ["Normalized Average True Range"],
              obv: ["On-Balance Volume"],
              ppo: ["Percentage Price oscillator"],
              roc: ["Rate of Change"],
              rsi: ["Relative Strength Index"],
              slowstochastic: ["Slow Stochastic"],
              stochastic: ["Stochastic"],
              trix: ["TRIX"],
              williamsr: ["Williams %R"]
            }
          }
        }
      },
      stockTools: {
        gui: {
          enabled: !0,
          className: "highcharts-bindings-wrapper",
          toolbarClassName: "stocktools-toolbar",
          buttons: ["indicators", "separator", "simpleShapes", "lines", "crookedLines", "measure", "advanced", "toggleAnnotations", "separator", "verticalLabels", "flags", "separator", "zoomChange", "fullScreen", "typeChange", "separator", "currentPriceIndicator", "saveChart"],
          definitions: {
            separator: {
              elementType: "span",
              symbol: "separator.svg"
            },
            simpleShapes: {
              items: ["label", "circle", "ellipse", "rectangle"],
              circle: {
                symbol: "circle.svg"
              },
              ellipse: {
                symbol: "ellipse.svg"
              },
              rectangle: {
                symbol: "rectangle.svg"
              },
              label: {
                symbol: "label.svg"
              }
            },
            flags: {
              items: ["flagCirclepin", "flagDiamondpin", "flagSquarepin", "flagSimplepin"],
              flagSimplepin: {
                symbol: "flag-basic.svg"
              },
              flagDiamondpin: {
                symbol: "flag-diamond.svg"
              },
              flagSquarepin: {
                symbol: "flag-trapeze.svg"
              },
              flagCirclepin: {
                symbol: "flag-elipse.svg"
              }
            },
            lines: {
              items: ["segment", "arrowSegment", "ray", "arrowRay", "line", "arrowInfinityLine", "horizontalLine", "verticalLine"],
              segment: {
                symbol: "segment.svg"
              },
              arrowSegment: {
                symbol: "arrow-segment.svg"
              },
              ray: {
                symbol: "ray.svg"
              },
              arrowRay: {
                symbol: "arrow-ray.svg"
              },
              line: {
                symbol: "line.svg"
              },
              arrowInfinityLine: {
                symbol: "arrow-line.svg"
              },
              verticalLine: {
                symbol: "vertical-line.svg"
              },
              horizontalLine: {
                symbol: "horizontal-line.svg"
              }
            },
            crookedLines: {
              items: ["elliott3", "elliott5", "crooked3", "crooked5"],
              crooked3: {
                symbol: "crooked-3.svg"
              },
              crooked5: {
                symbol: "crooked-5.svg"
              },
              elliott3: {
                symbol: "elliott-3.svg"
              },
              elliott5: {
                symbol: "elliott-5.svg"
              }
            },
            verticalLabels: {
              items: ["verticalCounter", "verticalLabel", "verticalArrow"],
              verticalCounter: {
                symbol: "vertical-counter.svg"
              },
              verticalLabel: {
                symbol: "vertical-label.svg"
              },
              verticalArrow: {
                symbol: "vertical-arrow.svg"
              }
            },
            advanced: {
              items: ["fibonacci", "fibonacciTimeZones", "pitchfork", "parallelChannel", "timeCycles"],
              pitchfork: {
                symbol: "pitchfork.svg"
              },
              fibonacci: {
                symbol: "fibonacci.svg"
              },
              fibonacciTimeZones: {
                symbol: "fibonacci-timezone.svg"
              },
              parallelChannel: {
                symbol: "parallel-channel.svg"
              },
              timeCycles: {
                symbol: "time-cycles.svg"
              }
            },
            measure: {
              items: ["measureXY", "measureX", "measureY"],
              measureX: {
                symbol: "measure-x.svg"
              },
              measureY: {
                symbol: "measure-y.svg"
              },
              measureXY: {
                symbol: "measure-xy.svg"
              }
            },
            toggleAnnotations: {
              symbol: "annotations-visible.svg"
            },
            currentPriceIndicator: {
              symbol: "current-price-show.svg"
            },
            indicators: {
              symbol: "indicators.svg"
            },
            zoomChange: {
              items: ["zoomX", "zoomY", "zoomXY"],
              zoomX: {
                symbol: "zoom-x.svg"
              },
              zoomY: {
                symbol: "zoom-y.svg"
              },
              zoomXY: {
                symbol: "zoom-xy.svg"
              }
            },
            typeChange: {
              items: ["typeOHLC", "typeLine", "typeCandlestick", "typeHollowCandlestick", "typeHLC", "typeHeikinAshi"],
              typeOHLC: {
                symbol: "series-ohlc.svg"
              },
              typeLine: {
                symbol: "series-line.svg"
              },
              typeCandlestick: {
                symbol: "series-candlestick.svg"
              },
              typeHLC: {
                symbol: "series-hlc.svg"
              },
              typeHeikinAshi: {
                symbol: "series-heikin-ashi.svg"
              },
              typeHollowCandlestick: {
                symbol: "series-hollow-candlestick.svg"
              }
            },
            fullScreen: {
              symbol: "fullscreen.svg"
            },
            saveChart: {
              symbol: "save-chart.svg"
            }
          },
          visible: !0
        }
      }
    };
  }), e(i, "Stock/StockTools/StockTools.js", [i["Core/Defaults.js"], i["Extensions/Annotations/NavigationBindingsUtilities.js"], i["Stock/StockTools/StockToolsBindings.js"], i["Stock/StockTools/StockToolsDefaults.js"], i["Stock/StockTools/StockToolsUtilities.js"], i["Core/Utilities.js"]], function (t, i, e, s, n, o) {
    let {
        setOptions: a
      } = t,
      {
        getAssignedAxis: r
      } = i,
      {
        isNotNavigatorYAxis: l,
        isPriceIndicatorEnabled: c
      } = n,
      {
        correctFloat: h,
        defined: p,
        isNumber: d,
        pick: g
      } = o;
    function u(t, i, e, s) {
      let n = 0,
        o,
        a,
        r;
      function l(t) {
        return p(t) && !d(t) && t.match("%");
      }
      return s && (r = h(parseFloat(s.top) / 100), a = h(parseFloat(s.height) / 100)), {
        positions: t.map((s, c) => {
          let p = h(l(s.options.height) ? parseFloat(s.options.height) / 100 : s.height / i),
            g = h(l(s.options.top) ? parseFloat(s.options.top) / 100 : (s.top - s.chart.plotTop) / i);
          return a ? (g > r && (g -= a), n = Math.max(n, (g || 0) + (p || 0))) : (d(p) || (p = t[c - 1].series.every(t => t.is("sma")) ? o : e / 100), d(g) || (g = n), o = p, n = h(Math.max(n, (g || 0) + (p || 0)))), {
            height: 100 * p,
            top: 100 * g
          };
        }),
        allAxesHeight: n
      };
    }
    function m(t) {
      let i = [];
      return t.forEach(function (e, s) {
        let n = t[s + 1];
        n ? i[s] = {
          enabled: !0,
          controlledAxis: {
            next: [g(n.options.id, n.index)]
          }
        } : i[s] = {
          enabled: !1
        };
      }), i;
    }
    function y(t, i, e, s) {
      return t.forEach(function (n, o) {
        let a = t[o - 1];
        n.top = a ? h(a.height + a.top) : 0, e && (n.height = h(n.height + s * i));
      }), t;
    }
    function f(t) {
      let i = this.chart,
        e = i.yAxis.filter(l),
        s = i.plotHeight,
        {
          positions: n,
          allAxesHeight: o
        } = this.getYAxisPositions(e, s, 20, t),
        a = this.getYAxisResizers(e);
      !t && o <= h(1) ? n[n.length - 1] = {
        height: 20,
        top: h(100 * o - 20)
      } : n.forEach(function (t) {
        t.height = t.height / (100 * o) * 100, t.top = t.top / (100 * o) * 100;
      }), n.forEach(function (t, i) {
        e[i].update({
          height: t.height + "%",
          top: t.top + "%",
          resize: a[i],
          offset: 0
        }, !1);
      });
    }
    return {
      compose: function (t) {
        let i = t.prototype;
        i.utils?.manageIndicators || (i.getYAxisPositions = u, i.getYAxisResizers = m, i.recalculateYAxisPositions = y, i.resizeYAxes = f, i.utils = i.utils || {}, i.utils.indicatorsWithAxes = n.indicatorsWithAxes, i.utils.indicatorsWithVolume = n.indicatorsWithVolume, i.utils.getAssignedAxis = r, i.utils.isPriceIndicatorEnabled = c, i.utils.manageIndicators = n.manageIndicators, a(s), a({
          navigation: {
            bindings: e
          }
        }));
      }
    };
  }), e(i, "Stock/StockTools/StockToolbar.js", [i["Core/Utilities.js"], i["Core/Renderer/HTML/AST.js"], i["Stock/StockTools/StockToolsUtilities.js"]], function (t, i, e) {
    let {
        addEvent: s,
        createElement: n,
        css: o,
        defined: a,
        fireEvent: r,
        getStyle: l,
        isArray: c,
        merge: h,
        pick: p
      } = t,
      {
        shallowArraysEqual: d
      } = e;
    class g {
      constructor(t, i, e) {
        this.width = 0, this.isDirty = !1, this.chart = e, this.options = t, this.lang = i, this.iconsURL = this.getIconsURL(), this.guiEnabled = t.enabled, this.visible = p(t.visible, !0), this.guiClassName = t.className, this.toolbarClassName = t.toolbarClassName, this.eventsToUnbind = [], this.guiEnabled && (this.createContainer(), this.createButtons(), this.showHideNavigation()), r(this, "afterInit");
      }
      createButtons() {
        let t = this.lang,
          i = this.options,
          e = this.toolbar,
          n = i.buttons,
          o = i.definitions,
          a = e.childNodes;
        this.buttonList = n, n.forEach(i => {
          let n = this.addButton(e, o, i, t);
          this.eventsToUnbind.push(s(n.buttonWrapper, "click", () => this.eraseActiveButtons(a, n.buttonWrapper))), c(o[i].items) && this.addSubmenu(n, o[i]);
        });
      }
      addSubmenu(t, i) {
        let e = t.submenuArrow,
          a = t.buttonWrapper,
          r = l(a, "width"),
          c = this.wrapper,
          h = this.listWrapper,
          p = this.toolbar.childNodes,
          d = this.submenu = n("ul", {
            className: "highcharts-submenu-wrapper"
          }, void 0, a);
        this.addSubmenuItems(a, i), this.eventsToUnbind.push(s(e, "click", t => {
          if (t.stopPropagation(), this.eraseActiveButtons(p, a), a.className.indexOf("highcharts-current") >= 0) h.style.width = h.startWidth + "px", a.classList.remove("highcharts-current"), d.style.display = "none";else {
            d.style.display = "block";
            let t = d.offsetHeight - a.offsetHeight - 3;
            d.offsetHeight + a.offsetTop > c.offsetHeight && a.offsetTop > t || (t = 0), o(d, {
              top: -t + "px",
              left: r + 3 + "px"
            }), a.className += " highcharts-current", h.startWidth = c.offsetWidth, h.style.width = h.startWidth + l(h, "padding-left") + d.offsetWidth + 3 + "px";
          }
        }));
      }
      addSubmenuItems(t, i) {
        let e;
        let n = this,
          o = this.submenu,
          a = this.lang,
          r = this.listWrapper;
        i.items.forEach(l => {
          e = this.addButton(o, i, l, a), this.eventsToUnbind.push(s(e.mainButton, "click", function () {
            n.switchSymbol(this, t, !0), r.style.width = r.startWidth + "px", o.style.display = "none";
          }));
        });
        let l = o.querySelectorAll("li > .highcharts-menu-item-btn")[0];
        this.switchSymbol(l, !1);
      }
      eraseActiveButtons(t, i, e) {
        [].forEach.call(t, t => {
          t !== i && (t.classList.remove("highcharts-current"), t.classList.remove("highcharts-active"), (e = t.querySelectorAll(".highcharts-submenu-wrapper")).length > 0 && (e[0].style.display = "none"));
        });
      }
      addButton(t, i, e, s = {}) {
        let o = i[e],
          a = o.items,
          r = g.prototype.classMapping,
          l = o.className || "",
          c = n("li", {
            className: p(r[e], "") + " " + l,
            title: s[e] || e
          }, void 0, t),
          h = n(o.elementType || "button", {
            className: "highcharts-menu-item-btn"
          }, void 0, c);
        if (a && a.length) {
          let t = n("button", {
            className: "highcharts-submenu-item-arrow highcharts-arrow-right"
          }, void 0, c);
          return t.style.backgroundImage = "url(" + this.iconsURL + "arrow-bottom.svg)", {
            buttonWrapper: c,
            mainButton: h,
            submenuArrow: t
          };
        }
        return h.style.backgroundImage = "url(" + this.iconsURL + o.symbol + ")", {
          buttonWrapper: c,
          mainButton: h
        };
      }
      addNavigation() {
        let t = this.wrapper;
        this.arrowWrapper = n("div", {
          className: "highcharts-arrow-wrapper"
        }), this.arrowUp = n("div", {
          className: "highcharts-arrow-up"
        }, void 0, this.arrowWrapper), this.arrowUp.style.backgroundImage = "url(" + this.iconsURL + "arrow-right.svg)", this.arrowDown = n("div", {
          className: "highcharts-arrow-down"
        }, void 0, this.arrowWrapper), this.arrowDown.style.backgroundImage = "url(" + this.iconsURL + "arrow-right.svg)", t.insertBefore(this.arrowWrapper, t.childNodes[0]), this.scrollButtons();
      }
      scrollButtons() {
        let t = this.wrapper,
          i = this.toolbar,
          e = .1 * t.offsetHeight,
          n = 0;
        this.eventsToUnbind.push(s(this.arrowUp, "click", () => {
          n > 0 && (n -= e, i.style.marginTop = -n + "px");
        })), this.eventsToUnbind.push(s(this.arrowDown, "click", () => {
          t.offsetHeight + n <= i.offsetHeight + e && (n += e, i.style.marginTop = -n + "px");
        }));
      }
      createContainer() {
        let t, i;
        let e = this.chart,
          o = this.options,
          a = e.container,
          r = e.options.navigation,
          l = r?.bindingsClassName,
          c = this,
          h = this.wrapper = n("div", {
            className: "highcharts-stocktools-wrapper " + o.className + " " + l
          });
        a.appendChild(h), this.showHideBtn = n("div", {
          className: "highcharts-toggle-toolbar highcharts-arrow-left"
        }, void 0, h), this.eventsToUnbind.push(s(this.showHideBtn, "click", () => {
          this.update({
            gui: {
              visible: !c.visible
            }
          });
        })), ["mousedown", "mousemove", "click", "touchstart"].forEach(t => {
          s(h, t, t => t.stopPropagation());
        }), s(h, "mouseover", t => e.pointer?.onContainerMouseLeave(t)), this.toolbar = i = n("ul", {
          className: "highcharts-stocktools-toolbar " + o.toolbarClassName
        }), this.listWrapper = t = n("div", {
          className: "highcharts-menu-wrapper"
        }), h.insertBefore(t, h.childNodes[0]), t.insertBefore(i, t.childNodes[0]), this.showHideToolbar(), this.addNavigation();
      }
      showHideNavigation() {
        this.visible && this.toolbar.offsetHeight > this.wrapper.offsetHeight - 50 ? this.arrowWrapper.style.display = "block" : (this.toolbar.style.marginTop = "0px", this.arrowWrapper.style.display = "none");
      }
      showHideToolbar() {
        let t = this.wrapper,
          i = this.listWrapper,
          e = this.submenu,
          s = this.showHideBtn,
          n = this.visible;
        s.style.backgroundImage = "url(" + this.iconsURL + "arrow-right.svg)", n ? (t.style.height = "100%", i.classList.remove("highcharts-hide"), s.classList.remove("highcharts-arrow-right"), s.style.top = l(i, "padding-top") + "px", s.style.left = t.offsetWidth + l(i, "padding-left") + "px") : (e && (e.style.display = "none"), s.style.left = "0px", n = this.visible = !1, i.classList.add("highcharts-hide"), s.classList.add("highcharts-arrow-right"), t.style.height = s.offsetHeight + "px");
      }
      switchSymbol(t, i) {
        let e = t.parentNode,
          s = e.className,
          n = e.parentNode.parentNode;
        !(s.indexOf("highcharts-disabled-btn") > -1) && (n.className = "", s && n.classList.add(s.trim()), n.querySelectorAll(".highcharts-menu-item-btn")[0].style.backgroundImage = t.style.backgroundImage, i && this.toggleButtonActiveClass(n));
      }
      toggleButtonActiveClass(t) {
        let i = t.classList;
        i.contains("highcharts-active") ? i.remove("highcharts-active") : i.add("highcharts-active");
      }
      unselectAllButtons(t) {
        let i = t.parentNode.querySelectorAll(".highcharts-active");
        [].forEach.call(i, i => {
          i !== t && i.classList.remove("highcharts-active");
        });
      }
      update(t, i) {
        this.isDirty = !!t.gui.definitions, h(!0, this.chart.options.stockTools, t), h(!0, this.options, t.gui), this.visible = p(this.options.visible && this.options.enabled, !0), this.chart.navigationBindings && this.chart.navigationBindings.update(), this.chart.isDirtyBox = !0, p(i, !0) && this.chart.redraw();
      }
      destroy() {
        let t = this.wrapper,
          i = t && t.parentNode;
        this.eventsToUnbind.forEach(t => t()), i && i.removeChild(t);
      }
      redraw() {
        if (this.options.enabled !== this.guiEnabled) this.handleGuiEnabledChange();else {
          if (!this.guiEnabled) return;
          this.updateClassNames(), this.updateButtons(), this.updateVisibility(), this.showHideNavigation(), this.showHideToolbar();
        }
      }
      handleGuiEnabledChange() {
        !1 === this.options.enabled && (this.destroy(), this.visible = !1), !0 === this.options.enabled && (this.createContainer(), this.createButtons()), this.guiEnabled = this.options.enabled;
      }
      updateClassNames() {
        this.options.className !== this.guiClassName && (this.guiClassName && this.wrapper.classList.remove(this.guiClassName), this.options.className && this.wrapper.classList.add(this.options.className), this.guiClassName = this.options.className), this.options.toolbarClassName !== this.toolbarClassName && (this.toolbarClassName && this.toolbar.classList.remove(this.toolbarClassName), this.options.toolbarClassName && this.toolbar.classList.add(this.options.toolbarClassName), this.toolbarClassName = this.options.toolbarClassName);
      }
      updateButtons() {
        (!d(this.options.buttons, this.buttonList) || this.isDirty) && (this.toolbar.innerHTML = i.emptyHTML, this.createButtons());
      }
      updateVisibility() {
        a(this.options.visible) && (this.visible = this.options.visible);
      }
      getIconsURL() {
        return this.chart.options.navigation.iconsURL || this.options.iconsURL || "https://code.highcharts.com/11.4.8/gfx/stock-icons/";
      }
    }
    return g.prototype.classMapping = {
      circle: "highcharts-circle-annotation",
      ellipse: "highcharts-ellipse-annotation",
      rectangle: "highcharts-rectangle-annotation",
      label: "highcharts-label-annotation",
      segment: "highcharts-segment",
      arrowSegment: "highcharts-arrow-segment",
      ray: "highcharts-ray",
      arrowRay: "highcharts-arrow-ray",
      line: "highcharts-infinity-line",
      arrowInfinityLine: "highcharts-arrow-infinity-line",
      verticalLine: "highcharts-vertical-line",
      horizontalLine: "highcharts-horizontal-line",
      crooked3: "highcharts-crooked3",
      crooked5: "highcharts-crooked5",
      elliott3: "highcharts-elliott3",
      elliott5: "highcharts-elliott5",
      pitchfork: "highcharts-pitchfork",
      fibonacci: "highcharts-fibonacci",
      fibonacciTimeZones: "highcharts-fibonacci-time-zones",
      parallelChannel: "highcharts-parallel-channel",
      measureX: "highcharts-measure-x",
      measureY: "highcharts-measure-y",
      measureXY: "highcharts-measure-xy",
      timeCycles: "highcharts-time-cycles",
      verticalCounter: "highcharts-vertical-counter",
      verticalLabel: "highcharts-vertical-label",
      verticalArrow: "highcharts-vertical-arrow",
      currentPriceIndicator: "highcharts-current-price-indicator",
      indicators: "highcharts-indicators",
      flagCirclepin: "highcharts-flag-circlepin",
      flagDiamondpin: "highcharts-flag-diamondpin",
      flagSquarepin: "highcharts-flag-squarepin",
      flagSimplepin: "highcharts-flag-simplepin",
      zoomX: "highcharts-zoom-x",
      zoomY: "highcharts-zoom-y",
      zoomXY: "highcharts-zoom-xy",
      typeLine: "highcharts-series-type-line",
      typeOHLC: "highcharts-series-type-ohlc",
      typeHLC: "highcharts-series-type-hlc",
      typeCandlestick: "highcharts-series-type-candlestick",
      typeHollowCandlestick: "highcharts-series-type-hollowcandlestick",
      typeHeikinAshi: "highcharts-series-type-heikinashi",
      fullScreen: "highcharts-full-screen",
      toggleAnnotations: "highcharts-toggle-annotations",
      saveChart: "highcharts-save-chart",
      separator: "highcharts-separator"
    }, g;
  }), e(i, "Stock/StockTools/StockToolsGui.js", [i["Core/Defaults.js"], i["Stock/StockTools/StockToolsDefaults.js"], i["Stock/StockTools/StockToolbar.js"], i["Core/Utilities.js"]], function (t, i, e, s) {
    let {
        setOptions: n
      } = t,
      {
        addEvent: o,
        getStyle: a,
        merge: r,
        pick: l
      } = s;
    function c(t) {
      let i = this.options,
        s = i.lang,
        n = r(i.stockTools && i.stockTools.gui, t && t.gui),
        o = s && s.stockTools && s.stockTools.gui;
      this.stockTools = new e(n, o, this), this.stockTools.guiEnabled && (this.isDirtyBox = !0);
    }
    function h() {
      this.setStockTools();
    }
    function p() {
      this.stockTools && (this.stockTools.redraw(), function (t) {
        if (t.stockTools?.guiEnabled) {
          let i = t.options.chart,
            e = t.stockTools.listWrapper,
            s = e && (e.startWidth + a(e, "padding-left") + a(e, "padding-right") || e.offsetWidth);
          t.stockTools.width = s;
          let n = !1;
          if (s < t.plotWidth) {
            let e = l(i.spacingLeft, i.spacing && i.spacing[3], 0) + s,
              o = e - t.spacingBox.x;
            t.spacingBox.x = e, t.spacingBox.width -= o, n = !0;
          } else 0 === s && (n = !0);
          s !== t.stockTools.prevOffsetWidth && (t.stockTools.prevOffsetWidth = s, n && (t.isDirtyLegend = !0));
        }
      }(this));
    }
    function d() {
      this.stockTools && this.stockTools.destroy();
    }
    function g() {
      let t = this.stockTools?.visible && this.stockTools.guiEnabled ? this.stockTools.width : 0;
      t && t < this.plotWidth && (this.plotLeft += t, this.spacing[3] += t);
    }
    function u() {
      let t = this.stockTools,
        i = t && t.toolbar && t.toolbar.querySelector(".highcharts-current-price-indicator");
      t && this.navigationBindings && this.options.series && i && (this.navigationBindings.utils?.isPriceIndicatorEnabled?.(this.series) ? i.firstChild.style["background-image"] = 'url("' + t.getIconsURL() + 'current-price-hide.svg")' : i.firstChild.style["background-image"] = 'url("' + t.getIconsURL() + 'current-price-show.svg")');
    }
    function m(t) {
      let i = this.chart.stockTools;
      if (i && i.guiEnabled) {
        let i = t.button;
        i.parentNode.className.indexOf("highcharts-submenu-wrapper") >= 0 && (i = i.parentNode.parentNode), i.classList.remove("highcharts-active");
      }
    }
    function y(t) {
      let i = this.chart.stockTools;
      if (i && i.guiEnabled) {
        let e = t.button;
        i.unselectAllButtons(t.button), e.parentNode.className.indexOf("highcharts-submenu-wrapper") >= 0 && (e = e.parentNode.parentNode), i.toggleButtonActiveClass(e);
      }
    }
    return {
      compose: function (t, e) {
        let s = t.prototype;
        s.setStockTools || (o(t, "afterGetContainer", h), o(t, "beforeRedraw", p), o(t, "beforeRender", p), o(t, "destroy", d), o(t, "getMargins", g, {
          order: 0
        }), o(t, "render", u), s.setStockTools = c, o(e, "deselectButton", m), o(e, "selectButton", y), n(i));
      }
    };
  }), e(i, "masters/modules/stock-tools.src.js", [i["Core/Globals.js"], i["Extensions/Annotations/NavigationBindings.js"], i["Stock/StockTools/StockTools.js"], i["Stock/StockTools/StockToolsGui.js"], i["Stock/StockTools/StockToolbar.js"]], function (t, i, e, s, n) {
    return t.NavigationBindings = t.NavigationBindings || i, t.Toolbar = n, e.compose(t.NavigationBindings), s.compose(t.Chart, t.NavigationBindings), t;
  });
});
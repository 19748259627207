import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // Set the dynamic title for the About page
    const title = 'About Us - Learn More About Our Financial Tools and Services';
    const description = 'Discover who we are, our mission, and the financial tools we offer to help you track market trends, analyze stocks, and make informed investment decisions.';

    // Set the dynamic title
    this.titleService.setTitle(title);

    // Set the dynamic meta description
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
  }
}

import { Component, Inject, PLATFORM_ID, OnDestroy, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; 
import { StockDataService } from '../stock-data.service';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { TextFieldModule } from '@angular/cdk/text-field';
import { LlmService } from '../llm.service';
import { Chart, registerables } from 'chart.js/auto';
import { linearRegression, rSquared, sampleCorrelation } from 'simple-statistics';
import { format } from 'date-fns';
import * as marked from 'marked';
import { MicrophoneComponent } from '../microphone/microphone.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { ActivatedRoute } from '@angular/router';
import { HowToUsePopupComponent } from '../how-to-use-popup/how-to-use-popup.component';

import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';



Chart.register(...registerables);
interface Message {
  text: string;
  fromUser: boolean;
  function?: any;
  link?: { text: string; url: string };
  graph?: boolean;
  table?: boolean;
  tableData?: (string | number | null)[][][];
  tableWidth?: string;
  tableHeight?: string;
  graphData?: any[];
  chartData?: any[];
  chart?: boolean;
  isStreaming?: boolean;
}

@Component({
  selector: 'app-chat-bot',
  standalone: true,
  imports: [CommonModule, FormsModule, TextFieldModule, MicrophoneComponent, DataTableComponent, HowToUsePopupComponent],
  templateUrl: './chat-bot.component.html',
  styleUrl: './chat-bot.component.css',
})

export class ChatBotComponent {
  @ViewChild('regressionChart') stockChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('messageContainer') messageContainer: ElementRef | undefined;
  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement>;
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('msgContainer') msgContainer!: ElementRef;
  messages: Message[] = [];
  buffer: any = undefined;
  chartIds: string[] = [];
  private observer: MutationObserver | null = null;

  constructor(private el: ElementRef, private stockDataService: StockDataService, private llmService: LlmService, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private titleService: Title, private metaService: Meta) {
    this.messageInput = {} as ElementRef<HTMLTextAreaElement>;
  }

  ngOnInit(): void {
    this.setPageMeta("Master Financial Analysis with Lambda Finance: Your All-in-One Tool",
      "Unlock the power of financial analysis with Lambda Finance. Access financial statements, perform vertical and comparables analysis, regression studies, rolling correlations, total return calculations, and earnings call summaries—all in one place."
    );
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.addClass(document.body, 'specific-component-background');
    }
    
  }

  setPageMeta(title: string, description: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  ngAfterViewInit() {
    this.scrollToBottom()
    // Initialize with any pre-existing messages
    //this.adjustChatHeight();
  }

  getConvertedHtml(markdownText: string): SafeHtml {
    const options = { async: false }; 
    const html:string = marked.parse(markdownText, options) as string; // Convert markdown to HTML
    return this.sanitizer.bypassSecurityTrustHtml(html); // Sanitize and return the HTML
  }

  ngAfterViewChecked() {
    
    if (this.chatContainer) {
      console.log(this.messages);
      let chatMessages = this.chatContainer.nativeElement.querySelectorAll('.chat__message, .fchat__message, .first__message');
      let totalHeight = 0;
      let containerHeight = this.chatContainer.nativeElement.clientHeight;
  
      // Calculate total height of all messages
      chatMessages.forEach((chatElement: HTMLElement) => {
        totalHeight += chatElement.scrollHeight;
      });
  
      // Set minimum height for chat container
      let minHeight = Math.max(containerHeight, totalHeight + 100); // Add some padding
  
      // Set the container height
      this.chatContainer.nativeElement.style.minHeight = `${minHeight}px`;
  

      // Check the screen width and set currentTop accordingly
      const isSmallScreen = window.matchMedia('(max-width: 68.75em)').matches;
      let currentTop = minHeight - (isSmallScreen ? 150 : 60); // Set to 60 for small screens, 150 otherwise
      // Iterate through the NodeList in reverse order
      for (let i = 0; i < chatMessages.length; i++) {
        const chatElement = chatMessages[i] as HTMLElement;
        const messageHeight = chatElement.scrollHeight;
        // Set the top position of the message
        chatElement.style.top = `${currentTop - messageHeight}px`;
  
        // Update the currentTop for the next message
        currentTop -= messageHeight + 10; // Add some space between messages
      }
    }
  
  }

  scrollToBottom(): void {
    try {
      window.scrollTo(0, document.body.scrollHeight);
      //this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Failed to scroll:', err);
    }
  }

  private isValidUrl(url: string | undefined): boolean {
    if (!url) return false;
    try {
      const parsedUrl = new URL(url);
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } catch {
      return false;
    }
  }

  addLinkToChat(messageIndex: number, linkText: string, linkUrl: string) {
    const chatContent = document.querySelectorAll('.chat__content')[messageIndex];
    const aElement = this.renderer.createElement('a');
    const text = this.renderer.createText(linkText);
    this.renderer.appendChild(aElement, text);
    this.renderer.setAttribute(aElement, 'href', linkUrl);
    this.renderer.setAttribute(aElement, 'target', '_blank');
    this.renderer.appendChild(chatContent, aElement);
  }

  handleTranscript(transcript: any) {
    this.messageInput.nativeElement.value = transcript;
    this.sendMessage(this.messageInput.nativeElement);
  }

  sendMessage(inputElement: HTMLTextAreaElement): void {
    if (inputElement.value !== '') {
      inputElement.disabled = true;

      const userMessage = inputElement.value;
      this.messages.unshift({ text: userMessage, fromUser: true });
      inputElement.value = '';

      
      let aiMessage: Message  = { text: '', fromUser: false, isStreaming: true, function: null }; // Initialize aiMessage as null
      let first: boolean = false;

      this.llmService.postData(userMessage).subscribe({
        next: (chunk: any) => {

          if (chunk.content ) {
            
            if (!first) {
              first = true;
              this.messages.unshift(aiMessage); // Add the message to the beginning of the array
              
            }

            setTimeout(() => {
              aiMessage.text += chunk.content;
              
              this.ngAfterViewChecked();
              this.changeDetectorRef.detectChanges();
              
            }, 100); // Delay in milliseconds
          }

          if (chunk.tool) {
              aiMessage.function = chunk.tool;
          }
        },
        error: (error) => {
          console.error('Error:', error);
          aiMessage.text += 'An error occurred while processing your request.';
          aiMessage.isStreaming = false;
          this.changeDetectorRef.detectChanges();
          
          inputElement.disabled = false;
        },
        complete: async () => {

          aiMessage.isStreaming = false;
          await this.processCompleteMessage(aiMessage);
          console.log("hello");
          this.changeDetectorRef.detectChanges();
          this.ngAfterViewChecked();
          console.log("hello");
          inputElement.disabled = false;
          this.scrollToBottom();
        }
      });
    }
  }

  private async processCompleteMessage(message: Message): Promise<void> {

    if (message.function) {
      const toolCall = message;
      const args = toolCall.function.arguments;

      switch (toolCall.function.name) {
        case "get_Statement":
          await this.handleGetStatement(args);
          break;
        case "get_HorizontalAnalysis":
          await this.handleGetHorizontalAnalysis(args);
          break;
        case "get_VerticalAnalysis":
          this.handleGetVerticalAnalysis(args);
          break;
        case "get_CompsAnalysis":
          this.handleGetCompsAnalysis(args);
          break;
        case "get_Regression":
          await this.handleGetRegression(args);
          break;
        case "get_RollingCorr":
          await this.handleGetRollingCorr(args);
          break;
        case "get_TotalReturn":
          await this.handleGetTotalReturn(args);
          break;
        default:
          console.log("Unknown function call:", toolCall.function.name);
      }
    }
  }

  private async handleGetRollingCorr(args: any): Promise<void> {
    const ret = await this.calculateRollingCorrelation(
      args.ticker1,
      args.ticker2,
      args.window || 30,
      args.from,
      args.to
    );
  
    if (ret === 1) {
      this.messages.unshift({
        text: `No data found. Please check the ticker/business names ${args.ticker1}, ${args.ticker2}`,
        fromUser: false,
        graph: false
      });
    } else {

      this.messages.unshift({ text: '', fromUser: false, chart: true, chartData: ret });
    }
  }
  
  private async handleGetRegression(args: any): Promise<void> {
    const ret = await this.getRegression(args.ticker1, args.ticker2, args.numberOfYears);
  
    if (ret === 1) {
      this.messages.unshift({
        text: `No statement found. Please check the ticker/business names ${args.ticker1}, ${args.ticker2}`,
        fromUser: false,
        graph: false
      });
    } else {
      this.messages.unshift({ text: '', fromUser: false, graph: true, graphData: ret });
    }
  }
  
  private async handleGetTotalReturn(args: any): Promise<void> {
    const ret = await this.getTotalReturn(args.tickers, args.shares, args.from, args.to);

    if (ret === 1) {
      this.messages.unshift({
        text: `No data found. Please check the ticker/business names ${args.tickers.join(', ')}`,
        fromUser: false,
        graph: false
      });
    } else {
      this.messages.unshift({ text: '', fromUser: false, chart: true, chartData: ret });
    }
  }
  
  private async handleGetStatement(args: any): Promise<void> {
    const types = Array.isArray(args.types) ? args.types : [args.types];
    const table = await this.downloadStatement(types, args.ticker, args.year);
    
    if (table === undefined) {
      this.messages.unshift({ 
        text: `No statement found. Please check the ticker/business name ${args.ticker} for year ${args.year}`,
        fromUser: false,
        table: false,
        link: { text: `${args.ticker}_${args.year}.xlsx`, url: '' },
        tableData: undefined,
        tableWidth: '70em',
        tableHeight: '70em'
      });
    } else {
      this.messages.unshift({ 
        text: '',
        fromUser: false,
        table: true,
        link: { text: `${args.ticker}_${args.year}.xlsx`, url: '' },
        tableData: table[0],
        tableWidth: '70em',
        tableHeight: '70em'
      });
    }
  }
  
  private async handleGetHorizontalAnalysis(args: any): Promise<void> {
    const year = args.year;
    const table = await this.downloadHorizontalAnalysis(args.ticker, year);
    this.messages.unshift({ 
      text: args.ai_notes || '',
      fromUser: false,
      table: true,
      link: { text: `${args.ticker}_${year}.xlsx`, url: '' },
      tableData: table,
      tableWidth: '70em',
      tableHeight: '70em'
    });
  }
  
  private async handleGetVerticalAnalysis(args: any): Promise<void> {
    await this.downloadVerticalAnalysis(args.ticker, args.year);
    this.messages.unshift({ 
      text: args.ai_notes || '',
      fromUser: false,
      link: { text: `${args.ticker}_${args.year}.xlsx`, url: '' }
    });
  }
  
  private async handleGetCompsAnalysis(args: any): Promise<void> {
    await this.downloadCompsAnalysis(args.tickers);
    this.messages.unshift({ 
      text: '',
      fromUser: false,
      link: { text: `${args.tickers.join('_')}.xlsx`, url: '' }
    });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(document.body, 'specific-component-background');
    }
  }

  saveBuffer(): void {
    if (this.buffer) {
      saveAs(this.buffer.file, `${this.buffer.ticker}_${this.buffer.year}`);
    }
  }

  async downloadStatement(types: string[], ticker: string, year: string): Promise<any> {
    // Query the MongoDB collection
    const workbook = new ExcelJS.Workbook();
    const data: any = [];

    for (const type of types) {
      let result;

      // Fetch the corresponding statement based on the type
      switch (type) {
        case 'income':
          result = await this.stockDataService.getIncomeStatement(ticker).toPromise();
          break;
        case 'balance':
          result = await this.stockDataService.getBalanceStatement(ticker).toPromise();
          break;
        case 'cash':
          result = await this.stockDataService.getCashStatement(ticker).toPromise();
          break;
        default:
          console.error(`Invalid statement type: ${type}`);
          continue;
      }

      if (result && result[0] && result[0][year]) {
        //const currentYear = new Date(year).getFullYear();
        const years = Array.from({ length: 5 }, (_, i) => parseInt(year) - i);

        // Create a single worksheet for all years
        const sheetName = `${ticker}-${type}`;
        const worksheet = workbook.addWorksheet(sheetName);

        const headerRow = worksheet.addRow([`${ticker.toUpperCase()} ${type.toUpperCase()} STATEMENT (Unaudited)`]);
        headerRow.font = { bold: true };
        worksheet.mergeCells(`A1:Z1`); // Merge cells for the header

        // Initialize HTML table data for this type
        const tableData1: any[][] = [];
        // Create and store column headers
        const headers = ['Item', ...years.map(y => y.toString())];
        tableData1.push(headers);

        let rowIndex = 2; // Start from the second row

        // Write the labels (keys) only once
        let firstYearData;
        if (result && result[0] && result[0][years[0]]) {
          firstYearData = result[0][years[0]];
          for (const key of Object.keys(firstYearData)) {
            worksheet.getRow(rowIndex).getCell(1).value = key;
            rowIndex++;
          }
        }

        rowIndex = 2; // Reset to the second row for writing values

        for (let yearIndex = 0; yearIndex < years.length; yearIndex++) {
          const year = years[yearIndex];
          const startColumn = 2 + yearIndex; // Shift the start column for each year's values

          if (result && result[0] && result[0][year]) {
            const sheetData = result[0][year];

            // Add data for the year
            let currentRowIndex = 2; // Start from the second row for data
            for (const key of Object.keys(firstYearData)) {
              const value = sheetData[key];
              const row = worksheet.getRow(currentRowIndex);
              row.getCell(startColumn).value = value;

              const valueCell = row.getCell(startColumn);
              if (typeof value === 'number') {
                valueCell.numFmt = '"$"#,##0.00_);("$"#,##0.00)';
              }

              // Add row data to HTML table
              if (!tableData1[currentRowIndex - 2]) tableData1[currentRowIndex - 2] = [];
              tableData1[currentRowIndex - 2][0] = key;
              tableData1[currentRowIndex - 2][yearIndex + 1] = typeof value === 'number' ? value.toLocaleString() : value;

              if (currentRowIndex % 2 === 0) {
                row.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFDDDDDD' }
                };
              }
              currentRowIndex++;
            }
          }
        }

        data.push(tableData1);
        // Write the workbook to a blob
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          this.buffer = {
            ticker: ticker,
            year: year,
            type: 'income',
            file: blob
          };
        });
      }
    }
    return data;
  }

  async downloadHorizontalAnalysis(ticker: string, nYears: number = 5): Promise<any> {
    // Query the MongoDB collection
    const result = await this.stockDataService.getIncomeStatement(ticker).toPromise();

    const years: string[] = this.getDefaultYears(nYears);
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    const tableData1: any[][] = [];

    if (result.length > 0) {
      const sheetName = ticker;
      const worksheet = workbook.addWorksheet(sheetName);

      // Keys to exclude from division
      const exceptionKeys = ['grossProfitRatio', 'ebitdaratio', 'operatingIncomeRatio', 'incomeBeforeTaxRatio', 'netIncomeRatio', 'eps', 'epsdiluted'];


      const headerRow = worksheet.addRow([`${ticker} HORIZONTAL ANALYSIS (Unaudited)`]);
      headerRow.font = { bold: true };
      worksheet.mergeCells(`A1:H1`); // Merge cells for the header

      // Add column headers
      const columnHeaders = ['Item', ...years, 'Percentage Change'];
      tableData1.push(columnHeaders);
      worksheet.addRow(columnHeaders);

      // Get keys for the first year as the basis for all rows
      const firstYearData = result[0][years[0]];
      const keys = Object.keys(firstYearData);

      const startIndex = keys.indexOf("period") + 1;
      const filteredKeys = keys.slice(startIndex, keys.length - 2);

      let rowIndex = 3; // Start from the third row
      filteredKeys.forEach(key => {
        const rowValues: (string | number | null)[] = [key];

        // Add values for each year
        for (const year of years) {
          const yearData = result[0][year] || {};
          let value = yearData[key] ?? null;

          // Divide by 1,000,000 if the key is not in the exception list and the value is a number
          if (!exceptionKeys.includes(key) && typeof value === 'number') {
            value = value / 1000000;
          }

          rowValues.push(value);
        }

        // Calculate percentage change if there are at least two years of data
        const baseValue = rowValues[1] as number;
        const currentValue = rowValues[rowValues.length - 2] as number;
        const percentageChange = baseValue ? ((currentValue - baseValue) / baseValue) * 100 : null;
        rowValues.push(percentageChange);

        const row = worksheet.addRow(rowValues);
        tableData1.push(rowValues);

        // Format cells for numbers and percentages
        row.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            if (typeof cell.value === 'number') {
              cell.numFmt = '"$"#,##0.00_);("$"#,##0.00)';
              if (colNumber === rowValues.length) {
                cell.numFmt = '0.00%';
              }
            }
          }
        });

        // Alternate row color for readability
        if (rowIndex % 2 === 0) {
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }
          };
        }
        rowIndex++;
      });
    }

    // Write the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.buffer = {
        ticker: ticker,
        year: years[years.length-1],
        type: 'horizontalAnalysis',
        file: blob
      };
    });
    return ([tableData1]);
  }

  // Method to check if a value is a number
  isNumber(value: any): boolean {
    return !isNaN(value) && isFinite(value);
  }

  getDefaultYears(numYears: number): string[] {
    const currentYear = new Date().getFullYear();
    const years: string[] = [];
    for (let i = 0; i < numYears; i++) {
      years.unshift((currentYear - i).toString());
    }
    return years;
  }

  async downloadVerticalAnalysis(ticker: string, year: string): Promise<void> {
    // Query the MongoDB collection
    const result = await this.stockDataService.getIncomeStatement(ticker).toPromise();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    if (result.length > 0) {
      const sheetData = result[0][year];
      if (sheetData) {
        const sheetName = ticker;
        const worksheet = workbook.addWorksheet(sheetName);

        const headerRow = worksheet.addRow([`${ticker} VERTICAL ANALYSIS (Unaudited) - ${year}`]);
        headerRow.font = { bold: true };
        worksheet.mergeCells(`A1:C1`); // Merge cells for the header

        // Add column headers
        const columnHeaders = ['Item', 'Amount', 'Percentage of Total Revenue'];
        worksheet.addRow(columnHeaders);

        // Calculate total revenue (or base figure)
        const totalRevenue = sheetData['revenue'];

        let rowIndex = 3; // Start from the third row
        for (const [key, value] of Object.entries(sheetData)) {
          const rowValues: (string | number | null)[] = [key, value as string | number | null];

          // Calculate the percentage of total revenue
          const percentage = totalRevenue ? (value as number) / totalRevenue : null;
          rowValues.push(percentage);

          const row = worksheet.addRow(rowValues);

          // Format cells for numbers and percentages
          row.getCell(2).numFmt = '"$"#,##0.00_);("$"#,##0.00)';
          if (row.getCell(3).value) {
            row.getCell(3).numFmt = '0.00%';
          }

          // Alternate row color for readability
          if (rowIndex % 2 === 0) {
            row.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFDDDDDD' }
            };
          }
          rowIndex++;
        }
      }
    }

    // Write the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.buffer = {
        ticker: ticker,
        year: year,
        type: 'verticalAnalysis',
        file: blob
      };
    });
  }

  async downloadCompsAnalysis(tickers: string[]): Promise<void> {
    // Define the key metrics to extract
    const keyMetrics = [
      'revenuePerShare', 'netIncomePerShare', 'operatingCashFlowPerShare', 'freeCashFlowPerShare',
      'cashPerShare', 'bookValuePerShare', 'tangibleBookValuePerShare', 'shareholdersEquityPerShare',
      'enterpriseValue', 'peRatio', 'priceToSalesRatio', 'pocfratio', 'pfcfRatio', 'pbRatio',
      'evToSales', 'enterpriseValueOverEBITDA', 'evToOperatingCashFlow', 'evToFreeCashFlow',
      'earningsYield', 'freeCashFlowYield', 'debtToEquity', 'debtToAssets', 'netDebtToEBITDA',
      'currentRatio', 'dividendYield', 'payoutRatio', 'capexToOperatingCashFlow', 'roic', 'roe'
    ];

    // Limit the tickers to the first 5
    const stocks = tickers.slice(0, 5);

    // Create an object to store the results
    const metricsData: any = {};
    const result2 = await this.stockDataService.getKeyMetrics(stocks[0]).toPromise();
    let yr = Object.keys(result2).sort((a, b) => parseInt(b) - parseInt(a))[0];;
    // Fetch the key metrics for each stock and only the latest year
    for (const stock of stocks) {
      const result = await this.stockDataService.getKeyMetrics(stock).toPromise();
      const latestYear = Object.keys(result).sort((a, b) => parseInt(b) - parseInt(a))[0];

      metricsData[stock] = keyMetrics.reduce((acc: any, metric: any) => {
        acc[metric] = result[latestYear][metric] || null;
        return acc;
      }, {});
    }

    // Create an Excel workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Key Metrics');

    // Add headers for each metric
    const headers = ['Ticker', ...keyMetrics];
    worksheet.addRow(headers);

    // Add data rows
    for (const stock of Object.keys(metricsData)) {
      const rowData = [stock];
      let rowIndex = 1; // Start from the third row
      for (const metric of keyMetrics) {
        rowData.push(metricsData[stock][metric]);
      }
      const row = worksheet.addRow(rowData);

      // Format cells for numbers and percentages
      row.getCell(2).numFmt = '"$"#,##0.00_);("$"#,##0.00)';
      if (row.getCell(3).value) {
        row.getCell(3).numFmt = '0.00%';
      }

      // Alternate row color for readability
      if (rowIndex % 2 === 0) {
        row.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFDDDDDD' }
        };
      }
      rowIndex++;
    }

    // Write the workbook to a buffer and create a Blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Prepare the buffer for download
      this.buffer = {
        ticker: stocks[0], // or any identifier you prefer
        year: yr,   // or any identifier you prefer
        type: 'CompsAnalysis',
        file: blob
      }
    });
  }

  async computeDCFAnalysis(ticker: string, discountRate: number, growthRate: number, projectionYears: number = 5): Promise<void> {
    // Query the MongoDB collection for the financial statements
    const profile = await this.stockDataService.getProfile(ticker).toPromise();
    const balanceStatement = await this.stockDataService.getBalanceStatement(ticker).toPromise();
    const cashStatement = await this.stockDataService.getCashStatement(ticker).toPromise();

    if (!profile || !balanceStatement || !cashStatement) {
      throw new Error('Failed to retrieve financial statements');
    }

    // Extract relevant data from the cash flow statement for FCF calculation
    const cashFlowData = cashStatement[0];
    const years = Object.keys(cashFlowData).sort();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${ticker} DCF Analysis`);

    // Header row
    worksheet.addRow(['Year', 'Cash Flow from Operations', 'Capital Expenditures', 'Free Cash Flow', 'Discount Factor', 'Present Value of FCF']);

    // Calculate and project future free cash flows
    let totalPVFCF = 0;
    const lastYear = years[years.length - 1];
    const cashFlowFromOperations = cashFlowData[lastYear]['operatingCashFlow'];
    const capitalExpenditures = cashFlowData[lastYear]['capitalExpenditure'];
    const freeCashFlow = cashFlowFromOperations - capitalExpenditures;

    if (growthRate >= discountRate) {
      throw new Error('Growth rate cannot be higher than the discount rate in the single-stage DCF model.');
    }

    for (let i = 0; i < projectionYears; i++) {
      const year = parseInt(lastYear) + i + 1;
      const projectedCashFlowFromOperations = freeCashFlow * Math.pow(1 + growthRate, i + 1);
      const projectedCapitalExpenditures = capitalExpenditures * Math.pow(1 + growthRate, i + 1);
      const projectedFreeCashFlow = projectedCashFlowFromOperations - projectedCapitalExpenditures;
      const discountFactor = 1 / Math.pow(1 + discountRate, i + 1);
      const presentValueOfFCF = projectedFreeCashFlow * discountFactor;

      worksheet.addRow([year, projectedCashFlowFromOperations, projectedCapitalExpenditures, projectedFreeCashFlow, discountFactor, presentValueOfFCF]);

      totalPVFCF += presentValueOfFCF;
    }

    // Calculate the terminal value
    const terminalValue = freeCashFlow * (1 + growthRate) / (discountRate - growthRate);
    const presentValueOfTerminalValue = terminalValue / Math.pow(1 + discountRate, projectionYears);

    totalPVFCF += presentValueOfTerminalValue;

    // Add terminal value row
    worksheet.addRow(['Terminal Value', '', '', '', '', presentValueOfTerminalValue]);

    // Add total PV of FCF row
    worksheet.addRow(['Total Present Value of FCF', '', '', '', '', totalPVFCF]);

    // Calculate the enterprise value (EV)
    const enterpriseValue = totalPVFCF;

    // Calculate the equity value
    const netDebt = balanceStatement[0][lastYear]['netDebt'];
    const equityValue = enterpriseValue - netDebt;

    // Calculate the stock price
    const stockPrice = equityValue / (profile[0][ticker]['mktCap'] / profile[0][ticker]['price']);

    // Add equity value row
    worksheet.addRow(['Equity Value', '', '', '', '', equityValue]);

    // Add stock price row
    worksheet.addRow(['Stock Price', '', '', '', '', stockPrice]);

    // Write the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.buffer = {
        ticker: ticker,
        year: years[years.length - 1],
        type: 'dcfAnalysis',
        file: blob
      };
    });
  }

  createGraph(regressionData: any[], regressionLineEquation: (x: number) => number): void {
    const id = `regressionChart${this.messages.length - 1}`;
    

    if (!this.chartIds.includes(id)) {
      this.chartIds.push(id);
      const canvas = document.getElementById(id) as HTMLCanvasElement;
      const ctx = canvas?.getContext('2d');
      new Chart('regressionChart', {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: 'Data Points',
              data: regressionData.map(d => ({ x: d[0], y: d[1] })),
              backgroundColor: 'rgba(75, 192, 192, 1)'
            },
            {
              label: 'Trendline',
              data: regressionData.map(d => ({ x: d[0], y: regressionLineEquation(d[0]) })),
              type: 'line',
              fill: false,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2
            }
          ]
        },
      });
    } else {
      console.error('Failed to get 2D context');
    }
  }

  private displayRegressionResults(slope: number, intercept: number, rSquared: number): void {
    const resultsTable = document.getElementById('regressionResults');
    if (resultsTable) {
      resultsTable.innerHTML = `
      <tr>
        <td>Slope:</td>
        <td>${slope.toFixed(2)}</td>
      </tr>
      <tr>
        <td>Intercept:</td>
        <td>${intercept.toFixed(2)}</td>
      </tr>
      <tr>
        <td>R-squared:</td>
        <td>${rSquared.toFixed(2)}</td>
      </tr>
    `;
    }
  }

  private filterAndNormalizeData(data: any[], startDateString: string): any[] {
    // Filter data based on start date
    data = data.filter((item: any) => {
      const date = new Date(item.date);
      return date >= new Date(startDateString);
    });

    return data;
  }

  async getRegression(ticker1: string, ticker2: string, nYears: number): Promise<any> {

    const endDate = new Date(this.stockDataService.lastWrkDay);
    const startDate = new Date();
    startDate.setFullYear(endDate.getFullYear() - nYears);
    let startDateString = startDate.toISOString().split('T')[0];
    let endDateString = endDate.toISOString().split('T')[0];

    const dataResponse1 = await this.stockDataService.getStockData(ticker1, startDateString).toPromise();
    const dataResponse2 = await this.stockDataService.getStockData(ticker2, startDateString).toPromise();

    if (Object.keys(dataResponse1).length === 0 || Object.keys(dataResponse2).length === 0) {
      return 1;
    }
    let data1 = this.filterAndNormalizeData(dataResponse1.historical, startDateString);
    let data2 = this.filterAndNormalizeData(dataResponse2.historical, startDateString);




    const regressionData: any[] = data1.map((d: any, i: any) => [d.close, data2[i].close ]);
    const regressionPoints: any[] = regressionData.map((d: any) => [d.close1, d.close2]);
    const regressionLine: any = linearRegression(regressionPoints);
    const regressionLineEquation = (x: number) => regressionLine.m * x + regressionLine.b;

    const slope: number = regressionLine.m;
    const intercept: number = regressionLine.b;

    //const observedValues: number[] = regressionPoints.map((d: any) => d[1]);
    //const predictedValues: number[] = regressionPoints.map((d: any) => regressionLineEquation(d[0]));

    //const rSquaredValue: number = rSquared(observedValues, predictedValues);

    // Create a graph with a trendline
    
    return [regressionData, regressionLineEquation];
    // Display the regression results in a table
    //this.displayRegressionResults(slope, intercept, rSquaredValue);
  }

  async calculateRollingCorrelation(ticker1: string, ticker2: string, windowSize: number, startDate: string, endDate: string): Promise<any> {
    const data1: any = await this.stockDataService.getStockData(ticker1).toPromise();
    const data2: any =  await this.stockDataService.getStockData(ticker2).toPromise();
    const start = new Date(startDate);
    start.setDate(start.getDate() - windowSize);
    const end = new Date(endDate);
    if (Object.keys(data1).length === 0 || Object.keys(data2).length === 0) {
      return 1;
    }
    const filteredData1 = this.filterDataByDate(data1.historical, start, end);
    const filteredData2 = this.filterDataByDate(data2.historical, start, end);
    
    let rollingCorrelations = [];
    for (let i = 0; i <= filteredData1.closeValues.length - windowSize; i++) {
      const subset1 = filteredData1.closeValues.slice(i, i + windowSize);
      const subset2 = filteredData2.closeValues.slice(i, i + windowSize);
      const corr = sampleCorrelation(subset1, subset2);
      
      rollingCorrelations.push(corr);
    }
    return (['line', filteredData1.dates, rollingCorrelations, "Rolling Correlation"]);
  }

  filterDataByDate(data: any[], startDate: Date, endDate: Date): any {
    const filteredData: any = {
      dates: [],
      closeValues: []
    };

    data.forEach(d => {
      const date = new Date(d.date);
      if (date >= startDate && date <= endDate) {
        filteredData.dates.push(d.date);
        filteredData.closeValues.push(d.close);
      }
    });

    return filteredData;
  }

  createChart(type: any, xData: string[], yData: number[], title: string): void {

    const id = `#regressionChart${this.messages.length - 1}`;
    if (!this.chartIds.includes(id)) {
      this.chartIds.push(id);
      const canvas = this.el.nativeElement.querySelector(id) as HTMLCanvasElement;
      let ctx: any = canvas.getContext('2d') //just an example
      new Chart(ctx, {
        type: type,
        data: {
          labels: xData,
          datasets: [
            {
              label: title,
              data: yData,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',  // Set a semi-transparent background color for points
              borderColor: 'rgba(75, 192, 192, 1)',  // Set the border color for the line
              borderWidth: 2,  // Set the width of the line
              fill: false,  // Ensure the area under the line is not filled
              pointRadius: 3,  // Set the radius of the points
              pointHoverRadius: 5,  // Set the radius of the points on hover
              tension: 0.1  // Set the line tension for a slightly curved line
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',  // Set the x-axis to be a time axis
              time: {
                unit: 'month',  // Adjust the time unit as needed (e.g., 'day', 'month', 'year')
                tooltipFormat: 'll'  // Format for the tooltip display
              },
              title: {
                display: true,
                text: 'Date'  // Title for the x-axis
              }
            },
            y: {
              title: {
                display: true,
                text: title  // Title for the y-axis
              }
            }
          }
        }
      });
    }
  }

  async getTotalReturn(tickers: string[], shares: number[], from: string, to: string): Promise<any> {
    if (tickers.length > 5) {
      throw new Error("A maximum of 5 tickers can be given.");
    }

    const sharesArray = shares.length === 0 ? Array(tickers.length).fill(1) : shares;

    if (sharesArray.length !== tickers.length) {
      throw new Error("The number of shares must match the number of tickers.");
    }

    const fromDate = new Date(from);
    const toDate = new Date(to);

    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      throw new Error("Invalid date format. Use 'MM/DD/YYYY'.");
    }

    const portfolioReturns: { [date: string]: number } = {};
    let cumulativeReturn = 0.0;
    let total = 0;

    for (let i = 0; i < tickers.length; i++) {
      const ticker = tickers[i];
      const share = sharesArray[i];

      const data: any = await this.stockDataService.getStockData(ticker).toPromise();

      if (Object.keys(data).length === 0) {
        return 1;
      }
      let startPrice: number | null = null;

      for (const entry of data.historical.reverse()) {
        const entryDate = new Date(entry.date);
        if (entryDate < fromDate || entryDate > toDate) {
          continue;
        }

        if (startPrice === null) {

          startPrice = entry.close;
          total += entry.close * share;
        }

        if (startPrice !== null) {
          const dailyReturn = (entry.close - startPrice)* share;
          if (!portfolioReturns[entry.date]) {
            portfolioReturns[entry.date] = 0;
          }
          portfolioReturns[entry.date] += dailyReturn;
        }
      }

      if (startPrice === null) {

        (`No data for ticker ${ticker} in the given date range.`);
      }
    }

    // Divide all elements of portfolioReturns by the divisor
    for (const date in portfolioReturns) {
      if (portfolioReturns.hasOwnProperty(date)) {
        portfolioReturns[date] = portfolioReturns[date]/total*100;
      }
    }


    return (['line', Object.keys(portfolioReturns), Object.values(portfolioReturns), "Portfolio Returns"]);
  }

  fixJsonString(jsonString: string): string {
    // Replace unescaped quotes with escaped quotes
    //jsonString = jsonString.replace(/(?<!\\)"/g, '\\"');

    // Replace newlines with escaped newlines
    jsonString = jsonString.replace('\n', '<br>');

    // Replace unescaped backslashes with escaped backslashes
    jsonString = jsonString.replace('\\', '\\\\');
    // Optionally, add more replacements if needed

    return jsonString;
  }

    extractAiNotesAndReplaceNewlines(inputString: string) {
      // Find the position of the key "ai_notes"
      const keyPosition = inputString.indexOf('"ai_notes"');

      // If the key is not found, return the original string
      if (keyPosition === -1) {
        return inputString;
      }

      // Find the first double quote after the key
      const startQuotePosition = inputString.indexOf('"', keyPosition + 10);
  
      // If the first double quote is not found, return the original string
      if (startQuotePosition === -1) {
        return inputString;
      }

      // Find the last double quote after the first one
      const endQuotePosition = inputString.lastIndexOf('"');

      // If the last double quote is not found, return the original string
      if (endQuotePosition === -1 || endQuotePosition <= startQuotePosition) {
        return inputString;
      }

      // Extract the value between the first and last double quotes
      const value = inputString.substring(startQuotePosition + 1, endQuotePosition);

      // Replace all \n characters with <br>
      const updatedValue = value.replace(/\n/g, '<br>');

      // Place the updated value back into the original string
      const updatedString = inputString.substring(0, startQuotePosition + 1) + updatedValue + inputString.substring(endQuotePosition);

      return updatedString;
  }

  replaceNewlines(inputString: string) {
    if (inputString) {
      return inputString.replace(/\n/g, '<br>');
    } else {
      return ''
    }
    
  }


}

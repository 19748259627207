import { Routes } from '@angular/router';
import { MortgagePmtComponent } from './mortgage-pmt/mortgage-pmt.component';
import { StockScreenerComponent } from './stock-screener/stock-screener.component';
import { HomeComponent } from './home/home.component';
import { StockChartComponent } from './stock-chart/stock-chart.component';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { HeatMapComponent } from '../heat-map/heat-map.component';
import { EarningsCalendarComponent } from './earnings-calendar/earnings-calendar.component';
import { CovarianceComponent } from './covariance/covariance.component';
import { StockListComponent } from './stock-list/stock-list.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { AboutComponent } from './about/about.component';
import { DdmCalculatorComponent } from './ddm-calculator/ddm-calculator.component';
import { NewsListComponent } from './news-list/news-list.component';
import { PortfolioAnalysisComponent } from './portfolio-analysis/portfolio-analysis.component';
import { StockListResolver } from './resolvers/stock-list.service';
import { AInewsComponent } from './ainews/ainews.component';
import { NewsDetailComponent } from './ainews/news-detail/news-detail.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'mortgage',
    component: MortgagePmtComponent,
  },
  {
    path: 'covariance',
    component: CovarianceComponent,
  },
  {
    path: 'screener',
    component: StockScreenerComponent,
  }, {
    path: 'newsRoom',
    component: NewsListComponent,
  },
  {
    path: 'news/:type/:page?',
    component: AInewsComponent
  },
  { 
    path: 'newsDetail/:publishedDate/:headline',
    component: NewsDetailComponent 
  },
  {
    path: 'portfolio',
    component: PortfolioAnalysisComponent,
  },
  {
    path: 'ddm',
    component: DdmCalculatorComponent,
    title: 'DDM-Calculator'
  },
  {
    path: 'chart/:id/:exch',
    component: StockChartComponent,
  },
  {
    path: 'economic-calendar',
    component: EconomicCalendarComponent,
  },
  {
    path: 'earnings-calendar',
    component: EarningsCalendarComponent,
  },
  {
    path: 'map/:index',
    component: HeatMapComponent,
  },
  {
    path: 'actives/:type',
    component: StockListComponent,
    resolve: {
      stockData: StockListResolver,
    }
  },
  {
    path: 'lambda',
    component: ChatBotComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  }
];

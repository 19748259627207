import { Component, AfterViewInit, ElementRef, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';
import { NewsBoxComponent } from '../news-box/news-box.component';
import { enUS } from 'date-fns/locale';
import { Chart, registerables } from 'chart.js/auto';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { DomSanitizer, Meta, SafeUrl, Title } from '@angular/platform-browser';


Chart.register(...registerables);

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule, CommonModule, NewsBoxComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  newsData: any[] = [];
  ticker: string = '';
  topGainers: any[]=[];
  topLosers: any[]=[];
  topActives: any[]=[];
  todayISOString: string = '';
  oneWeekAgoISOString: string = '';
  switchState: 'showPrice' | 'showChangesPercentage' = 'showPrice';
  currentIndex = 0;
  refreshInterval: any;

  gainersProfile: any[]=[];
  losersProfile: any[]=[];
  activesProfile: any[] = [];
  stockCandidates: any[] = [];

  constructor(private stockDataService: StockDataService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private elementRef: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer, private titleService: Title, private metaService: Meta) { }

  async ngOnInit(): Promise<void> {
    this.setPageMetadata();
    
    const todayISOString = this.stockDataService.lastWrkDay;

    // Create a Date object for midnight of the current date
    const today = new Date(todayISOString);

    this.todayISOString = today.toISOString().split('T')[0];
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 3);
    this.oneWeekAgoISOString = oneWeekAgo.toISOString().split('T')[0];
    // this.stockDataService.pollMostActive();
    // this.stockDataService.pollLosers();
    // this.stockDataService.pollGainers();

    

    this.stockDataService.gainers$.pipe(
      filter(data => data.length > 0)  // Filter out empty arrays
    ).subscribe(
      async (data: any[]) => {
        await this.processData(data, this.topGainers, this.gainersProfile);
      },
      (error: any) => {
        console.error('Error fetching gainers:', error);
      }
    );

    this.stockDataService.losers$.pipe(
      filter(data => data.length > 0)  // Filter out empty arrays
    ).subscribe(
      async (data: any[]) => {
        await this.processData(data, this.topLosers, this.losersProfile);
      },
      (error: any) => {
        console.error('Error fetching losers:', error);
      }
    );

    this.stockDataService.actives$.pipe(
      filter(data => data.length > 0)  // Filter out empty arrays
    ).subscribe(
      async (data: any[]) => {
        await this.processData(data, this.topActives, this.activesProfile);
      },
      (error: any) => {
        console.error('Error fetching actives:', error);
      }
    );

    this.stockCandidates = await this.stockDataService.stockList().toPromise();
    
  }

  private adjustZoom() {
    if (typeof window !== 'undefined') {
      const containerWidth = 80 * 16; // 78em * 16px/em
      const windowWidth = window.innerWidth;
      const zoomLevel = (windowWidth / containerWidth) * 100;
      
      this.elementRef.nativeElement.style.zoom = `${zoomLevel}%`;
    }
  }

  private setPageMetadata(): void {
    // Set the title
    this.titleService.setTitle("Master Modern Financial Strategies with Lambda's Expertise");

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content: 'At Lambda, we are transforming the financial industry by introducing innovative strategies and methodologies to replace outdated practices.'
    });
  }

  async processData(data: any[], topArray: any[], profileArray: any[]) {
    let i = 0;
    let j = 0;
    while (i < 6 && data) {
      const p = await this.stockDataService.getProfile(data[j]?.symbol).toPromise();
      if (p.length !== 0) {
        topArray.push(data[j]);
        profileArray.push(p[0][data[j]?.symbol]);
        i += 1;
      }
      j += 1;
    }
    //this.chart3 = this.renderChart('chart');
  }

  sanitizeImageUrl(url: string | undefined): SafeUrl | null {
    return url ? this.sanitizer.bypassSecurityTrustUrl(url) : null;
  }

  ngAfterViewInit() {
    // Set up interval to refresh every minute (60000 ms)
    if (isPlatformBrowser(this.platformId)) {
      this.refreshInterval = setInterval(async () => {
        this.stockDataService.pollMostActive();
        this.stockDataService.pollLosers();
        this.stockDataService.pollGainers();
      }, 120000); // 1 minute
    }

    this.adjustZoom();
  }

  navigateToChart(candidate: any): void {
    if (candidate && candidate.symbol) {
      // Find the stock candidate that matches the symbol
      const matchedStock = this.stockCandidates.find(stock => stock.symbol === candidate.symbol);

      if (matchedStock && matchedStock.exchangeShortName) {
        // If match found, navigate using the symbol and exchangeShortName
        this.router.navigate(['chart', candidate.symbol, matchedStock.exchangeShortName]);
      } else {
        console.log('Stock symbol not found or exchangeShortName missing');
      }
    }
  }

  async renderIntradayChart(ticker: string, id:string, timeframe: string, from: string): Promise<any> {
    const data = await this.stockDataService.getIntradayStockData(ticker, timeframe, from).toPromise();
    const stockData = data.map((item: any) => ({
      date: new Date(item.date),
      price: item.close
    }));
    const filteredStockData = stockData.filter((item: any) => {
      const date = item.date;
      // Check if the date is greater than today
      const isAfterDate = date > new Date(from);
      // Check if the time is between 9:30am and 4pm
      return isAfterDate;
    });

    const dates = filteredStockData.map((item: any) => item.date);
    const prices = filteredStockData.map((item: any) => item.price);

    var ctx = id; // jQuery instance
    var backgroundColor1 = '';
    var borderColor1 = '';

    if (prices[0] - prices[prices.length - 1] >= 0) {
      backgroundColor1 = 'rgba(0, 180, 0, 0.25)';
      borderColor1 = 'rgba(0, 150, 0)';
    } else {
      backgroundColor1 = 'rgba(180, 0, 0, 0.25)';
      borderColor1 = 'rgba(150, 0, 0)';
    }

    return new Chart(ctx, {
      plugins: [{
        id: 'customCursor',
        afterDraw: (chart: any) => {
          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let y = chart.tooltip._active[0].element.y;
            let yAxis = chart.scales.y;
            let xAxis = chart.scales.x;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.right);
            ctx.lineTo(x, yAxis.bottom);
            ctx.moveTo(y, yAxis.right);
            ctx.lineTo(y, yAxis.top);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
            ctx.stroke();
            ctx.restore();


          }
        }
      }],
      type: 'line',
      data: {
        labels: dates,
        datasets: [{
          label: 'Stock Prices',
          data: prices,
          backgroundColor: backgroundColor1,
          borderColor: borderColor1,
          borderWidth: 1,
          fill: true,
          pointRadius: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false // Hides the legend
          }
        },
        scales: {
          x: {
            stacked: true,
            display: true,
            type: 'time',
            adapters: {
              date: {
                locale: enUS,
              }
            },
            title: {
              display: true,
              text: 'Date'
            },
            grid: {
              display: false, // Hides the grid lines on the x-axis
              color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
            }
          },
          y: {
            stacked:true,
            display: true,
            title: {
              display: true,
              text: 'Price'
            },
            grid: {
              display: false, // Hides the grid lines on the x-axis
              color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
            }
          }
        }
      }
    });
  }

  accumulateReturns(list: number[]): number[] {
    return list.map((value, index) => {
      return list.slice(0, index + 1).reduce((acc, curr) => acc * (1 + curr / 100), 1);
    });
  }


  //async renderChart(id: string): Promise<any> {
  //  let data = await this.stockDataService.sectorHistorical().toPromise();
  //  data = data.reverse();

  //  const dates = data.map((item: any) => item.date);
  //  const keysToExclude = ["date", "consumerCyclicalChangesPercentage"];

  //  const dictionaryOfLists = data.reduce((acc: { [key: string]: any[] }, item: any) => {
  //    Object.keys(item).forEach((key) => {
  //      if (!keysToExclude.includes(key)) {
  //        if (!acc[key]) {
  //          acc[key] = [];
  //        }
  //        acc[key].push(item[key]);
  //      }
  //    });
  //    return acc;
  //  }, {});

  //  const list1 = [1, 2, -0.5];
  //  const accumulatedDictionaryOfLists: { [key: string]: number[] } = {};

  //  const a = this.accumulateReturns(list1);

  //  //
  //  Object.keys(dictionaryOfLists).forEach((key) => {
  //    accumulatedDictionaryOfLists[key] = this.accumulateReturns(dictionaryOfLists[key]);
  //    console.log(accumulatedDictionaryOfLists[key], key);
  //  });
    


  //  var ctx = id; // jQuery instance

  //  const allDataValues = Object.values(accumulatedDictionaryOfLists).reduce((acc, curr) => acc.concat(curr), []);

  //  return new Chart(ctx, {
  //    plugins: [{
  //      id: 'customCursor',
  //      afterDraw: (chart: any) => {
  //        if (chart.tooltip?._active?.length) {
  //          let x = chart.tooltip._active[0].element.x;
  //          let y = chart.tooltip._active[0].element.y;
  //          let yAxis = chart.scales.y;
  //          let xAxis = chart.scales.x;
  //          let ctx = chart.ctx;
  //          ctx.save();
  //          ctx.beginPath();
  //          ctx.moveTo(x, yAxis.right);
  //          ctx.lineTo(x, yAxis.bottom);
  //          ctx.moveTo(y, yAxis.right);
  //          ctx.lineTo(y, yAxis.top);
  //          ctx.lineWidth = 1;
  //          ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
  //          ctx.stroke();
  //          ctx.restore();
  //        }
  //      }
  //    }],
  //    type: 'line',
  //    data: {
  //      labels: dates,
  //      datasets: Object.keys(accumulatedDictionaryOfLists).map((key, index) => ({
  //        label: key,
  //        data: accumulatedDictionaryOfLists[key].reverse(),
  //        backgroundColor: `backgroundColor${index + 1}`,
  //        borderColor: `borderColor${index + 1}`,
  //        borderWidth: 1,
  //        fill: false,
  //        pointRadius: 0,
  //        yAxisID:`y`
  //      }))
  //    },
  //    options: {
  //      responsive: true,
  //      maintainAspectRatio: true,
  //      plugins: {
  //        legend: {
  //          display: false // Hides the legend
  //        }
  //      },
  //      scales: {
  //        x: {
  //          stacked: true,
  //          display: true,
  //          type: 'time',
  //          adapters: {
  //            date: {
  //              locale: enUS,
  //            }
  //          },
  //          title: {
  //            display: true,
  //            text: 'Date'
  //          },
  //          grid: {
  //            display: false, // Hides the grid lines on the x-axis
  //            color: 'rgba(0, 0, 0, 0)' // Removes any remaining border
  //          }
  //        }
  //      }
  //    }
  //  });

  //}
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StockDataService } from '../../stock-data.service';
import { RouterModule } from '@angular/router'; // Import RouterModule
import { CommonModule } from '@angular/common';
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-news-detail',
  imports: [CommonModule, RouterModule],
  standalone: true,
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  newsItem: any = null;
  convertedHtml: SafeHtml | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private stockDataService: StockDataService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.destroy$),
      switchMap(params => {
        const publishedDate = params['publishedDate'];
        const headline = decodeURIComponent(params['headline']);
        return this.stockDataService.getNewsByDateAndHeadline(publishedDate, headline);
      })
    ).subscribe({
      next: (news) => {
        this.newsItem = news;
        if (news?.articleText) {
          const options = { async: false };
          const html = marked.parse(news.articleText, options) as string;
          this.convertedHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        }
        this.cdr.detectChanges();
      },
      error: (error) => console.error('Error fetching news:', error)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
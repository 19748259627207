import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { StockDataService } from '../stock-data.service';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';


@Component({
  selector: 'app-stock-search-dialog',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatIcon,
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: './stock-search-dialog.component.html',
  styleUrl: './stock-search-dialog.component.css'
})
export class StockSearchDialogComponent {
  searchQuery: string = '';
  filteredCandidates: any[] = [];
  candidates: any[] = []; // All candidates will be stored here
  stockCandidates: any[] = [];
  cryptoCandidates: any[] = [];
  forexCandidates: any[] = [];
  selectedStockData: any = null;
  isDropdownOpen: boolean = false;
  hoveredItem: any = null;
  // New fields for trade price, date, and side of trade
  tradePrice: number = 0;
  shares: number = 0;
  tradeDate: Date = new Date;
  tradeSide: string= ''; // 'buy' or 'sell'
  stock: any = null;

  constructor(
    private stockDataService: StockDataService,
    private dialogRef: MatDialogRef<StockSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    // Fetch the candidates
    this.stockCandidates = await this.stockDataService.stockList().toPromise();
    this.cryptoCandidates = await this.stockDataService.cryptoList().toPromise();
    this.forexCandidates = await this.stockDataService.forexList().toPromise();
    
    // Combine them into one list of candidates
    this.candidates = this.stockCandidates.concat(this.cryptoCandidates, this.forexCandidates);

    // Set initial values for the new fields if needed
    this.tradePrice = this.data?.tradePrice || 0;
    this.tradeDate = this.data?.tradeDate || new Date();
    this.tradeSide = this.data?.tradeSide || '';
  }

  onSearch(event: Event): void {
    this.isDropdownOpen=true;
    const inputValue = (event.target as HTMLInputElement)?.value || '';
    this.filteredCandidates = this.candidates
      .sort((a: any, b: any) => a.symbol.length - b.symbol.length)
      .filter((candidate: any) => {
        const inputUpper = inputValue.toUpperCase();
        return (
          (!candidate.symbol.includes('.') && candidate.symbol.toUpperCase().startsWith(inputUpper)) ||
          candidate.name?.toUpperCase().includes(inputUpper)
        );
      })
      .slice(0, 5);

    // Dynamically adjust the dropdown height based on the number of filtered candidates
    if (this.filteredCandidates) {
      const height = this.filteredCandidates.length * 3.1;
      const dpdwnElement = document.getElementById('dpdwn');
      if (dpdwnElement) {
        dpdwnElement.style.height = `${height}em`;
      }
    }
  }

  // Function to add the stock data to a variable
  addStockData(stock: any): void {
    // Add stock data to the selectedStockData variable
    this.stock = stock;
    this.isDropdownOpen = false;
    // Log the updated selected stock data
  }

  selectStock(): void {
    // Close the dialog and return the selected stock along with trade data
    this.dialogRef.close({
      symbol: this.stock.symbol,
      name: this.stock.name,
      shares: this.shares,
      price: this.stock.price,
      tradePrice: this.tradePrice,
      tradeDate: this.tradeDate,
      tradeSide: this.tradeSide
    });
  }

  onNoClick(): void {
    this.dialogRef.close(); // Close without returning any stock
  }
}

<ng-container style="width:77em;">
  <div style="padding-top:1em;"></div>
  <app-chart-strip></app-chart-strip>
  <app-stock-display *ngIf="others"  [stocks]="selectedDisplay.stocks"
    [content]="selectedDisplay.description"
    [title]="selectedDisplay.title"
    [img]="selectedDisplay.img"
    [seoTitle]="selectedDisplay.seoTitle"
    [seoDescription]="selectedDisplay.seoMetaDescription"
    [type]="type"
    [index]="selectedDisplay.index"
    [stockDetails]="stockDetails">
  </app-stock-display>
  <table *ngIf="!others">
    <thead>
      <tr>
        <th>Symbol</th>
        <th>Name</th>
        <th>Change</th>
        <th>Price</th>
        <th>Percentage Changed</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let active of actives; let i = index" [ngClass]="{ 'white-row': i % 2 === 0, 'gray-row': i % 2 !== 0 }">
        <td>{{ active.symbol }}</td>
        <td>{{ active.name }}</td>
        <td>{{ active.change }}</td>
        <td>{{ active.price | currency }}</td>
        <td>{{ active.changesPercentage/100 | percent }}</td>
      </tr>
    </tbody>
  </table>  
</ng-container>

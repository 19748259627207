<form class="loan-form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="ticker1">Ticker 1:</label>
    <input type="text" [(ngModel)]="ticker1Str" placeholder="Enter First Ticker" name="ticker1Str" id="ticker1">
  </div>

  <div class="form-group">
    <label for="ticker2">Ticker 2:</label>
    <input type="text" [(ngModel)]="ticker2Str" placeholder="Enter Second Ticker" name="ticker2Str" id="ticker2">
  </div>

  <div class="form-group">
    <label>From:</label>
    <input type="date" [(ngModel)]="fromDate" name="fromDate" id="fromDate">
    <label>To:</label>
    <input type="date" [(ngModel)]="toDate" name="toDate" id="toDate">
  </div>

  <button type="submit">Calculate</button>

  <div class="calculated-number-box" *ngIf="covariance !== undefined">
    <p>Covariance:</p>
    <div class="calculated-number">{{ covariance }}</div>
  </div>
</form>



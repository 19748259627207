import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
  
  
})
export class DataTableComponent {
  @Input() tableData: any[][] | undefined = []; // Input property to receive data
  @Input() width: string | undefined = '100%'; // Default width
  @Input() height: string | undefined = '70em'; // Default height

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }
}

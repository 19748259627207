import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { StockDataService } from '../stock-data.service';
import { registerables } from 'chart.js/auto';
import { enUS } from 'date-fns/locale';

Chart.register(...registerables);
 
@Component({
  selector: 'app-analyst-estimate',
  standalone: true,
  imports: [],
  templateUrl: './analyst-estimate.component.html',
  styleUrl: './analyst-estimate.component.css'
})
export class AnalystEstimateComponent {
  @Input() ticker: string ='';
  public chart: any;

  constructor(private stockDataService: StockDataService) { }

  async ngOnInit(): Promise<void> {
    const incomeData = await this.stockDataService.getIncomeStatement(this.ticker).toPromise();
    this.stockDataService.getAnalystE(this.ticker).subscribe((data: any) => {
      // Convert revenue data to millions
      let revenueDataInMillions = data.reduce((acc: any, obj: any) => {
        const year = obj.date.split('-')[0]; // Extract the year from the date string
        acc[year] = {
          ...obj,
          estimatedRevenueAvg: obj.estimatedRevenueAvg / 1000000,
          revenue: incomeData[0][year]?.revenue / 1000000,
          eps: incomeData[0][year]?.eps
        };
        return acc;
      }, {});

      revenueDataInMillions = Object.values(revenueDataInMillions).reverse();
      /*const revenuedatainmillions = data.map((entry: any) => ({
        ...entry,
        estimatedrevenueavg: entry.estimatedrevenueavg / 1000000,
      }));*/

      // Display only the first 10 points if there are at least 10 points
      const slicedData = revenueDataInMillions.length >= 10 ? revenueDataInMillions.slice(0, 10) : revenueDataInMillions;

      this.renderChart(slicedData, 'estimatedRevenueAvg', 'revenue', 'analystChart', 'Estimated Revenue Avg (MM)');
      this.renderChart(slicedData, 'estimatedEpsAvg', 'eps', 'analystChart2', 'estimated Eps Avg');
    });
  }

  private renderChart(data: any, key: string, key2: string, id: string, label: string): void {
    const labels = data.map((entry: any) => entry.date);
    const revenueData = data.map((entry: any) => entry[key]);
    const revenueData2 = data.map((entry: any) => entry[key2]);
    const isSmallScreen = window.innerWidth <= 1100;
    const fontSize = isSmallScreen ? 24 : 12; // Increase font size for small screens
    const colors = revenueData2.map((dataPoint: any, index: any) => {
      if (dataPoint > revenueData[index]) {
        return 'green'; // Set color to green if revenueData2 is greater
      } else {
        return 'red'; // Set color to red otherwise
      }
    });
    const ctx = id;
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: revenueData,
          fill: false,
          pointStyle: 'circle',
          pointRadius: 10, // Set the radius of the empty circles (adjust as needed)
          pointBackgroundColor: 'transparent', // Set points to be unfilled
          pointBorderColor: 'blue', // Set the color of the point border
          pointBorderWidth: 2, // Set the width of the point border (adjust as needed)
          borderWidth: 0,
        },
        {
          label: label,
          data: revenueData2,
          backgroundColor: colors,
          fill: false,
          pointStyle: 'circle',
          pointRadius: 10, // Set the radius of the empty circles (adjust as needed)
          pointBackgroundColor: colors, // Set points to be unfilled
          pointBorderColor: colors, // Set the color based on the condition
          pointBorderWidth: 2, // Set the width of the point border (adjust as needed)
          borderWidth: 0,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false // Hides the legend
          }
        },
        scales: {
          
          x: {
            type: 'time',
            adapters: {
              date: {
                locale: enUS,
              }
            },
            grid: {
              display: false, // Remove x-axis gridlines
            },
            ticks: {
              font: {
                size: fontSize
              }
            }
          },
          y: {
            grid: {
              display: false, // Remove y-axis gridlines
            },
            ticks: {
              font: {
                size: fontSize
              }
            }
          },
        },
        elements: {
          point: {
            radius: 0, // Set point radius (adjust as needed)
            borderWidth: 2, // Set point border width (adjust as needed)
            backgroundColor: 'white', // Set point background color
          },
        },
      }
    });
  }

}

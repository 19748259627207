!function (e) {
  "object" == typeof module && module.exports ? (e.default = e, module.exports = e) : "function" == typeof define && define.amd ? define(["highcharts"], function (t) {
    return e(t), e.Highcharts = t, e;
  }) : e("undefined" != typeof Highcharts ? Highcharts : void 0);
}(function (e) {
  "use strict";

  var t,
    n,
    o,
    i = e ? e._modules : {};
  t = "masters/indicators/ema.src.js", n = [i["Core/Globals.js"]], o = function (e) {
    return e;
  }, i.hasOwnProperty(t) || (i[t] = o.apply(null, n), "function" == typeof CustomEvent && e.win.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
    detail: {
      path: t,
      module: i[t]
    }
  })));
});
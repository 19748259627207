<!--<div style="height:15em; width:30em; position: relative;">-->
<!-- Chart canvas -->
<!--<canvas id='chart'>{{ chart3 }}</canvas>
</div>-->
<div class="home-container">
  <div style="width:50em; display:flex; justify-content:center; flex-direction:column; margin-top:2em;">
    <span style="width:40em;" class="main-title">
      Foster Financial <span style="color: blue;">Freedom</span> with &lambda; finance
    </span>
    <span class="description" style="width:30em;">
      At Lambda, we are on a mission to revolutionize financial strategies and methodologies. We believe
      the financial industry relies on outdated practices, and we are here to change that.
    </span>
  </div>
  <div style="display: flex; flex-direction:row; margin-top:3em;">
    <div class="llm-agents-typewriter typewriter">
      <span class="type" style="--n:75">Initializing LLM Agents...  </span>
    </div>

    <div class="waves">
      <div class="wave-wrapper">
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
      </div>
      <div class="wave-wrapper">
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
        <div class="wave-arrow"></div>
      </div>
    </div>
    <div class="quantum-typewriter typewriter">
      <span class="type" style="--n:75; animation-delay:5s;">Calibrating Quantum Financial Models...   </span>
    </div>
  </div>
</div>


<div class="news-section">
  <h2 style="font-size: 2em;">General News</h2>
  <app-news-box [amt]="12" [typ]="'general'"></app-news-box>
</div>
<div class="product-promo-container">
  <div class="product-promo">
    <a href="https://amzn.to/48IvDGc" target="_blank">
      <div class="promo-text">
        <strong>Beats Studio Pro - Wireless Cancelling Headphones</strong><br>
        Personalized Spatial Audio, Apple & Android Compatibility, Up to 40 Hours Battery Life
      </div>
      <img src="assets/beats.jpg" alt="Beats Studio Pro" class="promo-image">
    </a>
  </div>
  <div class="product-promo">
    <a href="https://amzn.to/3Arvk6d" target="_blank">
      <div class="promo-text">
        <strong>Amazon Fire TV Stick 4K</strong><br>
        AI-powered Fire TV Search, Wi-Fi 6, stream over 1.5 million movies & shows, free & live TV
      </div>
      <div class="idk">
        <img src="assets/firestick.jpg" alt="Amazon Fire TV Stick 4K" class="promo-image">
      </div>
    </a>
  </div>
</div>
<div style="padding-top: 1em;margin-top:1em; width: 78em;">
  <div class="market-sections">
    <div class="market-section">
      <span class="section-title">Top Gainers</span>
      <div *ngFor="let index of [0, 1, 2, 3, 4, 5]" class="market-item" [routerLink]="['/chart', topGainers[index]?.symbol, topGainers[index]?.exchangeShortName]">
        <img [src]="sanitizeImageUrl(gainersProfile[index]?.image)" alt="Company Logo">
        <div class="company-info">
          <span class="company-name">{{topGainers[index]?.name}}</span>
          <span class="company-symbol">{{topGainers[index]?.symbol}}</span>
        </div>
        <div class="price-info">
          <span class="price">{{topGainers[index]?.price | currency:'USD'}}</span>
          <span class="change" [ngClass]="{'positive': topGainers[index]?.changesPercentage > 0, 'negative': topGainers[index]?.changesPercentage < 0}">
            {{topGainers[index]?.changesPercentage/100 | percent}}
          </span>
        </div>
      </div>
    </div>

    <div class="market-section">
      <span class="section-title">Top Losers</span>
      <div *ngFor="let index of [0, 1, 2, 3, 4, 5]" class="market-item" [routerLink]="['/chart', topLosers[index]?.symbol, topLosers[index]?.exchangeShortName]">
        <img [src]="sanitizeImageUrl(losersProfile[index]?.image)" alt="Company Logo">
        <div class="company-info">
          <span class="company-name">{{topLosers[index]?.name}}</span>
          <span class="company-symbol">{{topLosers[index]?.symbol}}</span>
        </div>
        <div class="price-info">
          <span class="price">{{topLosers[index]?.price | currency:'USD'}}</span>
          <span class="change" [ngClass]="{'positive': topLosers[index]?.changesPercentage > 0, 'negative': topLosers[index]?.changesPercentage < 0}">
            {{topLosers[index]?.changesPercentage/100 | percent}}
          </span>
        </div>
      </div>
    </div>

    <div class="market-section">
      <span class="section-title">Most Actives</span>
      <div *ngFor="let index of [0, 1, 2, 3, 4, 5]" class="market-item" [routerLink]="['/chart', topActives[index]?.symbol, topActives[index]?.exchangeShortName]">
        <img [src]="sanitizeImageUrl(activesProfile[index]?.image)" alt="Company Logo">
        <div class="company-info">
          <span class="company-name">{{topActives[index]?.name}}</span>
          <span class="company-symbol">{{topActives[index]?.symbol}}</span>
        </div>
        <div class="price-info">
          <span class="price">{{topActives[index]?.price | currency:'USD'}}</span>
          <span class="change" [ngClass]="{'positive': topActives[index]?.changesPercentage > 0, 'negative': topActives[index]?.changesPercentage < 0}">
            {{topActives[index]?.changesPercentage/100 | percent}}
          </span>
        </div>
      </div>      
    </div>
  </div>
</div>
<div class="product-promo-container">
  <div class="product-promo">
    <a href="https://amzn.to/3O0laN3" target="_blank">
      <div class="promo-text">
        <strong>MERACH Vibration Plate Exercise Machine</strong><br>
        Whole-body workout with Bluetooth & silicone pedal - perfect for lymphatic drainage and weight loss!
      </div>
      <img src="assets/pilates.jpg" alt="MERACH Vibration Plate" class="promo-image">
    </a>
  </div>
  
  <div class="product-promo">
    <a href="https://amzn.to/3AssVbs" target="_blank">
      <div class="promo-text">
        <strong>The New World Video Game</strong><br>
        Dive into a rich, open-world experience with immersive gameplay, exploration, and crafting in this action-packed MMORPG.
      </div>
      <img src="assets/newWorld.jpg" alt="New World Video Game" class="promo-image">
    </a>
  </div>
</div>
<div class="news-section">
  <h2 style="font-size: 3em;">Stock News</h2>
  <app-news-box [amt]="12"></app-news-box>
</div>
<div class="news-section">
  <h2 style="font-size: 3em;">Crypto News</h2>
  <app-news-box [amt]="12" [typ]="'CRYPTO'"></app-news-box>
</div>
<div class="news-section">
  <h2 style="font-size: 3em;">Forex News</h2>
  <app-news-box [amt]="12" [typ]="'FOREX'"></app-news-box>
</div>


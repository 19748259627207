<div style="padding-top:2em; padding-left:1em;" class="date-selector">
  <label>From:</label>
  <input type="date" [(ngModel)]="fromDate">
  <label>To:</label>
  <input type="date" [(ngModel)]="toDate">
  <button (click)="filterEvents()">Apply</button>
</div>

<div style="padding-top: 1em; padding-left: 1em;" class="country-selector">
  <label>Filter by Country:</label>
  <select [(ngModel)]="selectedCountry" (change)="filterEvents()">
    <option value="">All</option>
    <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
  </select>
</div>

<div class="economic-calendar">
  <table style="width:74em; padding-left:1em;">
    <thead>
      <tr>
        <th>Date/Time</th>
        <th>Event</th>
        <th>Actual</th>
        <th>Estimate</th>
        <th>Previous</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of filteredEvents.slice(0, eventsPerPage)">
        <td>{{ event.date | date: 'medium' }}</td>
        <td>{{ event.event }}</td>
        <td>{{ event.actual }}</td>
        <td>{{ event.estimate }}</td>
        <td>{{ event.previous }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="filteredEvents.length == 0">
    <ngx-skeleton-loader count="20" appearance="line"></ngx-skeleton-loader>
  </div>
  <div *ngIf="filteredEvents.length != eventsPerPage">
    <button (click)="showMoreEvents()">Show More</button>
  </div>
</div>


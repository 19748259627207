<table *ngIf="tableData" id="regressionResults"
       [ngStyle]="{ width: width, height: height }"
       style="font-size: 0.75em; border-collapse: collapse; table-layout: fixed;">
  <thead>
    <!--<tr>
      <th *ngFor="let header of tableData[0].slice(1)"
          style="font-weight: bold; border: 0.1em solid #ddd; padding: 0.5em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        {{ header }}
      </th>
    </tr>-->
  </thead>
  <tbody>
    <tr *ngFor="let row of tableData.slice(1); let i = index"
        [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
      <th style="font-weight: bold; border: 0.1em solid #ddd; padding: 0.5em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        {{ row[0] }}
      </th>
      <td *ngFor="let cell of row.slice(1)"
          style="border: 0.1em solid #ddd; padding: 0.5em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        <ng-container *ngIf="isNumber(cell); else textCell">
          {{ cell | number: '1.0-2' }}
        </ng-container>
        <ng-template #textCell>{{ cell }}</ng-template>
      </td>
    </tr>
  </tbody>
</table>

<div class="news-list-container" *ngIf="newsList.length; else noNews">
  <h1>News of Type: {{ type }}</h1>

  <ul class="news-list">
    <li *ngFor="let news of newsList" class="news-item">
      <div [routerLink]="news.routerLink">
        <div style="display: flex; flex-direction: row;">
          <img [src]="news.image" alt="News Image">
          <div style="padding-left: 1em;">
            <h3>{{ news.headline }}</h3>
            <p>{{ news.articleText | slice: 0:200 }}...</p>
            <span *ngIf="news.symbol">Symbol: {{ news.symbol }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <div class="pagination">
    <button 
      [routerLink]="[]" 
      [queryParams]="{ page: currentPage - 1 }" 
      [disabled]="currentPage <= 1">Previous</button>

    <span>Page {{ currentPage }}</span>

    <button 
      [routerLink]="[]" 
      [queryParams]="{ page: currentPage + 1 }" 
      [disabled]="newsList.length < itemsPerPage">Next</button>
  </div>
</div>

<ng-template #noNews>
  <p>No news available for this type.</p>
</ng-template>

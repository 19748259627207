<div style="padding-top:5em;">
  <h2>Dividend Discount Model Calculator</h2>
  <form [formGroup]="ddmForm" (ngSubmit)="onSubmit()">
    <label>
      Current Dividend:
      <input formControlName="currentDividend" type="number" step="0.01" />
    </label>
    <label>
      Discount Rate:
      <input formControlName="discountRate" type="number" step="0.01" />
    </label>

    <div>
      <label>
        <input type="radio" formControlName="ddmType" value="regular" />
        Regular DDM
      </label>
      <label>
        <input type="radio" formControlName="ddmType" value="twoStage" />
        Two-Stage DDM
      </label>
    </div>

    <div *ngIf="ddmForm.get('ddmType')?.value === 'regular'">
      <label>
        Growth Rate:
        <input formControlName="growthRate" type="number" step="0.01" />
      </label>
    </div>

    <div *ngIf="ddmForm.get('ddmType')?.value === 'twoStage'">
      <label>
        High Growth Period:
        <input formControlName="highGrowthPeriod" type="number" />
      </label>
      <label>
        High Growth Rate:
        <input formControlName="highGrowthRate" type="number" step="0.01" />
      </label>
      <label>
        Stable Growth Rate:
        <input formControlName="stableGrowthRate" type="number" step="0.01" />
      </label>
    </div>

    <button type="submit">Calculate</button>
  </form>

  <div *ngIf="result !== null">
    <h3>Calculated Stock Price: {{ result | number:'1.2-2' }}</h3>
  </div>
</div>

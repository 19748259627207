<div class="container">
  <div class="news-list-container">
    <div class="search-container">
      <input type="text"
             [(ngModel)]="searchQuery"
             (input)="onSearch()"
             placeholder="Search news by title or symbol" />
    </div>

    <!-- Stock News Section -->
    <div *ngIf="filteredStockNews.length > 0">
      <h2>Stock News</h2>
      <ul class="news-list">
        <li *ngFor="let newsItem of filteredStockNews" class="news-item">
          <div (click)="redirectToNews(newsItem.url)">
            <div style="display: flex; flex-direction: row;">
              <img [src]="newsItem.image" alt="News Image">
              <div style="padding-left: 1em;">
                <h3>{{ newsItem.title }}</h3>
                <p>{{ newsItem.text | slice:0:200 }}...</p> <!-- Display a part of the description -->
                <span *ngIf="newsItem.symbol">Symbol: {{ newsItem.symbol }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Crypto News Section -->
    <div *ngIf="filteredCryptoNews.length > 0">
      <h2>Crypto News</h2>
      <ul class="news-list">
        <li *ngFor="let newsItem of filteredCryptoNews" class="news-item">
          <div (click)="redirectToNews(newsItem.url)">
            <div style="display: flex; flex-direction: row;">
              <img [src]="newsItem.image" alt="News Image">
              <div style="padding-left: 1em;">
                <h3>{{ newsItem.title }}</h3>
                <p>{{ newsItem.text | slice:0:200 }}...</p>
                <span *ngIf="newsItem.symbol">Symbol: {{ newsItem.symbol }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Forex News Section -->
    <div *ngIf="filteredForexNews.length > 0">
      <h2>Forex News</h2>
      <ul class="news-list">
        <li *ngFor="let newsItem of filteredForexNews" class="news-item">
          <div (click)="redirectToNews(newsItem.url)">
            <div style="display: flex; flex-direction: row;">
              <img [src]="newsItem.image" alt="News Image">
              <div style="padding-left: 1em;">
                <h3>{{ newsItem.title }}</h3>
                <p>{{ newsItem.text | slice:0:200 }}...</p>
                <span *ngIf="newsItem.symbol">Symbol: {{ newsItem.symbol }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- General News Section -->
    <div *ngIf="filteredGeneralNews.length > 0">
      <h2>General News</h2>
      <ul class="news-list">
        <li *ngFor="let newsItem of filteredGeneralNews" class="news-item">
          <div (click)="redirectToNews(newsItem.url)">
            <div style="display: flex; flex-direction: row;">
              <img [src]="newsItem.image" alt="News Image">
              <div style="padding-left: 1em;">
                <h3>{{ newsItem.title }}</h3>
                <p>{{ newsItem.text | slice:0:200 }}...</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Skeleton loader in case data is loading -->
    <div *ngIf="newsList.length == 0">
      <ngx-skeleton-loader count="20" appearance="line"></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div class="news-container">
  <div class="news-item" *ngFor="let news of newsData" (click)="redirectToNews(news.url)">
    <img [src]="news.image" alt="News Image">
    <div class="news-content">
      <h3>{{news.title}}</h3>
      <p>{{news.text}}</p>
    </div>
  </div> 
  <div *ngIf="newsData.length === 0">
    <ngx-skeleton-loader class="news-box" count="6" appearance=""></ngx-skeleton-loader>
  </div>
</div>

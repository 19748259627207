import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Chart, registerables } from 'chart.js/auto';
import { StockDataService } from '../app/stock-data.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import {  BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChartStripComponent } from '../app/chart-strip/chart-strip.component';
import * as Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';
import TreemapModule from 'highcharts/modules/treemap';
import HighchartsMore from 'highcharts/highcharts-more';
import { HighchartsChartModule } from 'highcharts-angular';
import Accessibility from 'highcharts/modules/accessibility';
import heatmap from 'highcharts/modules/heatmap';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

Chart.register(...registerables);
//Chart.register(TreemapController, TreemapElement);

//if (typeof Highcharts !== 'undefined') {
//  HighchartsTreemap(Highcharts);
//  HighchartsMore(Highcharts);
//  Accessibility(Highcharts);
//}

if (typeof window !== 'undefined') {
  HighchartsTreemap(Highcharts);
  HighchartsMore(Highcharts);
  Accessibility(Highcharts);
  heatmap(Highcharts);
}


@Component({
  selector: 'app-heat-map',
  standalone: true,
  imports: [RouterModule, ChartStripComponent, HighchartsChartModule, CommonModule],
  templateUrl: './heat-map.component.html',
  styleUrl: './heat-map.component.css'
})
export class HeatMapComponent {
  constructor(private stockDataService: StockDataService, 
    private route: ActivatedRoute, 
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta) { 

      this.isBrowser = isPlatformBrowser(this.platformId);
      if (this.isBrowser) {
        this.Highcharts = Highcharts; // Assign only if running in the browser
      } else {
        this.Highcharts = undefined; // Explicitly set to undefined if not in the browser
      }
  }

  public stockHeatMap: any
  sectors: string[] = [];
  constituents: any[] = [];
  timePeriod: string = '1D';
  index: string = 'sp500';
  seriesData: any = [];
  drilldownSeries: any = [];
  Highcharts: typeof Highcharts | undefined; // Initially undefined
  chartOptions: any = {};
  refreshInterval: any;
  isBrowser: boolean;
  indexNames: { [key: string]: string } = {
    'sp500': 'S&P 500',
    'ndq': 'Nasdaq 100',
    'dow': 'Dow Jones Industrial Average'
  };

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (params: any) => {
      this.index = params['params']['index'];
      const indexName = this.indexNames[this.index];

      if (indexName) {
        const title = `${indexName} Heat Map - Performance Overview`;
        const description = `Explore the heat map for the ${indexName}, showing real-time performance of its components. This tool helps analyze the strength of individual stocks in the index, providing insights for investors.`;

        // Set the dynamic title
        this.titleService.setTitle(title);

        // Set the dynamic meta description
        this.metaService.updateTag({
          name: 'description',
          content: description
        });
      }

      if (this.stockHeatMap) {
        this.stockHeatMap.destroy();
        this.stockHeatMap = undefined;
      }
      await this.loadHeatmapData();

    });
    
  }

  ngAfterViewInit() {
    // Set up interval to refresh every minute (60000 ms)
    if (isPlatformBrowser(this.platformId)) {
      this.refreshInterval = setInterval(async () => {
        await this.loadHeatmapData();
      }, 300000); // 1 minute
    }
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  async loadHeatmapData() {
    if (this.index === 'sp500') {
      this.stockDataService.pollHeatmap('sp500');
      await this.getConstituents(this.stockDataService.heatMapSp500$);
      this.createStockHeatMap();
    } else if (this.index === 'ndq') {
      this.stockDataService.pollHeatmap('ndq');
      await this.getConstituents(this.stockDataService.heatMapNdq$);
      this.createStockHeatMap();
    } else {
      this.stockDataService.pollHeatmap('dow');
      await this.getConstituents(this.stockDataService.heatMapDow$);
      this.createStockHeatMap();
    }
  }

  changeTimePeriod(period: string): void {
    this.timePeriod = period;
    this.stockHeatMap.destroy();
    this.stockHeatMap = undefined;
    this.createStockHeatMap();
    // You can add additional logic here if needed
  }

  async getConstituents(index$: BehaviorSubject<any>) {

    try {
      index$.subscribe((data) => {
        if (data!= null) {
          this.constituents = Object.values(data);
          this.constituents.shift();

          const sectorsMap = new Map<string, { id: string, name: string }>();
          const subSectorsMap = new Map<string, { id: string, name: string, parent: string }>();
          this.constituents.forEach(constituent => {
            const sector = constituent.sector;
            const subSector = constituent.subSector;

            if (sector && !sectorsMap.has(sector)) {
              sectorsMap.set(sector, { id: sector, name: sector });
            }

            if (sector && subSector && sector!== subSector && !subSectorsMap.has(subSector)) {
              subSectorsMap.set(subSector, { id: subSector, name: subSector, parent: sector });
            }

            // Add the value property to the constituent based on marketCap
            constituent.value = constituent.marketCap/1000000000;
            constituent.parent = constituent.subSector;
            constituent.id = constituent.name;
            constituent.colorValue = constituent[this.timePeriod];
          });

          const sectors = Array.from(sectorsMap.values());
          const subSectors = Array.from(subSectorsMap.values());

          // Combine the three arrays
          this.constituents = [
            ...sectors,
            ...subSectors,
            ...this.constituents
          ];

        }
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Helper function to split an array into chunks
  chunkArray(array: any[], chunkSize: number): any[][] {
    const result: any[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

weightedAverage(array: any[], weightProperty: string, valueProperty: string): number {
  // Ensure the array is not empty
  if (array.length === 0) {
    return 0;
  }

  // Initialize variables to store the sum of weights and the weighted sum of values
  let totalWeight = 0;
  let weightedSum = 0;

  // Iterate through the array
  array.forEach(element => {
    // Check if the weight and value properties exist in the current element
    if (element.hasOwnProperty(weightProperty) && element.hasOwnProperty(valueProperty) && element.hasOwnProperty('price')) {
      const weight = element[weightProperty];
      const value = element[valueProperty];
      const price = element['price'];

      // Update the total weight and weighted sum
      totalWeight += weight;
      weightedSum += weight * (value);
    }
  });

  // Calculate the weighted average
  const weightedAverage = totalWeight !== 0 ? weightedSum / totalWeight : 0;

  return weightedAverage;
}


  createStockHeatMap() {
    if (this.isBrowser) {
      const ctx = 'stockHeatMap';
      // Get the computed style of the stock-heat-map class
      let fontSize = '1em';
      const stockHeatMapElement = document.querySelector('.stock-heat-map');
      if (stockHeatMapElement) {
        const computedStyle = window.getComputedStyle(stockHeatMapElement);
        fontSize = computedStyle.fontSize; // Get the font size
      }
  
  
      this.constituents = this.constituents.map(constituent => {
        constituent.colorValue = constituent[this.timePeriod];
        return constituent;
      });
  
      this.chartOptions = {
        colorAxis: {
          minColor: '#FF6347', // Dark red for negative values
          maxColor: '#32CD32', // Dark green for positive values
          min: -3,
          max: 3,
          stops: [
            [0, '#FF6347'],    // Start color for the color axis (min)
            [0.5, '#FFFFFF'],  // Middle color (white)
            [1, '#32CD32']     // End color for the color axis (max)
          ]
        },
        series: [{
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          allowDrillToNode: true,
          animationLimit: 1000,
          dataLabels: {
            enabled: false,
            style: {
              fontSize: fontSize // Set font size from the CSS class
          }
          },
          levels: [{
            level: 1,
            dataLabels: {
              enabled: true // Hide data labels for level 1 options
            },
            borderWidth: 3,
            levelIsConstant: false,
            colorAxis: {
              minColor: '#FF6347', // Dark red for negative values
              maxColor: '#32CD32', // Dark green for positive values
            },
          }, {
            level: 2,
            dataLabels: {
              enabled: false // Hide data labels for level 2 options
            },
            levelIsConstant: false,
            colorAxis: {
              minColor: '#FF6347', // Dark red for negative values
              maxColor: '#32CD32', // Dark green for positive values
            },
          }],
          accessibility: {
            exposeAsGroupOnly: true
          },
          data: this.constituents
        }],
        //drilldown: {
        //  series: this.drilldownSeries
        //},,
        tooltip: {
          pointFormat: '<b>{point.name}</b>: Market Cap(BB) - {point.value}, {point.colorValue}%'
        },
        title: {
          text: `${this.indexNames[this.index]} Heatmap with Drill-Down by Sector and Industry`
        }
      };
      this.stockHeatMap= Highcharts.chart('stockHeatMap', this.chartOptions);  
    }
    
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { StockDataService } from '../stock-data.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ainews',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './ainews.component.html',
  styleUrls: ['./ainews.component.css']
})
export class AInewsComponent implements OnInit, OnDestroy {
  newsList: any[] = [];
  type: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 30; // Server-side expects this
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute, 
    private stockDataService: StockDataService,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.type = params['type'];
        this.fetchNews();
      });

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(queryParams => {
        this.currentPage = queryParams['page'] || 1;
        this.updateRelLinks();
        this.fetchNews();
      });
  }

  fetchNews(): void {
    this.stockDataService
      .getAINews(undefined, this.type, (this.currentPage - 1) * this.itemsPerPage)
      .pipe(takeUntil(this.destroy$))
      .subscribe(news => {
        this.newsList = news.map((item: any) => ({
          ...item,
          routerLink: ['/newsDetail', item.publishedDate, encodeURIComponent(item.headline)]
        }));
      });
  }

  updateRelLinks(): void {
    const baseUrl = `https://www.lambdafin.com/news/${this.type}`;
    const prevUrl = `${baseUrl}?page=${this.currentPage - 1}`;
    const nextUrl = `${baseUrl}?page=${this.currentPage + 1}`;

    // Remove existing tags
    this.meta.removeTag("rel='prev'");
    this.meta.removeTag("rel='next'");

    // Add "prev" link if applicable
    if (this.currentPage > 1) {
      this.meta.addTag({ rel: 'prev', href: prevUrl });
    }

    // Add "next" link if applicable
    if (this.newsList.length === this.itemsPerPage) { // Only if there's more news to display
      this.meta.addTag({ rel: 'next', href: nextUrl });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { RouterOutlet, RouterModule, ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationError, Event } from '@angular/router';
import { StockChartComponent } from './stock-chart/stock-chart.component'
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { TickerTapeComponent } from './ticker-tape/ticker-tape.component';
import { HomeComponent } from './home/home.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, StockChartComponent, NavigationBarComponent, RouterModule, TickerTapeComponent, HomeComponent],
  templateUrl: 'app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  title = 'homes';
}




import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StockDataService } from '../stock-data.service';

@Component({
  selector: 'app-ticker-tape',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './ticker-tape.component.html',
  styleUrl: './ticker-tape.component.css'
})
export class TickerTapeComponent {
  constructor(private stockDataService: StockDataService) { }

  tickerItems: any[] = []; // Your ticker content
  tickerDuration = '120s'; // Duration of the scrolling animation
  ngOnInit() {
    this.loadMostActives();
  }

  loadMostActives() {
    this.stockDataService.mostActive().subscribe(
      (data: any[]) => {
        this.tickerItems = data;
      },
      (error: any) => {
        console.error('Error fetching economic events:', error);
      }
    );
  }
}
